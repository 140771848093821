import React, { useState } from "react";
import GoToTop from "../../../../GoToTop";
import Sidebar from "../../Sidebar";
import { useNavigate } from "react-router-dom";
import { db } from "../../../../firebase";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import Swal from "sweetalert2";
import { serverTimestamp } from "firebase/firestore";
import { Editor } from "@tinymce/tinymce-react";

function EditService() {
  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  const [subtitle, setsubTitle] = useState("");
  const [category, setCategory] = useState("");
  const [tags, setTags] = useState("");
  const [demo, setDemo] = useState("");
  const [price, setPrice] = useState("");
  const [compare_at_price, setCompare_at_price] = useState("");
  const [cgst, setCgst] = useState("");
  const [sgst, setSgst] = useState("");
  const [forex_charge, setForex] = useState("");
  const [brief, setBrief] = useState("");
  const [description, setDescription] = useState("");
  const [loader, setLoader] = useState(false);
  const [imageFile, setImageFile] = useState(null);
  const [imagePreview, setImagePreview] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoader(true);
    try {
      // Add the service details
      const docRef = await db.collection("OurProducts_M").add({
        title,
        subtitle,
        category,
        tags,
        demo,
        price: parseFloat(price) || 0,
        compare_at_price: parseFloat(compare_at_price) || 0,
        cgst: parseFloat(cgst) || 0,
        sgst: parseFloat(sgst) || 0,
        forex_charge: parseFloat(forex_charge) || 0,
        brief,
        description,
        createdOn: serverTimestamp(),
      });

      // Handle image upload if an image is selected
      if (imageFile) {
        const storage = getStorage();
        const storageRef = ref(storage, `images/${docRef.id}`);
        await uploadBytes(storageRef, imageFile);
        const url = await getDownloadURL(storageRef);
        await db.collection("OurProducts_M").doc(docRef.id).update({ url });
      }

      setLoader(false);
      Swal.fire("Success", "Service added successfully!", "success");
      navigate("/manage_services");
      // Reset the form fields
      setTitle("");
      setsubTitle("");
      setCategory("");
      setDemo("");
      setTags("");
      setPrice("");
      setCompare_at_price("");
      setCgst("");
      setSgst("");
      setForex("");
      setBrief("");
      setDescription("");
      setImageFile(null);
    } catch (error) {
      console.log("Error submitting applied project:", error);
      setLoader(false);
      Swal.fire("Error", "Failed to add service.", "error");
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImageFile(file);
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <>
      <GoToTop />
      <div className="dashboard_content_wrapper">
        <div className="dashboard dashboard_wrapper pr30 pr0-xl">
          <Sidebar />
          <form className="form-style1" onSubmit={handleSubmit}>
            <div className="dashboard__main pl0-md">
              <div className="dashboard__content hover-bgc-color">
                <div className="row pb40">
                  <div className="col-lg-9">
                    <div className="dashboard_title_area">
                      <h2>Edit Services</h2>
                      <p className="text">Update your service here...</p>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="text-lg-end">
                      {/* <a href="" className="ud-btn btn-dark">
                        Save & Publish
                        <i className="fal fa-arrow-right-long"></i>
                      </a> */}
                      <button
                        type="submit" disabled
                        className="ud-btn btn-dark"
                        // disabled={loader}
                      >
                        {loader ? "Submitting..." : "Save & Continue"}
                        <i className="fal fa-arrow-right-long"></i>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-12">
                    <div className="ps-widget bgc-white bdrs4 p30 mb30 overflow-hidden position-relative">
                      <div className="bdrb1 pb15 mb25">
                        <h5 className="list-title">Basic Information</h5>
                      </div>
                      <div className="col-xl-12">
                        <div className="row">
                          <div className="col-sm-5">
                            <div className="mb20">
                              <label className="heading-color ff-heading fw500 mb10">
                                Title
                              </label>
                              <input
                                value={title}
                                required
                                onChange={(e) => setTitle(e.target.value)}
                                type="text"
                                className="form-control"
                              />
                            </div>
                          </div>

                          <div className="col-sm-7">
                            <div className="mb20">
                              <label className="heading-color ff-heading fw500 mb10">
                                Sub Title
                              </label>
                              <input
                                value={subtitle}
                                required
                                onChange={(e) => setsubTitle(e.target.value)}
                                type="text"
                                className="form-control"
                              />
                            </div>
                          </div>

                          <div className="col-sm-7">
                            <div className="mb20">
                              <label className="heading-color ff-heading fw500 mb10">
                                Demo Link
                              </label>
                              <input
                                value={demo}
                                onChange={(e) => setDemo(e.target.value)}
                                type="text"
                                className="form-control"
                              />
                            </div>
                          </div>

                          <div className="col-sm-12">
                            <div className="mb20">
                              <label className="heading-color ff-heading fw500 mb10">
                                Brief (within 100 words)
                              </label>
                              <textarea
                                id="mytextarea"
                                value={brief}
                                required
                                onChange={(e) => setBrief(e.target.value)}
                                style={{ height: "120px" }}
                                className="form-control"
                              ></textarea>
                            </div>
                          </div>

                          <div className="col-sm-12">
                            <div className="mb20">
                              <label className="heading-color ff-heading fw500 mb10">
                                Description
                              </label>

                              <Editor
                                value={description}
                                onEditorChange={(content) =>
                                  setDescription(content)
                                }
                                apiKey="7054dlxok74vfo1ir3k23g8ei3fdlc6en91lol1n3mgsvivr"
                                init={{
                                  plugins: "link image media",
                                  toolbar:
                                    "undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat",
                                  tinycomments_mode: "embedded",
                                  tinycomments_author: "Author name",
                                  mergetags_list: [
                                    {
                                      value: "First.Name",
                                      title: "First Name",
                                    },
                                    { value: "Email", title: "Email" },
                                  ],
                                }}
                              />
                            </div>
                          </div>

                          <div className="col-sm-5">
                            <div className="mb20">
                              <label className="heading-color ff-heading fw500 mb10">
                                Category
                              </label>
                              <input
                                type="text"
                                required
                                value={category}
                                onChange={(e) => setCategory(e.target.value)}
                                className="form-control"
                              />
                            </div>
                          </div>

                          <div className="col-sm-7">
                            <div className="mb20">
                              <label className="heading-color ff-heading fw500 mb10">
                                Tags
                              </label>
                              <input
                                required
                                value={tags}
                                onChange={(e) => setTags(e.target.value)}
                                type="text"
                                className="form-control"
                              />
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="mb20">
                              <label className="heading-color ff-heading fw500 mb10">
                                Price
                              </label>
                              <input
                                required
                                min="1"
                                step="1"
                                value={price}
                                onChange={(e) => setPrice(e.target.value)}
                                type="number"
                                className="form-control"
                              />
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="mb20">
                              <label className="heading-color ff-heading fw500 mb10">
                                Compare at Price
                              </label>
                              <input
                                required
                                value={compare_at_price}
                                onChange={(e) =>
                                  setCompare_at_price(e.target.value)
                                }
                                type="number"
                                className="form-control"
                              />
                            </div>
                          </div>

                          <div className="col-sm-4">
                            <div className="mb20">
                              <label className="heading-color ff-heading fw500 mb10">
                                CGST
                              </label>
                              <input
                                value={cgst}
                                onChange={(e) => setCgst(e.target.value)}
                                type="number"
                                placeholder="9%"
                                className="form-control"
                              />
                            </div>
                          </div>

                          <div className="col-sm-4">
                            <div className="mb20">
                              <label className="heading-color ff-heading fw500 mb10">
                                SGST/UTGST
                              </label>
                              <input
                                value={sgst}
                                onChange={(e) => setSgst(e.target.value)}
                                type="number"
                                placeholder="9%"
                                className="form-control"
                              />
                            </div>
                          </div>

                          <div className="col-sm-4">
                            <div className="mb20">
                              <label className="heading-color ff-heading fw500 mb10">
                                Forex Charge
                              </label>
                              <input
                                type="number"
                                value={forex_charge}
                                onChange={(e) => setForex(e.target.value)}
                                className="form-control"
                              />
                            </div>
                          </div>

                          {/* <div className="col-md-12">
                            <div className="text-start">
                              <button
                                type="submit"
                                className="ud-btn btn-thm"
                                disabled={loader}
                              >
                                {loader ? "Submitting..." : "Save & Continue"}
                                <i className="fal fa-arrow-right-long"></i>
                              </button>
                            </div>
                          </div> */}
                        </div>
                      </div>
                    </div>

                    <div className="ps-widget bgc-white bdrs12 p30 mb30 overflow-hidden position-relative">
                      <div className="bdrb1 pb15 mb30">
                        <h5 className="list-title">Image</h5>
                      </div>
                      <div className="col-xl-9">
                        <div className="d-flex mb30">
                          {imagePreview && (
                            <div className="gallery-item me-3 bdrs4 overflow-hidden position-relative">
                              <img
                                className="w-100"
                                src={imagePreview}
                                alt="Selected"
                              />
                              <div className="del-edit">
                                <div className="d-flex justify-content-center">
                                  <button
                                    type="button"
                                    className="icon me-2"
                                    onClick={() => setImagePreview("")}
                                  >
                                    <span className="flaticon-pencil"></span>
                                  </button>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                        {!imagePreview && (
                          <p className="text">
                            Max file size is 1MB, Minimum dimension: 330x300 And
                            Suitable files are .jpg & .png
                          </p>
                        )}
                        <input
                          required
                          type="file"
                          onChange={handleImageChange}
                          className="form-control mb-2"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default EditService;
