import React from "react";
import GoToTop from "../GoToTop";

function Services() {
  return (
    <>
      <GoToTop />
      <div class="body_content" style={{ marginTop: "8vh" }}>
        <section class="categories_list_section overflow-hidden">
          <div class="container">
            <div class="row">
              <div class="col-lg-12">
                <div class="listings_category_nav_list_menu">
                  <ul class="mb0 d-flex ps-0">
                    <li>
                      <a>All Categories</a>
                    </li>
                    <li>
                      <a>Development & IT</a>
                    </li>
                    <li>
                      <a>Design & Creative</a>
                    </li>
                    <li>
                      <a>Sales & Marketing</a>
                    </li>
                    <li>
                      <a>Writing & translation</a>
                    </li>
                    <li>
                      <a>Admin & Customer Support</a>
                    </li>
                    <li>
                      <a>Engineerting & Architecture</a>
                    </li>
                    <li>
                      <a>Finance & Accounting</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="breadcumb-section pt-0 my-3">
          <div class="cta-service-v1 cta-banner mx-auto maxw1600 pt120 pb120 bdrs16 position-relative overflow-hidden d-flex align-items-center mx20-lg px30-lg">
            <img
              class="left-top-img wow zoomIn"
              src="images/vector-img/left-top.png"
              alt=""
            />
            <img
              class="right-bottom-img wow zoomIn"
              src="images/vector-img/right-bottom.png"
              alt=""
            />
            <img
              class="service-v1-vector bounce-y d-none d-lg-block"
              src="images/vector-img/vector-service-v1.png"
              alt=""
            />
            <div class="container">
              <div class="row wow fadeInUp">
                <div class="col-xl-5">
                  <div class="position-relative"></div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!-- Listings All Lists --> */}
        <section class="pt30 pb90">
          <div class="container">
            <div class="row">
              <div class="col-sm-6 col-xl-3">
                <div class="listing-style1">
                  <div class="list-thumb">
                    <img
                      class="w-100"
                      src="images/listings/Services/design1.png"
                      alt=""
                    />
                  </div>
                  <div class="list-content">
                    <p class="list-text body-color fz14 mb-1">
                      Responsive Web Design
                    </p>
                    <h5 class="list-title">
                      <a>Creating User-Friendly Websites Across Devices</a>
                    </h5>
                    <div class="review-meta d-flex align-items-center">
                      <i class="fas fa-star fz10 review-color me-2"></i>
                      <p class="mb-0 body-color fz14">
                        <span class="dark-color me-2">4.5</span>
                      </p>
                    </div>
                    <hr class="my-2" />
                    <div class="list-meta d-flex justify-content-between align-items-center mt15">
                      <div class="budget">
                        <p class="mb-0 body-color">
                          Starting at
                          <span class="fz17 fw500 dark-color ms-1">
                            ₹25,000
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-sm-6 col-xl-3">
                <div class="listing-style1">
                  <div class="list-thumb">
                    <img
                      class="w-100"
                      src="images/listings/Services/web1.png"
                      alt=""
                    />
                  </div>
                  <div class="list-content">
                    <p class="list-text body-color fz14 mb-1">
                      Front-End Development
                    </p>
                    <h5 class="list-title line-clamp2">
                      <a>
                        Building Engaging Interfaces with HTML, CSS, and
                        JavaScript
                      </a>
                    </h5>
                    <div class="review-meta d-flex align-items-center">
                      <i class="fas fa-star fz10 review-color me-2"></i>
                      <p class="mb-0 body-color fz14">
                        <span class="dark-color me-2">4.6</span>
                      </p>
                    </div>
                    <hr class="my-2" />
                    <div class="list-meta d-flex justify-content-between align-items-center mt15">
                      <div class="budget">
                        <p class="mb-0 body-color">
                          Starting at
                          <span class="fz17 fw500 dark-color ms-1">₹8,000</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-6 col-xl-3">
                <div class="listing-style1">
                  <div class="list-thumb">
                    <img
                      class="w-100"
                      src="images/listings/Services/web2.png"
                      alt=""
                    />
                  </div>
                  <div class="list-content">
                    <p class="list-text body-color fz14 mb-1">
                      Back-End Development
                    </p>
                    <h5 class="list-title">
                      <a>
                        Powering Websites with Efficient Server-Side
                        Technologies
                      </a>
                    </h5>
                    <div class="review-meta d-flex align-items-center">
                      <i class="fas fa-star fz10 review-color me-2"></i>
                      <p class="mb-0 body-color fz14">
                        <span class="dark-color me-2">4.6</span>
                      </p>
                    </div>
                    <hr class="my-2" />
                    <div class="list-meta d-flex justify-content-between align-items-center mt15">
                      <div class="budget">
                        <p class="mb-0 body-color">
                          Starting at
                          <span class="fz17 fw500 dark-color ms-1">
                            ₹40,000
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-6 col-xl-3">
                <div class="listing-style1">
                  <div class="list-thumb">
                    <img
                      class="w-100"
                      src="images/listings/Services/web3.png"
                      alt=""
                    />
                  </div>
                  <div class="list-content">
                    <p class="list-text body-color fz14 mb-1">
                      Full-Stack Development
                    </p>
                    <h5 class="list-title">
                      <a>Front-End and Back-End for Seamless Web Experiences</a>
                    </h5>
                    <div class="review-meta d-flex align-items-center">
                      <i class="fas fa-star fz10 review-color me-2"></i>
                      <p class="mb-0 body-color fz14">
                        <span class="dark-color me-2">4.6</span>
                      </p>
                    </div>
                    <hr class="my-2" />
                    <div class="list-meta d-flex justify-content-between align-items-center mt15">
                      <div class="budget">
                        <p class="mb-0 body-color">
                          Starting at
                          <span class="fz17 fw500 dark-color ms-1">
                            ₹60,000
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-6 col-xl-3">
                <div class="listing-style1">
                  <div class="list-thumb">
                    <img
                      class="w-100"
                      src="images/listings/Services/web4.png"
                      alt=""
                    />
                  </div>
                  <div class="list-content">
                    <p class="list-text body-color fz14 mb-1">
                      E-commerce Development
                    </p>
                    <h5 class="list-title">
                      <a>Creating Secure & Scalable Online Stores </a>
                    </h5>
                    <div class="review-meta d-flex align-items-center">
                      <i class="fas fa-star fz10 review-color me-2"></i>
                      <p class="mb-0 body-color fz14">
                        <span class="dark-color me-2">4.5</span>
                      </p>
                    </div>
                    <hr class="my-2" />
                    <div class="list-meta d-flex justify-content-between align-items-center mt15">
                      <div class="budget">
                        <p class="mb-0 body-color">
                          Starting at
                          <span class="fz17 fw500 dark-color ms-1">
                            ₹20,000
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-6 col-xl-3">
                <div class="listing-style1">
                  <div class="list-thumb">
                    <img
                      class="w-100"
                      src="images/listings/Services/app1.png"
                      alt=""
                    />
                  </div>
                  <div class="list-content">
                    <p class="list-text body-color fz14 mb-1">
                      Mobile App Development
                    </p>
                    <h5 class="list-title line-clamp2">
                      <a>Extending Your Web Presence to Mobile Platforms</a>
                    </h5>
                    <div class="review-meta d-flex align-items-center">
                      <i class="fas fa-star fz10 review-color me-2"></i>
                      <p class="mb-0 body-color fz14">
                        <span class="dark-color me-2">4.7</span>
                      </p>
                    </div>
                    <hr class="my-2" />
                    <div class="list-meta d-flex justify-content-between align-items-center mt15">
                      <div class="budget">
                        <p class="mb-0 body-color">
                          Starting at
                          <span class="fz17 fw500 dark-color ms-1">
                            ₹40,000
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-6 col-xl-3">
                <div class="listing-style1">
                  <div class="list-thumb">
                    <img
                      class="w-100"
                      src="images/listings/Services/logo1.png"
                      alt=""
                    />
                  </div>
                  <div class="list-content">
                    <p class="list-text body-color fz14 mb-1">Logo Design</p>
                    <h5 class="list-title">
                      <a>Crafting Memorable and Impactful Brand Identities</a>
                    </h5>
                    <div class="review-meta d-flex align-items-center">
                      <i class="fas fa-star fz10 review-color me-2"></i>
                      <p class="mb-0 body-color fz14">
                        <span class="dark-color me-2">4.8</span>
                      </p>
                    </div>
                    <hr class="my-2" />
                    <div class="list-meta d-flex justify-content-between align-items-center mt15">
                      <div class="budget">
                        <p class="mb-0 body-color">
                          Starting at
                          <span class="fz17 fw500 dark-color ms-1">₹1500</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-6 col-xl-3">
                <div class="listing-style1">
                  <div class="list-thumb">
                    <img
                      class="w-100"
                      src="images/listings/Services/illustration1.png"
                      alt=""
                    />
                  </div>
                  <div class="list-content">
                    <p class="list-text body-color fz14 mb-1">
                      Illustration and Vector Art
                    </p>
                    <h5 class="list-title">
                      <a>
                        Bringing Ideas to Life with Creative and Artistic
                        Expressions
                      </a>
                    </h5>
                    <div class="review-meta d-flex align-items-center">
                      <i class="fas fa-star fz10 review-color me-2"></i>
                      <p class="mb-0 body-color fz14">
                        <span class="dark-color me-2">4.3</span>
                      </p>
                    </div>
                    <hr class="my-2" />
                    <div class="list-meta d-flex justify-content-between align-items-center mt15">
                      <div class="budget">
                        <p class="mb-0 body-color">
                          Starting at
                          <span class="fz17 fw500 dark-color ms-1">₹5000</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-6 col-xl-3">
                <div class="listing-style1">
                  <div class="list-thumb">
                    <img
                      class="w-100"
                      src="images/listings/Services/socialmedia1.png"
                      alt=""
                    />
                  </div>
                  <div class="list-content">
                    <p class="list-text body-color fz14 mb-1">
                      Social Media Graphics
                    </p>
                    <h5 class="list-title">
                      <a>Creating Compelling Visual Content for Social Media</a>
                    </h5>
                    <div class="review-meta d-flex align-items-center">
                      <i class="fas fa-star fz10 review-color me-2"></i>
                      <p class="mb-0 body-color fz14">
                        <span class="dark-color me-2">4.8</span>
                      </p>
                    </div>
                    <hr class="my-2" />
                    <div class="list-meta d-flex justify-content-between align-items-center mt15">
                      <div class="budget">
                        <p class="mb-0 body-color">
                          Starting at
                          <span class="fz17 fw500 dark-color ms-1">₹3500</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-6 col-xl-3">
                <div class="listing-style1">
                  <div class="list-thumb">
                    <img
                      class="w-100"
                      src="images/listings/Services/dataentry1.png"
                      alt=""
                    />
                  </div>
                  <div class="list-content">
                    <p class="list-text body-color fz14 mb-1">
                      Data Entry for Business
                    </p>
                    <h5 class="list-title line-clamp2">
                      <a>
                        Optimizing Productivity and Decision-Making through
                        Effective Data Input
                      </a>
                    </h5>
                    <div class="review-meta d-flex align-items-center">
                      <i class="fas fa-star fz10 review-color me-2"></i>
                      <p class="mb-0 body-color fz14">
                        <span class="dark-color me-2">4.8</span>
                      </p>
                    </div>
                    <hr class="my-2" />
                    <div class="list-meta d-flex justify-content-between align-items-center mt15">
                      <div class="budget">
                        <p class="mb-0 body-color">
                          Starting at
                          <span class="fz17 fw500 dark-color ms-1">₹4500</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-6 col-xl-3">
                <div class="listing-style1">
                  <div class="list-thumb">
                    <img
                      class="w-100"
                      src="images/listings/Services//datentry2.png"
                      alt=""
                    />
                  </div>
                  <div class="list-content">
                    <p class="list-text body-color fz14 mb-1">
                      Data Entry for Research
                    </p>
                    <h5 class="list-title">
                      <a>
                        Organizing and Analyzing Data for Meaningful Insights
                      </a>
                    </h5>
                    <div class="review-meta d-flex align-items-center">
                      <i class="fas fa-star fz10 review-color me-2"></i>
                      <p class="mb-0 body-color fz14">
                        <span class="dark-color me-2">4.8</span>
                      </p>
                    </div>
                    <hr class="my-2" />
                    <div class="list-meta d-flex justify-content-between align-items-center mt15">
                      <div class="budget">
                        <p class="mb-0 body-color">
                          Starting at
                          <span class="fz17 fw500 dark-color ms-1">₹4500</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="mbp_pagination mt30 text-center">
                <ul class="page_navigation">
                  {/* <!-- <li class="page-item">
                    <a class="page-link" href="#">
                      <span class="fas fa-angle-left"></span
                    ></a>
                  </li> --> */}
                  <li class="page-item">
                    <a class="page-link" href="#">
                      1
                    </a>
                  </li>

                  <li class="page-item">
                    <a class="page-link" href="#">
                      2
                    </a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" href="#">
                      3
                    </a>
                  </li>

                  {/* <!-- <li class="page-item d-inline-block d-sm-none">
                    <a class="page-link" href="#">...</a>
                  </li> --> */}
                  {/* 
                  <!-- <li class="page-item">
                    <a class="page-link" href="#"
                      ><span class="fas fa-angle-right"></span
                    ></a>
                  </li> --> */}
                </ul>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default Services;
