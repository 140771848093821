import React, { useState, useEffect } from "react";
import { db, auth } from "../../firebase";
import { Alert } from "react-bootstrap";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { serverTimestamp } from "firebase/firestore";
import GoToTop from "../../GoToTop";
import Swal from "sweetalert2";
import { useNavigate, Link } from "react-router-dom";
function OrderProject() {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);

  const userId = auth.currentUser ? auth.currentUser.uid : null;

  const [fileF, setFileF] = useState(null);
  const [pDesc, setPdesc] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [location, setLocation] = useState("");
  const [contact, setContact] = useState("");
  const [subject, setSubject] = useState("");
  const [title, setTitle] = useState("");
  const [work_type, setWork_Type] = useState("");
  const [minCost, setMin_Cost] = useState("");
  const [maxCost, setMax_Cost] = useState("");
  const [skills, setSkills] = useState("");
  const [work_level, setWork_Level] = useState("");
  const [work_duration, setDuration] = useState("");
  const [loader, setLoader] = useState("");
  const [success, setsuccessLoader] = useState("");
  const isFinalDisabled = !fileF;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        const userId = user.uid;
        try {
          const userDocRef = db.collection("Clients").doc(userId);
          const userDoc = await userDocRef.get();

          if (userDoc.exists) {
            const userData = userDoc.data();
            setUserData(userData);
            setName(userData.fname + " " + userData.lname || "");

            setContact(userData.contact || "");
            setEmail(userData.email || "");
            setLocation(userData.city || "");
          } else {
            console.log("User document not found");
            navigate("/client_signin");
          }
        } catch (error) {
          console.log("Error getting user document:", error);
        }
      } else {
        console.log("User not authenticated");
        navigate("/client_signin");
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, [navigate]);

  function generateUniqueId(length) {
    const characters = "0123456789";
    let uniqueId = "";

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      uniqueId += characters.charAt(randomIndex);
    }

    return uniqueId;
  }

  const uniqueId = generateUniqueId(9);

  const today = new Date();
  const chattime =
    today.getHours().toString().padStart(2, "0") +
    ":" +
    today.getMinutes().toString().padStart(2, "0") +
    ":" +
    today.getSeconds().toString().padStart(2, "0");
  const current = new Date();
  const options = { year: "numeric", month: "long", day: "numeric" };
  const chatdate = current.toLocaleString("en-US", options);

  const handleSubmitComplete = async () => {
    setIsSubmitting(true);
    setLoader("Adding Project ! Please wait...");
    setsuccessLoader("");

    if (!fileF) {
      try {
        // Get the download URL

        db.collection("Projects")
          .doc(uniqueId)
          .set({
            name,
            user: userId,
            email,
            contact,
            location,
            title,
            work_type,
            work_duration,
            idd: uniqueId,
            subject,
            skills,
            minCost: parseFloat(minCost),
            maxCost: parseFloat(maxCost),
            work_level,
            pDesc,
            status: "Pending",
            posted: serverTimestamp(),
            empId: "--",
          });

        // Reset form fields and close the modal
        setFileF(null);
        setPdesc("");
        setName("");
        setEmail("");
        setLocation("");
        setContact("");
        setSubject("");
        setTitle("");
        setMin_Cost("");
        setWork_Level("");
        setDuration("");
        setWork_Type("");
        setLoader("");
        Swal.fire({
          title: "Hurray Project Submitted!",
          html: `We have received your order. Your Order Number is: <b>#${uniqueId}</b>`,
          icon: "success",
        }).then(() => {
          navigate("/");
        });
        setTimeout(() => {
          setLoader("");
          setsuccessLoader("");
          setIsSubmitting(false);
        }, 5000);
      } catch (error) {
        console.log("Error submitting applied project:", error);
        setIsSubmitting(false);
      }
    } else {
      try {
        // Create a storage reference
        const storage = getStorage();
        const storageRef = ref(storage, "Projects/" + chattime + fileF.name);

        // Upload the file to Firebase Storage
        await uploadBytes(storageRef, fileF);

        // Get the download URL
        const downloadURL = await getDownloadURL(storageRef);

        db.collection("Projects")
          .doc(uniqueId)
          .set({
            name,
            user: userId,
            email,
            location,
            contact,
            title,
            work_type,
            fileURL: downloadURL,
            subject,
            skills,
            minCost: parseFloat(minCost),
            maxCost: parseFloat(maxCost),
            work_level,
            pDesc,
            work_duration,
            idd: uniqueId,
            status: "Pending",
            posted: serverTimestamp(),
          });

        // Reset form fields and close the modal
        setFileF(null);
        setPdesc("");
        setName("");
        setEmail("");
        setLocation("");
        setContact("");
        setSubject("");
        setTitle("");
        setDuration("");
        setMin_Cost("");
        setWork_Level("");
        setWork_Type("");
        setLoader("");
        Swal.fire({
          title: "Hurray Project Submitted!",
          html: `We have received your order. Your Order Number is: <b>#${uniqueId}</b>`,
          icon: "success",
        }).then(() => {
          navigate("/my_projects");
        });
        setTimeout(() => {
          setLoader("");
          setsuccessLoader("");
          setIsSubmitting(false);
        }, 2000);
      } catch (error) {
        console.log("Error submitting applied project:", error);
        setIsSubmitting(false);
      }
    }
  };

  const handleFileChangeF = (e) => {
    const selectedFile = e.target.files[0];
    setFileF(selectedFile);
  };
  return (
    <>
      <GoToTop />
      <div class="body_content">
        <section class="our-terms">
          <div class="container">
            <div class="row">
              <div class="col-lg-6">
                <div class="main-title">
                  <h2>Place an Order for your Project</h2>
                  {/* <!-- <p class="text">
                    Give your visitor a smooth online experience with a solid UX
                    design
                  </p> --> */}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-4 my-2" style={{ display: "none" }}>
                <label>Fullname</label>
                <input
                  type="text"
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                  name="name"
                  placeholder="Full Name"
                  className="form-control"
                />
              </div>
              <div className="col-lg-4 my-2" style={{ display: "none" }}>
                <label>Email</label>
                <input
                  type="email"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  name="email"
                  placeholder="Email Address"
                  className="form-control"
                />
              </div>
              <div className="col-lg-4 my-2" style={{ display: "none" }}>
                <label>Contact</label>
                <input
                  type="tel"
                  onChange={(e) => setContact(e.target.value)}
                  value={contact}
                  name="phone"
                  placeholder="Email Address"
                  className="form-control"
                />
              </div>

              <div className="col-lg-4 my-2" style={{ display: "none" }}>
                <label>Location</label>
                <input
                  type="text"
                  onChange={(e) => setLocation(e.target.value)}
                  value={location}
                  name="location"
                  placeholder="Full Name"
                  className="form-control"
                />
              </div>

              <div className="col-lg-3">
                <label>Project Title</label>
              </div>

              <div className="col-lg-9 my-2">
                <input
                  type="text"
                  onChange={(e) => setTitle(e.target.value)}
                  value={title}
                  name="subject"
                  placeholder="Project Title"
                  className="form-control"
                />
              </div>

              <div className="col-lg-3">
                <label>Category</label>
              </div>

              <div className="col-lg-9 my-2">
                <select
                  onChange={(e) => setSubject(e.target.value)}
                  value={subject}
                  control="select"
                  name="assignment_type"
                  className="form-control"
                >
                  <option value=" ">--Select Category--</option>
                  <option value="Digital Marketing">Digital Marketing</option>
                  <option value="Website Design">Website Design</option>
                  <option value="Website Re-design">Website Re-design</option>
                  <option value="ReactJs Website">ReactJs Website</option>
                  <option value="WordPress Website">WordPress Website</option>

                  <option value="Woo Commerce Website">
                    Woo Commerce Website
                  </option>
                  <option value="Landing Page Webiste">
                    Landing Page Website
                  </option>
                  <option value="Static Website">Static Website</option>
                  <option value="Dynamic Website">Dynamic Website</option>
                  <option value="Sourcing">Sourcing</option>
                  <option value="Data Entry Work">Data Entry Work</option>
                  <option value="Meta Data">Meta Data</option>
                  <option value="Machine Learning">Machine Learning</option>
                  <option value="AI tools Designing">AI tools Designing</option>
                  <option value="Dashboard Development Project">
                    Dashboard Development Project
                  </option>
                  <option value="Python Projects">Python Projects</option>

                  <option value="ERP">ERP</option>
                  <option value="SAP">SAP</option>
                  <option value="Web Scrapping">Web Scrapping</option>
                  <option value="Software Testing">Software Testing</option>
                  <option value="Accounting and Finance">
                    Accounting and Finance
                  </option>
                  <option value="Medical Sciences">Medical Sciences</option>
                  <option value="Design">Design</option>
                  <option value="Video & Audio Editing">
                    Video & Audio Editing
                  </option>
                  <option value="Lifestyle">Lifestyle</option>
                  <option value="Writing & Transalation">
                    Writing & Transalation
                  </option>
                  <option value="Consulting & HR">Consulting & HR</option>

                  <option value="IT Computer Science">
                    IT Computer Science
                  </option>

                  <option value="Engineering">Engineering</option>
                  <option value="Science and Maths">Science and Maths</option>
                  <option value="Law">Law</option>
                  <option value="Statistics">Statistics</option>
                  <option value="General Subjects">General Subjects</option>
                  <option value="Arts and Humanities">
                    Arts and Humanities
                  </option>
                  <option value="Economics">Economics</option>
                  <option value="Management">Management</option>
                  <option value="Graphic Designing">Graphic Designing</option>
                  <option value="Mechanical Design">Mechanical Design</option>
                  <option value="Architectural Design">
                    Architectural Design
                  </option>
                  <option value="Simulation">Simulation</option>
                  <option value="Mechatronics">Mechatronics</option>
                  <option value="Aeronautical Design">
                    Aeronautical Design
                  </option>
                  <option value="ChatGPT/GPT Works">ChatGPT/GPT Works</option>

                  <option value="Music">Music</option>
                  <option value="Other">Other</option>
                </select>
              </div>

              {/* <div className="col-lg-2 my-2">
                <label>Word Count</label>
                <input
                  type="number"
                  onChange={(e) => setWork_Type(e.target.value)}
                  value={work_type}
                  name="pages"
                  placeholder="Word Count"
                  className="form-control"
                />
              </div> */}

              <div className="col-lg-3">
                <label>Description</label>
              </div>

              <div className="col-lg-9 my-2">
                <ReactQuill
                  theme="snow"
                  style={{ height: "40vh" }}
                  value={pDesc}
                  onChange={setPdesc}
                />
                <br></br> <br></br>
              </div>

              <div className="col-lg-3">
                <label>Project Type</label>
              </div>

              <div className="col-lg-3 my-2">
                <select
                  onChange={(e) => setWork_Type(e.target.value)}
                  value={work_type}
                  control="select"
                  name="assignment_type"
                  className="form-control"
                >
                  <option value=" ">--Select--</option>
                  <option value="Hourly">Hourly</option>
                  <option value="Fixed">Fixed</option>
                </select>
              </div>

              <div className="col-lg-3 my-2">
                <input
                  type="number"
                  id="minCost"
                  onChange={(e) => setMin_Cost(e.target.value)}
                  value={minCost}
                  placeholder="Min Cost"
                  className="form-control"
                />
              </div>

              <div className="col-lg-3 my-2">
                <input
                  type="number"
                  id="maxCost"
                  onChange={(e) => setMax_Cost(e.target.value)}
                  value={maxCost}
                  placeholder="Max Cost"
                  className="form-control"
                />
              </div>

              <div className="col-lg-3">
                <label>Project Duration</label>
              </div>
              <div className="col-lg-9 my-2">
                <select
                  onChange={(e) => setDuration(e.target.value)}
                  value={work_duration}
                  control="select"
                  name="assignment_type"
                  className="form-control"
                >
                  <option value=" ">--Select--</option>
                  <option value="1 Week">1 Week</option>
                  <option value="2 Weeks">2 Weeks</option>
                  <option value="3 Weeks">3 Weeks</option>
                  <option value="1 Month">1 Month</option>
                  <option value="2 Months">2 Months</option>
                  <option value="3 Months">3 Months</option>
                  <option value="4 Months">4 Months</option>
                  <option value="5 Months">5 Months</option>
                  <option value="6 Months">6 Months</option>
                </select>
              </div>

              <div className="col-lg-3">
                <label>Project Level</label>
              </div>
              <div className="col-lg-9 my-2">
                <select
                  onChange={(e) => setWork_Level(e.target.value)}
                  value={work_level}
                  control="select"
                  name="assignment_type"
                  className="form-control"
                >
                  <option value=" ">--Select--</option>
                  <option value="Affordable">Affordable</option>
                  <option value="Expensive">Expensive</option>
                </select>
              </div>

              <div className="col-lg-3">
                <label>Skills</label>
              </div>
              <div className="col-lg-9 my-2">
                <input
                  type="text"
                  id="skills"
                  onChange={(e) => setSkills(e.target.value)}
                  value={skills}
                  placeholder="HTML, CSS, Graphic Design, Web Development"
                  className="form-control"
                />
              </div>

              <div className="col-lg-3">
                <label>Project Attachment</label>
              </div>

              <div className="col-lg-9">
                <small style={{ color: "red" }}>
                  *File size should be less than 5MB
                </small>
                <input
                  onChange={handleFileChangeF}
                  type="file"
                  id="file"
                  name="file"
                  className="form-control"
                />
              </div>
            </div>

            <div className="row my-3">
              {loader && (
                <Alert
                  variant="warning"
                  style={{ fontSize: "11px", textAlign: "center" }}
                >
                  {loader}
                </Alert>
              )}
              {success && (
                <Alert
                  variant="success"
                  style={{ fontSize: "11px", textAlign: "center" }}
                >
                  {success}
                </Alert>
              )}

              <div className="col-10">
                <Link className="ud-btn btn-dark" to="/">
                  Skip/Cancel
                </Link>
              </div>
              <div className="col-2">
                {title.length > 0 &&
                work_level.length > 0 &&
                subject.length > 0 &&
                email.length > 0 &&
                contact.length > 0 &&
                pDesc.length > 0 &&
                name.length > 0 ? (
                  <>
                    <button
                      disabled={isSubmitting}
                      onClick={handleSubmitComplete}
                      type="button"
                      className="ud-btn btn-thm"
                    >
                      Place Order
                    </button>
                  </>
                ) : (
                  <button
                    disabled
                    style={{ cursor: "not-allowed" }}
                    type="button"
                    className="ud-btn btn-thm"
                  >
                    Place Order
                  </button>
                )}
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default OrderProject;
