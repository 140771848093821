import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { Link, useNavigate } from "react-router-dom";
import { db, auth } from "../../firebase";
import { useUserAuth } from "./Context/UserAuthContext";
import userImg from "../../images/user.png";
function Header() {
  const { logOut, user } = useUserAuth();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await logOut();
      navigate("/");
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    const fetchUserData = async () => {
      const userId = auth.currentUser ? auth.currentUser.uid : null;

      if (userId) {
        try {
          const userDocRef = db.collection("Admins").doc(userId);
          const userDoc = await userDocRef.get();

          if (userDoc.exists) {
            const userData = userDoc.data();
            setUserData(userData);
          } else {
            console.log("User document does not exist");
          }
        } catch (error) {
          console.log("Error getting user document:", error);
        }
      } else {
        console.log("User is not authenticated");
      }

      setLoading(false);
    };

    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        fetchUserData();
      } else {
        setLoading(false);
        console.log("User is not authenticated");
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  if (loading) {
    return <div style={{ height: "5vh" }}>Loading...</div>;
  }

  return (
    <>
      <header class="header-nav nav-innerpage-style menu-home4 dashboard_header main-menu">
        <nav class="posr">
          <div class="container-fluid pr30 pr15-xs pl30 posr menu_bdrt1">
            <div class="row align-items-center justify-content-between">
              <div class="col-6 col-lg-auto">
                <div class="text-center text-lg-start d-flex align-items-center">
                  <div class="dashboard_header_logo position-relative me-2 me-xl-5">
                    <Link to="/admin_dashboard" class="logo">
                      <img src="images/header-logo3.svg" alt="" />
                    </Link>
                  </div>
                  <div class="fz20 ml90">
                    <a class="dashboard_sidebar_toggle_icon vam">
                      <img src="images/dashboard-navicon.svg" alt="" />
                    </a>
                  </div>
                  {/* <a
                    class="login-info d-block d-xl-none ml40 vam"
                    data-bs-toggle="modal"
                    href="#exampleModalToggle"
                    role="button"
                  >
                    <span class="flaticon-loupe"></span>
                  </a> */}
                  {/* <div class="ml40 d-none d-xl-block">
                    <div class="search_area dashboard-style">
                      <input
                        type="text"
                        class="form-control border-0"
                        placeholder="What service are you looking for today?"
                      />
                      <label>
                        <span class="flaticon-loupe"></span>
                      </label>
                    </div>
                  </div> */}
                </div>
              </div>
              <div class="col-6 col-lg-auto">
                <div class="text-center text-lg-end header_right_widgets">
                  <ul class="dashboard_dd_menu_list d-flex align-items-center justify-content-center justify-content-sm-end mb-0 p-0">
                    <li class="d-none d-sm-block">
                      <a
                        class="text-center mr5 text-thm2 dropdown-toggle fz20"
                        type="button"
                        data-bs-toggle="dropdown"
                        href=""
                      >
                        <span class="flaticon-notification"></span>
                      </a>
                      <div class="dropdown-menu">
                        <div class="dboard_notific_dd px30 pt10 pb15">
                          <div class="notif_list d-flex align-items-center bdrb1 pb15 mb10">
                            <img src="images/resource/notif-1.png" alt="" />
                            <div class="details ml10">
                              <p class="text mb-0">Your resume</p>
                              <p class="text mb-0">updated!</p>
                            </div>
                          </div>
                          <div class="notif_list d-flex align-items-center bdrb1 pb15 mb10">
                            <img src="images/resource/notif-2.png" alt="" />
                            <div class="details ml10">
                              <p class="text mb-0">You changed</p>
                              <p class="text mb-0">password</p>
                            </div>
                          </div>
                          <div class="notif_list d-flex align-items-center bdrb1 pb15 mb10">
                            <img src="images/resource/notif-3.png" alt="" />
                            <div class="details ml10">
                              <p class="text mb-0">Your account has been</p>
                              <p class="text mb-0">created successfully</p>
                            </div>
                          </div>
                          <div class="notif_list d-flex align-items-center bdrb1 pb15 mb10">
                            <img src="images/resource/notif-4.png" alt="" />
                            <div class="details ml10">
                              <p class="text mb-0">You applied for a job </p>
                              <p class="text mb-0">Front-end Developer</p>
                            </div>
                          </div>
                          <div class="notif_list d-flex align-items-center">
                            <img src="images/resource/notif-5.png" alt="" />
                            <div class="details ml10">
                              <p class="text mb-0">Your course uploaded</p>
                              <p class="text mb-0">successfully</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li class="d-none d-sm-block">
                      <a
                        class="text-center mr5 text-thm2 dropdown-toggle fz20"
                        type="button"
                        data-bs-toggle="dropdown"
                        href=""
                      >
                        <span class="flaticon-mail"></span>
                      </a>
                      <div class="dropdown-menu">
                        <div class="dboard_notific_dd px30 pt20 pb15">
                          <div class="notif_list d-flex align-items-start bdrb1 pb25 mb10">
                            <img
                              class="img-2"
                              src="images/testimonials/testi-1.png"
                              alt=""
                            />
                            <div class="details ml15">
                              <p class="dark-color fw500 mb-2">Ali Tufan</p>
                              <p class="text mb-2">
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing.
                              </p>
                              <p class="mb-0 text-thm">4 hours ago</p>
                            </div>
                          </div>
                          <div class="notif_list d-flex align-items-start mb25">
                            <img
                              class="img-2"
                              src="images/testimonials/testi-2.png"
                              alt=""
                            />
                            <div class="details ml15">
                              <p class="dark-color fw500 mb-2">Ali Tufan</p>
                              <p class="text mb-2">
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing.
                              </p>
                              <p class="mb-0 text-thm">4 hours ago</p>
                            </div>
                          </div>
                          <div class="d-grid">
                            <a
                              href="page-dashboard-message.html"
                              class="ud-btn btn-thm w-100"
                            >
                              View All Messages
                              <i class="fal fa-arrow-right-long"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li class="d-none d-sm-block">
                      <a
                        class="text-center mr5 text-thm2 dropdown-toggle fz20"
                        type="button"
                        data-bs-toggle="dropdown"
                        href=""
                      >
                        <span class="flaticon-like"></span>
                      </a>
                      <div class="dropdown-menu">
                        <div class="dboard_notific_dd px30 pt10 pb15">
                          <div class="notif_list d-flex align-items-center bdrb1 pb15 mb10">
                            <img src="images/resource/notif-1.png" alt="" />
                            <div class="details ml10">
                              <p class="text mb-0">Your resume</p>
                              <p class="text mb-0">updated!</p>
                            </div>
                          </div>
                          <div class="notif_list d-flex align-items-center bdrb1 pb15 mb10">
                            <img src="images/resource/notif-2.png" alt="" />
                            <div class="details ml10">
                              <p class="text mb-0">You changed</p>
                              <p class="text mb-0">password</p>
                            </div>
                          </div>
                          <div class="notif_list d-flex align-items-center bdrb1 pb15 mb10">
                            <img src="images/resource/notif-3.png" alt="" />
                            <div class="details ml10">
                              <p class="text mb-0">Your account has been</p>
                              <p class="text mb-0">created successfully</p>
                            </div>
                          </div>
                          <div class="notif_list d-flex align-items-center bdrb1 pb15 mb10">
                            <img src="images/resource/notif-4.png" alt="" />
                            <div class="details ml10">
                              <p class="text mb-0">You applied for a job </p>
                              <p class="text mb-0">Front-end Developer</p>
                            </div>
                          </div>
                          <div class="notif_list d-flex align-items-center">
                            <img src="images/resource/notif-5.png" alt="" />
                            <div class="details ml10">
                              <p class="text mb-0">Your course uploaded</p>
                              <p class="text mb-0">successfully</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li class="user_setting">
                      <div class="dropdown">
                        <a class="btn" href="#" data-bs-toggle="dropdown">
                          <img
                            src={userImg}
                            alt="user.png"
                            style={{
                              width: "30px",
                              height: "30px",
                              objectFit: "cover",
                            }}
                          />
                        </a>
                        <div class="dropdown-menu">
                          <div class="user_setting_content">
                            <p class="fz15 fw400 ff-heading mb10 pl30">Start</p>
                            <a class="dropdown-item" href="page-dashboard.html">
                              <i class="flaticon-home mr10"></i>Dashboard
                            </a>
                            <a
                              class="dropdown-item"
                              href="page-dashboard-proposal.html"
                            >
                              <i class="flaticon-document mr10"></i>My Proposals
                            </a>
                            <a
                              class="dropdown-item"
                              href="page-dashboard-save.html"
                            >
                              <i class="flaticon-like mr10"></i>Saved
                            </a>
                            <a
                              class="dropdown-item"
                              href="page-dashboard-message.html"
                            >
                              <i class="flaticon-chat mr10"></i>Message
                            </a>
                            <a
                              class="dropdown-item"
                              href="page-dashboard-reviews.html"
                            >
                              <i class="flaticon-review-1 mr10"></i>Reviews
                            </a>
                            <a
                              class="dropdown-item"
                              href="page-dashboard-invoice.html"
                            >
                              <i class="flaticon-receipt mr10"></i>Invoice
                            </a>
                            <a
                              class="dropdown-item"
                              href="page-dashboard-payouts.html"
                            >
                              <i class="flaticon-dollar mr10"></i>Payouts
                            </a>
                            <a
                              class="dropdown-item"
                              href="page-dashboard-statement.html"
                            >
                              <i class="flaticon-web mr10"></i>Statements
                            </a>
                            <p class="fz15 fw400 ff-heading mt30 pl30">
                              Organize and Manage
                            </p>
                            <a
                              class="dropdown-item"
                              href="page-dashboard-manage-service.html"
                            >
                              <i class="flaticon-presentation mr10"></i>Manage
                              Services
                            </a>
                            <a
                              class="dropdown-item"
                              href="page-dashboard-manage-jobs.html"
                            >
                              <i class="flaticon-briefcase mr10"></i>Manage Jobs
                            </a>
                            <a
                              class="dropdown-item active"
                              href="page-dashboard-favorites.html"
                            >
                              <i class="flaticon-content mr10"></i>Manage
                              Project
                            </a>
                            <p class="fz15 fw400 ff-heading mt30 pl30">
                              Account
                            </p>
                            <a
                              class="dropdown-item"
                              href="page-dashboard-profile.html"
                            >
                              <i class="flaticon-photo mr10"></i>My Profile
                            </a>
                            <a class="dropdown-item" href="page-login.html">
                              <i class="flaticon-logout mr10"></i>Logout
                            </a>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
}

export default Header;
