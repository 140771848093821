import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useUserAuth } from "./Context/UserAuthContext";
import { db } from "../../firebase";
import Swal from "sweetalert2";
import GoToTop from "../../GoToTop";
import { serverTimestamp } from "firebase/firestore";
function ForgotPassword() {
  const { sendEmail } = useUserAuth();
  const navigate = useNavigate();

  const handlePasswordReset = async (event) => {
    event.preventDefault();
    const email = event.target.email.value;
    try {
      await sendEmail(email);

      Swal.fire({
        title: "Great",
        text: "Password reset email sent successfully!",
        icon: "success",
      });

      navigate("/signin");
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <GoToTop />
      <div class="body_content">
        {/* <!-- Our SignUp Area --> */}
        <section class="our-register">
          <div class="container">
            <div class="row">
              <div class="col-lg-6 m-auto wow fadeInUp" data-wow-delay="300ms">
                <div class="main-title text-center">
                  <h2 class="title">Forgot Password</h2>
                </div>
              </div>
            </div>
            <div
              class="row wow fadeInRight"
              data-wow-delay="300ms"
              style={{ marginTop: "-50px" }}
            >
              <div class="col-xl-6 mx-auto">
                <div class="log-reg-form search-modal form-style1 bgc-white p50 p30-sm default-box-shadow1 bdrs12">
                  <div class="mb30">
                    <h4>Reset your Password?</h4>
                    <p class="text mt20">
                      Already have an account?{" "}
                      <Link to="/signin" class="text-thm">
                        Log In!
                      </Link>
                    </p>
                  </div>
                  <form onSubmit={handlePasswordReset}>
                    <div class="mb25">
                      <label class="form-label fw500 dark-color">Email</label>
                      <input
                        type="email"
                        name="email"
                        class="form-control"
                        placeholder="Email Address"
                      />
                    </div>

                    <div class="d-grid mb20">
                      <button
                        class="ud-btn btn-thm default-box-shadow2"
                        type="Submit"
                      >
                        Reset Password <i class="fal fa-arrow-right-long"></i>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default ForgotPassword;
