import React, { useState, useEffect } from "react";
import GoToTop from "../../GoToTop";
import { useNavigate } from "react-router-dom";
import { db, auth } from "../../firebase";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import Swal from "sweetalert2";

function StepB() {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const userId = auth.currentUser ? auth.currentUser.uid : null;
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [contact, setContact] = useState("");
  const [gender, setGender] = useState("");
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [about, setAbout] = useState("");
  const [aadhar, setAadhar] = useState("");
  const [pan, setPAN] = useState("");
  const [loader, setLoader] = useState(false);
  const [imageFile, setImageFile] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        const userId = user.uid;
        try {
          const userDocRef = db.collection("Clients").doc(userId);
          const userDoc = await userDocRef.get();

          if (userDoc.exists) {
            const userData = userDoc.data();
            setUserData(userData);
            setFname(userData.fname || "");
            setLname(userData.lname || "");
            setContact(userData.contact || "");
            setGender(userData.gender || "");
            setCountry(userData.country || "");
            setCity(userData.city || "");
            setAbout(userData.about || "");

            // Fetch bank details from subcollection
            const bankDetailsDocRef = userDocRef
              .collection("BankDetails")
              .doc("bankInfo");
            const bankDetailsDoc = await bankDetailsDocRef.get();

            if (bankDetailsDoc.exists) {
              const bankData = bankDetailsDoc.data();
              setPAN(bankData.pan || "");
              setAadhar(bankData.aadhar || "");
            } else {
              console.log("Bank details not found");
            }
          } else {
            console.log("User document not found");
            navigate("/client_signin");
          }
        } catch (error) {
          console.log("Error getting user document:", error);
        }
      } else {
        console.log("User not authenticated");
        navigate("/client_signin");
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, [navigate]);

  useEffect(() => {
    if (country.toLowerCase() !== "india") {
      setAadhar("");
    }
  }, [country]);

  const validateAadhar = (aadhar) => {
    const regex = /^\d{12}$/;
    return regex.test(aadhar);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (country.toLowerCase() === "india" && !validateAadhar(aadhar)) {
      Swal.fire({
        title: "Error",
        text: "Aadhar Number Invalid!",
        icon: "error",
      });
      return; // Stop form submission if Aadhar number is invalid
    }

    try {
      const freelancerDocRef = db.collection("Clients").doc(userId);

      // Reference to the subcollection document
      const bankDetailsDocRef = freelancerDocRef
        .collection("BankDetails")
        .doc("bankInfo");

      // Start a batch
      const batch = db.batch();

      batch.update(freelancerDocRef, {
        fname,
        lname,
        gender,
        contact,
        country,
        city,
        about,
        profilestatus: "pendingB",
      });

      batch.set(bankDetailsDocRef, {
        pan,
        aadhar,
      });
      await batch.commit();

      Swal.fire({
        title: "Success",
        text: "Profile Updated!",
        icon: "success",
      });
      navigate("/create_project_");
    } catch (error) {
      console.log("Error submitting applied project:", error);
    }
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    setImageFile(file);
  };

  const handleProfilePic = async (event) => {
    event.preventDefault();
    if (!imageFile) return;

    const storage = getStorage();
    const storageRef = ref(storage, `ClientsProfile/${userId}/profile`);

    try {
      setLoader(true);

      await uploadBytes(storageRef, imageFile);

      const downloadURL = await getDownloadURL(storageRef);

      const userRef = db.collection("Clients").doc(userId);
      await userRef.update({ img: downloadURL });

      setUserData((prevData) => ({
        ...prevData,
        img: downloadURL,
      }));

      Swal.fire({
        title: "Success",
        text: "Profile Picture Updated!",
        icon: "success",
      });
    } catch (error) {
      Swal.fire({
        title: "Oops!",
        text: "Profile picture size should be less than 1MB",
        icon: "info",
      });
    } finally {
      setLoader(false);
    }
  };

  if (loading) {
    return <div></div>;
  }

  return (
    <>
      <GoToTop />
      <div className="body_content" style={{ marginTop: "10vh" }}>
        <section className="breadcumb-section mt40">
          <div className="container">
            <div className="dashboard_title_area">
              <h2>My Profile</h2>
              <p className="text">
                Explore and update your profile with ease, managing details,
                images, and skills effortlessly for professional presentation.
              </p>
            </div>
            <div className="row">
              <div className="col-xl-12">
                <div className="ps-widget bgc-white bdrs4 p30 mb30 overflow-hidden position-relative">
                  <div className="col-xl-7">
                    <div className="profile-box d-sm-flex align-items-center mb30">
                      <div className="profile-img mb20-sm">
                        <img
                          className="rounded-circle wa-xs"
                          src={userData ? userData.img : ""}
                          style={{
                            width: "110px",
                            height: "115px",
                            objectFit: "cover",
                          }}
                          alt=""
                        />
                      </div>
                      <div className="profile-content ml20 ml0-xs">
                        <form onSubmit={handleProfilePic}>
                          <div className="d-flex align-items-center my-3">
                            <input
                              type="file"
                              accept="image/*"
                              onChange={handleImageUpload}
                            />
                            {loader ? (
                              <button
                                style={{ height: "34px", border: "none" }}
                                className="upload-btn ml10"
                                disabled
                              >
                                Loading
                              </button>
                            ) : (
                              <button
                                style={{ border: "none" }}
                                type="submit"
                                className="upload-btn ml10"
                              >
                                Upload Images
                              </button>
                            )}
                          </div>
                        </form>
                        <p className="text mb-0">
                          Max file size is 1MB and suitable files are .jpg &
                          .png
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <form className="form-style1" onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-sm-4">
                          <div className="mb20">
                            <label className="heading-color ff-heading fw500 mb10">
                              First Name
                            </label>
                            <input
                              value={fname}
                              onChange={(e) => setFname(e.target.value)}
                              required
                              type="text"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <div className="mb20">
                            <label className="heading-color ff-heading fw500 mb10">
                              Last Name
                            </label>
                            <input
                              value={lname}
                              onChange={(e) => setLname(e.target.value)}
                              type="text"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <div className="mb20">
                            <label className="heading-color ff-heading fw500 mb10">
                              Email Address
                            </label>
                            <input
                              type="email"
                              className="form-control"
                              value={userData ? userData.email : ""}
                              readOnly
                            />
                          </div>
                        </div>
                        <div className="col-sm-3">
                          <div className="mb20">
                            <label className="heading-color ff-heading fw500 mb10">
                              Phone Number
                            </label>
                            <input
                              value={contact}
                              onChange={(e) => setContact(e.target.value)}
                              required
                              type="tel"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-sm-3">
                          <div className="mb20">
                            <label className="heading-color ff-heading fw500 mb10">
                              Gender
                            </label>
                            <select
                              onChange={(e) => setGender(e.target.value)}
                              required
                              value={gender}
                              className="form-control"
                            >
                              <option value="" disabled>
                                Select
                              </option>
                              <option value="Male">Male</option>
                              <option value="Female">Female</option>
                              <option value="Other">Other</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-sm-3">
                          <div className="mb20">
                            <label className="heading-color ff-heading fw500 mb10">
                              Country
                            </label>
                            <input
                              onChange={(e) => setCountry(e.target.value)}
                              required
                              value={country}
                              type="text"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-sm-3">
                          <div className="mb20">
                            <label className="heading-color ff-heading fw500 mb10">
                              City
                            </label>
                            <input
                              onChange={(e) => setCity(e.target.value)}
                              required
                              value={city}
                              type="text"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="col-sm-3">
                          <div className="mb20">
                            <label className="heading-color ff-heading fw500 mb10">
                              Aadhar Number
                            </label>
                            <input
                              onChange={(e) => setAadhar(e.target.value)}
                              value={aadhar}
                              maxLength={12}
                              disabled={country.toLowerCase() !== "india"}
                              type="text"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="col-sm-3">
                          <div className="mb20">
                            <label className="heading-color ff-heading fw500 mb10">
                              PAN Number
                            </label>
                            <input
                              onChange={(e) => setPAN(e.target.value)}
                              value={pan}
                              type="text"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="mb10">
                            <label className="heading-color ff-heading fw500 mb10">
                              Introduce Yourself
                            </label>
                            <textarea
                              style={{ minHeight: "130px" }}
                              onChange={(e) => setAbout(e.target.value)}
                              required
                              value={about}
                              placeholder="Description"
                              className="form-control"
                            ></textarea>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="text-start">
                            <button className="ud-btn btn-thm" type="submit">
                              Save & Continue
                              <i className="fal fa-arrow-right-long"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default StepB;
