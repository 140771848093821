import React, { useState, useEffect } from "react";
import GoToTop from "../../../GoToTop";
import { useNavigate } from "react-router-dom";
import { db, auth } from "../../../firebase";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import Swal from "sweetalert2";
import IFSC from "./IFSC";
function MyProfile() {
  const [userData, setUserData] = useState(null);
  const userId = auth.currentUser ? auth.currentUser.uid : null;
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [contact, setContact] = useState("");
  const [gender, setGender] = useState("");
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [about, setAbout] = useState("");
  const [languages, setLanguage] = useState("");
  const [aadhar, setAadhar] = useState("");
  const [pan, setPAN] = useState("");

  const [bank, setBank] = useState("");
  const [benificiary, setBenificiary] = useState("");
  const [ifsc, setIFSC] = useState("");
  const [branch, setBranch] = useState("");
  const [bank_address, setBAddress] = useState("");

  const [accountNo, setAccountNo] = useState("");
  const [confirmAccountNo, setCAccountNo] = useState("");
  const [iban, setIban] = useState("");
  const [swift, setSwift] = useState("");
  const [paypal, setPayPal] = useState("");
  const [skrill, setSkrill] = useState("");
  const [loader, setLoader] = useState(false);
  const [imageFile, setImageFile] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        const userId = user.uid;
        try {
          const userDocRef = db.collection("Clients").doc(userId);
          const userDoc = await userDocRef.get();

          if (userDoc.exists) {
            const userData = userDoc.data();
            setUserData(userData);
            setFname(userData.fname || "");
            setLname(userData.lname || "");
            setContact(userData.contact || "");
            setGender(userData.gender || "");
            setCountry(userData.country || "");
            setCity(userData.city || "");
            setAbout(userData.about || "");
            setLanguage(userData.languages || "");

            // Fetch bank details from subcollection
            const bankDetailsDocRef = userDocRef
              .collection("BankDetails")
              .doc("bankInfo");
            const bankDetailsDoc = await bankDetailsDocRef.get();

            if (bankDetailsDoc.exists) {
              const bankData = bankDetailsDoc.data();
              setPAN(bankData.pan || "");
              setAadhar(bankData.aadhar || "");
              setBank(bankData.bank || "");
              setBAddress(bankData.bank_address || "");
              setBranch(bankData.branch || "");
              setBenificiary(bankData.benificiary || "");
              setIFSC(bankData.ifsc || "");
              setAccountNo(bankData.accountNo || "");
              setCAccountNo(bankData.confirmAccountNo || "");
              setIban(bankData.iban || "");
              setSwift(bankData.swift || "");
              setSkrill(bankData.skrill || "");
              setPayPal(bankData.paypal || "");
            } else {
              console.log("Bank details not found");
            }
          } else {
            console.log("User document not found");
            navigate("/signin");
          }
        } catch (error) {
          console.log("Error getting user document:", error);
        }
      } else {
        console.log("User not authenticated");
        navigate("/signin");
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, [navigate]);

  const handleIFSCChangeClient = async (e) => {
    const ifscCode = e.target.value;
    setIFSC(ifscCode);

    try {
      const response = await fetch(`https://ifsc.razorpay.com/${ifscCode}`);
      if (!response.ok) {
        throw new Error("Bank details not found");
      }
      const data = await response.json();
      setBank(data.BANK);
      setBranch(data.BRANCH);
      setBAddress(data.ADDRESS);
    } catch (error) {
      console.error("Error fetching bank details:", error);
      setBank("");
      setBranch("");
      setBAddress("");
      // Swal.fire({
      //   title: "Error",
      //   text: "Bank details not found for the entered IFSC code",
      //   icon: "error",
      // });
    }
  };

  const handleSubmitClients = async (event) => {
    event.preventDefault();
    if (accountNo !== confirmAccountNo) {
      Swal.fire({
        title: "Error",
        text: "Account Number and Confirm Account Number do not match",
        icon: "error",
      });
      setAccountNo("");
      setCAccountNo("");
      return; // Prevent form submission if validation fails
    }

    try {
      // Reference to the main document
      const freelancerDocRef = db.collection("Clients").doc(userId);

      // Reference to the subcollection document
      const bankDetailsDocRef = freelancerDocRef
        .collection("BankDetails")
        .doc("bankInfo");

      // Start a batch
      const batch = db.batch();

      // Update the main document with non-sensitive data
      batch.update(freelancerDocRef, {
        fname,
        lname,
        gender,
        contact,
        country,
        city,
        about,
        languages,
        profilestatus: "completed",
      });

      // Set the sensitive data in the subcollection
      batch.update(bankDetailsDocRef, {
        bank,
        ifsc,
        bank_address,
        branch,
        benificiary,
        accountNo,
        confirmAccountNo,
        iban,
        swift,
        paypal,
        skrill,
      });

      // Commit the batch
      await batch.commit();

      Swal.fire({
        title: "Success",
        text: "Profile Updated!",
        icon: "success",
      });

      setTimeout(() => {
        window.location.reload();
      }, 3000);
      // navigate("/profile_completion_professional_details");
    } catch (error) {
      console.log("Error submitting applied project:", error);
      Swal.fire({
        title: "Error",
        text: "There was an error updating your profile.",
        icon: "error",
      });
    }
  };

  const handleImageUploadClient = (event) => {
    const file = event.target.files[0];
    setImageFile(file);
  };

  const handleProfilePic = async (event) => {
    event.preventDefault();
    if (!imageFile) return;

    const storage = getStorage();
    const storageRef = ref(storage, `profileImages/${userId}/profile`);

    try {
      setLoader(true);

      await uploadBytes(storageRef, imageFile);

      const downloadURL = await getDownloadURL(storageRef);

      const userRef = db.collection("Clients").doc(userId);
      await userRef.update({ img: downloadURL });

      setUserData((prevData) => ({
        ...prevData,
        img: downloadURL,
      }));

      Swal.fire({
        title: "Success",
        text: "Profile Picture Updated!",
        icon: "success",
      });
    } catch (error) {
      Swal.fire({
        title: "Oops!",
        text: "Profile picture size should be less than 1MB",
        icon: "info",
      });
    } finally {
      setLoader(false);
    }
  };

  if (loading) {
    return <div></div>;
  }
  return (
    <>
      <GoToTop />
      <div className="body_content" style={{ marginTop: "10vh" }}>
        <section className="breadcumb-section mt40">
          <div className="container">
            <div className="dashboard_title_area">
              <h2>My Profile</h2>
            </div>
            {/* <IFSC /> */}
            <div className="row">
              <div className="col-xl-12">
                <div className="ps-widget bgc-white bdrs4 p30 mb30 overflow-hidden position-relative">
                  <div className="col-xl-7">
                    <div className="profile-box d-sm-flex align-items-center mb30">
                      <div className="profile-img mb20-sm">
                        <img
                          className="rounded-circle wa-xs"
                          src={userData ? userData.img : ""}
                          style={{
                            width: "110px",
                            height: "115px",
                            objectFit: "cover",
                          }}
                          alt=""
                        />
                      </div>
                      <div className="profile-content ml20 ml0-xs">
                        <form onSubmit={handleProfilePic}>
                          <div className="d-flex align-items-center my-3">
                            <input
                              type="file"
                              accept="image/*"
                              onChange={handleImageUploadClient}
                            />
                            {loader ? (
                              <button
                                style={{ height: "34px", border: "none" }}
                                className="upload-btn ml10"
                                disabled
                              >
                                Loading
                              </button>
                            ) : (
                              <button
                                style={{ border: "none" }}
                                type="submit"
                                className="upload-btn ml10"
                              >
                                Upload Images
                              </button>
                            )}
                          </div>
                        </form>
                        <p className="text mb-0">
                          Max file size is 1MB and suitable files are .jpg &
                          .png
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <form
                      className="form-style1"
                      onSubmit={handleSubmitClients}
                    >
                      <div className="row">
                        <div className="col-sm-4">
                          <div className="mb20">
                            <label className="heading-color ff-heading fw500 mb10">
                              First Name
                            </label>
                            <input
                              value={fname}
                              onChange={(e) => setFname(e.target.value)}
                              required
                              type="text"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <div className="mb20">
                            <label className="heading-color ff-heading fw500 mb10">
                              Last Name
                            </label>
                            <input
                              value={lname}
                              onChange={(e) => setLname(e.target.value)}
                              type="text"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <div className="mb20">
                            <label className="heading-color ff-heading fw500 mb10">
                              Email Address
                            </label>
                            <input
                              type="email"
                              className="form-control"
                              value={userData ? userData.email : ""}
                              readOnly
                            />
                          </div>
                        </div>
                        <div className="col-sm-3">
                          <div className="mb20">
                            <label className="heading-color ff-heading fw500 mb10">
                              Phone Number
                            </label>
                            <input
                              value={contact}
                              onChange={(e) => setContact(e.target.value)}
                              required
                              type="tel"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-sm-3">
                          <div className="mb20">
                            <label className="heading-color ff-heading fw500 mb10">
                              Gender
                            </label>
                            <select
                              onChange={(e) => setGender(e.target.value)}
                              required
                              value={gender}
                              className="form-control"
                            >
                              <option value="" disabled>
                                Select
                              </option>
                              <option value="Male">Male</option>
                              <option value="Female">Female</option>
                              <option value="Other">Other</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-sm-3">
                          <div className="mb20">
                            <label className="heading-color ff-heading fw500 mb10">
                              Country
                            </label>
                            <input
                              onChange={(e) => setCountry(e.target.value)}
                              required
                              value={country}
                              type="text"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-sm-3">
                          <div className="mb20">
                            <label className="heading-color ff-heading fw500 mb10">
                              City
                            </label>
                            <input
                              onChange={(e) => setCity(e.target.value)}
                              required
                              value={city}
                              type="text"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-sm-3">
                          <div className="mb20">
                            <label className="heading-color ff-heading fw500 mb10">
                              Aadhar Number
                            </label>
                            <input
                              type="text"
                              value={aadhar}
                              disabled
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="col-sm-3">
                          <div className="mb20">
                            <label className="heading-color ff-heading fw500 mb10">
                              PAN Number
                            </label>
                            <input
                              value={pan}
                              disabled
                              type="text"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="mb10">
                            <label className="heading-color ff-heading fw500 mb10">
                              Introduce Yourself
                            </label>
                            <textarea
                              style={{ minHeight: "130px" }}
                              onChange={(e) => setAbout(e.target.value)}
                              required
                              value={about}
                              placeholder="Description"
                              className="form-control"
                            ></textarea>
                          </div>
                        </div>
                      </div>

                      <div
                        className="dashboard_title_area"
                        style={{ margin: "40px 0" }}
                      >
                        <h4>Professional Details:</h4>
                      </div>
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="mb20">
                            <label className="heading-color ff-heading fw500 mb10">
                              Tell us which languages you speak
                            </label>
                            <input
                              value={languages}
                              onChange={(e) => setLanguage(e.target.value)}
                              type="text"
                              placeholder="English, Hindi, Bangla, Tamil"
                              className="form-control"
                            />
                          </div>
                        </div>
                      </div>

                      <div
                        className="dashboard_title_area"
                        style={{ margin: "40px 0" }}
                      >
                        <h4>Bank Details:</h4>
                      </div>

                      <div className="row">
                        <div className="col-sm-4">
                          <div className="mb20">
                            <label className="heading-color ff-heading fw500 mb10">
                              IFSC Code
                            </label>
                            <input
                              required
                              maxlength="11"
                              minlength="11"
                              placeholder="IFSC&#32;Code"
                              value={ifsc}
                              onChange={handleIFSCChangeClient}
                              type="text"
                              className="form-control"
                            />{" "}
                            {loading && <span>Loading...</span>}
                          </div>
                        </div>

                        <div className="col-sm-4">
                          <div className="mb20">
                            <label className="heading-color ff-heading fw500 mb10">
                              Bank Name
                            </label>
                            <input
                              readOnly
                              required
                              style={{ border: "none" }}
                              value={bank}
                              onChange={(e) => setBank(e.target.value)}
                              type="text"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="col-sm-4">
                          <div className="mb20">
                            <label className="heading-color ff-heading fw500 mb10">
                              Bank Branch
                            </label>
                            <input
                              readOnly
                              required
                              style={{ border: "none" }}
                              value={branch}
                              onChange={(e) => setBranch(e.target.value)}
                              type="text"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="col-sm-8">
                          <div className="mb20">
                            <label className="heading-color ff-heading fw500 mb10">
                              Bank Address
                            </label>
                            <input
                              readOnly
                              style={{ border: "none" }}
                              value={bank_address}
                              onChange={(e) => setBAddress(e.target.value)}
                              type="text"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="col-sm-4">
                          <div className="mb20">
                            <label className="heading-color ff-heading fw500 mb10">
                              Benificiary Name
                            </label>
                            <input
                              required
                              value={benificiary}
                              onChange={(e) => setBenificiary(e.target.value)}
                              type="text"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="col-sm-6">
                          <div className="mb20">
                            <label className="heading-color ff-heading fw500 mb10">
                              Account Number
                            </label>
                            <input
                              required
                              value={accountNo}
                              onChange={(e) => setAccountNo(e.target.value)}
                              type="number"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="col-sm-6">
                          <div className="mb20">
                            <label className="heading-color ff-heading fw500 mb10">
                              Confirm Account Number
                            </label>
                            <input
                              required
                              value={confirmAccountNo}
                              onChange={(e) => setCAccountNo(e.target.value)}
                              type="number"
                              style={{ WebkitTextSecurity: "disc" }}
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="col-sm-6">
                          <div className="mb20">
                            <label className="heading-color ff-heading fw500 mb10">
                              PayPal
                            </label>
                            <input
                              value={paypal}
                              onChange={(e) => setPayPal(e.target.value)}
                              type="text"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="col-sm-6">
                          <div className="mb20">
                            <label className="heading-color ff-heading fw500 mb10">
                              IBAN
                            </label>
                            <input
                              value={iban}
                              onChange={(e) => setIban(e.target.value)}
                              type="text"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="col-sm-6">
                          <div className="mb20">
                            <label className="heading-color ff-heading fw500 mb10">
                              Swift
                            </label>
                            <input
                              value={swift}
                              onChange={(e) => setSwift(e.target.value)}
                              type="text"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="col-sm-6">
                          <div className="mb20">
                            <label className="heading-color ff-heading fw500 mb10">
                              Skrill
                            </label>
                            <input
                              value={skrill}
                              onChange={(e) => setSkrill(e.target.value)}
                              type="text"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="text-start">
                            <button className="ud-btn btn-thm" type="submit">
                              Update Profile
                              <i className="fal fa-arrow-right-long"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default MyProfile;
