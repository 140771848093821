import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { db } from "../firebase";
import { onSnapshot } from "firebase/firestore";
import GoToTop from "../GoToTop";
function ShopNow() {
  const [products, setProducts] = useState([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const unsub = onSnapshot(
      db.collection("OurProducts_M"),
      (snapshot) => {
        let list = [];
        snapshot.docs.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() });
        });
        setProducts(list);
        setLoading(false);
      },

      (error) => {
        console.error(error);
      }
    );
    return () => {
      unsub();
    };
  }, []);

  const formatPrice = (price) => {
    return new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
    }).format(price);
  };
  return (
    <>
      <GoToTop />
      <div class="body_content" style={{ marginTop: "10vh" }}>
        {/* <!-- Blog Section --> */}

        <section class="pt40 pb0">
          <div class="container">
            <div class="row">
              <div class="col-lg-12">
                <div class="main-title">
                  <h2 class="title">Our Products</h2>
                  <p class="text mb-0">
                    Empowering Your Vision: Crafting Tailored Solutions with Our
                    Custom Software Services
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!-- Shop Area --> */}
        <section class="shop-checkout pt-0">
          <div class="container">
            <div class="row wow fadeInUp" data-wow-delay="300ms">
              <div class="col-lg-3">
                <div class="shop-sidebar">
                  <div class="default-box-shadow1 mb30">
                    <div class="search_area">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Search"
                      />
                      <label>
                        <span class="flaticon-loupe"></span>
                      </label>
                    </div>
                  </div>
                  <div class="sidebar-accordion">
                    <div class="accordion" id="accordionExample2">
                      <div class="card mb20 pb0">
                        <div class="card-header active" id="headingTwos">
                          <h4>
                            <button
                              class="btn btn-link ps-0"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseTwos"
                              aria-expanded="true"
                              aria-controls="collapseTwos"
                            >
                              Category
                            </button>
                          </h4>
                        </div>
                        <div
                          id="collapseTwos"
                          class="collapse show"
                          aria-labelledby="headingTwos"
                          data-parent="#accordionExample"
                        >
                          <div class="card-body card-body px-0 pt-0">
                            <div class="checkbox-style1 mb15">
                              <label class="custom_checkbox">
                                Licences
                                {/* <!-- <input type="checkbox" /> -->
                                <!-- <span class="checkmark"></span> --> */}
                                <span class="right-tags">(1)</span>
                              </label>

                              <label class="custom_checkbox">
                                Web Development
                                {/* <!-- <span class="checkmark"></span> --> */}
                                <span class="right-tags">(4)</span>
                              </label>
                            </div>
                            {/* <!-- <a class="text-thm" href="">+20 more</a> --> */}
                          </div>
                        </div>
                      </div>
                      {/* <!-- <div class="card mb20 pb5">
                        <div class="card-header active" id="headingOnes">
                          <h4>
                            <button
                              class="btn btn-link ps-0"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseOnes"
                              aria-expanded="true"
                              aria-controls="collapseOnes"
                            >
                              Price
                            </button>
                          </h4>
                        </div>
                        <div
                          id="collapseOnes"
                          class="collapse show"
                          aria-labelledby="headingOnes"
                          data-parent="#accordionExample"
                        >
                          <div class="card-body card-body px-0 pt-0">
                            <div class="range-slider-style2">
                              <div class="range-wrapper">
                                <div class="mb10 mt15" id="slider"></div>
                                <div
                                  class="d-flex align-items-center justify-content-center"
                                >
                                  <span id="slider-range-value1"></span
                                  ><i
                                    class="fa-sharp fa-solid fa-minus mx-2 dark-color icon"
                                  ></i>
                                  <span id="slider-range-value2"></span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> --> */}
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-9">
                <div class="row mb20">
                  <div class="col-sm-6">
                    <div class="text-center text-sm-start">
                      <p class="text mb-0">
                        <span class="fw500">{products.length}</span> services
                        available
                      </p>
                    </div>
                  </div>
                </div>
                <div class="row">
                  {products &&
                    products.map((item, index) => (
                      <div class="col-sm-6 col-xl-4">
                        <div class="shop-item text-center">
                          <div class="thumb">
                            <img
                              class="w-100"
                              src="images/shop/udemy.jpg"
                              alt=""
                            />
                          </div>
                          <div class="details">
                            <p class="mb10">{item.subtitle}</p>
                            <h5 class="mb10" style={{ fontWeight: "900" }}>
                              {item.title}
                            </h5>
                            <h5 class="mb20">
                              {formatPrice(item.price)}
                              <span style={{ fontSize: "10px", color: "grey" }}>
                                {item.forex_charge ? "+ Forex Charge" : "GST"}
                              </span>
                            </h5>
                            <p
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                navigate(`/shop_now_detail/${item.id}`)
                              }
                              class="ud-btn btn-light-thm"
                            >
                              View Details
                              <i class="fal fa-arrow-right-long"></i>
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}

                  {/* <!-- <div class="col-sm-6 col-xl-4">
                    <div class="shop-item text-center">
                      <div class="thumb">
                        <img class="w-100" src="images/shop/9.png" alt="" />
                      </div>
                      <div class="details">
                        <p class="mb10">Clothing, Glasses, T-Shirts</p>
                        <h5 class="mb10">Front Originals</h5>
                        <h5 class="mb20">$55.00</h5>
                        <a
                          href="page-shop-cart.html"
                          class="ud-btn btn-light-thm"
                          >Add to cart<i class="fal fa-arrow-right-long"></i
                        ></a>
                      </div>
                    </div>
                  </div> --> */}
                </div>
                {/* <!-- <div class="row">
                  <div class="mbp_pagination text-center mt30">
                    <ul class="page_navigation">
                      <li class="page-item">
                        <a class="page-link" href="#">
                          <span class="fas fa-angle-left"></span
                        ></a>
                      </li>
                      <li class="page-item">
                        <a class="page-link" href="#">1</a>
                      </li>
                      <li class="page-item active" aria-current="page">
                        <a class="page-link" href="#"
                          >2 <span class="sr-only">(current)</span></a
                        >
                      </li>
                      <li class="page-item">
                        <a class="page-link" href="#">3</a>
                      </li>
                      <li class="page-item">
                        <a class="page-link" href="#">4</a>
                      </li>
                      <li class="page-item">
                        <a class="page-link" href="#">5</a>
                      </li>
                      <li class="page-item">
                        <a class="page-link" href="#">...</a>
                      </li>
                      <li class="page-item">
                        <a class="page-link" href="#">20</a>
                      </li>
                      <li class="page-item">
                        <a class="page-link" href="#"
                          ><span class="fas fa-angle-right"></span
                        ></a>
                      </li>
                    </ul>
                    <p class="mt10 mb-0 pagination_page_count text-center">
                      1 – 20 of 300+ property available
                    </p>
                  </div>
                </div> --> */}
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default ShopNow;
