import React from "react";
import GoToTop from "../GoToTop";
function About() {
  return (
    <>
      <GoToTop />
      <div class="body_content" style={{ marginTop: "10vh" }}>
        {/* <!-- Breadcumb Sections --> */}
        <section class="breadcumb-section mt40">
          <div class="cta-about-v1 mx-auto maxw1600 pt200 pb120 bdrs16 position-relative overflow-hidden d-flex align-items-center mx20-lg">
            <div class="container">
              <div class="row">
                <div class="col-xl-8">
                  <div class="position-relative">
                    <h2 class="text-white">About</h2>
                    <p class="text-white mb30">
                      Our Attractive appealing web designs bring you more
                      business, more customers, more revenue.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!-- About Section Area --> */}
        <section class="our-about pb0 pt60-lg">
          <div class="container">
            <div class="row align-items-center">
              <div class="col-md-6 col-xl-6">
                <div
                  class="about-img mb30-sm wow fadeInRight"
                  data-wow-delay="300ms"
                >
                  <img class="w100" src="images/about/about-1.png" alt="" />
                </div>
              </div>
              <div class="col-md-6 col-xl-5 offset-xl-1">
                <div
                  class="position-relative wow fadeInLeft"
                  data-wow-delay="300ms"
                >
                  <h2 class="mb25">About FXCTS</h2>
                  <p class="text mb10" style={{ textAlign: "justify" }}>
                    Welcome to FXCTS - Your Path to Successful Freelancing!
                    <br />
                    <br />
                    FXCTS is a vibrant freelance marketplace that gives you the
                    freedom to promote your abilities, network with clients, and
                    make a living at your own pace. Our platform provides
                    convenience and opportunities for freelancers at any level
                    of their careers with an easy user experience and a wide
                    choice of categories to pick from. Unlock your potential,
                    take advantage of flexibility and freedom, rely on our safe
                    platform, profit from our network and support, and advance
                    your career. <br />
                    <br />
                    Join FXCTS now to create a profile, network with clients,
                    and start a successful freelancing career. Join today to
                    realise your potential as a freelancer!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!-- Funfact --> */}
        <section class="pb0 pt60">
          <div class="container maxw1600 bdrb1 pb60">
            <div
              class="row justify-content-center wow fadeInUp"
              data-wow-delay="300ms"
            >
              <div class="col-6 col-md-3">
                <div class="funfact_one text-center">
                  <div class="details">
                    <ul class="ps-0 mb-0 d-flex justify-content-center">
                      <li>
                        <div class="timer">250</div>
                      </li>
                      <li>
                        <span>K</span>
                      </li>
                    </ul>
                    <p class="text mb-0">Total Freelancer</p>
                  </div>
                </div>
              </div>
              <div class="col-6 col-md-3">
                <div class="funfact_one text-center">
                  <div class="details">
                    <ul class="ps-0 mb-0 d-flex justify-content-center">
                      <li>
                        <div class="timer">150</div>
                      </li>
                      <li>
                        <span>K</span>
                      </li>
                    </ul>
                    <p class="text mb-0">Positive Review</p>
                  </div>
                </div>
              </div>
              <div class="col-6 col-md-3">
                <div class="funfact_one text-center">
                  <div class="details">
                    <ul class="ps-0 mb-0 d-flex justify-content-center">
                      <li>
                        <div class="timer">12</div>
                      </li>
                      <li>
                        <span>K</span>
                      </li>
                    </ul>
                    <p class="text mb-0">Order recieved</p>
                  </div>
                </div>
              </div>
              <div class="col-6 col-md-3">
                <div class="funfact_one text-center">
                  <div class="details">
                    <ul class="ps-0 mb-0 d-flex justify-content-center">
                      <li>
                        <div class="timer">85</div>
                      </li>
                      <li>
                        <span>K</span>
                      </li>
                    </ul>
                    <p class="text mb-0">Projects Completed</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!-- CTA Banner --> */}
        <section class="p-0">
          <div class="cta-banner mx-auto maxw1600 pt120 pt60-lg pb90 pb60-lg position-relative overflow-hidden mx20-lg">
            <div class="container">
              <div class="row align-items-center">
                <div
                  class="col-md-6 col-xl-5 pl30-md pl15-xs wow fadeInRight"
                  data-wow-delay="500ms"
                >
                  <div class="mb30">
                    <div class="main-title">
                      <h2 class="title">
                        A whole world of freelance
                        <br class="d-none d-lg-block" />
                        talent at your fingertips
                      </h2>
                    </div>
                  </div>
                  <div class="why-chose-list">
                    <div class="list-one d-flex align-items-start mb30">
                      <span class="list-icon flex-shrink-0 flaticon-badge"></span>
                      <div class="list-content flex-grow-1 ml20">
                        <h4 class="mb-1">Proof of quality</h4>
                        <p class="text mb-0 fz15">
                          Check any pro’s work samples, client reviews, and
                          identity <br class="d-none d-lg-block" />
                          verification.
                        </p>
                      </div>
                    </div>
                    <div class="list-one d-flex align-items-start mb30">
                      <span class="list-icon flex-shrink-0 flaticon-money"></span>
                      <div class="list-content flex-grow-1 ml20">
                        <h4 class="mb-1">No cost until you hire</h4>
                        <p class="text mb-0 fz15">
                          Interview potential fits for your job, negotiate
                          rates, and only pay <br class="d-none d-lg-block" />
                          for work you approve.
                        </p>
                      </div>
                    </div>
                    <div class="list-one d-flex align-items-start mb30">
                      <span class="list-icon flex-shrink-0 flaticon-security"></span>
                      <div class="list-content flex-grow-1 ml20">
                        <h4 class="mb-1">Safe and secure</h4>
                        <p class="text mb-0 fz15">
                          Focus on your work knowing we help protect your data
                          and privacy. We’re here with 24/7 support if you need
                          it.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="col-md-6 col-xl-6 offset-xl-1 wow fadeInLeft"
                  data-wow-delay="500ms"
                >
                  <div class="about-img">
                    <img class="w100" src="images/about/about-6.jpg" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!-- Our Funfact --> */}
        <section class="bgc-light-yellow pb90 pb30-md overflow-hidden maxw1700 mx-auto bdrs4">
          <img
            class="left-top-img wow zoomIn d-none d-lg-block"
            src="images/vector-img/left-top.png"
            alt=""
          />
          <img
            class="right-bottom-img wow zoomIn d-none d-lg-block"
            src="images/vector-img/right-bottom.png"
            alt=""
          />
          <div class="container">
            <div class="row align-items-center">
              <div
                class="col-md-6 col-xl-4 offset-xl-1 wow fadeInRight"
                data-wow-delay="100ms"
              >
                <div class="cta-style6 mb30-sm">
                  <h2 class="cta-title mb25">
                    Find the talent needed to
                    <br class="d-none d-lg-block" />
                    get your business growing.
                  </h2>
                  <p class="text-thm2 fz15 mb25">
                    Accelerate Your Business's Growth Trajectory:
                    <br class="d-none d-md-block" />
                    Strategies for Success.
                  </p>
                  <a
                    href="https://admin.falconxx.in/#/register_auth"
                    target="_blank"
                    class="ud-btn btn-thm"
                  >
                    Get Started <i class="fal fa-arrow-right-long"></i>
                  </a>
                </div>
              </div>
              <div
                class="col-md-6 col-xl-6 offset-xl-1 wow fadeInLeft"
                data-wow-delay="300ms"
              >
                <div class="row align-items-center">
                  <div class="col-sm-6">
                    <div class="funfact-style1 bdrs16 text-center ms-md-auto">
                      <ul class="ps-0 mb-0 d-flex justify-content-center">
                        <li>
                          <div class="timer title mb15">4</div>
                        </li>
                        <li>
                          <span>.9/5</span>
                        </li>
                      </ul>
                      <p class="fz15 dark-color">
                        Clients rate <br />
                        professionals on FXCTS
                      </p>
                    </div>
                    <div class="funfact-style1 bdrs16 text-center ms-md-auto">
                      <ul class="ps-0 mb-0 d-flex justify-content-center">
                        <li>
                          <div class="timer title mb15">96</div>
                        </li>
                        <li>
                          <span>%</span>
                        </li>
                      </ul>
                      <p class="fz15 dark-color">
                        96% of customers are satisfied through to see their
                        <br />
                        freelancers
                      </p>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="funfact-style1 bdrs16 text-center">
                      <img
                        class="service-v1-vector bounce-y d-none d-lg-block"
                        src="images/vector-img/vector-service-v1.png"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!-- CTA Banner --> */}
        <section class="cta-banner-about2 mx-auto maxw1700 position-relative mx20-lg pt60-lg pb60-lg">
          <img
            class="cta-about2-img d-none d-xl-block"
            src="images/about/about-7.jpg"
            alt=""
          />
          <div class="container">
            <div class="row">
              <div class="col-md-11 wow fadeInUp" data-wow-delay="200ms">
                <div class="main-title">
                  <h2 class="title text-capitalize">Need something done?</h2>
                  <p class="text">
                    Most viewed and all-time top-selling services
                  </p>
                </div>
              </div>
            </div>
            <div class="row wow fadeInDown" data-wow-delay="400ms">
              <div class="col-sm-6 col-lg-4 col-xl-3">
                <div class="iconbox-style9 default-box-shadow1 bgc-white p40 bdrs12 position-relative mb30">
                  <span class="icon fz40 flaticon-cv"></span>
                  <h4 class="iconbox-title mt20">Post a job</h4>
                  <p class="text mb-0">
                    It’s free and easy to post a job.
                    <br class="d-none d-md-block" />
                    Simply fill in a title, description.
                  </p>
                </div>
              </div>
              <div class="col-sm-6 col-lg-4 col-xl-3">
                <div class="iconbox-style9 default-box-shadow1 bgc-white p40 bdrs12 position-relative mb30">
                  <span class="icon fz40 flaticon-web-design"></span>
                  <h4 class="iconbox-title mt20">Choose freelancers</h4>
                  <p class="text mb-0">
                    Tap into a World of Talent:
                    <br class="d-none d-md-block" />
                    Choose Freelancers for Unparalleled Expertise!
                  </p>
                </div>
              </div>
              <div class="col-sm-6 col-lg-4 col-xl-3">
                <div class="iconbox-style9 default-box-shadow1 bgc-white p40 bdrs12 position-relative mb30">
                  <span class="icon fz40 flaticon-secure"></span>
                  <h4 class="iconbox-title mt20">Pay safely</h4>
                  <p class="text mb-0">
                    Safe and Secure Payments: <br class="d-none d-md-block" />
                    Trustworthy Transactions Guaranteed!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!-- Faq Area --> */}
        <section class="our-faq pb90">
          <div class="container">
            <div class="row">
              <div class="col-lg-6 m-auto wow fadeInUp" data-wow-delay="300ms">
                <div class="main-title text-center">
                  <h2 class="title">Frequently Asked Questions</h2>
                </div>
              </div>
            </div>
            <div class="row wow fadeInUp" data-wow-delay="300ms">
              <div class="col-xl-8 mx-auto">
                <div class="ui-content">
                  <div class="accordion-style1 faq-page mb-4 mb-lg-5">
                    <div class="accordion" id="accordionExample">
                      <div class="accordion-item active">
                        <h2 class="accordion-header" id="headingOne">
                          <button
                            class="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseOne"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                          >
                            What methods of payments are supported?
                          </button>
                        </h2>
                        <div
                          id="collapseOne"
                          class="accordion-collapse collapse show"
                          aria-labelledby="headingOne"
                          data-parent="#accordionExample"
                        >
                          <div class="accordion-body">
                            We support various methods of payment to ensure
                            convenience and flexibility for our customers. Here
                            are some of the commonly accepted payment methods:
                            <br />
                            <br />
                            Credit/Debit Cards: We accept major credit and debit
                            cards, including Visa, Mastercard, American Express,
                            and Discover. This allows for secure and
                            straightforward online transactions.
                            <br />
                            <br />
                            Bank Transfers: You can make payments through direct
                            bank transfers or wire transfers. This option is
                            suitable for customers who prefer transferring funds
                            directly from their bank accounts.
                            <br />
                            <br />
                            Online Payment Platforms: We also accept payments
                            through popular online payment platforms such as
                            PayPal, Stripe, and Google Pay. These platforms
                            offer secure and convenient payment processing.
                            <br />
                            <br />
                            Mobile Wallets: If you prefer using mobile payment
                            apps, we support popular options like Apple Pay and
                            Samsung Pay. These mobile wallets enable quick and
                            contactless transactions.
                            <br />
                            <br />
                            Cryptocurrencies: In some cases, we may accept
                            payments in cryptocurrencies such as Bitcoin,
                            Ethereum, or other digital currencies. This offers
                            an additional level of privacy and security for
                            certain transactions.
                            <br />
                            <br />
                            It's important to note that the specific payment
                            methods available may vary depending on your
                            location, the nature of the transaction, and any
                            applicable regulations. We strive to offer a range
                            of payment options to accommodate different
                            preferences and ensure a smooth payment experience
                            for our customers.
                          </div>
                        </div>
                      </div>
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="headingTwo">
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseTwo"
                            aria-expanded="false"
                            aria-controls="collapseTwo"
                          >
                            Can I cancel at anytime?
                          </button>
                        </h2>
                        <div
                          id="collapseTwo"
                          class="accordion-collapse collapse"
                          aria-labelledby="headingTwo"
                          data-parent="#accordionExample"
                        >
                          <div class="accordion-body">
                            The ability to cancel depends on the specific terms
                            and conditions of the service or product you are
                            referring to. In many cases, cancellation policies
                            are determined by the provider or seller. It's
                            important to review the terms of service or purchase
                            agreement to understand the cancellation policy and
                            any associated fees or requirements.
                            <br />
                            <br />
                            Some services or subscriptions may allow for
                            cancellation at any time, while others may have
                            specific conditions or notice periods. For products,
                            cancellation policies can vary depending on factors
                            such as return policies, warranties, or refund
                            eligibility.
                            <br />
                            <br />
                            To ensure clarity and avoid any potential issues, it
                            is advisable to familiarize yourself with the
                            cancellation policy before making a purchase or
                            entering into an agreement. If you have specific
                            concerns or questions regarding cancellation, it is
                            best to reach out to the provider or seller directly
                            for further information and clarification.
                          </div>
                        </div>
                      </div>
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="headingThree">
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseThree"
                            aria-expanded="false"
                            aria-controls="collapseThree"
                          >
                            How do I get a receipt for my purchase?
                          </button>
                        </h2>
                        <div
                          id="collapseThree"
                          class="accordion-collapse collapse"
                          aria-labelledby="headingThree"
                          data-parent="#accordionExample"
                        >
                          <div class="accordion-body">
                            To obtain a receipt for your completed projects, you
                            can follow these general steps: <br />
                            <br />
                            Contact the provider or freelancer: Reach out to the
                            individual or company with whom you worked on the
                            project. They should be able to assist you in
                            obtaining a receipt.
                            <br />
                            <br />
                            Request a receipt: Clearly communicate that you
                            require a receipt for the completed project. Provide
                            any relevant details such as the project name,
                            completion date, and payment information to
                            facilitate the process.
                            <br />
                            <br />
                            Specify receipt format: If you have specific
                            requirements for the receipt format (e.g., digital
                            or physical copy, specific details to be included),
                            make sure to communicate your preferences to the
                            provider.
                            <br />
                            <br />
                            Confirm delivery method: Determine how you would
                            like to receive the receipt. It can be emailed, sent
                            via a messaging platform, or delivered through
                            postal mail, depending on your preference and the
                            provider's capabilities.
                            <br />
                            <br />
                            Review and verify the receipt: Once you receive the
                            receipt, carefully review its contents to ensure
                            accuracy. Check that all necessary information,
                            including payment details, project description, and
                            relevant dates, are included and correct.
                            <br />
                            <br />
                            Store and organize the receipt: Save a copy of the
                            receipt for your records. You may want to keep it in
                            a designated folder or file for future reference,
                            accounting purposes, or reimbursement needs.
                            <br />
                            <br />
                            Remember, the specific process and details for
                            obtaining a receipt may vary depending on the
                            provider or freelancer you worked with. It's always
                            best to communicate your request directly and
                            promptly to ensure a smooth receipt issuance
                            process.
                          </div>
                        </div>
                      </div>

                      <div class="accordion-item">
                        <h2 class="accordion-header" id="headingFour">
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseFour"
                            aria-expanded="false"
                            aria-controls="collapseFour"
                          >
                            How to upload a file in order project?
                          </button>
                        </h2>
                        <div
                          id="collapseFour"
                          class="accordion-collapse collapse"
                          aria-labelledby="headingFour"
                          data-parent="#accordionExample"
                        >
                          <div class="accordion-body">
                            To upload a file in an Order Project section, you
                            typically need access to the Project management
                            system (PMS) or website platform where the
                            <a href="https://admin.falconxx.in/#/add_projects_clients">
                              <u>
                                https://admin.falconxx.in/#/add_projects_clients
                              </u>
                            </a>
                            . The exact steps may vary depending on the specific
                            PMS or platform you are using, but here's a general
                            outline of the process: <br />
                            <br />
                            <ol>
                              <li>
                                <i
                                  class="fa fa-angle-double-right"
                                  aria-hidden="true"
                                ></i>
                                Access the PMS or website : Log in to the
                                https://admin.falconxx.in/#/add_projects_clients
                                or website administration panel using your
                                credentials.
                              </li>
                              <li>
                                <i
                                  class="fa fa-angle-double-right"
                                  aria-hidden="true"
                                ></i>
                                Navigate to the section: Find the section or
                                page where the file is located.
                              </li>

                              {/* <li>
                                <i
                                  class="fa fa-angle-double-right"
                                  aria-hidden="true"
                                ></i>
                                <i
                                  >Note: Client has to check the filename before
                                  uploading as mentioned below:<br />
                                  <span style="color: red"
                                    >{<b>Fxcts upload .png</b> file will not be
                                    uploaded , you need to upload
                                    <span style="color: green"
                                      >Fxcts upload.png</span
                                    >
                                    or any other extension}</span
                                  >
                                </i>
                              </li> */}
                              <li>
                                <i
                                  class="fa fa-angle-double-right"
                                  aria-hidden="true"
                                ></i>
                                Remember to consider any restrictions or
                                limitations on file uploads, such as file size
                                restrictions or permitted file types, as
                                specified by your PMS or platform. Additionally,
                                it's good practice to provide clear instructions
                                for users on file requirements, if applicable,
                                and any steps they need to take to upload files
                                successfully.
                              </li>
                              <li>
                                <i
                                  class="fa fa-angle-double-right"
                                  aria-hidden="true"
                                ></i>
                                If you're working with a specific PMS or website
                                platform, consulting its documentation or
                                support resources can provide more detailed
                                instructions tailored to your situation.
                              </li>
                            </ol>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <a class="scrollToHome" href="#">
          <i class="fas fa-angle-up"></i>
        </a>
      </div>
    </>
  );
}

export default About;
