import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { db, auth } from "../../firebase";
import { doc, collection, getDoc, serverTimestamp } from "firebase/firestore";
import { onSnapshot } from "firebase/firestore";
import GoToTop from "../../GoToTop";
import { format } from "date-fns";
const initialState = {
  fname: "",
  lname: "",
};

function DetailFreelancer() {
  const [data, setData] = useState(initialState);

  const {
    fname,
    lname,
    designation,
    about,
    brief,
    services,
    createdate,
    languages,
    img,
    skills,
    gender,
    rate,
    city,
    country,
  } = data;
  const navigate = useNavigate();

  const { id } = useParams();

  useEffect(() => {
    id && getSinglePhoto();
  }, [id]);

  const getSinglePhoto = async () => {
    const docRef = doc(db, "Freelancers", id);
    const snapshot = await getDoc(docRef, "Freelancers");
    if (snapshot.exists()) {
      setData({ ...snapshot.data() });
    }
  };

  const formattedDate = createdate
    ? format(createdate.toDate(), "MMMM d, yyyy")
    : "Date not available";

  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const userId = auth.currentUser ? auth.currentUser.uid : null;
  useEffect(() => {
    const fetchUserData = async () => {
      if (userId) {
        try {
          // Try fetching from the Freelancers collection
          const freelancerDocRef = db.collection("Freelancers").doc(userId);
          const freelancerDoc = await freelancerDocRef.get();

          if (freelancerDoc.exists) {
            const userData = freelancerDoc.data();
            setUserData(userData);
          } else {
            // If not found in Freelancers, try fetching from the Clients collection
            const clientDocRef = db.collection("Clients").doc(userId);
            const clientDoc = await clientDocRef.get();

            if (clientDoc.exists) {
              const userData = clientDoc.data();
              setUserData(userData);
            } else {
              console.log("User document does not exist in either collection");
            }
          }
        } catch (error) {
          console.log("Error getting user document:", error);
        }
      } else {
        console.log("User is not authenticated");
      }

      setLoading(false);
    };

    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        fetchUserData();
      } else {
        setLoading(false);
        console.log("User is not authenticated");
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  let chatID = userId + "-" + id;

  const handleSubmit = async () => {
    if (userData) {
      const chatDocRef = db.collection("Chats").doc(chatID);

      try {
        const chatDoc = await chatDocRef.get();

        if (chatDoc.exists) {
          navigate("/chats");
        } else {
          await chatDocRef.set({
            clientID: userId,
            clientName: userData.fname,
            clientEMail: userData.email,
            freelancerID: id,
            idd: userId + "-" + id,
          });
          navigate("/chats");
        }
      } catch (error) {
        console.error("Error submitting user data:", error);
        navigate("/chats");
      }
    }
  };

  if (loading) {
    return <div class="preloader"></div>;
  }

  return (
    <>
      <GoToTop />
      <div class="body_content">
        {/* <section class="categories_list_section overflow-hidden">
          <div class="container">
            <div class="row">
              <div class="col-lg-12">
                <div class="listings_category_nav_list_menu">
                  <ul class="mb0 d-flex ps-0">
                    <li>
                      <a href="#">All Categories</a>
                    </li>
                    <li>
                      <a href="#">Graphics & Design</a>
                    </li>
                    <li>
                      <a class="active" href="#">
                        Digital Marketing
                      </a>
                    </li>
                    <li>
                      <a href="#">Writing & Translation</a>
                    </li>
                    <li>
                      <a href="#">Video & Animation</a>
                    </li>
                    <li>
                      <a href="#">Music & Audio</a>
                    </li>
                    <li>
                      <a href="#">Programming & Tech</a>
                    </li>
                    <li>
                      <a href="#">Business</a>
                    </li>
                    <li>
                      <a href="#">Lifestyle</a>
                    </li>
                    <li>
                      <a href="#">Trending</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        {/* <!-- Breadcumb Sections --> */}
        <section class="breadcumb-section">
          <div class="container">
            <div class="row">
              <div class="col-sm-8 col-lg-10">
                <div class="breadcumb-style1 mb10-xs">
                  <div class="breadcumb-list">
                    <a href="">Home</a>
                    <a href="">Freelancers</a>
                    <a href="">{fname}</a>
                  </div>
                </div>
              </div>
              <div class="col-sm-4 col-lg-2">
                <div class="d-flex align-items-center justify-content-sm-end">
                  <div class="share-save-widget d-flex align-items-center">
                    <span class="icon flaticon-share dark-color fz12 mr10"></span>
                    <div class="h6 mb-0">Share</div>
                  </div>
                  <div class="share-save-widget d-flex align-items-center ml15">
                    <span class="icon flaticon-like dark-color fz12 mr10"></span>
                    <div class="h6 mb-0">Save</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- Breadcumb Sections --> */}
        <section class="breadcumb-section pt-0">
          <div
            style={{ backgroundColor: "#d3e2f2" }}
            class="freelancer-single-style mx-auto maxw1700 pt120 pt60-sm pb120 pb60-sm bdrs16 position-relative overflow-hidden d-flex align-items-center mx20-lg px30-lg"
          >
            <img
              class="left-top-img wow zoomIn"
              src="images/vector-img/left-top.png"
              alt=""
            />
            <img
              class="right-bottom-img wow zoomIn"
              src="images/vector-img/right-bottom.png"
              alt=""
            />
            <div class="container">
              <div class="row wow fadeInUp">
                <div class="col-xl-7">
                  <div class="position-relative">
                    <h2>{brief}</h2>
                    <div class="list-meta d-sm-flex align-items-center mt30">
                      <a
                        class="position-relative freelancer-single-style"
                        href=""
                      >
                        <span class="online"></span>
                        <img
                          class="rounded-circle"
                          src={img}
                          style={{
                            width: "100px",
                            height: "100px",
                            objectFit: "cover",
                          }}
                          alt="Freelancer Photo"
                        />
                      </a>
                      <div class="ml20 ml0-xs">
                        <h5 class="title mb-1">
                          {fname} {lname}
                        </h5>
                        <p class="mb-0">{designation}</p>
                        {/* <p class="mb-0 dark-color fz15 fw500 list-inline-item mb5-sm">
                          <i class="fas fa-star vam fz10 review-color me-2"></i>{" "}
                          4.82 94 reviews
                        </p> */}
                        <p class="mb-0 dark-color fz15 fw500 list-inline-item ml15 mb5-sm ml0-xs">
                          <i class="flaticon-place vam fz20 me-2"></i> {city},{" "}
                          {country}
                        </p>
                        <p class="mb-0 dark-color fz15 fw500 list-inline-item ml15 mb5-sm ml0-xs">
                          <i class="flaticon-30-days vam fz20 me-2"></i> Member
                          since {formattedDate}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!-- Service Details --> */}
        <section class="pt10 pb90 pb30-md">
          <div class="container">
            <div class="row wow fadeInUp">
              <div class="col-lg-8">
                {/* <div class="row">
                  <div class="col-sm-6 col-xl-3">
                    <div class="iconbox-style1 contact-style d-flex align-items-start mb30">
                      <div class="icon flex-shrink-0">
                        <span class="flaticon-target"></span>
                      </div>
                      <div class="details">
                        <h5 class="title">Job Success</h5>
                        <p class="mb-0 text">98%</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6 col-xl-3">
                    <div class="iconbox-style1 contact-style d-flex align-items-start mb30">
                      <div class="icon flex-shrink-0">
                        <span class="flaticon-goal"></span>
                      </div>
                      <div class="details">
                        <h5 class="title">Total Jobs</h5>
                        <p class="mb-0 text">921</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6 col-xl-3">
                    <div class="iconbox-style1 contact-style d-flex align-items-start mb30">
                      <div class="icon flex-shrink-0">
                        <span class="flaticon-fifteen"></span>
                      </div>
                      <div class="details">
                        <h5 class="title">Total Hours</h5>
                        <p class="mb-0 text">1,499</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6 col-xl-3">
                    <div class="iconbox-style1 contact-style d-flex align-items-start mb30">
                      <div class="icon flex-shrink-0">
                        <span class="flaticon-file-1"></span>
                      </div>
                      <div class="details">
                        <h5 class="title">In Queue Service</h5>
                        <p class="mb-0 text">20</p>
                      </div>
                    </div>
                  </div>
                </div> */}
                <div class="service-about">
                  <h4>Description</h4>
                  <p class="text mb30">{about}</p>
                  <hr class="opacity-100 mb60 mt60" />
                  {/* <h4 class="mb30">Education</h4> */}
                  {/* <div class="educational-quality">
                    <div class="m-circle text-thm">M</div>
                    <div class="wrapper mb40">
                      <span class="tag">2012 - 2014</span>
                      <h5 class="mt15">Bachlors in Fine Arts</h5>
                      <h6 class="text-thm">Modern College</h6>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Proin a ipsum tellus. Interdum et malesuada fames ac
                        ante ipsum primis in faucibus.
                      </p>
                    </div>
                    <div class="m-circle before-none text-thm">M</div>
                    <div class="wrapper mb60">
                      <span class="tag">2008 - 2012</span>
                      <h5 class="mt15">Computer Science</h5>
                      <h6 class="text-thm">Harvartd University</h6>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Proin a ipsum tellus. Interdum et malesuada fames ac
                        ante ipsum primis in faucibus.
                      </p>
                    </div>
                  </div>
                  <hr class="opacity-100 mb60" />
                  <h4 class="mb30">Work & Experience</h4>
                  <div class="educational-quality">
                    <div class="m-circle text-thm">M</div>
                    <div class="wrapper mb40">
                      <span class="tag">2012 - 2014</span>
                      <h5 class="mt15">UX Designer</h5>
                      <h6 class="text-thm">Dropbox</h6>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Proin a ipsum tellus. Interdum et malesuada fames ac
                        ante ipsum primis in faucibus.
                      </p>
                    </div>
                    <div class="m-circle before-none text-thm">M</div>
                    <div class="wrapper mb60">
                      <span class="tag">2008 - 2012</span>
                      <h5 class="mt15">Art Director</h5>
                      <h6 class="text-thm">amazon</h6>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Proin a ipsum tellus. Interdum et malesuada fames ac
                        ante ipsum primis in faucibus.
                      </p>
                    </div>
                  </div>
                  <hr class="opacity-100 mb60" />
                  <h4 class="mb30">Awards adn Certificates</h4>
                  <div class="educational-quality ps-0">
                    <div class="wrapper mb40">
                      <span class="tag">2012 - 2014</span>
                      <h5 class="mt15">UI UX Design</h5>
                      <h6 class="text-thm">Udemy</h6>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Proin a ipsum tellus. Interdum et malesuada fames ac
                        ante ipsum <br class="d-none d-lg-block" /> primis in
                        faucibus.
                      </p>
                    </div>
                    <div class="wrapper mb60">
                      <span class="tag">2008 - 2012</span>
                      <h5 class="mt15">App Design</h5>
                      <h6 class="text-thm">Google</h6>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Proin a ipsum tellus. Interdum et malesuada fames ac
                        ante ipsum <br class="d-none d-lg-block" /> primis in
                        faucibus.
                      </p>
                    </div>
                  </div>
                  <hr class="opacity-100 mb60" />
                  <h4 class="mb30">Featured Services</h4>
                  <div class="row mb35">
                    <div class="col-sm-6 col-xl-4">
                      <div class="listing-style1">
                        <div class="list-thumb">
                          <img
                            class="w-100"
                            src="images/listings/g-1.jpg"
                            alt=""
                          />
                          <a href="" class="listing-fav fz12">
                            <span class="far fa-heart"></span>
                          </a>
                        </div>
                        <div class="list-content">
                          <p class="list-text body-color fz14 mb-1">
                            Web & App Design
                          </p>
                          <h6 class="list-title">
                            <a href="page-services-single.html">
                              I will design modern websites in figma or adobe xd
                            </a>
                          </h6>
                          <div class="review-meta d-flex align-items-center">
                            <i class="fas fa-star fz10 review-color me-2"></i>
                            <p class="mb-0 body-color fz14">
                              <span class="dark-color me-2">4.82</span>94
                              reviews
                            </p>
                          </div>
                          <hr class="my-2" />
                          <div class="list-meta mt15">
                            <div class="budget">
                              <p class="mb-0 body-color">
                                Starting at
                                <span class="fz17 fw500 dark-color ms-1">
                                  $983
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-6 col-xl-4">
                      <div class="listing-style1">
                        <div class="list-thumb">
                          <div class="listing-thumbIn-slider position-relative navi_pagi_bottom_center slider-1-grid owl-carousel owl-theme">
                            <div class="item">
                              <img
                                class="w-100"
                                src="images/listings/g-2.jpg"
                                alt=""
                              />
                              <a href="" class="listing-fav fz12">
                                <span class="far fa-heart"></span>
                              </a>
                            </div>
                            <div class="item">
                              <img
                                class="w-100"
                                src="images/listings/g-3.jpg"
                                alt=""
                              />
                              <a href="" class="listing-fav fz12">
                                <span class="far fa-heart"></span>
                              </a>
                            </div>
                            <div class="item">
                              <img
                                class="w-100"
                                src="images/listings/g-4.jpg"
                                alt=""
                              />
                              <a href="" class="listing-fav fz12">
                                <span class="far fa-heart"></span>
                              </a>
                            </div>
                            <div class="item">
                              <img
                                class="w-100"
                                src="images/listings/g-5.jpg"
                                alt=""
                              />
                              <a href="" class="listing-fav fz12">
                                <span class="far fa-heart"></span>
                              </a>
                            </div>
                          </div>
                        </div>
                        <div class="list-content">
                          <p class="list-text body-color fz14 mb-1">
                            Art & Illustration
                          </p>
                          <h6 class="list-title">
                            <a href="page-services-single.html">
                              I will create modern flat design illustration
                            </a>
                          </h6>
                          <div class="review-meta d-flex align-items-center">
                            <i class="fas fa-star fz10 review-color me-2"></i>
                            <p class="mb-0 body-color fz14">
                              <span class="dark-color me-2">4.82</span>94
                              reviews
                            </p>
                          </div>
                          <hr class="my-2" />
                          <div class="list-meta mt15">
                            <div class="budget">
                              <p class="mb-0 body-color">
                                Starting at
                                <span class="fz17 fw500 dark-color ms-1">
                                  $983
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-6 col-xl-4">
                      <div class="listing-style1">
                        <div class="list-thumb">
                          <img
                            class="w-100"
                            src="images/listings/g-3.jpg"
                            alt=""
                          />
                          <a href="" class="listing-fav fz12">
                            <span class="far fa-heart"></span>
                          </a>
                        </div>
                        <div class="list-content">
                          <p class="list-text body-color fz14 mb-1">
                            Design & Creative
                          </p>
                          <h6 class="list-title line-clamp2">
                            <a href="page-services-single.html">
                              I will build a fully responsive design in
                              HTML,CSS, bootstrap, and javascript
                            </a>
                          </h6>
                          <div class="review-meta d-flex align-items-center">
                            <i class="fas fa-star fz10 review-color me-2"></i>
                            <p class="mb-0 body-color fz14">
                              <span class="dark-color me-2">4.82</span>94
                              reviews
                            </p>
                          </div>
                          <hr class="my-2" />
                          <div class="list-meta mt15">
                            <div class="budget">
                              <p class="mb-0 body-color">
                                Starting at
                                <span class="fz17 fw500 dark-color ms-1">
                                  $983
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr class="opacity-100 mb60" />
                  <div class="product_single_content mb60">
                    <div class="mbp_pagination_comments">
                      <div class="row">
                        <div class="col-lg-12">
                          <div class="total_review mb30">
                            <h4>80 Reviews</h4>
                          </div>
                          <div class="d-md-flex align-items-center mb30">
                            <div class="total-review-box d-flex align-items-center text-center mb30-sm">
                              <div class="wrapper mx-auto">
                                <div class="t-review mb15">4.96</div>
                                <h5>Exceptional</h5>
                                <p class="text mb-0">3,014 reviews</p>
                              </div>
                            </div>
                            <div class="wrapper ml60 ml0-sm">
                              <div class="review-list d-flex align-items-center mb10">
                                <div class="list-number">5 Star</div>
                                <div class="progress">
                                  <div
                                    class="progress-bar"
                                    style={{ width: "90%" }}
                                    role="progressbar"
                                    aria-valuenow="90"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                  ></div>
                                </div>
                                <div class="value text-end">58</div>
                              </div>
                              <div class="review-list d-flex align-items-center mb10">
                                <div class="list-number">4 Star</div>
                                <div class="progress">
                                  <div
                                    class="progress-bar w-75"
                                    role="progressbar"
                                    aria-valuenow="75"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                  ></div>
                                </div>
                                <div class="value text-end">20</div>
                              </div>
                              <div class="review-list d-flex align-items-center mb10">
                                <div class="list-number">3 Star</div>
                                <div class="progress">
                                  <div
                                    class="progress-bar w-50"
                                    role="progressbar"
                                    aria-valuenow="50"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                  ></div>
                                </div>
                                <div class="value text-end">15</div>
                              </div>
                              <div class="review-list d-flex align-items-center mb10">
                                <div class="list-number">2 Star</div>
                                <div class="progress">
                                  <div
                                    class="progress-bar"
                                    style={{ width: "30%" }}
                                    role="progressbar"
                                    aria-valuenow="30"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                  ></div>
                                </div>
                                <div class="value text-end">2</div>
                              </div>
                              <div class="review-list d-flex align-items-center mb10">
                                <div class="list-number">1 Star</div>
                                <div class="progress">
                                  <div
                                    class="progress-bar"
                                    style={{ width: "20%" }}
                                    role="progressbar"
                                    aria-valuenow="10"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                  ></div>
                                </div>
                                <div class="value text-end">1</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="mbp_first position-relative d-flex align-items-center justify-content-start mb30-sm">
                            <img
                              src="images/blog/comments-2.png"
                              class="mr-3"
                              alt="comments-2.png"
                            />
                            <div class="ml20">
                              <h6 class="mt-0 mb-0">Bessie Cooper</h6>
                              <div>
                                <span class="fz14">12 March 2022</span>
                              </div>
                            </div>
                          </div>
                          <p class="text mt20 mb20">
                            There are many variations of passages of Lorem Ipsum
                            available, but the majority have suffered alteration
                            in some form, by injected humour, or randomised
                            words which don't look even slightly believable. If
                            you are going to use a passage of Lorem Ipsum, you
                            need to be sure there isn't anything embarrassing
                            hidden in the middle of text.
                          </p>
                          <div class="review_cansel_btns d-flex">
                            <a href="#">
                              <i class="fas fa-thumbs-up"></i>Helpful
                            </a>
                            <a href="#">
                              <i class="fas fa-thumbs-down"></i>Not helpful
                            </a>
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="mbp_first position-relative d-flex align-items-center justify-content-start mt30 mb30-sm">
                            <img
                              src="images/blog/comments-2.png"
                              class="mr-3"
                              alt="comments-2.png"
                            />
                            <div class="ml20">
                              <h6 class="mt-0 mb-0">Darrell Steward</h6>
                              <div>
                                <span class="fz14">12 March 2022</span>
                              </div>
                            </div>
                          </div>
                          <p class="text mt20 mb20">
                            There are many variations of passages of Lorem Ipsum
                            available, but the majority have suffered alteration
                            in some form, by injected humour, or randomised
                            words which don't look even slightly believable. If
                            you are going to use a passage of Lorem Ipsum, you
                            need to be sure there isn't anything embarrassing
                            hidden in the middle of text.
                          </p>
                          <div class="review_cansel_btns d-flex pb30">
                            <a href="#">
                              <i class="fas fa-thumbs-up"></i>Helpful
                            </a>
                            <a href="#">
                              <i class="fas fa-thumbs-down"></i>Not helpful
                            </a>
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="position-relative bdrb1 pb50">
                            <a
                              href="page-service-single.html"
                              class="ud-btn btn-light-thm"
                            >
                              See More<i class="fal fa-arrow-right-long"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="bsp_reveiw_wrt">
                    <h6 class="fz17">Add a Review</h6>
                    <p class="text">
                      Your email address will not be published. Required fields
                      are marked *
                    </p>
                    <h6>Your rating of this product</h6>
                    <div class="d-flex">
                      <i class="fas fa-star review-color"></i>
                      <i class="far fa-star review-color ms-2"></i>
                      <i class="far fa-star review-color ms-2"></i>
                      <i class="far fa-star review-color ms-2"></i>
                      <i class="far fa-star review-color ms-2"></i>
                    </div>
                    <form class="comments_form mt30 mb30-md">
                      <div class="row">
                        <div class="col-md-12">
                          <div class="mb-4">
                            <label class="fw500 fz16 ff-heading dark-color mb-2">
                              Comment
                            </label>
                            <textarea
                              class="pt15"
                              rows="6"
                              placeholder="There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text."
                            ></textarea>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="mb20">
                            <label class="fw500 ff-heading dark-color mb-2">
                              Name
                            </label>
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Ali Tufan"
                            />
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="mb20">
                            <label class="fw500 ff-heading dark-color mb-2">
                              Email
                            </label>
                            <input
                              type="email"
                              class="form-control"
                              placeholder="creativelayers088"
                            />
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="checkbox-style1 d-block d-sm-flex align-items-center justify-content-between mb20">
                            <label class="custom_checkbox fz15 ff-heading">
                              Save my name, email, and website in this browser
                              for the next time I comment.
                              <input type="checkbox" />
                              <span class="checkmark"></span>
                            </label>
                          </div>
                          <a href="" class="ud-btn btn-thm">
                            Send<i class="fal fa-arrow-right-long"></i>
                          </a>
                        </div>
                      </div>
                    </form>
                  </div> */}
                </div>
              </div>
              <div class="col-lg-4">
                <div class="blog-sidebar ms-lg-auto">
                  <div class="price-widget pt25 widget-mt-minus bdrs8">
                    <h3 class="widget-title">
                      ₹{rate} <small class="fz15 fw500">/per hour</small>
                    </h3>
                    <div class="category-list mt20">
                      <a
                        class="d-flex align-items-center justify-content-between bdrb1 pb-2"
                        href=""
                      >
                        <span class="text">
                          <i class="flaticon-place text-thm2 pe-2 vam"></i>
                          Location
                        </span>{" "}
                        <span class="">
                          {city}, {country}
                        </span>
                      </a>
                      <a
                        class="d-flex align-items-center justify-content-between bdrb1 pb-2"
                        href=""
                      >
                        <span class="text">
                          <i class="flaticon-30-days text-thm2 pe-2 vam"></i>
                          Member since
                        </span>{" "}
                        <span class="">{formattedDate}</span>
                      </a>
                      {/* <a
                        class="d-flex align-items-center justify-content-between bdrb1 pb-2"
                        href=""
                      >
                        <span class="text">
                          <i class="flaticon-calendar text-thm2 pe-2 vam"></i>
                          Last Delivery
                        </span>{" "}
                        <span class="">5 days</span>
                      </a> */}
                      <a
                        class="d-flex align-items-center justify-content-between bdrb1 pb-2"
                        href=""
                      >
                        <span class="text">
                          <i class="flaticon-mars text-thm2 pe-2 vam"></i>Gender
                        </span>{" "}
                        <span class="">{gender}</span>
                      </a>

                      {/* <a
                        class="d-flex align-items-center justify-content-between mb-3"
                        href=""
                      >
                        <span class="text">
                          <i class="flaticon-sliders text-thm2 pe-2 vam"></i>
                          English Level
                        </span>{" "}
                        <span class="">Fluent</span>
                      </a> */}
                    </div>
                    <div class="d-grid">
                      {!userData ? (
                        <>
                          <Link to="/signin" class="ud-btn btn-thm">
                            Connect<i class="fal fa-arrow-right-long"></i>
                          </Link>
                        </>
                      ) : (
                        <>
                          {userData.designation != "Client" || userId == id ? (
                            <>
                              <a
                                hidden
                                style={{ cursor: "not-allowed" }}
                                class="ud-btn btn-thm"
                              >
                                Connect<i class="fal fa-arrow-right-long"></i>
                              </a>
                            </>
                          ) : (
                            <a
                              style={{ cursor: "pointer" }}
                              onClick={handleSubmit}
                              class="ud-btn btn-thm"
                            >
                              Connect<i class="fal fa-arrow-right-long"></i>
                            </a>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                  <div class="sidebar-widget mb30 pb20 bdrs8">
                    <h4 class="widget-title">My Skills</h4>
                    <div class="tag-list mt30">
                      {skills
                        ? skills.split(",").map((skill, skillIndex) => (
                            <a key={skillIndex}>
                              {skill.trim()}
                              {""}
                              {/* Trim any leading or trailing spaces */}
                            </a>
                          ))
                        : "-"}
                    </div>
                    <br />
                    <h4 class="widget-title">Languages</h4>
                    <div class="tag-list mt30">
                      {languages
                        ? languages
                            .split(",")
                            .map((languages, languagesIndex) => (
                              <a key={languagesIndex}>
                                {languages.trim()}
                                {""}
                                {/* Trim any leading or trailing spaces */}
                              </a>
                            ))
                        : "-"}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default DetailFreelancer;
