import React from "react";
import { Link } from "react-router-dom";
function Footer() {
  return (
    <>
      {/* <!-- Our Footer -->  */}
      <section class="footer-style1 pt25 pb-0">
        <div class="container">
          <div class="row bb-white-light pb10 mb60">
            <div class="col-md-7">
              <div class="d-block text-center text-md-start justify-content-center justify-content-md-start d-md-flex align-items-center mb-3 mb-md-0">
                <a class="fz17 fw500 text-white mr15-md mr30" href="legal.html">
                  Terms of Service
                </a>
                <a class="fz17 fw500 text-white mr15-md mr30" href="legal.html">
                  Privacy Policy
                </a>
              </div>
            </div>
            <div class="col-md-5">
              <div class="social-widget text-center text-md-end">
                <div class="social-style1">
                  <a class="text-white me-2 fw500 fz17" href="">
                    Follow us
                  </a>
                  <a href="https://www.facebook.com/FXCTSIT/" target="_blank">
                    <i class="fab fa-facebook-f list-inline-item"></i>
                  </a>
                  <a href="https://twitter.com/FalconX_FXCTS" target="_blank">
                    <i class="fab fa-twitter list-inline-item"></i>
                  </a>
                  <a href="https://www.instagram.com/fxctsit/" target="_blank">
                    <i class="fab fa-instagram list-inline-item"></i>
                  </a>
                  <a
                    href="https://www.linkedin.com/in/sathish-vasanth/"
                    target="_blank"
                  >
                    <i class="fab fa-linkedin-in list-inline-item"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6 col-lg-3">
              <div class="link-style1 mb-4 mb-sm-5">
                <h5 class="text-white mb15">Useful Links</h5>
                <div class="link-list">
                  <Link to="/">Home</Link>
                  <Link to="/about_us">About Us</Link>
                  <Link to="/contact_us">Contact Us</Link>
                  <Link to="/services">Services</Link>
                  <Link to="/pricings">Pricing</Link>
                  <Link to="/join_us">Partnerships</Link>
                  <Link to="/admin_signin">Admin Login</Link>
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-lg-3">
              <div class="link-style1 mb-4 mb-sm-5">
                <h5 class="text-white mb15">Legal</h5>
                <ul class="ps-0">
                  <Link to="/policies">Privacy Policy</Link>
                  <Link to="/policies">Terms of Service</Link>
                  <Link to="/policies">Refund & Cancellation</Link>
                </ul>
              </div>
            </div>
            <div class="col-sm-6 col-lg-3">
              <div class="link-style1 mb-4 mb-sm-5">
                <h5 class="text-white mb15">Contact Us</h5>
                <ul class="ps-0" style={{ color: "white" }}>
                  <li>
                    <a>568/2A,Opp VIT,Vellakalmedu,</a>
                  </li>
                  <li>
                    <a>KN Road,Katpadi,Vellore,</a>
                  </li>
                  <li>
                    <a>Tamil Nadu-632006</a>
                  </li>
                  <li>
                    <a>Phone:+91 8838173949 +91 8248533577</a>
                  </li>
                  <li>
                    <a>Email:hr@falconxx.com</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="container white-bdrt1 py-4">
          <div class="row">
            <div class="col-md-6">
              <div class="text-center text-lg-start">
                <p class="copyright-text mb-0 text-white-light ff-heading">
                  © Copyright FXCTS. All Rights Reserved
                </p>
              </div>
            </div>
            <div class="col-md-6">
              <div class="footer_bottom_right_btns text-center text-lg-end"></div>
            </div>
          </div>
        </div>
      </section>
      <a class="scrollToHome at-home2" href="#">
        <i class="fas fa-angle-up"></i>
      </a>
    </>
  );
}

export default Footer;
