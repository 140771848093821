import React from "react";

function Home() {
  return (
    <>
      <div class="body_content" style={{ marginTop: "15vh" }}>
        {/* <!-- Home Banner Style V1 --> */}
        <section class="hero-home6 py-0">
          <div class="container">
            <div class="row align-items-center justify-content-between">
              <div class="col-lg-6">
                <div class="pr50 pr0-xl mb30-md position-relative">
                  <h1 class="animate-up-1 mb15 text-thm2">
                    Elevate productivity with
                    <br class="d-none d-xl-block" />
                    exceptional
                    <span class="text-thm"> freelance talent</span>
                  </h1>
                  <p class="animate-up-2 ff-heading mb30 text">
                    Thousands of people use FXCTS to turn their ideas into
                    reality.
                  </p>
                  <div class="advance-search-tab at-home6 bgc-white bdrs4 p10 position-relative zi2 animate-up-3">
                    <div class="row">
                      <div class="col-md-9 col-lg-8 col-xl-9">
                        <div class="advance-search-field mb10-sm">
                          <form class="form-search position-relative">
                            <div class="box-search">
                              <span class="icon far fa-magnifying-glass"></span>
                              <input
                                class="form-control"
                                type="text"
                                name="search"
                                placeholder="What are you looking for?"
                              />
                              <div class="search-suggestions">
                                <h6 class="fz14 ml30 mt25 mb-3">
                                  Popular Search
                                </h6>
                                <div class="box-suggestions">
                                  <ul class="px-0 m-0 pb-4">
                                    <li>
                                      <div class="info-product">
                                        <div class="item_title">
                                          web development
                                        </div>
                                      </div>
                                    </li>

                                    <li>
                                      <div class="info-product">
                                        <div class="item_title">web design</div>
                                      </div>
                                    </li>
                                    <li>
                                      <div class="info-product">
                                        <div class="item_title">
                                          graphic designing
                                        </div>
                                      </div>
                                    </li>
                                    <li>
                                      <div class="info-product">
                                        <div class="item_title">
                                          content writing
                                        </div>
                                      </div>
                                    </li>
                                    <li>
                                      <div class="info-product">
                                        <div class="item_title">
                                          mobile app development
                                        </div>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                      <div class="col-md-3 col-lg-4 col-xl-3">
                        <div class="text-center">
                          <a href="services.html">
                            <button
                              class="ud-btn btn-thm2 bdrs4 w-100"
                              type="button"
                            >
                              Search
                            </button>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="d-block d-md-flex mt30 banner-text animate-up-4">
                    <p class="hero-text fz15 me-2 mb-0 text">
                      Popular Searches:
                    </p>
                    <a class="text" href="">
                      {" "}
                      Web development,{" "}
                    </a>
                    <a class="text" href="">
                      {" "}
                      Web design,{" "}
                    </a>
                    <a class="text" href="">
                      {" "}
                      Graphic designing
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-xl-5">
                <div class="home6-hero-content position-relative">
                  <div class="iconbox-small1 d-none d-md-block wow fadeInRight default-box-shadow4 bounce-y animate-up-1">
                    <span class="icon flaticon-review"></span>
                    <div class="details">
                      <h6>4.9/5</h6>
                      <p class="text fz13 mb-0">Clients rate professionals</p>
                    </div>
                  </div>
                  <div class="iconbox-small2 d-none d-md-block wow fadeInLeft default-box-shadow4 bounce-y animate-up-2">
                    <span class="icon flaticon-review"></span>
                    <div class="details">
                      <h6>+1K</h6>
                      <p class="text fz13 mb-0">Project Completed</p>
                    </div>
                  </div>
                  <img
                    src="images/about/element-10.png"
                    alt=""
                    class="bounce-x img-4"
                  />
                  <img
                    src="images/about/element-11.png"
                    alt=""
                    class="spin-right img-5 d-none d-sm-block"
                  />
                  <img
                    src="images/about/home6-hero-element-1.png"
                    alt=""
                    class="bounce-y img-1 d-none d-sm-block"
                  />
                  <img
                    src="images/about/home6-hero-element-2.png"
                    alt=""
                    class="bounce-y img-2 d-none d-sm-block"
                  />
                  <img
                    src="images/about/home6-hero-element-3.png"
                    alt=""
                    class="bounce-y img-3 d-none d-sm-block"
                  />
                  <img
                    src="images/about/home6-hero-img-1.png"
                    alt=""
                    class="animate-up-1 w-100"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!-- Need something --> */}
        <section class="our-features bgc-thm2 pt60 pb35">
          <div class="container wow fadeInUp">
            <div class="row">
              <div class="col-lg-12">
                <div class="main-title">
                  <h2 class="text-white">Need something done?</h2>
                  <p class="text text-white">
                    Most viewed and all-time top-selling services
                  </p>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6 col-lg-3">
                <div class="iconbox-style1 at-home6 border-less p-0 bgc-transparent">
                  <div class="icon before-none">
                    <span class="flaticon-cv"></span>
                  </div>
                  <div class="details">
                    <h4 class="title text-white mt10 mb-3">Post a job</h4>
                    <p class="text text-white">
                      It’s free and easy to post a job. Simply fill
                      <br class="d-none d-xxl-block" />
                      in a title, description.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-sm-6 col-lg-3">
                <div class="iconbox-style1 at-home6 border-less p-0 bgc-transparent">
                  <div class="icon before-none">
                    <span class="flaticon-web-design"></span>
                  </div>
                  <div class="details">
                    <h4 class="title text-white mt10 mb-3">
                      Choose freelancers
                    </h4>
                    <p class="text text-white">
                      Tap into a World of Talent: Choose
                      <br class="d-none d-xxl-block" />
                      Freelancers for Unparalleled Expertise!
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-sm-6 col-lg-3">
                <div class="iconbox-style1 at-home6 border-less p-0 bgc-transparent">
                  <div class="icon before-none">
                    <span class="flaticon-secure"></span>
                  </div>
                  <div class="details">
                    <h4 class="title text-white mt10 mb-3">Pay safely</h4>
                    <p class="text text-white">
                      Safe and Secure Payments: Trustworthy
                      <br class="d-none d-xxl-block" />
                      Transactions Guaranteed!
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-sm-6 col-lg-3">
                <div class="iconbox-style1 at-home6 border-less p-0 bgc-transparent">
                  <div class="icon before-none">
                    <span class="flaticon-customer-service"></span>
                  </div>
                  <div class="details">
                    <h4 class="title text-white mt10 mb-3">
                      We’re here to help
                    </h4>
                    <p class="text text-white">
                      Partnering for Success: Rest Assured,
                      <br class="d-none d-xxl-block" />
                      We're Here to Help!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!-- Explore Apartment --> */}
        <section class="pb70 pb30-md">
          <div class="container">
            <div
              class="row align-items-md-center wow fadeInUp"
              data-wow-delay="00ms"
            >
              <div class="col-lg-9">
                <div class="main-title2">
                  <h2 class="title">Browse by category</h2>
                  <p class="paragraph">
                    Get some Inspirations from 1800+ skills
                  </p>
                </div>
              </div>
              <div class="col-lg-3">
                <div class="text-start text-lg-end mb-3">
                  <a class="ud-btn2" href="services.html">
                    All Categories
                    <i class="fal fa-arrow-right-long dark-color"></i>
                  </a>
                </div>
              </div>
            </div>
            <div class="row wow fadeInUp" data-wow-delay="300ms">
              <div class="col-sm-6 col-lg-3">
                <a>
                  <div class="position-relative mb50 mb20-md d-flex align-items-center">
                    <div class="city-img flex-shrink-0">
                      <img
                        class="bdrs4"
                        src="images/listings/ct-s-1.jpg"
                        alt=""
                      />
                    </div>
                    <div class="flex-shrink-1 ms-3">
                      <h6 class="mb-1">Development & IT</h6>
                      <p class="mb-0">2,253 skills</p>
                    </div>
                  </div>
                </a>
              </div>
              <div class="col-sm-6 col-lg-3">
                <a>
                  <div class="position-relative mb50 mb20-md d-flex align-items-center">
                    <div class="city-img flex-shrink-0">
                      <img
                        class="bdrs4"
                        src="images/listings/ct-s-2.jpg"
                        alt=""
                      />
                    </div>
                    <div class="flex-shrink-1 ms-3">
                      <h6 class="mb-1">Design & Creative</h6>
                      <p class="mb-0">1,053 skills</p>
                    </div>
                  </div>
                </a>
              </div>
              <div class="col-sm-6 col-lg-3">
                <a>
                  <div class="position-relative mb50 mb20-md d-flex align-items-center">
                    <div class="city-img flex-shrink-0">
                      <img
                        class="bdrs4"
                        src="images/listings/ct-s-3.jpg"
                        alt=""
                      />
                    </div>
                    <div class="flex-shrink-1 ms-3">
                      <h6 class="mb-1">Digital Marketing</h6>
                      <p class="mb-0">1,200 skills</p>
                    </div>
                  </div>
                </a>
              </div>
              <div class="col-sm-6 col-lg-3">
                <a>
                  <div class="position-relative mb50 mb20-md d-flex align-items-center">
                    <div class="city-img flex-shrink-0">
                      <img
                        class="bdrs4"
                        src="images/listings/ct-s-4.jpg"
                        alt=""
                      />
                    </div>
                    <div class="flex-shrink-1 ms-3">
                      <h6 class="mb-1">Writing & Translation</h6>
                      <p class="mb-0">1,000 skills</p>
                    </div>
                  </div>
                </a>
              </div>
              <div class="col-sm-6 col-lg-3">
                <a>
                  <div class="position-relative mb50 mb20-md d-flex align-items-center">
                    <div class="city-img flex-shrink-0">
                      <img
                        class="bdrs4"
                        src="images/listings/ct-s-5.jpg"
                        alt=""
                      />
                    </div>
                    <div class="flex-shrink-1 ms-3">
                      <h6 class="mb-1">Academic Writing</h6>
                      <p class="mb-0">1,800 skills</p>
                    </div>
                  </div>
                </a>
              </div>
              <div class="col-sm-6 col-lg-3">
                <a>
                  <div class="position-relative mb50 mb20-md d-flex align-items-center">
                    <div class="city-img flex-shrink-0">
                      <img
                        class="bdrs4"
                        src="images/listings/ct-s-6.jpg"
                        alt=""
                      />
                    </div>
                    <div class="flex-shrink-1 ms-3">
                      <h6 class="mb-1">Video & Animation</h6>
                      <p class="mb-0">900 skills</p>
                    </div>
                  </div>
                </a>
              </div>
              <div class="col-sm-6 col-lg-3">
                <a>
                  <div class="position-relative mb50 mb20-md d-flex align-items-center">
                    <div class="city-img flex-shrink-0">
                      <img
                        class="bdrs4"
                        src="images/listings/ct-s-7.jpg"
                        alt=""
                      />
                    </div>
                    <div class="flex-shrink-1 ms-3">
                      <h6 class="mb-1">Engineering & Architecture</h6>
                      <p class="mb-0">1,550 skills</p>
                    </div>
                  </div>
                </a>
              </div>
              <div class="col-sm-6 col-lg-3">
                <a>
                  <div class="position-relative mb50 mb20-md d-flex align-items-center">
                    <div class="city-img flex-shrink-0">
                      <img
                        class="bdrs4"
                        src="images/listings/ct-s-8.jpg"
                        alt=""
                      />
                    </div>
                    <div class="flex-shrink-1 ms-3">
                      <h6 class="mb-1">Finance & Accounting</h6>
                      <p class="mb-0">1,200 skills</p>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </section>

        {/* <!-- Popular Services --> */}
        <section class="pt-0 pb90">
          <div class="container">
            <div class="row align-items-center wow fadeInUp">
              <div class="col-xl-3">
                <div class="main-title mb30-lg">
                  <h2 class="title">Popular Services</h2>
                  <p class="paragraph">
                    Most viewed and all-time top-selling services
                  </p>
                </div>
              </div>
              <div class="col-xl-9">
                <div class="navpill-style2 at-home6 mb50-lg">
                  <ul
                    class="nav nav-pills mb20 justify-content-xl-end"
                    id="pills-tab"
                    role="tablist"
                  >
                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link active fw500 dark-color"
                        id="pills-dev-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-dev"
                        type="button"
                        role="tab"
                        aria-controls="pills-dev"
                        aria-selected="true"
                      >
                        Development & IT
                      </button>
                    </li>
                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link fw500 dark-color"
                        id="pills-design-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-design"
                        type="button"
                        role="tab"
                        aria-controls="pills-design"
                        aria-selected="false"
                      >
                        Design & Creative
                      </button>
                    </li>
                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link fw500 dark-color"
                        id="pills-digi-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-digi"
                        type="button"
                        role="tab"
                        aria-controls="pills-digi"
                        aria-selected="false"
                      >
                        Digital Marketing
                      </button>
                    </li>
                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link fw500 dark-color"
                        id="pills-aca-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-aca"
                        type="button"
                        role="tab"
                        aria-controls="pills-aca"
                        aria-selected="false"
                      >
                        Academic Writing
                      </button>
                    </li>
                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link fw500 dark-color"
                        id="pills-engg-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-engg"
                        type="button"
                        role="tab"
                        aria-controls="pills-engg"
                        aria-selected="false"
                      >
                        Engineering & Architecture
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12">
                <div class="navpill-style2">
                  <div class="tab-content ha" id="pills-tabContent">
                    <div
                      class="tab-pane fade fz15 text show active"
                      id="pills-dev"
                      role="tabpanel"
                      aria-labelledby="pills-dev-tab"
                    >
                      <div class="row">
                        <div class="col-md-6">
                          <div class="listing-style1 list-style d-block d-xl-flex align-items-center">
                            <div class="list-thumb flex-shrink-0">
                              <img
                                class="w-100"
                                src="images/listings/Services-g-17/web2.png"
                                alt=""
                              />
                            </div>
                            <div class="list-content flex-grow-1 ms-1">
                              <p class="list-text body-color fz14 mb-1">
                                Web Development
                              </p>
                              <h5 class="list-title">
                                <a>
                                  Streamlining Website Updates and Maintenance
                                </a>
                              </h5>
                              <div class="review-meta d-flex align-items-center">
                                <i class="fas fa-star fz10 review-color me-2"></i>
                                <p class="mb-0 body-color fz14">
                                  <span class="dark-color me-2">4.82</span>
                                </p>
                              </div>
                              <hr class="my-2" />
                              <div class="list-meta d-flex justify-content-between align-items-center mt15">
                                <div class="budget">
                                  <p class="mb-0 body-color">
                                    Starting at
                                    <span class="fz17 fw500 dark-color ms-1">
                                      ₹5000
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="listing-style1 list-style d-block d-xl-flex align-items-center">
                            <div class="list-thumb flex-shrink-0">
                              <img
                                class="w-100"
                                src="images/listings/Services-g-17/web1.png"
                                alt=""
                              />
                            </div>
                            <div class="list-content flex-grow-1 ms-1">
                              <p class="list-text body-color fz14 mb-1">
                                Web Development
                              </p>
                              <h5 class="list-title">
                                <a>
                                  Building Engaging Interfaces with HTML, CSS,
                                  and JavaScript
                                </a>
                              </h5>
                              <div class="review-meta d-flex align-items-center">
                                <i class="fas fa-star fz10 review-color me-2"></i>
                                <p class="mb-0 body-color fz14">
                                  <span class="dark-color me-2">4.82</span>
                                </p>
                              </div>
                              <hr class="my-2" />
                              <div class="list-meta d-flex justify-content-between align-items-center mt15">
                                <div class="budget">
                                  <p class="mb-0 body-color">
                                    Starting at
                                    <span class="fz17 fw500 dark-color ms-1">
                                      ₹8000
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="listing-style1 list-style d-block d-xl-flex align-items-center">
                            <div class="list-thumb flex-shrink-0">
                              <img
                                class="w-100"
                                src="images/listings/Services-g-17/app1.png"
                                alt=""
                              />
                            </div>
                            <div class="list-content flex-grow-1 ms-1">
                              <p class="list-text body-color fz14 mb-1">
                                Mobile App Development
                              </p>
                              <h5 class="list-title line-clamp2">
                                <a>
                                  Extending Your Web Presence to Mobile
                                  Platforms"
                                </a>
                              </h5>
                              <div class="review-meta d-flex align-items-center">
                                <i class="fas fa-star fz10 review-color me-2"></i>
                                <p class="mb-0 body-color fz14">
                                  <span class="dark-color me-2">4.82</span>
                                </p>
                              </div>
                              <hr class="my-2" />
                              <div class="list-meta d-flex justify-content-between align-items-center mt15">
                                <div class="budget">
                                  <p class="mb-0 body-color">
                                    Starting at
                                    <span class="fz17 fw500 dark-color ms-1">
                                      ₹60,000
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="listing-style1 list-style d-block d-xl-flex align-items-center">
                            <div class="list-thumb flex-shrink-0">
                              <img
                                class="w-100"
                                src="images/listings/Services-g-17/web3.png"
                                alt=""
                              />
                            </div>
                            <div class="list-content flex-grow-1 ms-1">
                              <p class="list-text body-color fz14 mb-1">
                                Web Development
                              </p>
                              <h5 class="list-title">
                                <a>
                                  Creating Secure and Scalable Online Stores for
                                  Business Growth
                                </a>
                              </h5>
                              <div class="review-meta d-flex align-items-center">
                                <i class="fas fa-star fz10 review-color me-2"></i>
                                <p class="mb-0 body-color fz14">
                                  <span class="dark-color me-2">4.82</span>
                                </p>
                              </div>
                              <hr class="my-2" />
                              <div class="list-meta d-flex justify-content-between align-items-center mt15">
                                <div class="budget">
                                  <p class="mb-0 body-color">
                                    Starting at
                                    <span class="fz17 fw500 dark-color ms-1">
                                      ₹30,000
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="listing-style1 list-style d-block d-xl-flex align-items-center">
                            <div class="list-thumb flex-shrink-0">
                              <img
                                class="w-100"
                                src="images/listings/Services-g-17/web4.png"
                                alt=""
                              />
                            </div>
                            <div class="list-content flex-grow-1 ms-1">
                              <p class="list-text body-color fz14 mb-1">
                                Web Development
                              </p>
                              <h5 class="list-title line-clamp2">
                                <a>
                                  Build a fully responsive design in HTML,CSS,
                                  bootstrap, and javascript
                                </a>
                              </h5>
                              <div class="review-meta d-flex align-items-center">
                                <i class="fas fa-star fz10 review-color me-2"></i>
                                <p class="mb-0 body-color fz14">
                                  <span class="dark-color me-2">4.82</span>
                                </p>
                              </div>
                              <hr class="my-2" />
                              <div class="list-meta d-flex justify-content-between align-items-center mt15">
                                <div class="budget">
                                  <p class="mb-0 body-color">
                                    Starting at
                                    <span class="fz17 fw500 dark-color ms-1">
                                      ₹10,000
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="listing-style1 list-style d-block d-xl-flex align-items-center">
                            <div class="list-thumb flex-shrink-0">
                              <img
                                class="w-100"
                                src="images/listings/Services-g-17/app2.png"
                                alt=""
                              />
                            </div>
                            <div class="list-content flex-grow-1 ms-1">
                              <p class="list-text body-color fz14 mb-1">
                                Mobile App Development
                              </p>
                              <h5 class="list-title">
                                <a>
                                  Mobile app development for ios and android
                                </a>
                              </h5>
                              <div class="review-meta d-flex align-items-center">
                                <i class="fas fa-star fz10 review-color me-2"></i>
                                <p class="mb-0 body-color fz14">
                                  <span class="dark-color me-2">4.82</span>
                                </p>
                              </div>
                              <hr class="my-2" />
                              <div class="list-meta d-flex justify-content-between align-items-center mt15">
                                <div class="budget">
                                  <p class="mb-0 body-color">
                                    Starting at
                                    <span class="fz17 fw500 dark-color ms-1">
                                      ₹90,000
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="tab-pane fade fz15 text"
                      id="pills-design"
                      role="tabpanel"
                      aria-labelledby="pills-design-tab"
                    >
                      <div class="row">
                        <div class="col-md-6">
                          <div class="listing-style1 list-style d-block d-xl-flex align-items-center">
                            <div class="list-thumb flex-shrink-0">
                              <img
                                class="w-100"
                                src="images/listings/Services-g-17/logo1.png"
                                alt=""
                              />
                            </div>
                            <div class="list-content flex-grow-1 ms-1">
                              <p class="list-text body-color fz14 mb-1">
                                Logo Design
                              </p>
                              <h5 class="list-title">
                                <a>
                                  Crafting Memorable and Impactful Brand
                                  Identities
                                </a>
                              </h5>
                              <div class="review-meta d-flex align-items-center">
                                <i class="fas fa-star fz10 review-color me-2"></i>
                                <p class="mb-0 body-color fz14">
                                  <span class="dark-color me-2">4.7</span>
                                </p>
                              </div>
                              <hr class="my-2" />
                              <div class="list-meta d-flex justify-content-between align-items-center mt15">
                                <div class="budget">
                                  <p class="mb-0 body-color">
                                    Starting at
                                    <span class="fz17 fw500 dark-color ms-1">
                                      ₹1500
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col-md-6">
                          <div class="listing-style1 list-style d-block d-xl-flex align-items-center">
                            <div class="list-thumb flex-shrink-0">
                              <img
                                class="w-100"
                                src="images/listings/Services-g-17/design2.png"
                                alt=""
                              />
                            </div>
                            <div class="list-content flex-grow-1 ms-1">
                              <p class="list-text body-color fz14 mb-1">
                                Responsive Web Design
                              </p>
                              <h5 class="list-title line-clamp2">
                                <a>
                                  Creating User-Friendly Websites Across Devices
                                </a>
                              </h5>
                              <div class="review-meta d-flex align-items-center">
                                <i class="fas fa-star fz10 review-color me-2"></i>
                                <p class="mb-0 body-color fz14">
                                  <span class="dark-color me-2">4.9</span>
                                </p>
                              </div>
                              <hr class="my-2" />
                              <div class="list-meta d-flex justify-content-between align-items-center mt15">
                                <div class="budget">
                                  <p class="mb-0 body-color">
                                    Starting at
                                    <span class="fz17 fw500 dark-color ms-1">
                                      ₹25,000
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="listing-style1 list-style d-block d-xl-flex align-items-center">
                            <div class="list-thumb flex-shrink-0">
                              <img
                                class="w-100"
                                src="images/listings/Services-g-17/design1.png"
                                alt=""
                              />
                            </div>
                            <div class="list-content flex-grow-1 ms-1">
                              <p class="list-text body-color fz14 mb-1">
                                User Interface (UI) Design
                              </p>
                              <h5 class="list-title">
                                <a>
                                  Designing Intuitive and Engaging Interfaces
                                  for Digital Experiences
                                </a>
                              </h5>
                              <div class="review-meta d-flex align-items-center">
                                <i class="fas fa-star fz10 review-color me-2"></i>
                                <p class="mb-0 body-color fz14">
                                  <span class="dark-color me-2">4.8</span>
                                </p>
                              </div>
                              <hr class="my-2" />
                              <div class="list-meta d-flex justify-content-between align-items-center mt15">
                                <div class="budget">
                                  <p class="mb-0 body-color">
                                    Starting at
                                    <span class="fz17 fw500 dark-color ms-1">
                                      ₹8000
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="listing-style1 list-style d-block d-xl-flex align-items-center">
                            <div class="list-thumb flex-shrink-0">
                              <img
                                class="w-100"
                                src="images/listings/Services-g-17/design3.png"
                                alt=""
                              />
                            </div>
                            <div class="list-content flex-grow-1 ms-1">
                              <p class="list-text body-color fz14 mb-1">
                                Social Media Graphics
                              </p>
                              <h5 class="list-title line-clamp2">
                                <a>
                                  Creating Compelling Visual Content for Social
                                  Media Platforms
                                </a>
                              </h5>
                              <div class="review-meta d-flex align-items-center">
                                <i class="fas fa-star fz10 review-color me-2"></i>
                                <p class="mb-0 body-color fz14">
                                  <span class="dark-color me-2">4.6</span>
                                </p>
                              </div>
                              <hr class="my-2" />
                              <div class="list-meta d-flex justify-content-between align-items-center mt15">
                                <div class="budget">
                                  <p class="mb-0 body-color">
                                    Starting at
                                    <span class="fz17 fw500 dark-color ms-1">
                                      ₹3500
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="listing-style1 list-style d-block d-xl-flex align-items-center">
                            <div class="list-thumb flex-shrink-0">
                              <img
                                class="w-100"
                                src="images/listings/Services-g-17/design4.png"
                                alt=""
                              />
                            </div>
                            <div class="list-content flex-grow-1 ms-1">
                              <p class="list-text body-color fz14 mb-1">
                                Motion Graphics and Animation
                              </p>
                              <h5 class="list-title">
                                <a>
                                  Adding Life and Dynamism to Visual Designs
                                </a>
                              </h5>
                              <div class="review-meta d-flex align-items-center">
                                <i class="fas fa-star fz10 review-color me-2"></i>
                                <p class="mb-0 body-color fz14">
                                  <span class="dark-color me-2">4.5</span>
                                </p>
                              </div>
                              <hr class="my-2" />
                              <div class="list-meta d-flex justify-content-between align-items-center mt15">
                                <div class="budget">
                                  <p class="mb-0 body-color">
                                    Starting at
                                    <span class="fz17 fw500 dark-color ms-1">
                                      ₹20,000
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col-md-6">
                          <div class="listing-style1 list-style d-block d-xl-flex align-items-center">
                            <div class="list-thumb flex-shrink-0">
                              <img
                                class="w-100"
                                src="images/listings/Services-g-17/design5.png"
                                alt=""
                              />
                            </div>
                            <div class="list-content flex-grow-1 ms-1">
                              <p class="list-text body-color fz14 mb-1">
                                Typography and Typeface Design
                              </p>
                              <h5 class="list-title">
                                <a>
                                  Shaping Words into Beautiful and Expressive
                                  Designs
                                </a>
                              </h5>
                              <div class="review-meta d-flex align-items-center">
                                <i class="fas fa-star fz10 review-color me-2"></i>
                                <p class="mb-0 body-color fz14">
                                  <span class="dark-color me-2">4.4</span>
                                </p>
                              </div>
                              <hr class="my-2" />
                              <div class="list-meta d-flex justify-content-between align-items-center mt15">
                                <div class="budget">
                                  <p class="mb-0 body-color">
                                    Starting at
                                    <span class="fz17 fw500 dark-color ms-1">
                                      ₹2500
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="tab-pane fade fz15 text"
                      id="pills-digi"
                      role="tabpanel"
                      aria-labelledby="pills-digi-tab"
                    >
                      <div class="row">
                        <div class="col-md-6">
                          <div class="listing-style1 list-style d-block d-xl-flex align-items-center">
                            <div class="list-thumb flex-shrink-0">
                              <img
                                class="w-100"
                                src="images/listings/Services-g-17/dg1.png"
                                alt=""
                              />
                            </div>
                            <div class="list-content flex-grow-1 ms-1">
                              <p class="list-text body-color fz14 mb-1">
                                Social Media Management
                              </p>
                              <h5 class="list-title">
                                <a>
                                  Managing social media accounts, creating
                                  content calendars...
                                </a>
                              </h5>
                              <div class="review-meta d-flex align-items-center">
                                <i class="fas fa-star fz10 review-color me-2"></i>
                                <p class="mb-0 body-color fz14">
                                  <span class="dark-color me-2">4.8</span>
                                </p>
                              </div>
                              <hr class="my-2" />
                              <div class="list-meta d-flex justify-content-between align-items-center mt15">
                                <div class="budget">
                                  <p class="mb-0 body-color">
                                    Starting at
                                    <span class="fz17 fw500 dark-color ms-1">
                                      ₹20,000
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col-md-6">
                          <div class="listing-style1 list-style d-block d-xl-flex align-items-center">
                            <div class="list-thumb flex-shrink-0">
                              <img
                                class="w-100"
                                src="images/listings/Services-g-17/dg2.png"
                                alt=""
                              />
                            </div>
                            <div class="list-content flex-grow-1 ms-1">
                              <p class="list-text body-color fz14 mb-1">
                                Content Creation
                              </p>
                              <h5 class="list-title line-clamp2">
                                <a>
                                  Writing blog posts, articles, website copy,
                                  product descriptions...
                                </a>
                              </h5>
                              <div class="review-meta d-flex align-items-center">
                                <i class="fas fa-star fz10 review-color me-2"></i>
                                <p class="mb-0 body-color fz14">
                                  <span class="dark-color me-2">4.6</span>
                                </p>
                              </div>
                              <hr class="my-2" />
                              <div class="list-meta d-flex justify-content-between align-items-center mt15">
                                <div class="budget">
                                  <p class="mb-0 body-color">
                                    Starting at
                                    <span class="fz17 fw500 dark-color ms-1">
                                      ₹12,000
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="listing-style1 list-style d-block d-xl-flex align-items-center">
                            <div class="list-thumb flex-shrink-0">
                              <img
                                class="w-100"
                                src="images/listings/Services-g-17/dg3.png"
                                alt=""
                              />
                            </div>
                            <div class="list-content flex-grow-1 ms-1">
                              <p class="list-text body-color fz14 mb-1">
                                SEO (Search Engine Optimization)
                              </p>
                              <h5 class="list-title">
                                <a>
                                  Optimizing website content, conducting keyword
                                  research...
                                </a>
                              </h5>
                              <div class="review-meta d-flex align-items-center">
                                <i class="fas fa-star fz10 review-color me-2"></i>
                                <p class="mb-0 body-color fz14">
                                  <span class="dark-color me-2">4.7</span>
                                </p>
                              </div>
                              <hr class="my-2" />
                              <div class="list-meta d-flex justify-content-between align-items-center mt15">
                                <div class="budget">
                                  <p class="mb-0 body-color">
                                    Starting at
                                    <span class="fz17 fw500 dark-color ms-1">
                                      ₹15,000
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="listing-style1 list-style d-block d-xl-flex align-items-center">
                            <div class="list-thumb flex-shrink-0">
                              <img
                                class="w-100"
                                src="images/listings/Services-g-17/dg4.png"
                                alt=""
                              />
                            </div>
                            <div class="list-content flex-grow-1 ms-1">
                              <p class="list-text body-color fz14 mb-1">
                                Email Marketing
                              </p>
                              <h5 class="list-title line-clamp2">
                                <a>
                                  Developing email marketing campaigns, writing
                                  compelling email copy...
                                </a>
                              </h5>
                              <div class="review-meta d-flex align-items-center">
                                <i class="fas fa-star fz10 review-color me-2"></i>
                                <p class="mb-0 body-color fz14">
                                  <span class="dark-color me-2">4.8</span>
                                </p>
                              </div>
                              <hr class="my-2" />
                              <div class="list-meta d-flex justify-content-between align-items-center mt15">
                                <div class="budget">
                                  <p class="mb-0 body-color">
                                    Starting at
                                    <span class="fz17 fw500 dark-color ms-1">
                                      ₹8000
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="listing-style1 list-style d-block d-xl-flex align-items-center">
                            <div class="list-thumb flex-shrink-0">
                              <img
                                class="w-100"
                                src="images/listings/Services-g-17/dg5.png"
                                alt=""
                              />
                            </div>
                            <div class="list-content flex-grow-1 ms-1">
                              <p class="list-text body-color fz14 mb-1">
                                Digital Marketing Strategy
                              </p>
                              <h5 class="list-title">
                                <a>
                                  Developing comprehensive digital marketing
                                  strategies tailored to clients' goals...
                                </a>
                              </h5>
                              <div class="review-meta d-flex align-items-center">
                                <i class="fas fa-star fz10 review-color me-2"></i>
                                <p class="mb-0 body-color fz14">
                                  <span class="dark-color me-2">4.7</span>
                                </p>
                              </div>
                              <hr class="my-2" />
                              <div class="list-meta d-flex justify-content-between align-items-center mt15">
                                <div class="budget">
                                  <p class="mb-0 body-color">
                                    Starting at
                                    <span class="fz17 fw500 dark-color ms-1">
                                      ₹12,000
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="tab-pane fade fz15 text"
                      id="pills-aca"
                      role="tabpanel"
                      aria-labelledby="pills-aca-tab"
                    >
                      <div class="row">
                        <div class="col-md-6">
                          <div class="listing-style1 list-style d-block d-xl-flex align-items-center">
                            <div class="list-thumb flex-shrink-0">
                              <img
                                class="w-100"
                                src="images/listings/Services-g-17/write1.png"
                                alt=""
                              />
                            </div>
                            <div class="list-content flex-grow-1 ms-1">
                              <p class="list-text body-color fz14 mb-1">
                                Academic Writing
                              </p>
                              <h5 class="list-title">
                                <a>
                                  The Impact of Social Media on Society: A
                                  Comprehensive Analysis
                                </a>
                              </h5>
                              <div class="review-meta d-flex align-items-center">
                                <i class="fas fa-star fz10 review-color me-2"></i>
                                <p class="mb-0 body-color fz14">
                                  <span class="dark-color me-2">4.6</span>
                                </p>
                              </div>
                              <hr class="my-2" />
                              <div class="list-meta d-flex justify-content-between align-items-center mt15">
                                <div class="budget">
                                  <p class="mb-0 body-color">
                                    Starting at
                                    <span class="fz17 fw500 dark-color ms-1">
                                      ₹4500
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col-md-6">
                          <div class="listing-style1 list-style d-block d-xl-flex align-items-center">
                            <div class="list-thumb flex-shrink-0">
                              <img
                                class="w-100"
                                src="images/listings/Services-g-17/write2.png"
                                alt=""
                              />
                            </div>
                            <div class="list-content flex-grow-1 ms-1">
                              <p class="list-text body-color fz14 mb-1">
                                Academic Writing
                              </p>
                              <h5 class="list-title line-clamp2">
                                <a>
                                  The Importance of Early Childhood Education
                                  for Cognitive Development
                                </a>
                              </h5>
                              <div class="review-meta d-flex align-items-center">
                                <i class="fas fa-star fz10 review-color me-2"></i>
                                <p class="mb-0 body-color fz14">
                                  <span class="dark-color me-2">4.8</span>
                                </p>
                              </div>
                              <hr class="my-2" />
                              <div class="list-meta d-flex justify-content-between align-items-center mt15">
                                <div class="budget">
                                  <p class="mb-0 body-color">
                                    Starting at
                                    <span class="fz17 fw500 dark-color ms-1">
                                      ₹3500
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="listing-style1 list-style d-block d-xl-flex align-items-center">
                            <div class="list-thumb flex-shrink-0">
                              <img
                                class="w-100"
                                src="images/listings/Services-g-17/write3.png"
                                alt=""
                              />
                            </div>
                            <div class="list-content flex-grow-1 ms-1">
                              <p class="list-text body-color fz14 mb-1">
                                Academic Writing
                              </p>
                              <h5 class="list-title">
                                <a>
                                  Climate Change and its Effects on Global
                                  Ecosystems
                                </a>
                              </h5>
                              <div class="review-meta d-flex align-items-center">
                                <i class="fas fa-star fz10 review-color me-2"></i>
                                <p class="mb-0 body-color fz14">
                                  <span class="dark-color me-2">4.8</span>
                                </p>
                              </div>
                              <hr class="my-2" />
                              <div class="list-meta d-flex justify-content-between align-items-center mt15">
                                <div class="budget">
                                  <p class="mb-0 body-color">
                                    Starting at
                                    <span class="fz17 fw500 dark-color ms-1">
                                      ₹5000
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="listing-style1 list-style d-block d-xl-flex align-items-center">
                            <div class="list-thumb flex-shrink-0">
                              <img
                                class="w-100"
                                src="images/listings/Services-g-17/write4.png"
                                alt=""
                              />
                            </div>
                            <div class="list-content flex-grow-1 ms-1">
                              <p class="list-text body-color fz14 mb-1">
                                Academic Writing
                              </p>
                              <h5 class="list-title line-clamp2">
                                <a>
                                  The Role of Artificial Intelligence in Modern
                                  Healthcare
                                </a>
                              </h5>
                              <div class="review-meta d-flex align-items-center">
                                <i class="fas fa-star fz10 review-color me-2"></i>
                                <p class="mb-0 body-color fz14">
                                  <span class="dark-color me-2">4.9</span>
                                </p>
                              </div>
                              <hr class="my-2" />
                              <div class="list-meta d-flex justify-content-between align-items-center mt15">
                                <div class="budget">
                                  <p class="mb-0 body-color">
                                    Starting at
                                    <span class="fz17 fw500 dark-color ms-1">
                                      ₹8000
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="listing-style1 list-style d-block d-xl-flex align-items-center">
                            <div class="list-thumb flex-shrink-0">
                              <img
                                class="w-100"
                                src="images/listings/Services-g-17/write5.png"
                                alt=""
                              />
                            </div>
                            <div class="list-content flex-grow-1 ms-1">
                              <p class="list-text body-color fz14 mb-1">
                                Academic Writing
                              </p>
                              <h5 class="list-title">
                                <a>
                                  The Influence of Cultural Diversity on
                                  Organizational Performance
                                </a>
                              </h5>
                              <div class="review-meta d-flex align-items-center">
                                <i class="fas fa-star fz10 review-color me-2"></i>
                                <p class="mb-0 body-color fz14">
                                  <span class="dark-color me-2">4.8</span>
                                </p>
                              </div>
                              <hr class="my-2" />
                              <div class="list-meta d-flex justify-content-between align-items-center mt15">
                                <div class="budget">
                                  <p class="mb-0 body-color">
                                    Starting at
                                    <span class="fz17 fw500 dark-color ms-1">
                                      ₹6000
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col-md-6">
                          <div class="listing-style1 list-style d-block d-xl-flex align-items-center">
                            <div class="list-thumb flex-shrink-0">
                              <img
                                class="w-100"
                                src="images/listings/Services-g-17/write6.png"
                                alt=""
                              />
                            </div>
                            <div class="list-content flex-grow-1 ms-1">
                              <p class="list-text body-color fz14 mb-1">
                                Academic Writing
                              </p>
                              <h5 class="list-title">
                                <a>
                                  The Ethics of Genetic Engineering and Human
                                  Cloning
                                </a>
                              </h5>
                              <div class="review-meta d-flex align-items-center">
                                <i class="fas fa-star fz10 review-color me-2"></i>
                                <p class="mb-0 body-color fz14">
                                  <span class="dark-color me-2">4.8</span>
                                </p>
                              </div>
                              <hr class="my-2" />
                              <div class="list-meta d-flex justify-content-between align-items-center mt15">
                                <div class="budget">
                                  <p class="mb-0 body-color">
                                    Starting at
                                    <span class="fz17 fw500 dark-color ms-1">
                                      ₹7500
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="tab-pane fade fz15 text"
                      id="pills-engg"
                      role="tabpanel"
                      aria-labelledby="pills-engg-tab"
                    >
                      <div class="row">
                        <div class="col-md-6">
                          <div class="listing-style1 list-style d-block d-xl-flex align-items-center">
                            <div class="list-thumb flex-shrink-0">
                              <img
                                class="w-100"
                                src="images/listings/Services-g-17/engg1.png"
                                alt=""
                              />
                            </div>
                            <div class="list-content flex-grow-1 ms-1">
                              <p class="list-text body-color fz14 mb-1">
                                Engineering
                              </p>
                              <h5 class="list-title">
                                <a>
                                  Design and Analysis of a Mechanical Component
                                  using SolidWorks Simulation
                                </a>
                              </h5>
                              <div class="review-meta d-flex align-items-center">
                                <i class="fas fa-star fz10 review-color me-2"></i>
                                <p class="mb-0 body-color fz14">
                                  <span class="dark-color me-2">5.0</span>
                                </p>
                              </div>
                              <hr class="my-2" />
                              <div class="list-meta d-flex justify-content-between align-items-center mt15">
                                <div class="budget">
                                  <p class="mb-0 body-color">
                                    Starting at
                                    <span class="fz17 fw500 dark-color ms-1">
                                      ₹15,000
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col-md-6">
                          <div class="listing-style1 list-style d-block d-xl-flex align-items-center">
                            <div class="list-thumb flex-shrink-0">
                              <img
                                class="w-100"
                                src="images/listings/Services-g-17/engg3.png"
                                alt=""
                              />
                            </div>
                            <div class="list-content flex-grow-1 ms-1">
                              <p class="list-text body-color fz14 mb-1">
                                Engineering
                              </p>
                              <h5 class="list-title line-clamp2">
                                <a>
                                  Product Design and Prototyping: Creating a
                                  Functional Prototype with SolidWorks
                                </a>
                              </h5>
                              <div class="review-meta d-flex align-items-center">
                                <i class="fas fa-star fz10 review-color me-2"></i>
                                <p class="mb-0 body-color fz14">
                                  <span class="dark-color me-2">5.0</span>
                                </p>
                              </div>
                              <hr class="my-2" />
                              <div class="list-meta d-flex justify-content-between align-items-center mt15">
                                <div class="budget">
                                  <p class="mb-0 body-color">
                                    Starting at
                                    <span class="fz17 fw500 dark-color ms-1">
                                      ₹7000
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="listing-style1 list-style d-block d-xl-flex align-items-center">
                            <div class="list-thumb flex-shrink-0">
                              <img
                                class="w-100"
                                src="images/listings/Services-g-17/engg2.png"
                                alt=""
                              />
                            </div>
                            <div class="list-content flex-grow-1 ms-1">
                              <p class="list-text body-color fz14 mb-1">
                                Engineering
                              </p>
                              <h5 class="list-title">
                                <a>
                                  Sheet Metal Design: Developing Custom Sheet
                                  Metal Parts using SolidWorks
                                </a>
                              </h5>
                              <div class="review-meta d-flex align-items-center">
                                <i class="fas fa-star fz10 review-color me-2"></i>
                                <p class="mb-0 body-color fz14">
                                  <span class="dark-color me-2">5.0</span>
                                </p>
                              </div>
                              <hr class="my-2" />
                              <div class="list-meta d-flex justify-content-between align-items-center mt15">
                                <div class="budget">
                                  <p class="mb-0 body-color">
                                    Starting at
                                    <span class="fz17 fw500 dark-color ms-1">
                                      ₹5500
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="listing-style1 list-style d-block d-xl-flex align-items-center">
                            <div class="list-thumb flex-shrink-0">
                              <img
                                class="w-100"
                                src="images/listings/Services-g-17/engg4.png"
                                alt=""
                              />
                            </div>
                            <div class="list-content flex-grow-1 ms-1">
                              <p class="list-text body-color fz14 mb-1">
                                Engineering
                              </p>
                              <h5 class="list-title line-clamp2">
                                <a>
                                  Parametric Modeling: Designing Configurable
                                  Models for Flexibility and Adaptability
                                </a>
                              </h5>
                              <div class="review-meta d-flex align-items-center">
                                <i class="fas fa-star fz10 review-color me-2"></i>
                                <p class="mb-0 body-color fz14">
                                  <span class="dark-color me-2">4.9</span>
                                </p>
                              </div>
                              <hr class="my-2" />
                              <div class="list-meta d-flex justify-content-between align-items-center mt15">
                                <div class="budget">
                                  <p class="mb-0 body-color">
                                    Starting at
                                    <span class="fz17 fw500 dark-color ms-1">
                                      ₹7500
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="listing-style1 list-style d-block d-xl-flex align-items-center">
                            <div class="list-thumb flex-shrink-0">
                              <img
                                class="w-100"
                                src="images/listings/Services-g-17/engg5.png"
                                alt=""
                              />
                            </div>
                            <div class="list-content flex-grow-1 ms-1">
                              <p class="list-text body-color fz14 mb-1">
                                Architecture
                              </p>
                              <h5 class="list-title">
                                <a>
                                  Designing Sustainable Structures: Exploring
                                  Green Building Solutions through CAD Design
                                </a>
                              </h5>
                              <div class="review-meta d-flex align-items-center">
                                <i class="fas fa-star fz10 review-color me-2"></i>
                                <p class="mb-0 body-color fz14">
                                  <span class="dark-color me-2">4.6</span>
                                </p>
                              </div>
                              <hr class="my-2" />
                              <div class="list-meta d-flex justify-content-between align-items-center mt15">
                                <div class="budget">
                                  <p class="mb-0 body-color">
                                    Starting at
                                    <span class="fz17 fw500 dark-color ms-1">
                                      ₹50,000
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col-md-6">
                          <div class="listing-style1 list-style d-block d-xl-flex align-items-center">
                            <div class="list-thumb flex-shrink-0">
                              <img
                                class="w-100"
                                src="images/listings/Services-g-17/engg6.png"
                                alt=""
                              />
                            </div>
                            <div class="list-content flex-grow-1 ms-1">
                              <p class="list-text body-color fz14 mb-1">
                                Architecture
                              </p>
                              <h5 class="list-title">
                                <a>
                                  Virtual Prototyping: Simulating and Validating
                                  Product Designs using CAD Software
                                </a>
                              </h5>
                              <div class="review-meta d-flex align-items-center">
                                <i class="fas fa-star fz10 review-color me-2"></i>
                                <p class="mb-0 body-color fz14">
                                  <span class="dark-color me-2">4.5</span>
                                </p>
                              </div>
                              <hr class="my-2" />
                              <div class="list-meta d-flex justify-content-between align-items-center mt15">
                                <div class="budget">
                                  <p class="mb-0 body-color">
                                    Starting at
                                    <span class="fz17 fw500 dark-color ms-1">
                                      ₹30,000
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!-- CTA Banner --> */}
        <section class="cta-banner-about2 at-home10-2 at-home6 mx-auto position-relative pt60-lg pb60-lg">
          <div class="container">
            <div
              class="row align-items-center wow fadeInDown"
              data-wow-delay="400ms"
            >
              <div class="col-lg-7 col-xl-5 mb60-xs mb100-md">
                <div class="mb30">
                  <div class="main-title">
                    <h2 class="title">
                      A whole world of freelance
                      <br class="d-none d-xl-block" />
                      talent at your fingertips
                    </h2>
                  </div>
                </div>
                <div class="why-chose-list">
                  <div class="list-one d-flex align-items-start mb30">
                    <span class="list-icon flex-shrink-0 flaticon-badge"></span>
                    <div class="list-content flex-grow-1 ml20">
                      <h4 class="mb-1">Proof of quality</h4>
                      <p class="text mb-0 fz15">
                        Check any pro’s work samples, client reviews, and
                        identity <br class="d-none d-lg-block" />
                        verification.
                      </p>
                    </div>
                  </div>
                  <div class="list-one d-flex align-items-start mb30">
                    <span class="list-icon flex-shrink-0 flaticon-money"></span>
                    <div class="list-content flex-grow-1 ml20">
                      <h4 class="mb-1">No cost until you hire</h4>
                      <p class="text mb-0 fz15">
                        Interview potential fits for your job, negotiate rates,
                        and only pay <br class="d-none d-lg-block" />
                        for work you approve.
                      </p>
                    </div>
                  </div>
                  <div class="list-one d-flex align-items-start mb30">
                    <span class="list-icon flex-shrink-0 flaticon-security"></span>
                    <div class="list-content flex-grow-1 ml20">
                      <h4 class="mb-1">Safe and secure</h4>
                      <p class="text mb-0 fz15">
                        Focus on your work knowing we help protect your data and
                        privacy. We’re here with 24/7 support if you need it.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-5 col-xl-4 offset-xl-1">
                <div class="listbox-style1 px30 py-5 bdrs16 bgc-thm2 position-relative">
                  <div class="list-style1">
                    <ul class="mb-0">
                      <li class="text-white fw500">
                        <i class="far fa-check dark-color bgc-white"></i>The
                        best for every budget
                      </li>
                      <li class="text-white fw500">
                        <i class="far fa-check dark-color bgc-white"></i>Quality
                        work done quickly
                      </li>
                      <li class="text-white fw500">
                        <i class="far fa-check dark-color bgc-white"></i>
                        Protected payments, every time
                      </li>
                      <li class="text-white fw500 mb-0">
                        <i class="far fa-check dark-color bgc-white"></i>24/7
                        support
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img class="home6-cta-img" src="images/about/about-10.jpg" alt="" />
        </section>

        {/* <!-- Funfact --> */}
        <section class="pb-0 pt60">
          <div class="container maxw1600 bdrb1 pb60">
            <div class="row">
              <div class="col-xl-10 mx-auto">
                <div
                  class="row justify-content-center wow fadeInUp"
                  data-wow-delay="300ms"
                >
                  <div class="col-6 col-md-3">
                    <div class="funfact_one mb20-sm text-center">
                      <div class="details">
                        <ul class="ps-0 mb-0 d-flex justify-content-center">
                          <li>
                            <div class="timer">250</div>
                          </li>
                          <li>
                            <span>K</span>
                          </li>
                        </ul>
                        <p class="text mb-0">Total Freelancer</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-6 col-md-3">
                    <div class="funfact_one mb20-sm text-center">
                      <div class="details">
                        <ul class="ps-0 mb-0 d-flex justify-content-center">
                          <li>
                            <div class="timer">150</div>
                          </li>
                          <li>
                            <span>K</span>
                          </li>
                        </ul>
                        <p class="text mb-0">Positive Review</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-6 col-md-3">
                    <div class="funfact_one mb20-sm text-center">
                      <div class="details">
                        <ul class="ps-0 mb-0 d-flex justify-content-center">
                          <li>
                            <div class="timer">12</div>
                          </li>
                          <li>
                            <span>K</span>
                          </li>
                        </ul>
                        <p class="text mb-0">Order recieved</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-6 col-md-3">
                    <div class="funfact_one mb20-sm text-center">
                      <div class="details">
                        <ul class="ps-0 mb-0 d-flex justify-content-center">
                          <li>
                            <div class="timer">85</div>
                          </li>
                          <li>
                            <span>K</span>
                          </li>
                        </ul>
                        <p class="text mb-0">Projects Completed</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!-- Our Testimonials --> */}
        <section class="our-testimonial">
          <div class="container">
            <div class="row">
              <div class="col-lg-6 mx-auto wow fadeInUp" data-wow-delay="300ms">
                <div class="main-title text-center">
                  <h2>Testimonials</h2>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-8 m-auto wow fadeInUp" data-wow-delay="500ms">
                <div class="testimonial-style2">
                  <div class="tab-content" id="pills-tabContent">
                    <div
                      class="tab-pane fade"
                      id="pills-1st"
                      role="tabpanel"
                      aria-labelledby="pills-1st-tab"
                    >
                      <div class="testi-content text-md-center">
                        <span class="icon fas fa-quote-left"></span>
                        <h4 class="testi-text">
                          FXCTS Freelance Community is the epitome of freelance
                          greatness! As a seasoned freelancer, I have roamed the
                          digital landscapes seeking the ultimate platform to
                          showcase my skills and connect with top-tier clients.
                          My quest was finally fulfilled when I stumbled upon
                          FXCTS, and boy, was I blown away!
                        </h4>
                        <h6 class="name">Nisha</h6>
                        <p class="design">
                          HR Manager (Human Resources Manager)
                        </p>
                      </div>
                    </div>
                    <div
                      class="tab-pane fade show active"
                      id="pills-2nd"
                      role="tabpanel"
                      aria-labelledby="pills-2nd-tab"
                    >
                      <div class="testi-content text-md-center">
                        <span class="icon fas fa-quote-left"></span>
                        <h4 class="testi-text">
                          Let's not forget the incredible support team. It's
                          like they possess psychic powers; they anticipate our
                          needs even before we do! Any hiccup, no matter how
                          minor, is dealt with lightning speed, leaving us free
                          to focus on what we do best – delivering exceptional
                          results to our clients.
                        </h4>
                        <h6 class="name">Siddharth</h6>
                        <p class="design">Project Manager</p>
                      </div>
                    </div>
                    <div
                      class="tab-pane fade"
                      id="pills-3rd"
                      role="tabpanel"
                      aria-labelledby="pills-3rd-tab"
                    >
                      <div class="testi-content text-md-center">
                        <span class="icon fas fa-quote-left"></span>
                        <h4 class="testi-text">
                          From the moment I joined, it was clear that FXCTS is
                          no ordinary freelancing hub. The community exudes a
                          palpable sense of camaraderie, where fellow
                          freelancers cheer each other on, sharing tips and
                          tricks like a secret society of success. It's like
                          having an army of like-minded professionals rooting
                          for your every triumph!
                        </h4>
                        <h6 class="name">Aaradhya</h6>
                        <p class="design">Senior Developer</p>
                      </div>
                    </div>
                    <div
                      class="tab-pane fade"
                      id="pills-4th"
                      role="tabpanel"
                      aria-labelledby="pills-4th-tab"
                    >
                      <div class="testi-content text-md-center">
                        <span class="icon fas fa-quote-left"></span>
                        <h4 class="testi-text">
                          The platform itself is a masterpiece. With its sleek
                          interface and intuitive navigation, finding projects
                          tailored to my expertise became an exciting treasure
                          hunt. And the best part? The sheer variety of projects
                          on offer! FXCTS caters to every niche, making sure
                          every freelancer finds their perfect fit.
                        </h4>
                        <h6 class="name">Vikram</h6>
                        <p class="design">Financial Analyst</p>
                      </div>
                    </div>
                    <div
                      class="tab-pane fade"
                      id="pills-5th"
                      role="tabpanel"
                      aria-labelledby="pills-5th-tab"
                    >
                      <div class="testi-content text-md-center">
                        <span class="icon fas fa-quote-left"></span>
                        <h4 class="testi-text">
                          But the real magic lies in the clients. These are no
                          ordinary clients; they are dream collaborators!
                          Generous, appreciative, and open-minded, they value
                          our expertise and never shy away from rewarding
                          top-notch work. Thanks to FXCTS, I've had the pleasure
                          of working with some of the most visionary minds in
                          the industry, which has undoubtedly propelled my
                          career to new heights.
                        </h4>
                        <h6 class="name">Ishita</h6>
                        <p class="design">Graphic Designer</p>
                      </div>
                    </div>
                  </div>
                  <div class="tab-list position-relative">
                    <ul
                      class="nav nav-pills justify-content-md-center"
                      id="pills-tab"
                      role="tablist"
                    >
                      <li class="nav-item" role="presentation">
                        <button
                          class="nav-link ps-0"
                          id="pills-1st-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-1st"
                          type="button"
                          role="tab"
                          aria-controls="pills-1st"
                          aria-selected="true"
                        >
                          <img src="images/testimonials/testi-1.png" alt="" />
                        </button>
                      </li>
                      <li class="nav-item" role="presentation">
                        <button
                          class="nav-link active"
                          id="pills-2nd-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-2nd"
                          type="button"
                          role="tab"
                          aria-controls="pills-2nd"
                          aria-selected="false"
                        >
                          <img src="images/testimonials/testi-1.png" alt="" />
                        </button>
                      </li>
                      <li class="nav-item" role="presentation">
                        <button
                          class="nav-link"
                          id="pills-3rd-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-3rd"
                          type="button"
                          role="tab"
                          aria-controls="pills-3rd"
                          aria-selected="false"
                        >
                          <img src="images/testimonials/testi-1.png" alt="" />
                        </button>
                      </li>
                      <li class="nav-item" role="presentation">
                        <button
                          class="nav-link"
                          id="pills-4th-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-4th"
                          type="button"
                          role="tab"
                          aria-controls="pills-4th"
                          aria-selected="false"
                        >
                          <img src="images/testimonials/testi-1.png" alt="" />
                        </button>
                      </li>
                      <li class="nav-item" role="presentation">
                        <button
                          class="nav-link pe-0"
                          id="pills-5th-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-5th"
                          type="button"
                          role="tab"
                          aria-controls="pills-5th"
                          aria-selected="false"
                        >
                          <img src="images/testimonials/testi-1.png" alt="" />
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!-- Trending Services --> */}
        <section class="pb80 pb20-md bgc-thm2">
          <div class="container">
            <div class="row align-items-center">
              <div class="col-lg-9 wow fadeInUp">
                <div class="main-title">
                  <h2 class="title text-white">Trending Services</h2>
                  <p class="paragraph text-white">
                    Discover the Next Big Thing with Trending Services
                  </p>
                </div>
              </div>
              <div class="col-lg-3">
                <div class="text-start text-lg-end mb-4 mb-lg-2">
                  <a class="ud-btn2 text-white" href="services.html">
                    All Services<i class="fal fa-arrow-right-long"></i>
                  </a>
                </div>
              </div>
            </div>
            <div class="row align-items-center">
              <div class="col-lg-12">
                <div
                  class="slider-outer-dib vam_nav_style dots_none slider-4-grid owl-carousel owl-theme wow fadeInUp"
                  data-wow-delay="300ms"
                >
                  <div class="item">
                    <div class="listing-style1 default-box-shadow1 border-0">
                      <div class="list-thumb">
                        <img
                          class="w-100"
                          src="images/listings/TrendingServices/1.png"
                          alt=""
                        />
                      </div>
                      <div class="list-content">
                        <p class="list-text body-color fz14 mb-1">
                          Web & App Design
                        </p>
                        <h5 class="list-title">
                          <a>Design modern websites in figma or adobe xd</a>
                        </h5>
                        <div class="review-meta d-flex align-items-center">
                          <i class="fas fa-star fz10 review-color me-2"></i>
                          <p class="mb-0 body-color fz14">
                            <span class="dark-color me-2">4.8</span>
                          </p>
                        </div>
                        <hr class="my-2" />
                        <div class="list-meta d-flex justify-content-between align-items-center mt15">
                          <div class="budget">
                            <p class="mb-0 body-color">
                              Starting at
                              <span class="fz17 fw500 dark-color ms-1">
                                ₹15,000
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="item">
                    <div class="listing-style1 default-box-shadow1 border-0">
                      <div class="list-thumb">
                        <img
                          class="w-100"
                          src="images/listings/TrendingServices/3.png"
                          alt=""
                        />
                      </div>
                      <div class="list-content">
                        <p class="list-text body-color fz14 mb-1">
                          Design & Creative
                        </p>
                        <h5 class="list-title line-clamp2">
                          <a>
                            Build a fully responsive design in HTML,CSS,
                            bootstrap, and javascript
                          </a>
                        </h5>
                        <div class="review-meta d-flex align-items-center">
                          <i class="fas fa-star fz10 review-color me-2"></i>
                          <p class="mb-0 body-color fz14">
                            <span class="dark-color me-2">4.7</span>
                          </p>
                        </div>
                        <hr class="my-2" />
                        <div class="list-meta d-flex justify-content-between align-items-center mt15">
                          <div class="budget">
                            <p class="mb-0 body-color">
                              Starting at
                              <span class="fz17 fw500 dark-color ms-1">
                                ₹8000
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="item">
                    <div class="listing-style1 default-box-shadow1 border-0">
                      <div class="list-thumb">
                        <img
                          class="w-100"
                          src="images/listings/TrendingServices/4.png"
                          alt=""
                        />
                      </div>
                      <div class="list-content">
                        <p class="list-text body-color fz14 mb-1">
                          Development & IT
                        </p>
                        <h5 class="list-title line-clamp2">
                          <a>Mobile app development for ios and android</a>
                        </h5>
                        <div class="review-meta d-flex align-items-center">
                          <i class="fas fa-star fz10 review-color me-2"></i>
                          <p class="mb-0 body-color fz14">
                            <span class="dark-color me-2">4.5</span>
                          </p>
                        </div>
                        <hr class="my-2" />
                        <div class="list-meta d-flex justify-content-between align-items-center mt15">
                          <div class="budget">
                            <p class="mb-0 body-color">
                              Starting at
                              <span class="fz17 fw500 dark-color ms-1">
                                ₹40,000
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="item">
                    <div class="listing-style1 default-box-shadow1 border-0">
                      <div class="list-thumb">
                        <img
                          class="w-100"
                          src="images/listings/TrendingServices/2.png"
                          alt=""
                        />
                      </div>
                      <div class="list-content">
                        <p class="list-text body-color fz14 mb-1">
                          Art & Illustration
                        </p>
                        <h5 class="list-title">
                          <a>Create modern design art & illustration</a>
                        </h5>
                        <div class="review-meta d-flex align-items-center">
                          <i class="fas fa-star fz10 review-color me-2"></i>
                          <p class="mb-0 body-color fz14">
                            <span class="dark-color me-2">4.6</span>
                          </p>
                        </div>
                        <hr class="my-2" />
                        <div class="list-meta d-flex justify-content-between align-items-center mt15">
                          <div class="budget">
                            <p class="mb-0 body-color">
                              Starting at
                              <span class="fz17 fw500 dark-color ms-1">
                                ₹5000
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="item">
                    <div class="listing-style1 default-box-shadow1 border-0">
                      <div class="list-thumb">
                        <img
                          class="w-100"
                          src="images/listings/TrendingServices/5.png"
                          alt=""
                        />
                      </div>
                      <div class="list-content">
                        <p class="list-text body-color fz14 mb-1">
                          Development & IT
                        </p>
                        <h5 class="list-title">
                          <a>Creating User-Friendly Websites Across Devices</a>
                        </h5>
                        <div class="review-meta d-flex align-items-center">
                          <i class="fas fa-star fz10 review-color me-2"></i>
                          <p class="mb-0 body-color fz14">
                            <span class="dark-color me-2">4.8</span>
                          </p>
                        </div>
                        <hr class="my-2" />
                        <div class="list-meta d-flex justify-content-between align-items-center mt15">
                          <div class="budget">
                            <p class="mb-0 body-color">
                              Starting at
                              <span class="fz17 fw500 dark-color ms-1">
                                ₹25,000
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="item">
                    <div class="listing-style1 default-box-shadow1 border-0">
                      <div class="list-thumb">
                        <img
                          class="w-100"
                          src="images/listings/TrendingServices/7.png"
                          alt=""
                        />
                      </div>
                      <div class="list-content">
                        <p class="list-text body-color fz14 mb-1">
                          Design & Creative
                        </p>
                        <h5 class="list-title">
                          <a>
                            Crafting Memorable and Impactful Brand Identities
                          </a>
                        </h5>
                        <div class="review-meta d-flex align-items-center">
                          <i class="fas fa-star fz10 review-color me-2"></i>
                          <p class="mb-0 body-color fz14">
                            <span class="dark-color me-2">4.5</span>
                          </p>
                        </div>
                        <hr class="my-2" />
                        <div class="list-meta d-flex justify-content-between align-items-center mt15">
                          <div class="budget">
                            <p class="mb-0 body-color">
                              Starting at
                              <span class="fz17 fw500 dark-color ms-1">
                                ₹20,000
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="item">
                    <div class="listing-style1 default-box-shadow1 border-0">
                      <div class="list-thumb">
                        <img
                          class="w-100"
                          src="images/listings/TrendingServices/8.png"
                          alt=""
                        />
                      </div>
                      <div class="list-content">
                        <p class="list-text body-color fz14 mb-1">
                          Admin & Customer Support
                        </p>
                        <h5 class="list-title line-clamp2">
                          <a>
                            Data Entry for Healthcare: Managing Patient Records
                            with Confidentiality and Precision.
                          </a>
                        </h5>
                        <div class="review-meta d-flex align-items-center">
                          <i class="fas fa-star fz10 review-color me-2"></i>
                          <p class="mb-0 body-color fz14">
                            <span class="dark-color me-2">4.7</span>
                          </p>
                        </div>
                        <hr class="my-2" />
                        <div class="list-meta d-flex justify-content-between align-items-center mt15">
                          <div class="budget">
                            <p class="mb-0 body-color">
                              Starting at
                              <span class="fz17 fw500 dark-color ms-1">
                                ₹4500
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!-- Our Partners --> */}
        {/* <!-- <section class="our-partners pt-5">
          <div class="container">
            <div class="row wow fadeInUp">
              <div class="col-lg-12">
                <div class="main-title text-center">
                  <h6>Trusted Companies</h6>
                </div>
              </div>
            </div>
            <div class="row wow fadeInUp">
              <div class="col-6 col-md-4 col-xl-2">
                <div class="partner_item text-center mb30-lg">
                  <img
                    class="wa m-auto"
                    src="images/partners/1.png"
                    alt="1.png"
                  />
                </div>
              </div>
              <div class="col-6 col-md-4 col-xl-2">
                <div class="partner_item text-center mb30-lg">
                  <img
                    class="wa m-auto"
                    src="images/partners/2.png"
                    alt="2.png"
                  />
                </div>
              </div>
              <div class="col-6 col-md-4 col-xl-2">
                <div class="partner_item text-center mb30-lg">
                  <img
                    class="wa m-auto"
                    src="images/partners/3.png"
                    alt="3.png"
                  />
                </div>
              </div>
              <div class="col-6 col-md-4 col-xl-2">
                <div class="partner_item text-center mb30-lg">
                  <img
                    class="wa m-auto"
                    src="images/partners/4.png"
                    alt="4.png"
                  />
                </div>
              </div>
              <div class="col-6 col-md-4 col-xl-2">
                <div class="partner_item text-center mb30-lg">
                  <img
                    class="wa m-auto"
                    src="images/partners/5.png"
                    alt="5.png"
                  />
                </div>
              </div>
              <div class="col-6 col-md-4 col-xl-2">
                <div class="partner_item text-center mb30-lg">
                  <img
                    class="wa m-auto"
                    src="images/partners/6.png"
                    alt="6.png"
                  />
                </div>
              </div>
            </div>
          </div>
        </section> -->*/}
      </div>
    </>
  );
}

export default Home;
