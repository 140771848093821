import React from "react";
import GoToTop from "../GoToTop";

function Policies() {
  return (
    <>
      <GoToTop />
      <div class="body_content">
        {/* <!-- Our Terms & Conditions --> */}
        <section class="our-terms">
          <div class="container">
            <div class="row">
              <div class="col-lg-6">
                <div class="main-title">
                  <h2>Terms and Conditions</h2>
                  {/* <!-- <p class="text">
                    Give your visitor a smooth online experience with a solid UX
                    design
                  </p> --> */}
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-3 col-lg-3 col-xl-2">
                <div class="terms_condition_widget mb30-sm">
                  <div class="widget_list">
                    <nav>
                      <div
                        class="nav nav-tabs text-start"
                        id="nav-tab"
                        role="tablist"
                      >
                        <button
                          class="nav-link text-start active"
                          id="nav-accountpayment-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#nav-accountpayment"
                          type="button"
                          role="tab"
                          aria-controls="nav-accountpayment"
                          aria-selected="true"
                        >
                          Privacy Policy
                        </button>
                        <button
                          class="nav-link text-start"
                          id="nav-manageother-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#nav-manageother"
                          type="button"
                          role="tab"
                          aria-controls="nav-manageother"
                          aria-selected="false"
                        >
                          Manage Orders
                        </button>
                        <button
                          class="nav-link text-start"
                          id="nav-returrefund-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#nav-returrefund"
                          type="button"
                          role="tab"
                          aria-controls="nav-returrefund"
                          aria-selected="false"
                        >
                          Returns and Refund Policy
                        </button>

                        <button
                          class="nav-link text-start"
                          id="nav-terms-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#nav-terms"
                          type="button"
                          role="tab"
                          aria-controls="nav-terms"
                          aria-selected="false"
                        >
                          Terms of Service
                        </button>
                      </div>
                    </nav>
                  </div>
                </div>
              </div>
              <div class="col-md-9 col-lg-9 col-xl-9 offset-xl-1">
                <div class="terms_condition_grid text-start">
                  <div class="tab-content" id="nav-tabContent">
                    <div
                      class="tab-pane fade show active"
                      id="nav-accountpayment"
                      role="tabpanel"
                      aria-labelledby="nav-accountpayment-tab"
                    >
                      <div class="grids mb90 mb40-md">
                        <h4 class="title">
                          Privacy Policy - FXCTS Freelance Marketplace
                        </h4>
                        <p class="mb25 text fz15">
                          This Privacy Policy (&quot;Policy&quot;) outlines how
                          FXCTS collects, uses, and protects your personal
                          information when you use our freelance marketplace
                          platform. We are committed to safeguarding your
                          privacy and ensuring that your personal information is
                          protected.
                          <br />
                          <br />
                          <b>Information We Collect:</b>
                          <br />
                          <br />
                          <b>a. Personal Information:</b>
                          <br />
                          <br />
                          i. When you register an account on our platform, we
                          may collect personal information such as your name,
                          email address, contact details, and billing
                          information.
                          <br />
                          <br />
                          ii. We may collect additional personal information
                          necessary for providing our services, such as your
                          location, skills, portfolio, and professional
                          background.
                          <br />
                          <br />
                          <b>b. Usage Information:</b>
                          <br />
                          <br />
                          i. We may collect information about your interactions
                          with our platform, including the pages you visit, the
                          projects you browse or engage with, and the actions
                          you take.
                          <br />
                          <br />
                          ii. This information may include your IP address,
                          browser type, device information, and operating
                          system.
                          <br />
                          <br />
                          <b>c. Communication Information:</b>
                          <br />
                          <br />
                          i. We may collect information related to your
                          communication with other users or our customer support
                          team, including messages, feedback, and support
                          inquiries.
                          <br />
                          <br />
                          <b>d. Cookies and Similar Technologies:</b>
                          <br />
                          <br />
                          i. We may use cookies and similar technologies to
                          enhance your experience on our platform, track usage
                          patterns, and gather information about your
                          preferences.
                          <br />
                          <br />
                          <b>Use of Personal Information:</b>
                          <br />
                          <br />
                          a. We may use your personal information for the
                          following purposes:
                          <br />
                          <br />
                          i. To create and manage your account and provide our
                          freelance marketplace services to you.
                          <br />
                          <br />
                          ii. To facilitate communication and collaboration
                          between users on our platform.
                          <br />
                          <br />
                          iii. To personalize your experience, provide relevant
                          content, and improve our platform&#39;s functionality.
                          <br />
                          <br />
                          iv. To process payments, verify transactions, and
                          prevent fraud.
                          <br />
                          <br />
                          v. To respond to your inquiries, provide customer
                          support, and address any issues or concerns.
                          <br />
                          <br />
                          vi. To send you promotional and marketing
                          communications, subject to your preferences and
                          applicable laws.
                          <br />
                          <br />
                          vii. To analyze and understand user behavior, trends,
                          and preferences to improve our services and enhance
                          user satisfaction.
                          <br />
                          <br />
                          b. We will not sell, rent, or lease your personal
                          information to third parties unless we have your
                          permission or are required by law to do so.
                          <br />
                          <br />
                          <b>Data Security:</b>
                          <br />
                          <br />
                          a. We implement industry-standard security measures to
                          protect your personal information from unauthorized
                          access, disclosure, alteration, or destruction.
                          <br />
                          <br />
                          b. We regularly review our security practices and
                          update our systems to ensure the ongoing protection of
                          your personal information.
                          <br />
                          <br />
                          <b>Data Retention:</b>
                          <br />
                          <br />
                          a. We retain your personal information for as long as
                          necessary to fulfill the purposes outlined in this
                          Privacy Policy unless a longer retention period is
                          required or permitted by law.
                          <br />
                          <br />
                          b. If you request the deletion of your account, we
                          will securely delete or anonymize your personal
                          information, subject to any legal obligations or
                          legitimate business interests.
                          <br />
                          <br />
                          <b>Third-Party Services:</b> <br />
                          <br />
                          a. Our platform may contain links to third-party
                          websites or services. This Privacy Policy applies
                          solely to information collected on our platform, and
                          we are not responsible for the privacy practices of
                          third-party websites or services.
                          <br />
                          <br />
                          <b>Children&#39;s Privacy:</b>
                          <br />
                          <br />
                          a. Our platform is not intended for individuals under
                          the age of 18. We do not knowingly collect personal
                          information from children. If we become aware that we
                          have inadvertently collected personal information from
                          a child, we will take steps to delete it promptly.
                          <br />
                          <br />
                          <b>Changes to the Privacy Policy:</b>
                          <br />
                          <br />
                          a. We reserve the right to modify or update this
                          Privacy Policy at any time. Any changes will be
                          effective upon posting the revised Policy on our
                          platform. We encourage you to review this Policy
                          periodically for any updates.
                          <br />
                          <br />
                          <b>Contact Us:</b> <br />
                          <br />
                          a. If you have any questions, concerns, or requests
                          regarding this Privacy Policy or the handling of your
                          personal information, please contact us at [provide
                          contact information].
                          <br />
                          <br />
                          By using the FXCTS freelance marketplace platform, you
                          acknowledge that you have read, understood,
                        </p>
                      </div>
                    </div>
                    <div
                      class="tab-pane fade"
                      id="nav-manageother"
                      role="tabpanel"
                      aria-labelledby="nav-manageother-tab"
                    >
                      <div class="grids mb90 mb40-md">
                        <p class="mb25 text fz15">
                          These Terms and Conditions (&quot;Agreement&quot;)
                          govern the management of freelance projects or orders
                          within our platform. By using our platform to manage
                          freelance projects or orders, you agree to comply with
                          these terms and conditions.
                          <br />
                          <br />
                          <b>Project/Order Management:</b>
                          <br />
                          <br />
                          a. Our platform allows users to create, manage, and
                          track freelance projects or orders.
                          <br />
                          <br />
                          b. Users are responsible for providing accurate and
                          complete information regarding project requirements,
                          timelines, deliverables, and any other relevant
                          details.
                          <br />
                          <br />
                          c. Users shall communicate and collaborate effectively
                          within the platform to ensure smooth project
                          execution.
                          <br />
                          <br />
                          d. Users shall promptly respond to project-related
                          inquiries, provide necessary feedback, and address any
                          issues or concerns.
                          <br />
                          <br />
                          <b>Project/Order Completion:</b>
                          <br />
                          <br />
                          a. Freelancers are responsible for delivering the
                          agreed-upon deliverables within the specified
                          timelines and meeting the quality standards set by the
                          client.
                          <br />
                          <br />
                          b. Clients shall review and approve the deliverables
                          within a reasonable timeframe. If revisions are
                          required, clients shall provide clear and constructive
                          feedback to freelancers.
                          <br />
                          <br />
                          c. Once the deliverables are approved or the project
                          is deemed completed, clients shall mark the project as
                          &quot;completed&quot; within the platform.
                          <br />
                          <br />
                          <b>Disputes and Conflict Resolution:</b> <br />
                          <br />
                          a. In the event of any disputes or conflicts arising
                          during the project management process, users shall
                          make reasonable efforts to resolve the issues amicably
                          through direct communication and negotiation.
                          <br />
                          <br />
                          b. If a resolution cannot be reached, either party may
                          notify the platform, and we will provide assistance in
                          mediating the dispute. However, the platform shall
                          have no liability for the outcome of such dispute
                          resolution.
                          <br />
                          <br />
                          <b>Confidentiality and Intellectual Property:</b>
                          <br />
                          <br />
                          a. Users shall respect the confidentiality of any
                          proprietary or confidential information shared during
                          the project management process.
                          <br />
                          <br />
                          b. Freelancers shall retain the intellectual property
                          rights to their work, unless otherwise specified in
                          the project agreement. Clients shall respect the
                          freelancers&#39; intellectual property rights and not
                          use the deliverables beyond the agreed-upon scope
                          without proper authorization.
                          <br />
                          <br />
                          <b>Payment and Fees:</b>
                          <br />
                          <br />
                          a. Payment terms and fees shall be agreed upon between
                          the client and the freelancer, and any financial
                          transactions shall be conducted outside the platform.
                          <br />
                          <br />
                          b. The platform does not hold any responsibility for
                          payment disputes or financial matters between clients
                          and freelancers.
                          <br />
                          <br />
                          <b>Account Termination:</b>
                          <br />
                          <br />
                          a. The platform reserves the right to terminate or
                          suspend user accounts, in whole or in part, at any
                          time and without prior notice, for violations of this
                          Agreement or for any other reason determined at our
                          sole discretion.
                          <br />
                          <br />
                          <b>Limitation of Liability:</b> <br />
                          <br />
                          a. The platform shall not be liable for any direct,
                          indirect, incidental, consequential, or punitive
                          damages arising out of or related to the use or
                          inability to use our platform for managing freelance
                          projects or orders.
                          <br />
                          <br />
                          <b>Modification of Terms:</b>
                          <br />
                          <br />
                          a. The platform reserves the right to modify or update
                          these Terms and Conditions at any time. Any changes
                          will be communicated to users through the platform or
                          via email. Your continued use of the platform after
                          such modifications constitutes your acceptance of the
                          revised terms.
                          <br />
                          <br />
                          <b>Governing Law and Jurisdiction:</b>
                          <br />
                          <br />
                          a. This Agreement shall be governed by and construed
                          in accordance with the laws of [specify jurisdiction].
                          Any legal action or proceeding arising out of or
                          relating to this Agreement shall be subject to the
                          exclusive jurisdiction of the courts in [specify
                          jurisdiction].
                          <br />
                          <br />
                          By using our platform to manage freelance projects or
                          orders, you acknowledge that you have read,
                          understood, and agreed to be bound by these Terms and
                          Conditions. If you do not agree to these terms, you
                          may not use the platform for managing freelance
                          projects or orders.
                        </p>
                      </div>
                    </div>
                    <div
                      class="tab-pane fade"
                      id="nav-returrefund"
                      role="tabpanel"
                      aria-labelledby="nav-returrefund-tab"
                    >
                      <div class="grids mb90 mb40-md">
                        <p class="mb25 text fz15">
                          These Terms and Conditions (&quot;Agreement&quot;)
                          outline the returns and refund policy for products or
                          services purchased through FXCTS. By making a
                          purchase, you agree to comply with this policy.
                          <br />
                          <br />
                          <b>Eligibility for Returns and Refunds:</b> <br />
                          <br />
                          a. Products or services may be eligible for returns
                          and refunds subject to the specific terms and
                          conditions outlined in this policy. <br />
                          <br />
                          b. Eligibility for returns and refunds may vary based
                          on the type of product or service purchased.
                          <br />
                          <br />
                          <b>Return and Refund Process:</b> <br />
                          <br />
                          a. To initiate a return or refund, you must contact
                          our customer support within a specified timeframe from
                          the date of purchase, as determined by the specific
                          product or service. <br />
                          <br />
                          b. You may be required to provide proof of purchase
                          and any supporting documentation to process your
                          return or refund request. <br />
                          <br />
                          c. Our customer support team will guide you through
                          the return and refund process, including providing
                          instructions for returning the product or ceasing the
                          service. <br />
                          <br />
                          d. Returns and refunds will be processed in accordance
                          with the specific terms and conditions outlined below.
                          <br />
                          <br />
                          <b>Product Returns:</b> <br />
                          <br />
                          a. Physical Products: <br />
                          <br />
                          i. Physical products may be eligible for return if
                          they are defective, damaged, or do not meet the
                          specifications as described on the FXCTS platform.
                          <br />
                          <br />
                          ii. To be eligible for a return, physical products
                          must be returned in their original condition,
                          including all packaging materials, accessories, and
                          documentation. <br />
                          <br />
                          iii. The cost of return shipping may be the
                          responsibility of the customer, unless otherwise
                          specified.
                          <br />
                          <br />
                          iv. Once the returned product is received and
                          inspected, a refund may be issued, subject to the
                          terms and conditions outlined below. <br />
                          <br />
                          b. Digital Products: <br />
                          <br />
                          i. Due to the nature of digital products, returns may
                          not be accepted unless the product is defective or
                          does not meet the specifications as described on the
                          FXCTS platform. <br />
                          <br />
                          ii. If a digital product is found to be defective,
                          please contact our customer support for further
                          assistance. <br />
                          <br />
                          <b>Service Cancellation and Refunds:</b> <br />
                          <br />
                          a. Services: <br />
                          <br />
                          i. Services, such as subscriptions, memberships, or
                          consultations, may be eligible for cancellation and
                          refund subject to the specific terms and conditions
                          outlined during the purchase process. <br />
                          <br />
                          ii. Refunds for services may be prorated based on the
                          portion of the service already consumed or provided.
                          <br />
                          <br />
                          b. Digital Downloads: <br />
                          <br />
                          i. Digital downloads, such as e-books or software
                          licenses, may not be eligible for refunds unless
                          otherwise specified at the time of purchase. <br />
                          <br />
                          <b>Non-Refundable Items:</b> <br />
                          <br />
                          a. Certain items may be non-refundable, including:
                          <br />
                          <br />
                          i. Customized or personalized products or services.
                          <br />
                          <br />
                          ii. Products or services explicitly marked as
                          non-refundable. <br />
                          <br />
                          <b>Processing Time and Method of Refunds:</b> <br />
                          <br />
                          a. Refunds will be processed within a reasonable
                          timeframe from the approval of the return or refund
                          request. <br />
                          <br />
                          b. Refunds will be issued using the original payment
                          method unless otherwise agreed upon. <br />
                          <br />
                          Modifications to the Returns and Refund Policy: <br />
                          <br />
                          a. FXCTS reserves the right to modify or update this
                          Returns and Refund Policy at any time. Any changes
                          will be communicated to you through our platform or
                          via email. Your continued use of our platform after
                          such modifications constitutes your acceptance of the
                          revised policy. <br />
                          <br />
                          <b>Governing Law and Jurisdiction:</b> <br />
                          <br />
                          a. This Agreement shall be governed by and construed
                          in accordance with the laws of [specify jurisdiction].
                          Any legal action or proceeding arising out of or
                          relating to this Agreement shall be subject to the
                          exclusive jurisdiction of the courts in [specify
                          jurisdiction]. <br />
                          <br />
                          By making a purchase through FXCTS, you acknowledge
                          that you have read, understood, and agreed to be bound
                          by this Returns and Refund Policy. If you do not agree
                          to these terms, please refrain from making a purchase.
                        </p>
                      </div>
                    </div>
                    <div
                      class="tab-pane fade"
                      id="nav-covid19"
                      role="tabpanel"
                      aria-labelledby="nav-covid19-tab"
                    >
                      <div class="grids mb90 mb40-md">
                        <h4 class="title">1. Introduction</h4>
                        <p class="mb25 text fz15">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Risus nascetur morbi nisl, mi, in semper metus
                          porttitor non. Augue nunc amet fringilla sit.
                          Fringilla eget arcu sodales sed a, parturient
                          fermentum amet scelerisque. Amet purus urna, dictumst
                          aliquet aliquam natoque non, morbi pretium. Integer
                          amet fermentum nibh viverra mollis consectetur arcu,
                          ultrices dolor. Gravida purus arcu viverra eget.
                          Aliquet tincidunt dignissim aliquam tempor nec id.
                          Habitant suscipit sit semper duis odio amet, at.
                        </p>
                        <p class="text fz15">
                          Massa ultricies a arcu velit eget gravida purus
                          ultrices eget. Orci, fames eu facilisi justo. Lacus
                          netus a at sed justo vel leo leo pellentesque. Nulla
                          ut laoreet luctus cum turpis et amet ac viverra. Vitae
                          neque orci dui eu ac tincidunt. Egestas placerat
                          egestas netus nec velit gravida consectetur laoreet
                          vitae. Velit sed enim habitant habitant non diam.
                          Semper tellus turpis tempus ac leo tempor. Ultricies
                          amet, habitasse adipiscing bibendum consequat amet,
                          sed. Id convallis suspendisse vitae, lacinia mattis
                          cursus montes, dui. Tortor lobortis dignissim eget
                          egestas. Eget enim auctor nunc eget mattis
                          sollicitudin senectus diam. Tincidunt morbi egestas
                          dignissim eget id aliquam.
                        </p>
                      </div>
                      <div class="grids mb90 mb40-md">
                        <h4 class="title">2. Your Use of the Freeio Sites</h4>
                        <p class="mb25 text fz15">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Risus nascetur morbi nisl, mi, in semper metus
                          porttitor non. Augue nunc amet fringilla sit.
                          Fringilla eget arcu sodales sed a, parturient
                          fermentum amet scelerisque. Amet purus urna, dictumst
                          aliquet aliquam natoque non, morbi pretium. Integer
                          amet fermentum nibh viverra mollis consectetur arcu,
                          ultrices dolor. Gravida purus arcu viverra eget.
                          Aliquet tincidunt dignissim aliquam tempor nec id.
                          Habitant suscipit sit semper duis odio amet, at.
                        </p>
                        <p class="text fz15">
                          Massa ultricies a arcu velit eget gravida purus
                          ultrices eget. Orci, fames eu facilisi justo. Lacus
                          netus a at sed justo vel leo leo pellentesque. Nulla
                          ut laoreet luctus cum turpis et amet ac viverra. Vitae
                          neque orci dui eu ac tincidunt. Egestas placerat
                          egestas netus nec velit gravida consectetur laoreet
                          vitae. Velit sed enim habitant habitant non diam.
                          Semper tellus turpis tempus ac leo tempor. Ultricies
                          amet, habitasse adipiscing bibendum consequat amet,
                          sed. Id convallis suspendisse vitae, lacinia mattis
                          cursus montes, dui. Tortor lobortis dignissim eget
                          egestas. Eget enim auctor nunc eget mattis
                          sollicitudin senectus diam. Tincidunt morbi egestas
                          dignissim eget id aliquam.
                        </p>
                      </div>
                      <div class="grids">
                        <h4 class="title">3. Content and Ideas</h4>
                        <p class="mb25 text fz15">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Risus nascetur morbi nisl, mi, in semper metus
                          porttitor non. Augue nunc amet fringilla sit.
                          Fringilla eget arcu sodales sed a, parturient
                          fermentum amet scelerisque. Amet purus urna, dictumst
                          aliquet aliquam natoque non, morbi pretium. Integer
                          amet fermentum nibh viverra mollis consectetur arcu,
                          ultrices dolor. Gravida purus arcu viverra eget.
                          Aliquet tincidunt dignissim aliquam tempor nec id.
                          Habitant suscipit sit semper duis odio amet, at.
                        </p>
                        <p class="text fz15">
                          Massa ultricies a arcu velit eget gravida purus
                          ultrices eget. Orci, fames eu facilisi justo. Lacus
                          netus a at sed justo vel leo leo pellentesque. Nulla
                          ut laoreet luctus cum turpis et amet ac viverra. Vitae
                          neque orci dui eu ac tincidunt. Egestas placerat
                          egestas netus nec velit gravida consectetur laoreet
                          vitae. Velit sed enim habitant habitant non diam.
                          Semper tellus turpis tempus ac leo tempor. Ultricies
                          amet, habitasse adipiscing bibendum consequat amet,
                          sed. Id convallis suspendisse vitae, lacinia mattis
                          cursus montes, dui. Tortor lobortis dignissim eget
                          egestas. Eget enim auctor nunc eget mattis
                          sollicitudin senectus diam. Tincidunt morbi egestas
                          dignissim eget id aliquam.
                        </p>
                      </div>
                    </div>
                    <div
                      class="tab-pane fade"
                      id="nav-terms"
                      role="tabpanel"
                      aria-labelledby="nav-terms-tab"
                    >
                      <div class="grids mb90 mb40-md">
                        <h4 class="title">
                          Terms of Service - FXCTS Freelance Marketplace
                        </h4>
                        <p class="mb25 text fz15">
                          These Terms of Service (&quot;Terms&quot;) govern your
                          use of the FXCTS Freelance Marketplace platform. By
                          accessing or using our platform, you agree to comply
                          with these Terms. Please read them carefully before
                          using our services.
                          <br />
                          <br />
                          <b>Account Registration:</b>
                          <br />
                          <br />
                          a. To use our platform, you must create an account by
                          providing accurate and complete information.
                          <br />
                          <br />
                          b. You are solely responsible for maintaining the
                          confidentiality of your account credentials and for
                          all activities that occur under your account.
                          <br />
                          <br />
                          c. You agree to notify us immediately of any
                          unauthorized use of your account or any other breach
                          of security.
                          <br />
                          <br />
                          <b>User Conduct:</b>
                          <br />
                          <br />
                          a. You agree to use our platform in compliance with
                          applicable laws, regulations, and these Terms.
                          <br />
                          <br />
                          b. You shall not engage in any activities that may:
                          <br />
                          <br />
                          i. Violate the rights of others or infringe upon any
                          intellectual property or proprietary rights.
                          <br />
                          <br />
                          ii. Invade the privacy of others or collect and use
                          personal information without consent.
                          <br />
                          <br />
                          iii. Promote or engage in illegal, fraudulent, or
                          harmful activities.
                          <br />
                          <br />
                          iv. Interfere with the proper functioning of our
                          platform or disrupt the experience of other users.
                          <br />
                          <br />
                          v. Transmit or distribute any viruses, malware, or
                          other harmful software.
                          <br />
                          <br />
                          <b>Project Management:</b>
                          <br />
                          <br />
                          a. Our platform facilitates the management of
                          freelance projects between clients and freelancers.
                          <br />
                          <br />
                          b. Clients are responsible for providing accurate
                          project requirements, specifications, and
                          deliverables.
                          <br />
                          <br />
                          c. Freelancers are responsible for delivering
                          high-quality work within the agreed-upon timelines and
                          meeting the client&#39;s requirements.
                          <br />
                          <br />
                          d. Any disputes or conflicts arising from project
                          management shall be resolved directly between the
                          client and freelancer. FXCTS assumes no liability for
                          the outcome of such disputes.
                          <br />
                          <br />
                          <b>Payments and Fees:</b>
                          <br />
                          <br />
                          a. FXCTS facilitates the payment process between
                          clients and freelancers but is not responsible for the
                          actual payment transactions.
                          <br />
                          <br />
                          b. Clients and freelancers agree to adhere to the
                          agreed-upon payment terms and any applicable fees or
                          charges.
                          <br />
                          <br />
                          c. FXCTS may charge service fees or commissions for
                          using the platform, which will be disclosed and agreed
                          upon during the registration process.
                          <br />
                          <br />
                          Intellectual Property:
                          <br />
                          <br />
                          a. Users retain ownership of their respective
                          intellectual property rights.
                          <br />
                          <br />
                          b. Clients may use the deliverables provided by
                          freelancers solely for the intended purpose of the
                          project.
                          <br />
                          <br />
                          c. Freelancers grant clients a limited, non-exclusive
                          license to use their work for the agreed-upon project.
                          <br />
                          <br />
                          d. Users shall respect and not infringe upon the
                          intellectual property rights of others.
                          <br />
                          <br />
                          <b>Disclaimer of Warranty:</b>
                          <br />
                          <br />
                          a. Our platform is provided on an &quot;as is&quot;
                          and &quot;as available&quot; basis, without warranties
                          of any kind, whether express or implied.
                          <br />
                          <br />
                          b. FXCTS does not guarantee the accuracy,
                          completeness, or reliability of any information or
                          content on the platform.
                          <br />
                          <br />
                          c. We do not warrant that the platform will be
                          error-free, uninterrupted, or free from viruses or
                          other harmful components.
                          <br />
                          <br />
                          <b>Limitation of Liability:</b>
                          <br />
                          <br />
                          a. FXCTS shall not be liable for any direct, indirect,
                          incidental, consequential, or punitive damages arising
                          out of or in connection with your use of our platform
                          or the services provided.
                          <br />
                          <br />
                          b. In no event shall our total liability exceed the
                          amount paid by you, if any, for accessing or using our
                          platform.
                          <br />
                          <br />
                          <b>Termination:</b>
                          <br />
                          <br />
                          a. FXCTS reserves the right to suspend or terminate
                          your account, at our discretion, for violations of
                          these Terms or for any other reason.
                          <br />
                          <br />
                          b. Upon termination, your access to our platform and
                          services will cease, and you must cease using our
                          platform.
                          <br />
                          <br />
                          <b>Modification of Terms:</b>
                          <br />
                          <br />
                          a. FXCTS reserves the right to modify or update these
                          Terms at any time. We will notify you of any changes
                          by posting the revised Terms on our platform or via
                          email.
                          <br />
                          <br />
                          b. Your continued use of our platform after the
                          revised
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default Policies;
