import React from "react";
import GoToTop from "../GoToTop";

function Contact() {
  return (
    <>
      <GoToTop />
      <div class="body_content" style={{ marginTop: "10vh" }}>
        {/* <!-- Breadcumb Sections --> */}
        <section class="breadcumb-section wow fadeInUp mt40">
          <div class="cta-commmon-v1 cta-banner bgc-thm2 mx-auto maxw1600 pt120 pb120 bdrs16 position-relative overflow-hidden d-flex align-items-center mx20-lg">
            <img
              class="left-top-img wow zoomIn"
              src="images/vector-img/left-top.png"
              alt=""
            />
            <img
              class="right-bottom-img wow zoomIn"
              src="images/vector-img/right-bottom.png"
              alt=""
            />
            <div class="container">
              <div class="row">
                <div class="col-xl-5">
                  <div
                    class="position-relative wow fadeInUp"
                    data-wow-delay="300ms"
                  >
                    <h2 class="text-white">Contact us</h2>
                    <p class="text mb0 text-white">
                      We'd love to talk about how we can help you.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- Our Contact Info --> */}
        <section class="pt-0">
          <div class="container">
            <div class="row wow fadeInUp" data-wow-delay="300ms">
              <div class="col-lg-6">
                <div class="position-relative mt40">
                  <div class="main-title">
                    <h4 class="form-title mb25">Keep In Touch With Us.</h4>
                  </div>
                  <div class="iconbox-style1 contact-style d-flex align-items-start mb30">
                    <div class="icon flex-shrink-0">
                      <span class="flaticon-tracking"></span>
                    </div>
                    <div class="details">
                      <h5 class="title">Address</h5>
                      <p class="mb-0 text">
                        568/2A,Opp VIT, Vellakalmedu,
                        <br />
                        KN Road,Katpadi,Vellore,
                        <br />
                        Tamil Nadu-632006
                      </p>
                    </div>
                  </div>
                  <div class="iconbox-style1 contact-style d-flex align-items-start mb30">
                    <div class="icon flex-shrink-0">
                      <span class="flaticon-call"></span>
                    </div>
                    <div class="details">
                      <h5 class="title">Phone</h5>
                      <p class="mb-0 text">
                        <a href="tel:8838173949">+91 8838173949</a>
                        <a href="tel:8248533577">+91 8248533577</a>
                      </p>
                    </div>
                  </div>
                  <div class="iconbox-style1 contact-style d-flex align-items-start mb30">
                    <div class="icon flex-shrink-0">
                      <span class="flaticon-mail"></span>
                    </div>
                    <div class="details">
                      <h5 class="title">Email</h5>
                      <p class="mb-0 text">
                        <a href="mailto:hr@falconxx.com">hr@falconxx.com</a>;{" "}
                        <a href="mailto:projects@falconxx.com">
                          projects@falconxx.com
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="contact-page-form default-box-shadow1 bdrs8 bdr1 p50 mb30-md bgc-white">
                  <h4 class="form-title mb25">Tell us about yourself</h4>
                  <p class="text mb30">
                    Whether you have questions or you would just like to say
                    hello, contact us.
                  </p>
                  <form
                    action="forms/contact.php"
                    method="post"
                    role="form"
                    class="form-style1"
                  >
                    <div class="row">
                      <div class="col-md-6">
                        <div class="mb20">
                          <label
                            class="heading-color ff-heading fw500 mb10"
                            for=""
                          >
                            Name
                          </label>
                          <input
                            name="name"
                            id="name"
                            type="text"
                            class="form-control"
                            placeholder="Enter your name"
                          />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="mb20">
                          <label
                            class="heading-color ff-heading fw500 mb10"
                            for=""
                          >
                            Email
                          </label>
                          <input
                            name="email"
                            id="email"
                            type="email"
                            class="form-control"
                            placeholder="Enter your Email"
                          />
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="mb20">
                          <label
                            class="heading-color ff-heading fw500 mb10"
                            for=""
                          >
                            Subject
                          </label>
                          <input
                            name="subject"
                            required
                            type="text"
                            class="form-control"
                            placeholder="Enter a subject"
                          />
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="mb20">
                          <label
                            class="heading-color ff-heading fw500 mb10"
                            for=""
                          >
                            Messages
                          </label>
                          <textarea
                            name="message"
                            required
                            cols="30"
                            rows="6"
                            placeholder="Description"
                          ></textarea>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="">
                          <a class="ud-btn btn-thm" type="submit">
                            Send Messages<i class="fal fa-arrow-right-long"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!-- Google Map --> */}
        <section class="p-0 wow fadeInUp" data-wow-delay="300ms">
          <div class="mx-auto maxw1700 bdrs16 position-relative mx20-lg">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.0838149111933!2d79.15415187594583!3d12.96648841498851!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bad47d652268e03%3A0xa76869b8cd39f2cf!2sFALCONX%20CONSULTING%20%26%20TECHNOLOGY%20SERVICES%20(%20Best%20Website%20designing%20Company%20in%20Vellore)%20Ecommerce%20site%20%2C%20App%20Development!5e0!3m2!1sen!2sin!4v1688037504929!5m2!1sen!2sin"
              width="600"
              height="450"
              style={{ border: "0" }}
              allowfullscreen=""
              loading="lazy"
              title="FXCTS- Vellore"
              class="contact-page-map"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </section>

        {/* <!-- Faq --> */}
        <section class="our-faq pb90">
          <div class="container">
            <div class="row">
              <div class="col-lg-6 m-auto wow fadeInUp" data-wow-delay="300ms">
                <div class="main-title text-center">
                  <h2 class="title">Frequently Asked Questions</h2>
                </div>
              </div>
            </div>
            <div class="row wow fadeInUp" data-wow-delay="300ms">
              <div class="col-xl-8 mx-auto">
                <div class="ui-content">
                  <div class="accordion-style1 faq-page mb-4 mb-lg-5">
                    <div class="accordion" id="accordionExample">
                      <div class="accordion-item active">
                        <h2 class="accordion-header" id="headingOne">
                          <button
                            class="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseOne"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                          >
                            What methods of payments are supported?
                          </button>
                        </h2>
                        <div
                          id="collapseOne"
                          class="accordion-collapse collapse show"
                          aria-labelledby="headingOne"
                          data-parent="#accordionExample"
                        >
                          <div class="accordion-body">
                            We support various methods of payment to ensure
                            convenience and flexibility for our customers. Here
                            are some of the commonly accepted payment methods:
                            <br />
                            <br />
                            Credit/Debit Cards: We accept major credit and debit
                            cards, including Visa, Mastercard, American Express,
                            and Discover. This allows for secure and
                            straightforward online transactions.
                            <br />
                            <br />
                            Bank Transfers: You can make payments through direct
                            bank transfers or wire transfers. This option is
                            suitable for customers who prefer transferring funds
                            directly from their bank accounts.
                            <br />
                            <br />
                            Online Payment Platforms: We also accept payments
                            through popular online payment platforms such as
                            PayPal, Stripe, and Google Pay. These platforms
                            offer secure and convenient payment processing.
                            <br />
                            <br />
                            Mobile Wallets: If you prefer using mobile payment
                            apps, we support popular options like Apple Pay and
                            Samsung Pay. These mobile wallets enable quick and
                            contactless transactions.
                            <br />
                            <br />
                            Cryptocurrencies: In some cases, we may accept
                            payments in cryptocurrencies such as Bitcoin,
                            Ethereum, or other digital currencies. This offers
                            an additional level of privacy and security for
                            certain transactions.
                            <br />
                            <br />
                            It's important to note that the specific payment
                            methods available may vary depending on your
                            location, the nature of the transaction, and any
                            applicable regulations. We strive to offer a range
                            of payment options to accommodate different
                            preferences and ensure a smooth payment experience
                            for our customers.
                          </div>
                        </div>
                      </div>
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="headingTwo">
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseTwo"
                            aria-expanded="false"
                            aria-controls="collapseTwo"
                          >
                            Can I cancel at anytime?
                          </button>
                        </h2>
                        <div
                          id="collapseTwo"
                          class="accordion-collapse collapse"
                          aria-labelledby="headingTwo"
                          data-parent="#accordionExample"
                        >
                          <div class="accordion-body">
                            The ability to cancel depends on the specific terms
                            and conditions of the service or product you are
                            referring to. In many cases, cancellation policies
                            are determined by the provider or seller. It's
                            important to review the terms of service or purchase
                            agreement to understand the cancellation policy and
                            any associated fees or requirements.
                            <br />
                            <br />
                            Some services or subscriptions may allow for
                            cancellation at any time, while others may have
                            specific conditions or notice periods. For products,
                            cancellation policies can vary depending on factors
                            such as return policies, warranties, or refund
                            eligibility.
                            <br />
                            <br />
                            To ensure clarity and avoid any potential issues, it
                            is advisable to familiarize yourself with the
                            cancellation policy before making a purchase or
                            entering into an agreement. If you have specific
                            concerns or questions regarding cancellation, it is
                            best to reach out to the provider or seller directly
                            for further information and clarification.
                          </div>
                        </div>
                      </div>
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="headingThree">
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseThree"
                            aria-expanded="false"
                            aria-controls="collapseThree"
                          >
                            How do I get a receipt for my purchase?
                          </button>
                        </h2>
                        <div
                          id="collapseThree"
                          class="accordion-collapse collapse"
                          aria-labelledby="headingThree"
                          data-parent="#accordionExample"
                        >
                          <div class="accordion-body">
                            To obtain a receipt for your completed projects, you
                            can follow these general steps: <br />
                            <br />
                            Contact the provider or freelancer: Reach out to the
                            individual or company with whom you worked on the
                            project. They should be able to assist you in
                            obtaining a receipt.
                            <br />
                            <br />
                            Request a receipt: Clearly communicate that you
                            require a receipt for the completed project. Provide
                            any relevant details such as the project name,
                            completion date, and payment information to
                            facilitate the process.
                            <br />
                            <br />
                            Specify receipt format: If you have specific
                            requirements for the receipt format (e.g., digital
                            or physical copy, specific details to be included),
                            make sure to communicate your preferences to the
                            provider.
                            <br />
                            <br />
                            Confirm delivery method: Determine how you would
                            like to receive the receipt. It can be emailed, sent
                            via a messaging platform, or delivered through
                            postal mail, depending on your preference and the
                            provider's capabilities.
                            <br />
                            <br />
                            Review and verify the receipt: Once you receive the
                            receipt, carefully review its contents to ensure
                            accuracy. Check that all necessary information,
                            including payment details, project description, and
                            relevant dates, are included and correct.
                            <br />
                            <br />
                            Store and organize the receipt: Save a copy of the
                            receipt for your records. You may want to keep it in
                            a designated folder or file for future reference,
                            accounting purposes, or reimbursement needs.
                            <br />
                            <br />
                            Remember, the specific process and details for
                            obtaining a receipt may vary depending on the
                            provider or freelancer you worked with. It's always
                            best to communicate your request directly and
                            promptly to ensure a smooth receipt issuance
                            process.
                          </div>
                        </div>
                      </div>

                      <div class="accordion-item">
                        <h2 class="accordion-header" id="headingFour">
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseFour"
                            aria-expanded="false"
                            aria-controls="collapseFour"
                          >
                            How to upload a file in order project?
                          </button>
                        </h2>
                        <div
                          id="collapseFour"
                          class="accordion-collapse collapse"
                          aria-labelledby="headingFour"
                          data-parent="#accordionExample"
                        >
                          <div class="accordion-body">
                            To upload a file in an Order Project section, you
                            typically need access to the Project management
                            system (PMS) or website platform where the{" "}
                            <a href="https://admin.falconxx.in/#/add_projects_clients">
                              <u>
                                https://admin.falconxx.in/#/add_projects_clients
                              </u>
                            </a>{" "}
                            . The exact steps may vary depending on the specific
                            PMS or platform you are using, but here's a general
                            outline of the process: <br />
                            <br />
                            <ol>
                              <li>
                                <i
                                  class="fa fa-angle-double-right"
                                  aria-hidden="true"
                                ></i>{" "}
                                Access the PMS or website : Log in to the
                                https://admin.falconxx.in/#/add_projects_clients
                                or website administration panel using your
                                credentials.
                              </li>
                              <li>
                                <i
                                  class="fa fa-angle-double-right"
                                  aria-hidden="true"
                                ></i>{" "}
                                Navigate to the section: Find the section or
                                page where the file is located.
                              </li>

                              {/* <li><i class="fa fa-angle-double-right" aria-hidden="true"></i> <i>Note: Client has to check the filename before uploading as mentioned below:<br/> <span style={{color:"red"}}>{<b>Fxcts upload .png</b> file will not be uploaded , you need to upload <span style="color: green;">Fxcts upload.png</span> or any other extension}</span> </i></li> */}
                              <li>
                                <i
                                  class="fa fa-angle-double-right"
                                  aria-hidden="true"
                                ></i>{" "}
                                Remember to consider any restrictions or
                                limitations on file uploads, such as file size
                                restrictions or permitted file types, as
                                specified by your PMS or platform. Additionally,
                                it's good practice to provide clear instructions
                                for users on file requirements, if applicable,
                                and any steps they need to take to upload files
                                successfully.
                              </li>
                              <li>
                                <i
                                  class="fa fa-angle-double-right"
                                  aria-hidden="true"
                                ></i>{" "}
                                If you're working with a specific PMS or website
                                platform, consulting its documentation or
                                support resources can provide more detailed
                                instructions tailored to your situation.
                              </li>
                            </ol>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default Contact;
