import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { db } from "../firebase";
import { doc, collection, getDoc } from "firebase/firestore";
import { onSnapshot } from "firebase/firestore";
import GoToTop from "../GoToTop";

const initialState = {
  title: "",
  subtitle: "",
};

function ShopNow_details() {
  const [data, setData] = useState(initialState);
  const [quantity, setQuantity] = useState(1);
  const [totalPrice, setTotalPrice] = useState(0);
  const [ctotalPrice, setCTotalPrice] = useState(0);
  const [totalgst, setGST] = useState(0);
  const {
    title,
    subtitle,
    url,
    price,
    compare_at_price,
    tags,
    cgst,
    demo,
    sgst,
    category,
    description,
    forex_charge,
    brief,
  } = data;
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    id && getSinglePhoto();
  }, [id]);

  const getSinglePhoto = async () => {
    const docRef = doc(db, "OurProducts_M", id);
    const snapshot = await getDoc(docRef, "OurProducts_M");
    if (snapshot.exists()) {
      setData({ ...snapshot.data() });
    }
  };

  useEffect(() => {
    setTotalPrice(price * quantity);
    setCTotalPrice(compare_at_price * quantity);
    setGST(cgst + sgst);
  }, [price, compare_at_price, quantity]);

  const handleQuantityChange = (e) => {
    const value = Math.max(1, Number(e.target.value));
    setQuantity(value);
  };

  const formatPrice = (price) => {
    return new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
    }).format(price);
  };

  return (
    <>
      <GoToTop />
      <div class="body_content" style={{ marginTop: "10vh" }}>
        {/* <!-- Blog Section --> */}
        <section class="breadcumb-section">
          <div class="container">
            <div class="row">
              <div class="col-lg-12">
                <div class="breadcumb-style1">
                  <div class="breadcumb-list">
                    <a href="index.html">Home</a>
                    <a href="page-shop.html">Products</a>
                    <a href="">{title}</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!-- Shop Single Area --> */}
        <section class="shop-checkout pt40 pb90">
          <div class="container">
            <div class="row wow fadeInUp" data-wow-delay="300ms">
              <div class="col-lg-6">
                <div class="shop-single">
                  <div class="tab-content" id="pills-tabContent">
                    <div
                      class="tab-pane fade show active"
                      id="pills-item1"
                      role="tabpanel"
                      aria-labelledby="pills-item1-tab"
                    >
                      <div class="shop-single-item mb10 bdr1 bdrs8 text-center py-5">
                        <a class="popup-img">
                          <img class="img-fluid" src={url} alt="" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="shop-single-content">
                  <h2 class="mb15">{subtitle}</h2>

                  <p class="text">{brief}</p>
                  <h4 class="price mb30">
                    <span id="currentPrice">{formatPrice(totalPrice)}</span>
                    <small>
                      <del
                        id="comcurrentPrice"
                        class="body-light-color fz13 fw400"
                      >
                        {" "}
                        {formatPrice(ctotalPrice)}
                      </del>
                    </small>{" "}
                    <small style={{ color: "grey", fontSize: "12px" }}>
                      {forex_charge ? " + Forex Charge" : ""}
                      {totalgst > 0 ? ` + ${totalgst} GST` : ""}
                    </small>
                  </h4>
                  {/* <!-- <div id="totalPrice"></div> --> */}

                  <p
                    style={{
                      fontSize: "12px",
                      marginTop: "-10px",
                      marginBottom: "3px",
                    }}
                  >
                    <i>Quantity</i>
                  </p>

                  <div class="row">
                    <div class="col-lg-2">
                      <input
                        id="quantity"
                        className="form-control"
                        value={quantity}
                        min="1"
                        type="number"
                        onChange={handleQuantityChange}
                      />
                    </div>

                    <div class="col-lg-4">
                      <p
                        style={{ width: "100%", cursor: "pointer" }}
                        class="ud-btn btn-thm"
                        onClick={() =>
                          navigate(`/shop_now_pay/${id}`, {
                            state: { quantity },
                          })
                        }
                      >
                        Add to cart<i class="fal fa-arrow-right-long"></i>
                      </p>
                    </div>

                    <div class="col-lg-4">
                      {demo ? (
                        <a
                          style={{ width: "100%" }}
                          class="ud-btn btn-dark"
                          href={demo}
                          target="_blank"
                        >
                          Demo<i class="fal fa-arrow-right-long"></i>
                        </a>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div class="d-flex mt30 mb20-sm">
                    <ul class="ps-0">
                      <li class="text">Category:</li>
                      <li class="text">Tags:</li>
                    </ul>
                    <ul class="ps-0 ms-4">
                      <li class="text">{category}</li>
                      <li class="text">{tags}</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="row wow fadeInUp mt90" data-wow-delay="300ms">
              <div class="col-md-3 col-lg-4">
                <div class="vertical-tab">
                  <div class="widget_list">
                    <nav>
                      <div
                        class="nav flex-column nav-tabs text-start"
                        id="nav-tab"
                        role="tablist"
                      >
                        <button
                          class="nav-link active text-start"
                          id="nav-accountpayment-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#nav-accountpayment"
                          type="button"
                          role="tab"
                          aria-controls="nav-accountpayment"
                          aria-selected="true"
                        >
                          <span>Description</span>
                        </button>
                      </div>
                    </nav>
                  </div>
                </div>
              </div>
              <div class="col-md-9 col-lg-8">
                <div class="terms_condition_grid text-start">
                  <div class="tab-content" id="nav-tabContent">
                    <div
                      class="tab-pane fade show active"
                      id="nav-accountpayment"
                      role="tabpanel"
                      aria-labelledby="nav-accountpayment-tab"
                    >
                      <div class="grids">
                        <h4 class="title mb20">Details</h4>
                        <p
                          class="mb25 text fz15"
                          dangerouslySetInnerHTML={{ __html: description }}
                        ></p>
                      </div>
                    </div>
                    <div
                      class="tab-pane fade"
                      id="nav-manageother"
                      role="tabpanel"
                      aria-labelledby="nav-manageother-tab"
                    >
                      <div class="grids">
                        <div class="product_single_content mb50">
                          <div class="mbp_pagination_comments">
                            <div class="row">
                              <div class="col-lg-12">
                                <div class="total_review d-flex align-items-center justify-content-between mb20">
                                  <h4 class="mb15 mb0-sm">80 Reviews</h4>
                                </div>
                              </div>
                              <div class="col-md-12">
                                <div class="mbp_first position-relative d-flex align-items-center justify-content-start mb30-sm">
                                  <img
                                    src="images/blog/comments-2.png"
                                    class="mr-3"
                                    alt="comments-2.png"
                                  />
                                  <div class="ml20">
                                    <h6 class="mt-0 mb-0">Bessie Cooper</h6>
                                    <div>
                                      <span class="fz14">12 March 2022</span>
                                    </div>
                                  </div>
                                </div>
                                <p class="text mt20 mb20">
                                  There are many variations of passages of Lorem
                                  Ipsum available, but the majority have
                                  suffered alteration in some form, by injected
                                  humour, or randomised words which don't look
                                  even slightly believable. If you are going to
                                  use a passage of Lorem Ipsum, you need to be
                                  sure there isn't anything embarrassing hidden
                                  in the middle of text.
                                </p>
                                <div class="review_cansel_btns d-flex">
                                  <a href="#">
                                    <i class="fas fa-thumbs-up"></i>Helpful
                                  </a>
                                  <a href="#">
                                    <i class="fas fa-thumbs-down"></i>Not
                                    helpful
                                  </a>
                                </div>
                              </div>
                              <div class="col-md-12">
                                <div class="mbp_first position-relative d-flex align-items-center justify-content-start mt30 mb30-sm">
                                  <img
                                    src="images/blog/comments-2.png"
                                    class="mr-3"
                                    alt="comments-2.png"
                                  />
                                  <div class="ml20">
                                    <h6 class="mt-0 mb-0">Darrell Steward</h6>
                                    <div>
                                      <span class="fz14">12 March 2022</span>
                                    </div>
                                  </div>
                                </div>
                                <p class="text mt20 mb20">
                                  There are many variations of passages of Lorem
                                  Ipsum available, but the majority have
                                  suffered alteration in some form, by injected
                                  humour, or randomised words which don't look
                                  even slightly believable. If you are going to
                                  use a passage of Lorem Ipsum, you need to be
                                  sure there isn't anything embarrassing hidden
                                  in the middle of text.
                                </p>
                                <div class="review_cansel_btns d-flex pb30">
                                  <a href="#">
                                    <i class="fas fa-thumbs-up"></i>Helpful
                                  </a>
                                  <a href="#">
                                    <i class="fas fa-thumbs-down"></i>Not
                                    helpful
                                  </a>
                                </div>
                              </div>
                              <div class="col-md-12">
                                <div class="position-relative bdrb1 pb50">
                                  <a
                                    href="page-service-single.html"
                                    class="ud-btn btn-light-thm"
                                  >
                                    See More
                                    <i class="fal fa-arrow-right-long"></i>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="bsp_reveiw_wrt">
                          <h6 class="fz17">Add a Review</h6>
                          <p class="text">
                            Your email address will not be published. Required
                            fields are marked *
                          </p>
                          <form class="comments_form mt30">
                            <div class="row">
                              <div class="col-md-12">
                                <div class="mb-4">
                                  <label class="fw500 ff-heading dark-color mb-2">
                                    Comment
                                  </label>
                                  <textarea
                                    class="pt15"
                                    rows="6"
                                    placeholder="There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text."
                                  ></textarea>
                                </div>
                              </div>
                              <div class="col-md-6">
                                <div class="mb20">
                                  <label class="fw500 ff-heading dark-color mb-2">
                                    Name
                                  </label>
                                  <input
                                    type="text"
                                    class="form-control"
                                    placeholder="Ali Tufan"
                                  />
                                </div>
                              </div>
                              <div class="col-md-6">
                                <div class="mb20">
                                  <label class="fw500 ff-heading dark-color mb-2">
                                    Email
                                  </label>
                                  <input
                                    type="email"
                                    class="form-control"
                                    placeholder="creativelayers088"
                                  />
                                </div>
                              </div>
                              <div class="col-md-12">
                                <div class="checkbox-style1 d-block d-sm-flex align-items-center justify-content-between mb20">
                                  <label class="custom_checkbox fz15 ff-heading">
                                    Save my name, email, and website in this
                                    browser for the next time I comment.
                                    <input type="checkbox" />
                                    <span class="checkmark"></span>
                                  </label>
                                </div>
                                <a href="" class="ud-btn btn-thm">
                                  Send<i class="fal fa-arrow-right-long"></i>
                                </a>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default ShopNow_details;
