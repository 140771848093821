import React, { useState, useEffect } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { db } from "../firebase";
import { doc, getDoc } from "firebase/firestore";
import { serverTimestamp } from "firebase/firestore";
import Swal from "sweetalert2";
import GoToTop from "../GoToTop";
const initialState = {
  title: "",
  subtitle: "",
};

function ShopNow_pay() {
  const [data, setData] = useState(initialState);
  const { title, subtitle, price, forex_charge, cgst, sgst } = data;
  const [quantity, setQuantity] = useState(1);
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalcgst, setcgst] = useState(0);
  const [totalsgst, setSGST] = useState(0);

  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();

  useEffect(() => {
    id && getSinglePhoto();
    if (location.state && location.state.quantity) {
      setQuantity(location.state.quantity);
    }
  }, [id, location.state]);

  const getSinglePhoto = async () => {
    const docRef = doc(db, "OurProducts_M", id);
    const snapshot = await getDoc(docRef, "OurProducts_M");
    if (snapshot.exists()) {
      setData({ ...snapshot.data() });
    }
  };

  useEffect(() => {
    setTotalPrice(price * quantity);
  }, [price, quantity]);

  useEffect(() => {
    setcgst(totalPrice * (cgst / 100));
    setSGST(totalPrice * (sgst / 100));
  }, [totalPrice, sgst, cgst]);

  const formatPrice = (price) => {
    return new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
    }).format(price);
  };

  let totalAmountt = totalPrice + totalsgst + totalcgst + forex_charge;
  let totalAmount = totalAmountt.toFixed(2);

  // Razorpay Integration and form
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  const [company, setCompany] = useState("");
  const [state, setState] = useState("");
  const [pincode, setPin] = useState("");
  const [country, setCountry] = useState("");
  const [address, setAddress] = useState("");
  const [loader, setLoader] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);

    try {
      // Add data to trialPayments collection
      const PaymentRef = await db.collection("ShopNow_Payments").add({
        name: name,
        email: email,
        date: serverTimestamp(),
        contact: contact,
        company: company,
        state: state,
        pincode: pincode,
        country: country,
        address: address,
        purpose: title,
        amount: parseFloat(totalAmount),
        status: "Pending",
      });

      // Get the document ID of the added document
      const PaymentId = PaymentRef.id;

      // Set up the payment options
      const options = {
        // key: "rzp_test_xTqaVwutNxOCyh",
        // key_secret: "HHYiqWOuGQuD6dwpzcmsAMOG",

        key: "rzp_live_sqeaDFthWw4XhK",
        key_secret: "80DnEkFsb93qYjbTz3QXZhyf",
        amount: totalAmount * 100,
        currency: "INR",
        name: "FXCTS",
        description: title,
        prefill: {
          name: name,
          email: email,
          contact: contact,
        },
        notes: {
          address: "Vellore, Tamil Nadu",
        },
        theme: {
          color: "#16151a",
        },
        handler: async (response) => {
          try {
            // Add data to Funds collection using the same document ID
            await db.collection("ShopNow_Payments").doc(PaymentId).update({
              date: serverTimestamp(),
              id: PaymentId,
              payId: response.razorpay_payment_id,
              status: "Paid",
            });

            // Redirect to success page
            Swal.fire({
              title: "Hurray Payment Successful!",
              html: `We have received your payment of <b>${formatPrice(
                totalAmount
              )}</b>. Your payment ID is <b>${
                response.razorpay_payment_id
              }</b>`,
              icon: "success",
            }).then(() => {
              navigate("/shop_now");
            });
          } catch (error) {
            console.error("Error processing payment:", error);
          }
        },
        modal: {
          ondismiss: function () {
            Swal.fire({
              title: "Error!",
              html: `Payment Cancelled`,
              icon: "error",
            });
            navigate("/shop_now");
          },
        },
      };

      // Create a new Razorpay instance and open the payment modal
      const pay = new window.Razorpay(options);
      pay.open();
    } catch (error) {
      console.error("Error initiating payment:", error);
    } finally {
      setLoader(false);
      setName("");
      setEmail("");
      setContact("");
      setCompany("");
      setCountry("");
      setPin("");
      setAddress("");
      setCountry("");
    }
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   setLoader(true);

  //   db.collection("trialPayments").add({
  //     name: name,
  //     email: email,
  //     date: serverTimestamp(),
  //     contact: contact,
  //     company: company,
  //     state: state,
  //     pincode: pincode,
  //     country: country,
  //     pincode: pincode,
  //     address: address,
  //     purpose: title,
  //     amount: totalAmount,
  //     status: "Pending",
  //   });

  //   try {
  //     // Set up the payment options
  //     const options = {
  //       key: "rzp_live_sqeaDFthWw4XhK",
  //       key_secret: "80DnEkFsb93qYjbTz3QXZhyf",
  //       amount: totalAmount * 100,
  //       currency: "INR",
  //       name: "FXCTS",
  //       description: title,
  //       prefill: {
  //         name: name,
  //         email: email,
  //         contact: contact,
  //       },
  //       notes: {
  //         address: "Vellore, Tamil Nadu",
  //       },
  //       theme: {
  //         color: "#16151a",
  //       },
  //       handler: async (response) => {
  //         try {
  //           await db.collection("Funds").add({
  //             name: name,
  //             email: email,
  //             date: serverTimestamp(),
  //             contact: contact,
  //             company: company,
  //             state: state,
  //             pincode: pincode,
  //             country: country,
  //             pincode: pincode,
  //             address: address,
  //             purpose: title,
  //             amount: totalAmount,
  //             payId: response.razorpay_payment_id,
  //             status: "Paid",
  //           });
  //           window.location.replace("/submission_success");
  //         } catch (error) {
  //           console.error("Error processing payment:", error);
  //         }
  //       },
  //       modal: {
  //         ondismiss: function () {
  //           alert("Payment cancelled");
  //           window.location.replace("/fundraiser");
  //         },
  //       },
  //     };

  //     // Create a new Razorpay instance and open the payment modal
  //     const pay = new window.Razorpay(options);
  //     pay.open();
  //   } catch (error) {
  //     console.error("Error initiating payment:", error);
  //   } finally {
  //     setLoader(false);
  //     setName("");
  //     setEmail("");
  //     setContact("");
  //     setCompany("");
  //     setCountry("");
  //     setPin("");
  //     setAddress("");
  //     setCountry("");
  //   }
  // };

  return (
    <>
      <GoToTop />
      <div class="body_content" style={{ marginTop: "10vh" }}>
        {/* <!-- Blog Section --> */}

        <section class="pt40 pb0">
          <div class="container">
            <div class="row">
              <div class="col-lg-12">
                <div class="main-title">
                  <h2 class="title">Checkout</h2>
                  <p class="text mb-0">{subtitle}</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!-- Shop Checkout Area --> */}
        <form
          class="form2"
          id="coupon_form"
          name="contact_form"
          onSubmit={handleSubmit}
        >
          <section class="shop-checkout pt-0">
            <div class="container">
              <div class="row wow fadeInUp" data-wow-delay="300ms">
                <div class="col-md-7 col-lg-8">
                  <div class="checkout_form">
                    <h4 class="title mb30">Billing details</h4>
                    <div class="checkout_coupon">
                      <div class="row">
                        <div class="col-sm-6">
                          <div class="mb25">
                            <h6 class="mb15">Full Name *</h6>
                            <input
                              class="form-control"
                              type="text"
                              required
                              id="name"
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                              name="name"
                              placeholder="Enter your full name"
                            />
                          </div>
                        </div>

                        <div class="col-sm-6">
                          <div class="mb25">
                            <h6 class="mb15">Email *</h6>
                            <input
                              class="form-control"
                              type="email"
                              required
                              id="email"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                              name="email"
                              placeholder="Enter your email address"
                            />
                          </div>
                        </div>

                        <div class="col-sm-6">
                          <div class="mb25">
                            <h6 class="mb15">Contact No. *</h6>
                            <input
                              class="form-control"
                              type="tel"
                              id="contact"
                              value={contact}
                              onChange={(e) => setContact(e.target.value)}
                              name="contact"
                              required
                              placeholder="Enter your contact no."
                            />
                          </div>
                        </div>

                        <div class="col-sm-6">
                          <div class="mb25">
                            <h6 class="mb15">Company Name (Optional)</h6>
                            <input
                              class="form-control"
                              type="text"
                              id="company"
                              value={company}
                              onChange={(e) => setCompany(e.target.value)}
                              name="company"
                              placeholder="Enter your company name"
                            />
                          </div>
                        </div>

                        <div class="col-sm-6">
                          <div class="mb25">
                            <h6 class="mb15">State *</h6>
                            <input
                              class="form-control"
                              type="text"
                              id="state"
                              name="state"
                              required
                              value={state}
                              onChange={(e) => setState(e.target.value)}
                              placeholder="Enter your state"
                            />
                          </div>
                        </div>

                        <div class="col-sm-12">
                          <div class="mb25">
                            <h6 class="mb15">Apartment, suite, unit, etc.</h6>
                            <input
                              id="apartment"
                              name="address"
                              value={address}
                              onChange={(e) => setAddress(e.target.value)}
                              class="form-control"
                              type="text"
                            />
                          </div>
                        </div>

                        <div class="col-sm-6">
                          <div class="mb25">
                            <h6 class="mb15">Country *</h6>
                            <input
                              class="form-control"
                              type="text"
                              required
                              id="country"
                              name="country"
                              value={country}
                              onChange={(e) => setCountry(e.target.value)}
                              placeholder="Enter your country"
                            />
                          </div>
                        </div>

                        <div class="col-sm-6">
                          <div class="mb25">
                            <h6 class="mb15">ZIP *</h6>
                            <input
                              class="form-control"
                              type="number"
                              required
                              id="zip"
                              value={pincode}
                              onChange={(e) => setPin(e.target.value)}
                              name="zip"
                              placeholder="Enter ZIP Code"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-5 col-lg-4">
                  <div class="shop-sidebar ms-md-auto">
                    <div class="order_sidebar_widget mb30 default-box-shadow1">
                      <h4 class="title">Your Order</h4>
                      <ul class="p-0 mb-0">
                        <li class="bdrb1 mb20">
                          <h6>
                            Product <span class="float-end">Subtotal</span>
                          </h6>
                        </li>
                        <li class="mb20">
                          <p class="body-color">
                            {title}
                            <span class="float-end">
                              {formatPrice(totalPrice)}
                            </span>
                          </p>
                        </li>
                        <li class="mb20">
                          <p class="body-color">
                            CGST
                            <span class="float-end">
                              {formatPrice(totalcgst)}
                            </span>
                          </p>
                        </li>
                        <li class="mb20">
                          <p class="body-color">
                            SGST/UTGST
                            <span class="float-end">
                              {formatPrice(totalsgst)}
                            </span>
                          </p>
                        </li>

                        <li class="bdrb1 mb20">
                          <p class="body-color">
                            Forex charge
                            <span class="float-end">
                              {formatPrice(forex_charge)}
                            </span>
                          </p>
                        </li>

                        {/* <!-- <li class="bdrb1 mb15">
                        <h6>
                          Subtotal <span class="float-end">₹29,601.00</span>
                        </h6>
                      </li> --> */}

                        <li class="mb20">
                          <h6>
                            Total{" "}
                            <span class="float-end">
                              {formatPrice(totalAmount)}
                            </span>
                          </h6>
                        </li>
                      </ul>
                    </div>

                    <div class="d-grid default-box-shadow2">
                      <button type="submit" class="ud-btn btn-thm">
                        Place Order<i class="fal fa-arrow-right-long"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </form>
      </div>
    </>
  );
}

export default ShopNow_pay;
