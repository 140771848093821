import React, { useState, useEffect } from "react";
import GoToTop from "../../../GoToTop";
import { Link, useNavigate } from "react-router-dom";
import { db, auth } from "../../../firebase";
function StepA() {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      const userId = auth.currentUser ? auth.currentUser.uid : null;

      if (userId) {
        try {
          const userDocRef = db.collection("Freelancers").doc(userId);
          const userDoc = await userDocRef.get();

          if (userDoc.exists) {
            const userData = userDoc.data();
            setUserData(userData);
          } else {
            navigate("/signin");
          }
        } catch (error) {
          console.log("Error getting user document:", error);
        }
      } else {
        navigate("/signin");
      }

      setLoading(false);
    };

    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        fetchUserData();
      } else {
        setLoading(false);
        navigate("/signin");
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  if (loading) {
    return <div></div>;
  }

  return (
    <>
      <GoToTop />
      <div class="body_content" style={{ marginTop: "20vh" }}>
        {/* <!-- Breadcumb Sections --> */}
        <section class="breadcumb-section mt40">
          <div class="container" style={{ textAlign: "center" }}>
            <h2>
              Hey, {userData ? userData.fname : "user"}. Ready for your next big
              opportunity?
            </h2>
            <Link
              className="ud-btn btn-thm my-5"
              to="/profile_completion_personal_details"
            >
              Get Started<i class="fal fa-arrow-right-long"></i>
            </Link>
          </div>
        </section>
      </div>
    </>
  );
}

export default StepA;
