import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { db } from "../../../../firebase";
import { onSnapshot } from "firebase/firestore";
import GoToTop from "../../../../GoToTop";
import Sidebar from "../../Sidebar";

function Services() {
  const [products, setProducts] = useState([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const unsub = onSnapshot(
      db.collection("OurProducts_M"),
      (snapshot) => {
        let list = [];
        snapshot.docs.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() });
        });
        setProducts(list);
        setLoading(false);
      },

      (error) => {
        console.error(error);
      }
    );
    return () => {
      unsub();
    };
  }, []);

  const formatPrice = (price) => {
    return new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
    }).format(price);
  };
  return (
    <>
      <GoToTop />
      <div class="dashboard_content_wrapper">
        <div class="dashboard dashboard_wrapper pr30 pr0-xl">
          <Sidebar />
          <div class="dashboard__main pl0-md">
            <div class="dashboard__content hover-bgc-color">
              <div class="row pb40">
                <div class="col-lg-9">
                  <div class="dashboard_title_area">
                    <h2>Manage Services</h2>
                    <p class="text">Manage all your services here...</p>
                  </div>
                </div>
                <div class="col-lg-3">
                  <div class="text-lg-end">
                    <Link
                      to="/add_service"
                      class="ud-btn btn-dark default-box-shadow2"
                    >
                      Add Service<i class="fal fa-arrow-right-long"></i>
                    </Link>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-12">
                  <div class="ps-widget bgc-white bdrs4 p30 mb30 overflow-hidden position-relative">
                    <div class="navtab-style1">
                      <div class="tab-content" id="nav-tabContent">
                        <div
                          class="tab-pane fade show active"
                          id="nav-item1"
                          role="tabpanel"
                          aria-labelledby="nav-item1-tab"
                        >
                          <div class="packages_table table-responsive">
                            <table class="table-style3 table at-savesearch">
                              <thead class="t-head">
                                <tr>
                                  <th scope="col">Title</th>
                                  <th scope="col">Category</th>
                                  <th scope="col">Type/Cost</th>
                                  <th scope="col">Actions</th>
                                </tr>
                              </thead>
                              <tbody class="t-body">
                                {products &&
                                  products.map((item, index) => (
                                    <tr>
                                      <th
                                        class="dashboard-img-service"
                                        scope="row"
                                      >
                                        <div class="listing-style1 list-style d-block d-xl-flex align-items-start border-0 mb-0">
                                          <div class="list-thumb flex-shrink-0 bdrs4 mb10-lg">
                                            <img
                                              class="w-100"
                                              src={
                                                item.url
                                                  ? item.url
                                                  : "images/listings/g-1.jpg"
                                              }
                                              alt=""
                                            />
                                          </div>
                                          <div class="list-content flex-grow-1 py-0 pl15 pl0-lg">
                                            <h6 class="list-title mb-0">
                                              <a>{item.title}</a>
                                            </h6>
                                            <ul class="list-style-type-bullet ps-3 dashboard-style mb-0">
                                              <li>{item.subtitle}</li>
                                            </ul>
                                          </div>
                                        </div>
                                      </th>
                                      <td class="align-top">
                                        <span class="fz15 fw400">
                                          {item.category}
                                        </span>
                                      </td>
                                      <td class="align-top">
                                        <span class="fz14 fw400">
                                          {formatPrice(item.price)}
                                        </span>
                                        <br />
                                        <s style={{ color: "grey" }}>
                                          {formatPrice(item.compare_at_price)}
                                        </s>
                                      </td>
                                      <td class="align-top">
                                        <div class="d-flex">
                                          <a
                                            class="icon me-2"
                                            data-bs-toggle="tooltip"
                                            data-bs-placement="top"
                                            title="Edit"
                                            onClick={() =>
                                              navigate(
                                                `/edit-service/${item.id}`
                                              )
                                            }
                                          >
                                            <span class="flaticon-pencil"></span>
                                          </a>
                                          {/* <a
                                            class="icon"
                                            data-bs-toggle="tooltip"
                                            data-bs-placement="top"
                                            title="Delete"
                                          >
                                            <span class="flaticon-delete"></span>
                                          </a> */}
                                        </div>
                                      </td>
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                            {/* <div class="mbp_pagination text-center mt30">
                              <ul class="page_navigation">
                                <li class="page-item">
                                  <a class="page-link" href="#">
                                    {" "}
                                    <span class="fas fa-angle-left"></span>
                                  </a>
                                </li>
                                <li class="page-item">
                                  <a class="page-link" href="#">
                                    1
                                  </a>
                                </li>
                                <li
                                  class="page-item active"
                                  aria-current="page"
                                >
                                  <a class="page-link" href="#">
                                    2 <span class="sr-only">(current)</span>
                                  </a>
                                </li>
                                <li class="page-item">
                                  <a class="page-link" href="#">
                                    3
                                  </a>
                                </li>
                                <li class="page-item">
                                  <a class="page-link" href="#">
                                    4
                                  </a>
                                </li>
                                <li class="page-item">
                                  <a class="page-link" href="#">
                                    5
                                  </a>
                                </li>
                                <li class="page-item">
                                  <a class="page-link" href="#">
                                    ...
                                  </a>
                                </li>
                                <li class="page-item">
                                  <a class="page-link" href="#">
                                    20
                                  </a>
                                </li>
                                <li class="page-item">
                                  <a class="page-link" href="#">
                                    <span class="fas fa-angle-right"></span>
                                  </a>
                                </li>
                              </ul>
                              <p class="mt10 mb-0 pagination_page_count text-center">
                                1 – 20 of 300+ property available
                              </p>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Services;
