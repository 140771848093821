import React from "react";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { db } from "../firebase";
import { serverTimestamp } from "firebase/firestore";
import GoToTop from "../GoToTop";
function Pricing() {
  const formatPrice = (price) => {
    return new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
    }).format(price);
  };

  const navigate = useNavigate();

  const handleBuyNow = (price, planName) => {
    Swal.fire({
      title: "Confirm Purchase",
      html: `
        <p>You are about to purchase the <b>${planName}</b> plan for <b>${formatPrice(
        price
      )}</b></p>
        <input id="nameInput" class="form-control my-1" type="text" placeholder="Name"/>
        <input id="emailInput" class="form-control my-1" type="email" placeholder="Email"/>
        <input id="contactInput" class="form-control my-1" type="tel" placeholder="Contact"/>
      `,
      showCancelButton: true,
      confirmButtonText: "Yes, Buy Now",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        // Get input field values
        const name = document.getElementById("nameInput").value;
        const email = document.getElementById("emailInput").value;
        const contact = document.getElementById("contactInput").value;

        try {
          // Save purchase details and form inputs to Firestore
          const purchaseRef = await db.collection("Plan_Purchases").add({
            planName: planName,
            price: parseFloat(price),
            name: name,
            email: email,
            contact: contact,
            date: serverTimestamp(),
            status: "Pending",
          });

          const PaymentId = purchaseRef.id;

          const options = {
            // key: "rzp_test_xTqaVwutNxOCyh",
            // key_secret: "HHYiqWOuGQuD6dwpzcmsAMOG",

            key: "rzp_live_sqeaDFthWw4XhK",
            key_secret: "80DnEkFsb93qYjbTz3QXZhyf",
            amount: price * 100,
            currency: "INR",
            name: "FXCTS",
            description: planName,
            prefill: {
              name: name,
              email: email,
              contact: contact,
            },
            notes: {
              address: "Vellore, Tamil Nadu",
            },
            theme: {
              color: "#16151a",
            },
            handler: async (response) => {
              try {
                // Add data to Funds collection using the same document ID
                await db.collection("Plan_Purchases").doc(PaymentId).update({
                  payId: response.razorpay_payment_id,
                  date: serverTimestamp(),
                  id: PaymentId,
                  status: "Paid",
                });

                // Redirect to success page
                Swal.fire({
                  title: "Hurray Payment Successful!",
                  html: `We have received your payment of <b>${formatPrice(
                    price
                  )}</b>. Your payment ID is <b>${
                    response.razorpay_payment_id
                  }</b>`,
                  icon: "success",
                }).then(() => {
                  navigate("/pricings");
                });
              } catch (error) {
                console.error("Error processing payment:", error);
              }
            },
            modal: {
              ondismiss: function () {
                Swal.fire({
                  title: "Error!",
                  html: `Payment Cancelled`,
                  icon: "error",
                });
                navigate("/pricings");
              },
            },
          };

          const pay = new window.Razorpay(options);
          pay.open();
          // Redirect to purchase confirmation page
        } catch (error) {
          console.error("Error adding purchase to Firestore:", error);
          Swal.fire({
            title: "Error",
            text: "Failed to process your purchase. Please try again later.",
            icon: "error",
          });
        }
      }
    });
  };

  return (
    <>
      <GoToTop />
      <div class="body_content">
        {/* <!-- Pricing Table Area --> */}
        <section class="our-pricing pb90">
          <div class="container">
            <div class="row my-5">
              <div class="col-lg-6 m-auto wow fadeInUp">
                <div class="main-title text-center mb30">
                  <h2 class="title">Membership Plans</h2>
                  <p class="paragraph mt10">
                    Give your visitor a smooth online experience with a solid UX
                    design
                  </p>
                </div>
              </div>
            </div>
            {/* <!-- <div class="row wow fadeInUp" data-wow-delay="200ms">
              <div class="col-lg-12">
                <div
                  class="pricing_packages_top d-flex align-items-center justify-content-center mb60"
                >
                  <div class="toggle-btn">
                    <span class="pricing_save1 dark-color ff-heading"
                      >Billed Monthly</span
                    >
                    <label class="switch">
                      <input type="checkbox" id="checbox" onclick="check()" />
                      <span class="pricing_table_switch_slide round"></span>
                    </label>
                    <span class="pricing_save2 dark-color ff-heading"
                      >Billed Yearly</span
                    >
                  </div>
                </div>
              </div>
            </div> --> */}
            <div class="row wow fadeInUp" data-wow-delay="300ms">
              <div class="col-sm-6 col-xl-3">
                <div class="pricing_packages text-center bdrs16">
                  <div class="heading mb10">
                    <h1 class="text2">
                      ₹100 <small>/ monthly</small>
                    </h1>
                    {/* <!-- <h1 class="text1">$39 <small>/ monthly</small></h1> --> */}
                    <h4 class="package_title mt-2">Basic Plan</h4>
                  </div>
                  <div class="details">
                    <p class="text mb30">
                      One time fee for one listing or task highlighted in search
                      results.
                    </p>
                    <div class="pricing-list mb40">
                      <ul class="px-0">
                        <li>1 Freelance Listing</li>
                        <li>30 Days Visibility</li>
                        <li>Highlighted Skill lists – 1</li>
                        <li>Priority Projects under your skill</li>
                        <li>Get Reviews</li>
                        <li>4 Revisions</li>
                        <li>9 days Delivery Time</li>
                        <li>Products Support</li>
                      </ul>
                    </div>
                    <div class="d-grid">
                      <a
                        style={{ cursor: "pointer" }}
                        onClick={() => handleBuyNow("100", "Basic Plan")}
                        class="ud-btn btn-thm-border bdrs8"
                      >
                        Buy Now<i class="fal fa-arrow-right-long"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-6 col-xl-3">
                <div class="pricing_packages active text-center bdrs16">
                  <div class="heading mb10">
                    <h1 class="text2">
                      ₹500 <small>/ monthly</small>
                    </h1>
                    {/* <!-- <h1 class="text1">$59 <small>/ monthly</small></h1> --> */}
                    <h4 class="package_title mt-2">Standard Plan</h4>
                  </div>
                  <div class="details">
                    <p class="text mb30">
                      One time fee for one listing or task highlighted in search
                      results.
                    </p>
                    <div class="pricing-list mb40">
                      <ul class="px-0">
                        <li>1 Freelance Listing</li>
                        <li>30 Days Visibility</li>
                        <li>Highlighted Skill lists – 2</li>
                        <li>Priority Projects under your skill</li>
                        <li>Get Reviews</li>
                        <li>4 Revisions</li>
                        <li>9 days Delivery Time</li>
                        <li>Products Support</li>
                      </ul>
                    </div>
                    <div class="d-grid">
                      <a
                        style={{ cursor: "pointer" }}
                        onClick={() => handleBuyNow("500", "Standard Plan")}
                        class="ud-btn btn-thm-border bdrs8"
                      >
                        Buy Now<i class="fal fa-arrow-right-long"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-6 col-xl-3">
                <div class="pricing_packages text-center bdrs16">
                  <div class="heading mb10">
                    <h1 class="text2">
                      ₹750 <small>/ monthly</small>
                    </h1>
                    {/* <!-- <h1 class="text1">$99 <small>/ monthly</small></h1> --> */}
                    <h4 class="package_title mt-2">Extended Plan</h4>
                  </div>
                  <div class="details">
                    <p class="text mb30">
                      One time fee for one listing or task highlighted in search
                      results.
                    </p>
                    <div class="pricing-list mb40">
                      <ul class="px-0">
                        <li>1 Freelance Listing</li>
                        <li>30 Days Visibility</li>
                        <li>Highlighted Skill lists – 3</li>
                        <li>Priority Projects under your skill</li>
                        <li>Get Reviews</li>
                        <li>4 Revisions</li>
                        <li>9 days Delivery Time</li>
                        <li>Products Support</li>
                      </ul>
                    </div>
                    <div class="d-grid">
                      <a
                        style={{ cursor: "pointer" }}
                        onClick={() => handleBuyNow("750", "Extended Plan")}
                        class="ud-btn btn-thm-border bdrs8"
                      >
                        Buy Now<i class="fal fa-arrow-right-long"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-6 col-xl-3">
                <div class="pricing_packages text-center bdrs16">
                  <div class="heading mb10">
                    <h1 class="text2">
                      ₹1250 <small>/ monthly</small>
                    </h1>
                    {/* <!-- <h1 class="text1">$139 <small>/ monthly</small></h1> --> */}
                    <h4 class="package_title mt-2">Enterprise Plan</h4>
                  </div>
                  <div class="details">
                    <p class="text mb30">
                      One time fee for one listing or task highlighted in search
                      results.
                    </p>
                    <div class="pricing-list mb40">
                      <ul class="px-0">
                        <li>1 Freelance Listing</li>
                        <li>30 Days Visibility</li>
                        <li>Highlighted –Custom – up to 7</li>
                        <li>Priority Projects under your skill</li>
                        <li>Get Reviews</li>
                        <li>4 Revisions</li>
                        <li>9 days Delivery Time</li>
                        <li>Products Support</li>
                      </ul>
                    </div>
                    <div class="d-grid">
                      <a
                        style={{ cursor: "pointer" }}
                        onClick={() => handleBuyNow("1250", "Enterprise Plan")}
                        class="ud-btn btn-thm-border bdrs8"
                      >
                        Buy Now<i class="fal fa-arrow-right-long"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default Pricing;
