import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { db } from "../../firebase";
import { onSnapshot } from "firebase/firestore";
import GoToTop from "../../GoToTop";

function Freelancers() {
  const [freelancers, setFreelancer] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const unsub = onSnapshot(
      // db.collection("Freelancers"),
      db.collection("Freelancers").where("profilestatus", "==", "completed"),
      (snapshot) => {
        let list = [];
        snapshot.docs.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() });
        });
        setFreelancer(list);
        setLoading(false);
      },

      (error) => {
        console.error(error);
      }
    );
    return () => {
      unsub();
    };
  }, []);

  const formatPrice = (price) => {
    return new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
    }).format(price);
  };
  return (
    <>
      <GoToTop />
      <div class="body_content">
        {/* <section class="categories_list_section overflow-hidden">
          <div class="container">
            <div class="row">
              <div class="col-lg-12">
                <div class="listings_category_nav_list_menu">
                  <ul class="mb0 d-flex ps-0">
                    <li>
                      <a href="#">All Categories</a>
                    </li>
                    <li>
                      <a href="#">Graphics & Design</a>
                    </li>
                    <li>
                      <a class="active" href="#">
                        Digital Marketing
                      </a>
                    </li>
                    <li>
                      <a href="#">Writing & Translation</a>
                    </li>
                    <li>
                      <a href="#">Video & Animation</a>
                    </li>
                    <li>
                      <a href="#">Music & Audio</a>
                    </li>
                    <li>
                      <a href="#">Programming & Tech</a>
                    </li>
                    <li>
                      <a href="#">Business</a>
                    </li>
                    <li>
                      <a href="#">Lifestyle</a>
                    </li>
                    <li>
                      <a href="#">Trending</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        {/* <!-- Breadcumb Sections --> */}
        <section class="breadcumb-section">
          <div class="container">
            <div class="row">
              <div class="col-lg-12">
                <div class="breadcumb-style1">
                  <div class="breadcumb-list">
                    <Link to="/">Home</Link>
                    <a href="">Freelancers</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- Breadcumb Sections --> */}
        <section class="breadcumb-section">
          <div class="cta-about-v1 mx-auto maxw1600 pt200 pb120 bdrs16 position-relative overflow-hidden d-flex align-items-center mx20-lg">
            <div class="container">
              <div class="row">
                <div class="col-xl-8">
                  <div class="position-relative">
                    <h2 class="text-white">Freelancers List</h2>
                    <p class="text-white mb30">
                      Our Attractive appealing web designs bring you more
                      business, more customers, more revenue.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!-- Listings All Lists --> */}
        <section class="pt30 pb90">
          <div class="container">
            <div class="row">
              <div class="col-lg-3">
                <div class="list-sidebar-style1 d-none d-lg-block">
                  <div class="accordion" id="accordionExample">
                    <div class="card mb20 pb10 mt-0">
                      <div class="card-header active" id="heading0">
                        <h4>
                          <button
                            class="btn btn-link ps-0 pt-0"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapse0"
                            aria-expanded="true"
                            aria-controls="collapse0"
                          >
                            Skills
                          </button>
                        </h4>
                      </div>
                      <div
                        id="collapse0"
                        class="collapse show"
                        aria-labelledby="heading0"
                        data-parent="#accordionExample"
                      >
                        <div class="card-body card-body px-0 pt-0">
                          <div class="checkbox-style1 mb15">
                            <label class="custom_checkbox">
                              UX Designer
                              <input type="checkbox" />
                              <span class="checkmark"></span>
                              <span class="right-tags">(3)</span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="card mb20 pb5">
                      <div class="card-header active" id="heading2">
                        <h4>
                          <button
                            class="btn btn-link ps-0"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapse2"
                            aria-expanded="true"
                            aria-controls="collapse2"
                          >
                            Location
                          </button>
                        </h4>
                      </div>
                      <div
                        id="collapse2"
                        class="collapse show"
                        aria-labelledby="heading2"
                        data-parent="#accordionExample"
                      >
                        <div class="card-body card-body px-0 pt-0">
                          <div class="search_area mb15">
                            <input
                              type="text"
                              class="form-control"
                              placeholder="What are you looking for?"
                            />
                            <label>
                              <span class="flaticon-loupe"></span>
                            </label>
                          </div>
                          <div class="checkbox-style1 mb15">
                            <label class="custom_checkbox">
                              India
                              <input type="checkbox" />
                              <span class="checkmark"></span>
                              <span class="right-tags">(1)</span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div class="card mb20 pb5">
                      <div class="card-header active" id="heading3">
                        <h4>
                          <button
                            class="btn btn-link ps-0"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapse3"
                            aria-expanded="true"
                            aria-controls="collapse3"
                          >
                            Languange
                          </button>
                        </h4>
                      </div>
                      <div
                        id="collapse3"
                        class="collapse show"
                        aria-labelledby="heading3"
                        data-parent="#accordionExample"
                      >
                        <div class="card-body card-body px-0 pt-0">
                          <div class="checkbox-style1 mb15">
                            <label class="custom_checkbox">
                              Turkish
                              <input type="checkbox" />
                              <span class="checkmark"></span>
                              <span class="right-tags">(1,945)</span>
                            </label>
                            <label class="custom_checkbox">
                              English
                              <input type="checkbox" checked="checked" />
                              <span class="checkmark"></span>
                              <span class="right-tags">(8,136)</span>
                            </label>
                            <label class="custom_checkbox">
                              Italian
                              <input type="checkbox" />
                              <span class="checkmark"></span>
                              <span class="right-tags">(917)</span>
                            </label>
                            <label class="custom_checkbox">
                              Spanish
                              <input type="checkbox" />
                              <span class="checkmark"></span>
                              <span class="right-tags">(240)</span>
                            </label>
                          </div>
                          <a class="text-thm" href="">
                            +20 more
                          </a>
                        </div>
                      </div>
                    </div> */}
                    {/* <div class="card mb20 pb5">
                      <div class="card-header active" id="heading4">
                        <h4>
                          <button
                            class="btn btn-link ps-0"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapse4"
                            aria-expanded="true"
                            aria-controls="collapse4"
                          >
                            Level
                          </button>
                        </h4>
                      </div>
                      <div
                        id="collapse4"
                        class="collapse show"
                        aria-labelledby="heading4"
                        data-parent="#accordionExample"
                      >
                        <div class="card-body card-body px-0 pt-0">
                          <div class="checkbox-style1">
                            <label class="custom_checkbox">
                              Top Rated Seller
                              <input type="checkbox" />
                              <span class="checkmark"></span>
                              <span class="right-tags">(1,945)</span>
                            </label>
                            <label class="custom_checkbox">
                              Level Two
                              <input type="checkbox" checked="checked" />
                              <span class="checkmark"></span>
                              <span class="right-tags">(8,136)</span>
                            </label>
                            <label class="custom_checkbox">
                              Level One
                              <input type="checkbox" />
                              <span class="checkmark"></span>
                              <span class="right-tags">(917)</span>
                            </label>
                            <label class="custom_checkbox">
                              New Seller
                              <input type="checkbox" />
                              <span class="checkmark"></span>
                              <span class="right-tags">(240)</span>
                            </label>
                          </div>
                          <a class="text-thm" href="">
                            +20 more
                          </a>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
              <div class="col-lg-9">
                <div class="row align-items-center mb20">
                  <div class="col-md-6">
                    <div class="text-center text-md-start">
                      <p class="text mb-0 mb10-sm">
                        <span class="fw500">{freelancers.length}</span>{" "}
                        freelancers available
                      </p>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="page_control_shorting d-md-flex align-items-center justify-content-center justify-content-md-end">
                      <div class="dropdown-lists d-block d-lg-none me-2 mb10-sm">
                        <ul class="p-0 mb-0 text-center text-md-start">
                          <li>
                            {/* <!-- Advance Features modal trigger --> */}
                            <button
                              type="button"
                              class="open-btn filter-btn-left"
                            >
                              {" "}
                              <img
                                class="me-2"
                                src="images/icon/all-filter-icon.svg"
                                alt=""
                              />{" "}
                              All Filter
                            </button>
                          </li>
                        </ul>
                      </div>
                      {/* <div class="pcs_dropdown dark-color pr10 text-center text-md-end">
                        <span>Sort by</span>
                        <select class="selectpicker show-tick">
                          <option>Best Selling</option>
                          <option>Recommended</option>
                          <option>New Arrivals</option>
                        </select>
                      </div> */}
                    </div>
                  </div>
                </div>
                <div class="row">
                  {freelancers &&
                    freelancers.map((item, index) => (
                      <div class="col-md-6 col-lg-12">
                        <div class="freelancer-style1 bdr1 hover-box-shadow row ms-0">
                          <div class="col-lg-8 ps-0">
                            <div class="d-lg-flex bdrr1 bdrn-lg">
                              <div class="thumb w90 position-relative rounded-circle mb15-md">
                                <img
                                  class="rounded-circle mx-auto"
                                  src={item.img ? item.img : "Not available"}
                                  style={{
                                    width: "90px",
                                    height: "90px",
                                    objectFit: "cover",
                                  }}
                                  alt=""
                                />
                                <span class="online"></span>
                              </div>
                              <div class="details ml15 ml0-md mb15-md">
                                <h5 class="title mb-1">
                                  {item.fname} {item.lname}
                                </h5>
                                <p className="mb-0">
                                  {item.designation ? item.designation : "-"}
                                </p>

                                {/* <div class="review">
                                  <p>
                                    <i class="fas fa-star fz10 review-color pr10"></i>
                                    <span class="dark-color fw500">4.9</span>{" "}
                                    (595 reviews)
                                  </p>
                                </div> */}
                                <div class="skill-tags d-flex align-items-center justify-content-lg-center mb-0">
                                  {item.skills
                                    ? item.skills
                                        .split(",")
                                        .slice(0, 3)
                                        .map((skill, skillIndex) => (
                                          <span
                                            className="tag mx5"
                                            key={skillIndex}
                                          >
                                            {skill.trim()}{" "}
                                            {/* Trim any leading or trailing spaces */}
                                          </span>
                                        ))
                                    : "-"}
                                  {/* <span class="tag">Figma</span>
                                  <span class="tag mx10">Sketch</span>
                                  <span class="tag">HTML5</span> */}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-4 ps-0 ps-xl-3 pe-0">
                            <div class="details">
                              <div class="fl-meta d-flex align-items-center justify-content-between">
                                <a class="meta fw500 text-start">
                                  Location
                                  <br />
                                  <span class="fz14 fw400">{item.country}</span>
                                </a>
                                <a class="meta fw500 text-start">
                                  Rate
                                  <br />
                                  <span class="fz14 fw400">
                                    ₹{item.rate} / hr
                                  </span>
                                </a>
                                {/* <a class="meta fw500 text-start">
                                  Job Success
                                  <br />
                                  <span class="fz14 fw400">%98</span>
                                </a> */}
                              </div>
                              <div class="d-grid mt15">
                                <a
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    navigate(`/freelancer_profile/${item.id}`)
                                  }
                                  class="ud-btn btn-light-thm"
                                >
                                  View Profile
                                  <i class="fal fa-arrow-right-long"></i>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
                {/* <div class="row">
                  <div class="mbp_pagination mt30 text-center">
                    <ul class="page_navigation">
                      <li class="page-item">
                        <a class="page-link" href="#">
                          {" "}
                          <span class="fas fa-angle-left"></span>
                        </a>
                      </li>
                      <li class="page-item">
                        <a class="page-link" href="#">
                          1
                        </a>
                      </li>
                      <li class="page-item active" aria-current="page">
                        <a class="page-link" href="#">
                          2 <span class="sr-only">(current)</span>
                        </a>
                      </li>
                      <li class="page-item">
                        <a class="page-link" href="#">
                          3
                        </a>
                      </li>
                      <li class="page-item">
                        <a class="page-link" href="#">
                          4
                        </a>
                      </li>
                      <li class="page-item d-inline-block d-sm-none">
                        <a class="page-link" href="#">
                          ...
                        </a>
                      </li>
                      <li class="page-item">
                        <a class="page-link" href="#">
                          5
                        </a>
                      </li>
                      <li class="page-item d-none d-sm-inline-block">
                        <a class="page-link" href="#">
                          ...
                        </a>
                      </li>
                      <li class="page-item d-none d-sm-inline-block">
                        <a class="page-link" href="#">
                          20
                        </a>
                      </li>
                      <li class="page-item">
                        <a class="page-link" href="#">
                          <span class="fas fa-angle-right"></span>
                        </a>
                      </li>
                    </ul>
                    <p class="mt10 mb-0 pagination_page_count text-center">
                      1 – 20 of 300+ property available
                    </p>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default Freelancers;
