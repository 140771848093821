import "./App.css";
import { Routes, Route } from "react-router-dom";
import Navbar from "./Components/Navbar";
import Home from "./Components/Home";
import About from "./Components/About";
import Footer from "./Components/Footer";
import Services from "./Components/Services";
import Pricing from "./Components/Pricing";
import Blogs from "./Components/Blogs";
import Contact from "./Components/Contact";
import Joinus from "./Components/Joinus";
import ShopNow from "./Components/ShopNow";
import ShopNow_details from "./Components/ShopNow_details";
import ShopNow_pay from "./Components/ShopNow_pay";
import Page404 from "./Components/Page404";
import Policies from "./Components/Policies";
import Freelancers from "./Components/Freelancers/Freelancers";
import Login from "./Components/Portal/Login";
import ForgotPassword from "./Components/Portal/ForgotPassword";
import SignUpHome from "./Components/Portal/SignUp_home";
import AdminLogin from "./Components/Portal/AdminLogin ";
import Register from "./Components/Portal/Register";
import ClientRegister from "./Components/Clients/Register";

import ProtectedRoute from "./Components/Portal/Context/ProtectedRoute";
import { UserAuthContextProvider } from "./Components/Portal/Context/UserAuthContext";
import StepA from "./Components/Portal/Profile/StepA";
import ClientStepA from "./Components/Clients/StepA";
import StepB from "./Components/Portal/Profile/StepB";
import ClientStepB from "./Components/Clients/StepB";
import StepC from "./Components/Portal/Profile/StepC";
import ClientStepC from "./Components/Clients/StepC";
import Dashboard from "./Components/Portal/AdminPortal/Dashboard";
import Manage_services from "./Components/Portal/AdminPortal/Services/Services";
import AdminFreelancers from "./Components/Portal/AdminPortal/Freelancers/Freelancers";
import Header from "./Components/Portal/Header";
import AddService from "./Components/Portal/AdminPortal/Services/AddService";
import MyProfile from "./Components/Portal/Profile/MyProfile";
import DetailFreelancer from "./Components/Freelancers/DetailFreelancer";
import ChatBox from "./Components/Message/ChatBox";
import Chats from "./Components/Message/Chats";
import FreelancerChatBox from "./Components/Message/FreelancerChatBox";
import MyPojects from "./Components/Clients/MyProjects";
import Projects from "./Components/Clients/Projects";
import ProjectDetail from "./Components/Clients/ProjectDetail";
import AddProject from "./Components/Portal/AdminPortal/Projects/AddProject";
import EditProject from "./Components/Portal/AdminPortal/Projects/EditProject";
import EditService from "./Components/Portal/AdminPortal/Services/EditService";
import AllPayments from "./Components/Portal/AdminPortal/Payments/AllPayments";

function App() {
  return (
    <div className="App">
      <UserAuthContextProvider>
        <Routes>
          <Route
            path="*"
            element={
              <>
                <Navbar />
                <Page404 />
                <Footer />
              </>
            }
          />
          <Route
            path="/"
            element={
              <>
                <Navbar />
                <Home />
                <Footer />
              </>
            }
          />

          <Route
            path="/about_us"
            element={
              <>
                <Navbar />
                <About />
                <Footer />
              </>
            }
          />

          <Route
            path="/services"
            element={
              <>
                <Navbar />
                <Services />
                <Footer />
              </>
            }
          />

          <Route
            path="/pricings"
            element={
              <>
                <Navbar />
                <Pricing />
                <Footer />
              </>
            }
          />

          <Route
            path="/policies"
            element={
              <>
                <Navbar />
                <Policies />
                <Footer />
              </>
            }
          />

          <Route
            path="/blogs"
            element={
              <>
                <Navbar />
                <Blogs />
                <Footer />
              </>
            }
          />

          <Route
            path="/contact_us"
            element={
              <>
                <Navbar />
                <Contact />
                <Footer />
              </>
            }
          />

          <Route
            path="/join_us"
            element={
              <>
                <Navbar />
                <Joinus />
                <Footer />
              </>
            }
          />

          <Route
            path="/shop_now"
            element={
              <>
                <Navbar />
                <ShopNow />
                <Footer />
              </>
            }
          />

          <Route
            path="/shop_now_detail/:id"
            element={
              <>
                <Navbar />
                <ShopNow_details />
                <Footer />
              </>
            }
          />

          <Route
            path="/shop_now_pay/:id"
            element={
              <>
                <Navbar />
                <ShopNow_pay />
                <Footer />
              </>
            }
          />

          <Route
            path="/freelancers"
            element={
              <>
                <Navbar />
                <Freelancers />
                <Footer />
              </>
            }
          />

          <Route
            path="/freelancer_profile/:id"
            element={
              <>
                <Navbar />
                <DetailFreelancer />
                <Footer />
              </>
            }
          />

          <Route
            path="/admin_signin"
            element={
              <>
                <Navbar />
                <AdminLogin />
                <Footer />
              </>
            }
          />

          <Route
            path="/admin_dashboard"
            element={
              <>
                <ProtectedRoute>
                  <Header />
                  <Dashboard />
                </ProtectedRoute>
              </>
            }
          />

          <Route
            path="/manage-projects"
            element={
              <>
                <ProtectedRoute>
                  <Header />
                  <Dashboard />
                </ProtectedRoute>
              </>
            }
          />

          <Route
            path="/edit-project/:id"
            element={
              <>
                <ProtectedRoute>
                  <Header />
                  <EditProject />
                </ProtectedRoute>
              </>
            }
          />

          <Route
            path="/manage_services"
            element={
              <>
                <ProtectedRoute>
                  <Header />
                  <Manage_services />
                </ProtectedRoute>
              </>
            }
          />

          <Route
            path="/add_service"
            element={
              <>
                <ProtectedRoute>
                  <Header />
                  <AddService />
                </ProtectedRoute>
              </>
            }
          />

<Route
            path="/edit-service/:id"
            element={
              <>
                <ProtectedRoute>
                  <Header />
                  <EditService />
                </ProtectedRoute>
              </>
            }
          />

          <Route
            path="/add_project"
            element={
              <>
                <ProtectedRoute>
                  <Header />
                  <AddProject />
                </ProtectedRoute>
              </>
            }
          />

<Route
            path="/all-payments"
            element={
              <>
                <ProtectedRoute>
                  <Header />
                  <AllPayments />
                </ProtectedRoute>
              </>
            }
          />

          <Route
            path="/allfreelancers"
            element={
              <>
                <ProtectedRoute>
                  <Header />
                  <AdminFreelancers />
                </ProtectedRoute>
              </>
            }
          />

          <Route
            path="/signin"
            element={
              <>
                <Navbar />
                <Login />
                <Footer />
              </>
            }
          />

          <Route
            path="/forgotPassword"
            element={
              <>
                <Navbar />
                <ForgotPassword />
                <Footer />
              </>
            }
          />

          <Route
            path="/signup_home"
            element={
              <>
                <Navbar />
                <SignUpHome />
                <Footer />
              </>
            }
          />

          <Route
            path="/signup"
            element={
              <>
                <Navbar />
                <Register />
                <Footer />
              </>
            }
          />

          <Route
            path="/client_signup"
            element={
              <>
                <Navbar />
                <ClientRegister />
                <Footer />
              </>
            }
          />

          <Route
            path="/ready_for_your_next_big_step"
            element={
              <>
                <ProtectedRoute>
                  <Navbar />
                  <StepA />
                  <Footer />
                </ProtectedRoute>
              </>
            }
          />

          <Route
            path="/ready_for_your_next_big_step_client"
            element={
              <>
                <ProtectedRoute>
                  <Navbar />
                  <ClientStepA />
                  <Footer />
                </ProtectedRoute>
              </>
            }
          />

          <Route
            path="/profile_completion_personal_details"
            element={
              <>
                <ProtectedRoute>
                  <Navbar />
                  <StepB />
                  <Footer />
                </ProtectedRoute>
              </>
            }
          />

          <Route
            path="/profile_completion_personal_details_client"
            element={
              <>
                <ProtectedRoute>
                  <Navbar />
                  <ClientStepB />
                  <Footer />
                </ProtectedRoute>
              </>
            }
          />

          <Route
            path="/profile_completion_professional_details"
            element={
              <>
                <ProtectedRoute>
                  <Navbar />
                  <StepC />
                  <Footer />
                </ProtectedRoute>
              </>
            }
          />

          <Route
            path="/create_project_"
            element={
              <>
                <ProtectedRoute>
                  <Navbar />
                  <ClientStepC />
                  <Footer />
                </ProtectedRoute>
              </>
            }
          />

          <Route
            path="/profile"
            element={
              <>
                <ProtectedRoute>
                  <Navbar />
                  <MyProfile />
                  <Footer />
                </ProtectedRoute>
              </>
            }
          />

          <Route
            path="/chats/:id"
            element={
              <>
                <ProtectedRoute>
                  <Navbar />
                  <Chats />
                  <Footer />
                </ProtectedRoute>
              </>
            }
          />

          <Route
            path="/chats"
            element={
              <>
                <ProtectedRoute>
                  <Navbar />
                  <ChatBox />
                  <Footer />
                </ProtectedRoute>
              </>
            }
          />

          <Route
            path="/projects"
            element={
              <>
                <Navbar />
                <Projects />
                <Footer />
              </>
            }
          />

          <Route
            path="/my_projects"
            element={
              <>
                <ProtectedRoute>
                  <Navbar />
                  <MyPojects />
                  <Footer />
                </ProtectedRoute>
              </>
            }
          />

          <Route
            path="/project/:id"
            element={
              <>
                <Navbar />
                <ProjectDetail />
                <Footer />
              </>
            }
          />

          <Route
            path="/my_chats"
            element={
              <>
                <ProtectedRoute>
                  <Navbar />
                  <FreelancerChatBox />
                  <Footer />
                </ProtectedRoute>
              </>
            }
          />
        </Routes>
      </UserAuthContextProvider>
    </div>
  );
}

export default App;
