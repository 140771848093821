import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useUserAuth } from "./Context/UserAuthContext";
import { db } from "../../firebase";
import Swal from "sweetalert2";
import GoToTop from "../../GoToTop";
import { serverTimestamp } from "firebase/firestore";
function Register() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [contact, setContact] = useState("");
  let navigate = useNavigate();
  const { signUp } = useUserAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const userCredential = await signUp(email, password);
      const user = userCredential.user;
      const uid = user.uid;
      await db.collection("Freelancers").doc(uid).set({
        fname,
        lname,
        email,
        contact,
        profilestatus: "pending",
        createdate: serverTimestamp(),
      });

      navigate("/ready_for_your_next_big_step");
      Swal.fire({
        title: "Success",
        text: "Account has been created successfully!",
        icon: "success",
      });
      return false;
    } catch (err) {
      Swal.fire({
        title: "Error",
        text: `Oops! ${err.message}`,
        icon: "error",
      });
    }
  };

  //   const handleSubmit = async (e) => {
  //     e.preventDefault();
  //     setError("");
  //     try {
  //       const user = await signUp(email, password);
  //       await db.collection("Freelancers").doc(email).set({
  //         fullname,
  //         username,
  //         email,
  //         contact,
  //       });
  //       navigate("/signin");
  //       return false;
  //     } catch (err) {
  //       setError(err.message);
  //     }
  //   };
  return (
    <>
      <GoToTop />
      <div class="body_content">
        {/* <!-- Our SignUp Area --> */}
        <section class="our-register">
          <div class="container">
            <div class="row">
              <div class="col-lg-6 m-auto wow fadeInUp" data-wow-delay="300ms">
                <div class="main-title text-center">
                  <h2 class="title">Register</h2>
                  <p class="paragraph">Join as a freelancer</p>
                </div>
              </div>
            </div>
            <div
              class="row wow fadeInRight"
              data-wow-delay="300ms"
              style={{ marginTop: "-50px" }}
            >
              <div class="col-xl-6 mx-auto">
                <div class="log-reg-form search-modal form-style1 bgc-white p50 p30-sm default-box-shadow1 bdrs12">
                  <div class="mb30">
                    <h4>Let's create your account!</h4>
                    <p class="text mt20">
                      Already have an account?{" "}
                      <Link to="/signin" class="text-thm">
                        Log In!
                      </Link>
                    </p>
                  </div>
                  <form onSubmit={handleSubmit}>
                    <div class="mb25">
                      <label class="form-label fw500 dark-color">
                        First Name
                      </label>
                      <input
                        type="text"
                        value={fname}
                        onChange={(e) => setFname(e.target.value)}
                        class="form-control"
                        placeholder="First Name"
                      />
                    </div>
                    <div class="mb25">
                      <label class="form-label fw500 dark-color">
                        Last Name
                      </label>
                      <input
                        type="text"
                        value={lname}
                        onChange={(e) => setLname(e.target.value)}
                        class="form-control"
                        placeholder="Last Name"
                      />
                    </div>

                    <div class="mb25">
                      <label class="form-label fw500 dark-color">Contact</label>
                      <input
                        type="tel"
                        value={contact}
                        onChange={(e) => setContact(e.target.value)}
                        class="form-control"
                        placeholder="+91-0123456789"
                      />
                    </div>

                    <div class="mb25">
                      <label class="form-label fw500 dark-color">Email</label>
                      <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        class="form-control"
                        placeholder="Email Address"
                      />
                    </div>
                    <div class="mb15">
                      <label class="form-label fw500 dark-color">
                        Password
                      </label>
                      <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        class="form-control"
                        placeholder="*******"
                      />
                    </div>
                    <div class="d-grid mb20">
                      <button
                        class="ud-btn btn-thm default-box-shadow2"
                        type="Submit"
                      >
                        Creat Account <i class="fal fa-arrow-right-long"></i>
                      </button>
                    </div>
                  </form>
                  <div class="hr_content mb20">
                    <hr />
                    <span class="hr_top_text">OR</span>
                  </div>
                  <div class="d-md-flex justify-content-between">
                    <button
                      class="ud-btn btn-fb fz14 fw400 mb-2 mb-md-0"
                      type="button"
                    >
                      <i class="fab fa-facebook-f pr10"></i> Continue Facebook
                    </button>
                    <button
                      class="ud-btn btn-google fz14 fw400 mb-2 mb-md-0"
                      type="button"
                    >
                      <i class="fab fa-google"></i> Continue Google
                    </button>
                    <button class="ud-btn btn-apple fz14 fw400" type="button">
                      <i class="fab fa-apple"></i> Continue Apple
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default Register;
