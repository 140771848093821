import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import GoToTop from "../../GoToTop";
import { useUserAuth } from "./Context/UserAuthContext";
import { Alert } from "react-bootstrap";
import { db } from "../../firebase";
import Swal from "sweetalert2";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const [loading, setLoading] = useState(false);
  const [successmsg, setSuccessMsg] = useState("");
  const { logIn } = useUserAuth();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setLoading(true);
    try {
      const userCredential = await logIn(email, password);
      const user = userCredential.user;
      const uid = user.uid;
      setSuccessMsg("Credentials verified. Redirecting...");

      // Get the user's document from Firestore
      const userDoc = await db.collection("Admins").doc(uid).get();
      if (userDoc.exists) {
        const userData = userDoc.data();
        Swal.fire({
          title: "Login Success",
          text: "Hurray! Login successfull",
          icon: "success",
        });
        navigate("/admin_dashboard");
      } else {
        setError("User not found in Firestore");
      }

      setTimeout(() => {
        setSuccessMsg("");
        setLoading(false);
      }, 4000);
    } catch (err) {
      setError("Alert! You are not authorized");
      setLoading(false);
      setTimeout(() => {
        setError("");
        setLoading(false);
      }, 3000);
    }
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   setError("");
  //   setLoading(true);
  //   try {
  //     await logIn(email, password);
  //     setSuccessMsg("Credentials verified. Redirecting...");

  //     setTimeout(() => {
  //       setSuccessMsg("");
  //       setLoading(false);
  //       navigate("/portal", { replace: true });
  //     }, 4000);
  //   } catch (err) {
  //     setError("Alert! You are not authorized");
  //     setLoading(false);
  //     setTimeout(() => {
  //       setError("");
  //       setLoading(false);
  //     }, 3000);
  //   }
  // };
  return (
    <>
      <GoToTop />
      <div class="body_content">
        {/* <!-- Our LogIn Area --> */}
        <section class="our-login">
          <div class="container">
            <div class="row">
              <div class="col-lg-6 m-auto wow fadeInUp" data-wow-delay="300ms">
                <div class="main-title text-center">
                  <h2 class="title">Admin Log In</h2>

                  {/* <p class="paragraph">
                    Give your visitor a smooth online experience with a solid UX
                    design
                  </p> */}
                </div>
              </div>
            </div>
            <div
              class="row wow fadeInRight"
              data-wow-delay="300ms"
              style={{ marginTop: "-50px" }}
            >
              <div class="col-xl-6 mx-auto">
                <div class="log-reg-form search-modal form-style1 bgc-white p50 p30-sm default-box-shadow1 bdrs12">
                  <div class="mb30">
                    <h4>We're glad to see you again!</h4>
                  </div>
                  {error && <Alert variant="danger">{error}</Alert>}
                  {successmsg && (
                    <Alert
                      variant="success"
                      style={{ fontSize: "12px", textAlign: "center" }}
                    >
                      {successmsg}
                    </Alert>
                  )}
                  <form onSubmit={handleSubmit}>
                    <div class="mb20">
                      <label class="form-label fw600 dark-color">
                        Email Address
                      </label>
                      <input
                        type="email"
                        onChange={(e) => setEmail(e.target.value)}
                        class="form-control"
                        placeholder="alitfn58@gmail.com"
                      />
                    </div>
                    <div class="mb15">
                      <label class="form-label fw600 dark-color">
                        Password
                      </label>
                      <input
                        type="password"
                        onChange={(e) => setPassword(e.target.value)}
                        class="form-control"
                        placeholder="*******"
                      />
                    </div>
                    <div class="checkbox-style1 d-block d-sm-flex align-items-center justify-content-between mb20">
                      {/* <label class="custom_checkbox fz14 ff-heading">Remember me
                  <input type="checkbox" checked="checked">
                  <span class="checkmark"></span>
                </label> */}
                      <a class="fz14 ff-heading" href="#">
                        Lost your password?
                      </a>
                    </div>
                    <div class="d-grid mb20">
                      {loading === true ? (
                        <>
                          <button class="ud-btn btn-thm" disabled>
                            Loading...
                          </button>
                        </>
                      ) : (
                        <>
                          <button class="ud-btn btn-thm" type="submit">
                            Log In <i class="fal fa-arrow-right-long"></i>
                          </button>
                        </>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default Login;
