import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import GoToTop from "../../GoToTop";
import { useUserAuth } from "./Context/UserAuthContext";
import { Alert } from "react-bootstrap";
import { db } from "../../firebase";
import Swal from "sweetalert2";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const [loading, setLoading] = useState(false);
  const [successmsg, setSuccessMsg] = useState("");
  const { logIn } = useUserAuth();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setLoading(true);
    try {
      const userCredential = await logIn(email, password);
      const user = userCredential.user;
      const uid = user.uid;
      setSuccessMsg("Credentials verified. Redirecting...");

      // Function to check user document in both collections
      const getUserDoc = async (uid) => {
        let userDoc = await db.collection("Freelancers").doc(uid).get();
        if (userDoc.exists) {
          return { userDoc, collection: "Freelancers" };
        }
        userDoc = await db.collection("Clients").doc(uid).get();
        if (userDoc.exists) {
          return { userDoc, collection: "Clients" };
        }
        return null;
      };

      // Get the user's document from Firestore
      const userDocResult = await getUserDoc(uid);
      if (userDocResult) {
        const { userDoc, collection } = userDocResult;
        const userData = userDoc.data();
        if (userData.profilestatus === "pending") {
          Swal.fire({
            title: "Ohhhooo",
            text: "Profile Incomplete! Please complete your profile.",
            icon: "info",
          });
          if (collection === "Freelancers") {
            navigate("/profile_completion_personal_details");
          } else if (collection === "Clients") {
            navigate("/profile_completion_personal_details_client");
          }
        } else if (userData.profilestatus === "pendingB") {
          if (collection === "Freelancers") {
            Swal.fire({
              title: "Ohhhooo",
              text: "Profile Incomplete! Please complete your profile.",
              icon: "info",
            });
            navigate("/profile_completion_professional_details");
          } else if (collection === "Clients") {
            navigate("/");
          }
        } else {
          navigate("/");
        }
      } else {
        setError("User not found in Firestore");
      }

      setTimeout(() => {
        setSuccessMsg("");
        setLoading(false);
      }, 4000);
    } catch (err) {
      setError("Alert! You are not authorized");
      setLoading(false);
      setTimeout(() => {
        setError("");
        setLoading(false);
      }, 3000);
    }
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   setError("");
  //   setLoading(true);
  //   try {
  //     await logIn(email, password);
  //     setSuccessMsg("Credentials verified. Redirecting...");

  //     setTimeout(() => {
  //       setSuccessMsg("");
  //       setLoading(false);
  //       navigate("/portal", { replace: true });
  //     }, 4000);
  //   } catch (err) {
  //     setError("Alert! You are not authorized");
  //     setLoading(false);
  //     setTimeout(() => {
  //       setError("");
  //       setLoading(false);
  //     }, 3000);
  //   }
  // };
  return (
    <>
      <GoToTop />
      <div class="body_content">
        {/* <!-- Our LogIn Area --> */}
        <section class="our-login">
          <div class="container">
            <div class="row">
              <div class="col-lg-6 m-auto wow fadeInUp" data-wow-delay="300ms">
                <div class="main-title text-center">
                  <h2 class="title">Log In</h2>

                  {/* <p class="paragraph">
                   Login 
                  </p>  */}
                </div>
              </div>
            </div>
            <div
              class="row wow fadeInRight"
              data-wow-delay="300ms"
              style={{ marginTop: "-50px" }}
            >
              <div class="col-xl-6 mx-auto">
                <div class="log-reg-form search-modal form-style1 bgc-white p50 p30-sm default-box-shadow1 bdrs12">
                  <div class="mb30">
                    <h4>We're glad to see you again!</h4>
                    <p class="text">
                      Don't have an account?{" "}
                      <Link to="/signup_home" class="text-thm">
                        Sign Up!
                      </Link>
                    </p>
                  </div>
                  {error && <Alert variant="danger">{error}</Alert>}
                  {successmsg && (
                    <Alert
                      variant="success"
                      style={{ fontSize: "12px", textAlign: "center" }}
                    >
                      {successmsg}
                    </Alert>
                  )}
                  <form onSubmit={handleSubmit}>
                    <div class="mb20">
                      <label class="form-label fw600 dark-color">
                        Email Address
                      </label>
                      <input
                        type="email"
                        onChange={(e) => setEmail(e.target.value)}
                        class="form-control"
                        placeholder="xyz@gmail.com"
                      />
                    </div>
                    <div class="mb15">
                      <label class="form-label fw600 dark-color">
                        Password
                      </label>
                      <input
                        type="password"
                        onChange={(e) => setPassword(e.target.value)}
                        class="form-control"
                        placeholder="*******"
                      />
                    </div>
                    <div class="checkbox-style1 d-block d-sm-flex align-items-center justify-content-between mb20">
                      {/* <label class="custom_checkbox fz14 ff-heading">Remember me
                  <input type="checkbox" checked="checked">
                  <span class="checkmark"></span>
                </label> */}
                      <Link class="fz14 ff-heading" to="/forgotPassword">
                        Lost your password?
                      </Link>
                    </div>
                    <div class="d-grid mb20">
                      {loading === true ? (
                        <>
                          <button class="ud-btn btn-thm" disabled>
                            Loading...
                          </button>
                        </>
                      ) : (
                        <>
                          <button class="ud-btn btn-thm" type="submit">
                            Log In <i class="fal fa-arrow-right-long"></i>
                          </button>
                        </>
                      )}
                    </div>
                  </form>
                  <div class="hr_content mb20">
                    <hr />
                    <span class="hr_top_text">OR</span>
                  </div>
                  <div class="d-md-flex justify-content-between">
                    <button
                      class="ud-btn btn-fb fz14 fw400 mb-2 mb-md-0"
                      type="button"
                    >
                      <i class="fab fa-facebook-f pr10"></i> Continue Facebook
                    </button>
                    <button
                      class="ud-btn btn-google fz14 fw400 mb-2 mb-md-0"
                      type="button"
                    >
                      <i class="fab fa-google"></i> Continue Google
                    </button>
                    <button class="ud-btn btn-apple fz14 fw400" type="button">
                      <i class="fab fa-apple"></i> Continue Apple
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default Login;
