import React, { useState, useEffect } from "react";
import GoToTop from "../../GoToTop";
import { db, auth } from "../../firebase";
import {
  onSnapshot,
  getDoc,
  doc,
  collection,
  query,
  where,
} from "firebase/firestore";
import FreelancerChats from "./FreelancerChats";

function FreelancerChatBox() {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [userId, setUserId] = useState(null);
  const [freelancers, setFreelancers] = useState([]);
  const [selectedItemId, setSelectedItemId] = useState(null); // State to hold selected item ID

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUserId(user.uid);
        fetchUserData(user.uid);
      } else {
        setLoading(false);
        console.log("User is not authenticated");
      }
    });

    const fetchUserData = async (userId) => {
      try {
        const freelancerDocRef = doc(db, "Freelancers", userId);
        const freelancerDoc = await getDoc(freelancerDocRef);

        if (freelancerDoc.exists()) {
          setUserData(freelancerDoc.data());
        } else {
          const clientDocRef = doc(db, "Clients", userId);
          const clientDoc = await getDoc(clientDocRef);

          if (clientDoc.exists()) {
            setUserData(clientDoc.data());
          } else {
            console.log("User document does not exist in either collection");
          }
        }
      } catch (error) {
        console.log("Error getting user document:", error);
      }

      setLoading(false);
    };

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (!userId) return;

    const fetchFreelancerDetails = async (freelancerIds) => {
      const freelancerPromises = freelancerIds.map(async (id) => {
        const userDoc = await getDoc(doc(db, "Clients", id));
        return { id: userDoc.id, ...userDoc.data() };
      });
      const freelancerDetails = await Promise.all(freelancerPromises);
      setFreelancers(freelancerDetails);
      setLoading(false);
    };

    const unsub = onSnapshot(
      query(collection(db, "Chats"), where("freelancerID", "==", userId)),
      (snapshot) => {
        const freelancerIds = snapshot.docs.map((doc) => doc.data().clientID);
        fetchFreelancerDetails(freelancerIds);
      },
      (error) => {
        console.error(error);
      }
    );

    return () => unsub();
  }, [userId]);

  const handleClick = (itemId) => {
    setSelectedItemId(itemId); // Update selected item ID
  };

  if (loading) {
    return <div className="preloader"></div>;
  }
  return (
    <>
      <GoToTop />
      <div className="body_content" style={{ marginTop: "2vh" }}>
        <div className="dashboard__content hover-bgc-color">
          <div className="row mb40">
            <div className="col-lg-6 col-xl-5 col-xxl-4">
              <div className="message_container">
                <div className="inbox_user_list">
                  <div className="iu_heading pr35">
                    <div className="chat_user_search">
                      <form className="d-flex align-items-center">
                        <button className="btn">
                          <span className="far fa-magnifying-glass"></span>
                        </button>
                        <input
                          style={{ padding: "5px", border: "none" }}
                          className="form-control"
                          type="search"
                          placeholder="Search"
                          aria-label="Search"
                        />
                      </form>
                    </div>
                  </div>
                  <div className="chat-member-list pr20">
                    {freelancers && freelancers.length > 0 ? (
                      freelancers.map((item) => (
                        <div className="list-item pt5" key={item.id}>
                          <a
                            onClick={() => handleClick(item.id)} // Passes item.id to handleClick
                            style={{ cursor: "pointer" }}
                          >
                            <div className="d-flex align-items-center position-relative">
                              <img
                                style={{
                                  width: "45px",
                                  height: "45px",
                                  objectFit: "cover",
                                }}
                                className="img-fluid float-start rounded-circle mr10"
                                src={item.img}
                                alt={item.fname}
                              />
                              <div className="d-sm-flex">
                                <div className="d-inline-block">
                                  <div className="fz15 fw500 dark-color ff-heading mb-0">
                                    {item.fname} {item.lname}
                                  </div>
                                  <p className="preview">{item.designation}</p>
                                </div>
                                <div className="iul_notific">
                                  <small>{item.timee}</small>
                                </div>
                              </div>
                            </div>
                          </a>
                        </div>
                      ))
                    ) : (
                      <p style={{ textAlign: "center" }}>No chats available</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-xl-7 col-xxl-8">
              <div className="message_container mt30-md">
                {/* Render Chats component with selectedItemId */}
                <FreelancerChats itemId={selectedItemId} userId={userId} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FreelancerChatBox;
