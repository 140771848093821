import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { db } from "../../../firebase";
import { onSnapshot } from "firebase/firestore";
import Sidebar from "../Sidebar";

function Dashboard() {
  const [projects, setProjects] = useState([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const unsub = onSnapshot(
      db.collection("AllProjects"),
      (snapshot) => {
        let list = [];
        snapshot.docs.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() });
        });
        setProjects(list);
        setLoading(false);
      },

      (error) => {
        console.error(error);
      }
    );
    return () => {
      unsub();
    };
  }, []);

  const formatPrice = (price) => {
    return new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
    }).format(price);
  };
  return (
    <>
      <div class="dashboard_content_wrapper">
        <div class="dashboard dashboard_wrapper pr30 pr0-xl">
          <Sidebar />

          <div class="dashboard__main pl0-md">
            <div class="dashboard__content hover-bgc-color">
              <div class="row pb40">
                <div class="col-lg-12">
                  <div class="dashboard_navigationbar d-block d-lg-none">
                    <div class="dropdown">
                      <button onclick="myFunction()" class="dropbtn">
                        <i class="fa fa-bars pr10"></i> Dashboard Navigation
                      </button>
                      <ul id="myDropdown" class="dropdown-content">
                        <li>
                          <p class="fz15 fw400 ff-heading mt30 pl30">Start</p>
                        </li>
                        <li>
                          <a href="page-dashboard.html">
                            <i class="flaticon-home mr10"></i>Dashboard
                          </a>
                        </li>
                        <li>
                          <a href="page-dashboard-proposal.html">
                            <i class="flaticon-document mr10"></i>Projects
                          </a>
                        </li>
                        <li>
                          <a href="page-dashboard-save.html">
                            <i class="flaticon-like mr10"></i>Freelancers
                          </a>
                        </li>

                        <li>
                          <p class="fz15 fw400 ff-heading mt30 pl30">
                            Organize and Manage
                          </p>
                        </li>
                        <li>
                          <a href="page-dashboard-manage-service.html">
                            <i class="flaticon-presentation mr10"></i>Manage
                            Services
                          </a>
                        </li>
                        <li>
                          <a href="page-dashboard-manage-jobs.html">
                            <i class="flaticon-briefcase mr10"></i>Manage Jobs
                          </a>
                        </li>
                        <li class="active">
                          <a href="page-dashboard-manage-project.html">
                            <i class="flaticon-content mr10"></i>Manage Project
                          </a>
                        </li>
                        <li>
                          <p class="fz15 fw400 ff-heading mt30 pl30">Account</p>
                        </li>
                        <li>
                          <a href="page-dashboard-profile.html">
                            <i class="flaticon-photo mr10"></i>My Profile
                          </a>
                        </li>
                        <li>
                          <a href="page-login.html">
                            <i class="flaticon-logout mr10"></i>Logout
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="col-lg-9">
                  <div class="dashboard_title_area">
                    <h2>Manage Project</h2>
                    <p class="text">Manage all your projects here...</p>
                  </div>
                </div>
                <div class="col-lg-3">
                  <div class="text-lg-end">
                    <Link
                      to="/add_project"
                      class="ud-btn btn-dark default-box-shadow2"
                    >
                      Create Project<i class="fal fa-arrow-right-long"></i>
                    </Link>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-12">
                  <div class="ps-widget bgc-white bdrs4 p30 mb30 overflow-hidden position-relative">
                    <div class="navtab-style1">
                      <nav>
                        <div
                          class="nav nav-tabs mb30"
                          id="nav-tab2"
                          role="tablist"
                        >
                          <button
                            class="nav-link active fw500 ps-0"
                            id="nav-item1-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#nav-item1"
                            type="button"
                            role="tab"
                            aria-controls="nav-item1"
                            aria-selected="true"
                          >
                            Posted Projects
                          </button>
                          <button
                            class="nav-link fw500"
                            id="nav-item2-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#nav-item2"
                            type="button"
                            role="tab"
                            aria-controls="nav-item2"
                            aria-selected="false"
                          >
                            Pending Projects
                          </button>
                          <button
                            class="nav-link fw500"
                            id="nav-item3-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#nav-item3"
                            type="button"
                            role="tab"
                            aria-controls="nav-item3"
                            aria-selected="false"
                          >
                            Ongoing Projects
                          </button>
                          <button
                            class="nav-link fw500"
                            id="nav-item4-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#nav-item4"
                            type="button"
                            role="tab"
                            aria-controls="nav-item4"
                            aria-selected="false"
                          >
                            Expired Projects
                          </button>
                          <button
                            class="nav-link fw500"
                            id="nav-item5-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#nav-item5"
                            type="button"
                            role="tab"
                            aria-controls="nav-item5"
                            aria-selected="false"
                          >
                            Completed Projects
                          </button>
                          <button
                            class="nav-link fw500"
                            id="nav-item6-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#nav-item6"
                            type="button"
                            role="tab"
                            aria-controls="nav-item6"
                            aria-selected="false"
                          >
                            Canceled Projects
                          </button>
                        </div>
                      </nav>
                      <div class="tab-content" id="nav-tabContent">
                        <div
                          class="tab-pane fade show active"
                          id="nav-item1"
                          role="tabpanel"
                          aria-labelledby="nav-item1-tab"
                        >
                          <div class="packages_table table-responsive">
                            <table class="table-style3 table at-savesearch">
                              <thead class="t-head">
                                <tr>
                                  <th scope="col">Title</th>
                                  <th scope="col">Category</th>
                                  <th scope="col">Type/Cost</th>
                                  <th scope="col">Actions</th>
                                </tr>
                              </thead>
                              <tbody class="t-body">
                                {projects &&
                                  projects.map((item, index) => (
                                    <tr>
                                      <th scope="row">
                                        <div class="freelancer-style1 box-shadow-none row m-0 p-0 align-items-lg-end">
                                          <div class="d-lg-flex px-0">
                                            <div class="details mb15-md-md">
                                              <h5 class="title mb10">
                                                {item.title}
                                              </h5>
                                              {/* <p class="mb-0 fz14 list-inline-item mb5-sm pe-1">
                                                <i class="flaticon-place fz16 vam text-thm2 me-1"></i>{" "}
                                                London, UK
                                              </p> */}
                                              <p class="mb-0 fz14 list-inline-item mb5-sm pe-1">
                                                <i class="flaticon-30-days fz16 vam text-thm2 me-1 bdrl1 pl15 pl0-xs bdrn-xs"></i>{" "}
                                                {/* {item.createdOn} */}
                                              </p>
                                              <p class="mb-0 fz14 list-inline-item mb5-sm text-thm">
                                                <i class="flaticon-contract fz16 vam me-1 bdrl1 pl15 pl0-xs bdrn-xs"></i>{" "}
                                                1 Received
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </th>
                                      <td class="vam">
                                        <span class="fz15 fw400">
                                          {item.category}
                                        </span>
                                      </td>
                                      <td class="vam">
                                        <span class="fz14 fw400">
                                          {formatPrice(item.budget)}
                                        </span>
                                      </td>
                                      <td>
                                        <div class="d-flex">
                                          <a
                                            onClick={() =>
                                              navigate(
                                                `/edit-project/${item.id}`
                                              )
                                            }
                                            class="icon me-2"
                                            data-bs-toggle="tooltip"
                                            data-bs-placement="top"
                                            title="Edit"
                                          >
                                            <span class="flaticon-pencil"></span>
                                          </a>
                                          {/* <a
                                            class="icon"
                                            data-bs-toggle="tooltip"
                                            data-bs-placement="top"
                                            title="Delete"
                                          >
                                            <span class="flaticon-delete"></span>
                                          </a> */}
                                        </div>
                                      </td>
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                        {/* <div
                          class="tab-pane fade"
                          id="nav-item2"
                          role="tabpanel"
                          aria-labelledby="nav-item2-tab"
                        >
                          <div class="packages_table table-responsive">
                            <table class="table-style3 table at-savesearch">
                              <thead class="t-head">
                                <tr>
                                  <th scope="col">Title</th>
                                  <th scope="col">Category</th>
                                  <th scope="col">Type/Cost</th>
                                  <th scope="col">Actions</th>
                                </tr>
                              </thead>
                              <tbody class="t-body">
                                <tr>
                                  <th scope="row">
                                    <div class="freelancer-style1 box-shadow-none row m-0 p-0 align-items-lg-end">
                                      <div class="d-lg-flex px-0">
                                        <div class="details mb15-md-md">
                                          <h5 class="title mb10">
                                            Food Delviery Mobile App
                                          </h5>
                                          <p class="mb-0 fz14 list-inline-item mb5-sm pe-1">
                                            <i class="flaticon-place fz16 vam text-thm2 me-1"></i>{" "}
                                            London, UK
                                          </p>
                                          <p class="mb-0 fz14 list-inline-item mb5-sm pe-1">
                                            <i class="flaticon-30-days fz16 vam text-thm2 me-1 bdrl1 pl15 pl0-xs bdrn-xs"></i>{" "}
                                            2 hours ago
                                          </p>
                                          <p class="mb-0 fz14 list-inline-item mb5-sm text-thm">
                                            <i class="flaticon-contract fz16 vam me-1 bdrl1 pl15 pl0-xs bdrn-xs"></i>{" "}
                                            1 Received
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </th>
                                  <td class="vam">
                                    <span class="fz15 fw400">
                                      Web & App Design
                                    </span>
                                  </td>
                                  <td class="vam">
                                    <span class="fz14 fw400">
                                      $500.00/Fixed
                                    </span>
                                  </td>
                                  <td>
                                    <div class="d-flex">
                                      <a
                                        href=""
                                        class="icon me-2"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        title="Edit"
                                      >
                                        <span class="flaticon-pencil"></span>
                                      </a>
                                      <a
                                        href=""
                                        class="icon"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        title="Delete"
                                      >
                                        <span class="flaticon-delete"></span>
                                      </a>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <th scope="row">
                                    <div class="freelancer-style1 box-shadow-none row m-0 p-0 align-items-lg-end">
                                      <div class="d-lg-flex px-0">
                                        <div class="details mb15-md-md">
                                          <h5 class="title mb10">
                                            Swift / SwiftUI Developer for B2B
                                            iOS apps
                                          </h5>
                                          <p class="mb-0 fz14 list-inline-item mb5-sm pe-1">
                                            <i class="flaticon-place fz16 vam text-thm2 me-1"></i>{" "}
                                            London, UK
                                          </p>
                                          <p class="mb-0 fz14 list-inline-item mb5-sm pe-1">
                                            <i class="flaticon-30-days fz16 vam text-thm2 me-1 bdrl1 pl15 pl0-xs bdrn-xs"></i>{" "}
                                            2 hours ago
                                          </p>
                                          <p class="mb-0 fz14 list-inline-item mb5-sm text-thm">
                                            <i class="flaticon-contract fz16 vam me-1 bdrl1 pl15 pl0-xs bdrn-xs"></i>{" "}
                                            1 Received
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </th>
                                  <td class="vam">
                                    <span class="fz15 fw400">
                                      Web & App Design
                                    </span>
                                  </td>
                                  <td class="vam">
                                    <span class="fz14 fw400">
                                      $500.00/Fixed
                                    </span>
                                  </td>
                                  <td>
                                    <div class="d-flex">
                                      <a
                                        href=""
                                        class="icon me-2"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        title="Edit"
                                      >
                                        <span class="flaticon-pencil"></span>
                                      </a>
                                      <a
                                        href=""
                                        class="icon"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        title="Delete"
                                      >
                                        <span class="flaticon-delete"></span>
                                      </a>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <th scope="row">
                                    <div class="freelancer-style1 box-shadow-none row m-0 p-0 align-items-lg-end">
                                      <div class="d-lg-flex px-0">
                                        <div class="details mb15-md-md">
                                          <h5 class="title mb10">
                                            English content writer for Fintech
                                          </h5>
                                          <p class="mb-0 fz14 list-inline-item mb5-sm pe-1">
                                            <i class="flaticon-place fz16 vam text-thm2 me-1"></i>{" "}
                                            London, UK
                                          </p>
                                          <p class="mb-0 fz14 list-inline-item mb5-sm pe-1">
                                            <i class="flaticon-30-days fz16 vam text-thm2 me-1 bdrl1 pl15 pl0-xs bdrn-xs"></i>{" "}
                                            2 hours ago
                                          </p>
                                          <p class="mb-0 fz14 list-inline-item mb5-sm text-thm">
                                            <i class="flaticon-contract fz16 vam me-1 bdrl1 pl15 pl0-xs bdrn-xs"></i>{" "}
                                            1 Received
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </th>
                                  <td class="vam">
                                    <span class="fz15 fw400">
                                      Web & App Design
                                    </span>
                                  </td>
                                  <td class="vam">
                                    <span class="fz14 fw400">
                                      $500.00/Fixed
                                    </span>
                                  </td>
                                  <td>
                                    <div class="d-flex">
                                      <a
                                        href=""
                                        class="icon me-2"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        title="Edit"
                                      >
                                        <span class="flaticon-pencil"></span>
                                      </a>
                                      <a
                                        href=""
                                        class="icon"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        title="Delete"
                                      >
                                        <span class="flaticon-delete"></span>
                                      </a>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <th scope="row">
                                    <div class="freelancer-style1 box-shadow-none row m-0 p-0 align-items-lg-end">
                                      <div class="d-lg-flex px-0">
                                        <div class="details mb15-md-md">
                                          <h5 class="title mb10">
                                            Full-stack Developer to help us to
                                            build our
                                          </h5>
                                          <p class="mb-0 fz14 list-inline-item mb5-sm pe-1">
                                            <i class="flaticon-place fz16 vam text-thm2 me-1"></i>{" "}
                                            London, UK
                                          </p>
                                          <p class="mb-0 fz14 list-inline-item mb5-sm pe-1">
                                            <i class="flaticon-30-days fz16 vam text-thm2 me-1 bdrl1 pl15 pl0-xs bdrn-xs"></i>{" "}
                                            2 hours ago
                                          </p>
                                          <p class="mb-0 fz14 list-inline-item mb5-sm text-thm">
                                            <i class="flaticon-contract fz16 vam me-1 bdrl1 pl15 pl0-xs bdrn-xs"></i>{" "}
                                            1 Received
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </th>
                                  <td class="vam">
                                    <span class="fz15 fw400">
                                      Web & App Design
                                    </span>
                                  </td>
                                  <td class="vam">
                                    <span class="fz14 fw400">
                                      $500.00/Fixed
                                    </span>
                                  </td>
                                  <td>
                                    <div class="d-flex">
                                      <a
                                        href=""
                                        class="icon me-2"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        title="Edit"
                                      >
                                        <span class="flaticon-pencil"></span>
                                      </a>
                                      <a
                                        href=""
                                        class="icon"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        title="Delete"
                                      >
                                        <span class="flaticon-delete"></span>
                                      </a>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <th scope="row">
                                    <div class="freelancer-style1 box-shadow-none row m-0 p-0 align-items-lg-end">
                                      <div class="d-lg-flex px-0">
                                        <div class="details mb15-md-md">
                                          <h5 class="title mb10">
                                            Looking for team members for web
                                            agency
                                          </h5>
                                          <p class="mb-0 fz14 list-inline-item mb5-sm pe-1">
                                            <i class="flaticon-place fz16 vam text-thm2 me-1"></i>{" "}
                                            London, UK
                                          </p>
                                          <p class="mb-0 fz14 list-inline-item mb5-sm pe-1">
                                            <i class="flaticon-30-days fz16 vam text-thm2 me-1 bdrl1 pl15 pl0-xs bdrn-xs"></i>{" "}
                                            2 hours ago
                                          </p>
                                          <p class="mb-0 fz14 list-inline-item mb5-sm text-thm">
                                            <i class="flaticon-contract fz16 vam me-1 bdrl1 pl15 pl0-xs bdrn-xs"></i>{" "}
                                            1 Received
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </th>
                                  <td class="vam">
                                    <span class="fz15 fw400">
                                      Web & App Design
                                    </span>
                                  </td>
                                  <td class="vam">
                                    <span class="fz14 fw400">
                                      $500.00/Fixed
                                    </span>
                                  </td>
                                  <td>
                                    <div class="d-flex">
                                      <a
                                        href=""
                                        class="icon me-2"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        title="Edit"
                                      >
                                        <span class="flaticon-pencil"></span>
                                      </a>
                                      <a
                                        href=""
                                        class="icon"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        title="Delete"
                                      >
                                        <span class="flaticon-delete"></span>
                                      </a>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <th scope="row">
                                    <div class="freelancer-style1 box-shadow-none row m-0 p-0 align-items-lg-end">
                                      <div class="d-lg-flex px-0">
                                        <div class="details mb15-md-md">
                                          <h5 class="title mb10">
                                            Video animator to bring some
                                            illustrations to life
                                          </h5>
                                          <p class="mb-0 fz14 list-inline-item mb5-sm pe-1">
                                            <i class="flaticon-place fz16 vam text-thm2 me-1"></i>{" "}
                                            London, UK
                                          </p>
                                          <p class="mb-0 fz14 list-inline-item mb5-sm pe-1">
                                            <i class="flaticon-30-days fz16 vam text-thm2 me-1 bdrl1 pl15 pl0-xs bdrn-xs"></i>{" "}
                                            2 hours ago
                                          </p>
                                          <p class="mb-0 fz14 list-inline-item mb5-sm text-thm">
                                            <i class="flaticon-contract fz16 vam me-1 bdrl1 pl15 pl0-xs bdrn-xs"></i>{" "}
                                            1 Received
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </th>
                                  <td class="vam">
                                    <span class="fz15 fw400">
                                      Web & App Design
                                    </span>
                                  </td>
                                  <td class="vam">
                                    <span class="fz14 fw400">
                                      $500.00/Fixed
                                    </span>
                                  </td>
                                  <td>
                                    <div class="d-flex">
                                      <a
                                        href=""
                                        class="icon me-2"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        title="Edit"
                                      >
                                        <span class="flaticon-pencil"></span>
                                      </a>
                                      <a
                                        href=""
                                        class="icon"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        title="Delete"
                                      >
                                        <span class="flaticon-delete"></span>
                                      </a>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <th scope="row">
                                    <div class="freelancer-style1 box-shadow-none row m-0 p-0 align-items-lg-end">
                                      <div class="d-lg-flex px-0">
                                        <div class="details mb15-md-md">
                                          <h5 class="title mb10">
                                            Food Delviery Mobile App
                                          </h5>
                                          <p class="mb-0 fz14 list-inline-item mb5-sm pe-1">
                                            <i class="flaticon-place fz16 vam text-thm2 me-1"></i>{" "}
                                            London, UK
                                          </p>
                                          <p class="mb-0 fz14 list-inline-item mb5-sm pe-1">
                                            <i class="flaticon-30-days fz16 vam text-thm2 me-1 bdrl1 pl15 pl0-xs bdrn-xs"></i>{" "}
                                            2 hours ago
                                          </p>
                                          <p class="mb-0 fz14 list-inline-item mb5-sm text-thm">
                                            <i class="flaticon-contract fz16 vam me-1 bdrl1 pl15 pl0-xs bdrn-xs"></i>{" "}
                                            1 Received
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </th>
                                  <td class="vam">
                                    <span class="fz15 fw400">
                                      Web & App Design
                                    </span>
                                  </td>
                                  <td class="vam">
                                    <span class="fz14 fw400">
                                      $500.00/Fixed
                                    </span>
                                  </td>
                                  <td>
                                    <div class="d-flex">
                                      <a
                                        href=""
                                        class="icon me-2"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        title="Edit"
                                      >
                                        <span class="flaticon-pencil"></span>
                                      </a>
                                      <a
                                        href=""
                                        class="icon"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        title="Delete"
                                      >
                                        <span class="flaticon-delete"></span>
                                      </a>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            <div class="mbp_pagination text-center mt30">
                              <ul class="page_navigation">
                                <li class="page-item">
                                  <a class="page-link" href="#">
                                    {" "}
                                    <span class="fas fa-angle-left"></span>
                                  </a>
                                </li>
                                <li class="page-item">
                                  <a class="page-link" href="#">
                                    1
                                  </a>
                                </li>
                                <li
                                  class="page-item active"
                                  aria-current="page"
                                >
                                  <a class="page-link" href="#">
                                    2 <span class="sr-only">(current)</span>
                                  </a>
                                </li>
                                <li class="page-item">
                                  <a class="page-link" href="#">
                                    3
                                  </a>
                                </li>
                                <li class="page-item">
                                  <a class="page-link" href="#">
                                    4
                                  </a>
                                </li>
                                <li class="page-item">
                                  <a class="page-link" href="#">
                                    5
                                  </a>
                                </li>
                                <li class="page-item">
                                  <a class="page-link" href="#">
                                    ...
                                  </a>
                                </li>
                                <li class="page-item">
                                  <a class="page-link" href="#">
                                    20
                                  </a>
                                </li>
                                <li class="page-item">
                                  <a class="page-link" href="#">
                                    <span class="fas fa-angle-right"></span>
                                  </a>
                                </li>
                              </ul>
                              <p class="mt10 mb-0 pagination_page_count text-center">
                                1 – 20 of 300+ property available
                              </p>
                            </div>
                          </div>
                        </div>
                        <div
                          class="tab-pane fade"
                          id="nav-item3"
                          role="tabpanel"
                          aria-labelledby="nav-item3-tab"
                        >
                          <div class="packages_table table-responsive">
                            <table class="table-style3 table at-savesearch">
                              <thead class="t-head">
                                <tr>
                                  <th scope="col">Title</th>
                                  <th scope="col">Category</th>
                                  <th scope="col">Type/Cost</th>
                                  <th scope="col">Actions</th>
                                </tr>
                              </thead>
                              <tbody class="t-body">
                                <tr>
                                  <th scope="row">
                                    <div class="freelancer-style1 box-shadow-none row m-0 p-0 align-items-lg-end">
                                      <div class="d-lg-flex px-0">
                                        <div class="details mb15-md-md">
                                          <h5 class="title mb10">
                                            Food Delviery Mobile App
                                          </h5>
                                          <p class="mb-0 fz14 list-inline-item mb5-sm pe-1">
                                            <i class="flaticon-place fz16 vam text-thm2 me-1"></i>{" "}
                                            London, UK
                                          </p>
                                          <p class="mb-0 fz14 list-inline-item mb5-sm pe-1">
                                            <i class="flaticon-30-days fz16 vam text-thm2 me-1 bdrl1 pl15 pl0-xs bdrn-xs"></i>{" "}
                                            2 hours ago
                                          </p>
                                          <p class="mb-0 fz14 list-inline-item mb5-sm text-thm">
                                            <i class="flaticon-contract fz16 vam me-1 bdrl1 pl15 pl0-xs bdrn-xs"></i>{" "}
                                            1 Received
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </th>
                                  <td class="vam">
                                    <span class="fz15 fw400">
                                      Web & App Design
                                    </span>
                                  </td>
                                  <td class="vam">
                                    <span class="fz14 fw400">
                                      $500.00/Fixed
                                    </span>
                                  </td>
                                  <td>
                                    <div class="d-flex">
                                      <a
                                        href=""
                                        class="icon me-2"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        title="Edit"
                                      >
                                        <span class="flaticon-pencil"></span>
                                      </a>
                                      <a
                                        href=""
                                        class="icon"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        title="Delete"
                                      >
                                        <span class="flaticon-delete"></span>
                                      </a>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <th scope="row">
                                    <div class="freelancer-style1 box-shadow-none row m-0 p-0 align-items-lg-end">
                                      <div class="d-lg-flex px-0">
                                        <div class="details mb15-md-md">
                                          <h5 class="title mb10">
                                            Swift / SwiftUI Developer for B2B
                                            iOS apps
                                          </h5>
                                          <p class="mb-0 fz14 list-inline-item mb5-sm pe-1">
                                            <i class="flaticon-place fz16 vam text-thm2 me-1"></i>{" "}
                                            London, UK
                                          </p>
                                          <p class="mb-0 fz14 list-inline-item mb5-sm pe-1">
                                            <i class="flaticon-30-days fz16 vam text-thm2 me-1 bdrl1 pl15 pl0-xs bdrn-xs"></i>{" "}
                                            2 hours ago
                                          </p>
                                          <p class="mb-0 fz14 list-inline-item mb5-sm text-thm">
                                            <i class="flaticon-contract fz16 vam me-1 bdrl1 pl15 pl0-xs bdrn-xs"></i>{" "}
                                            1 Received
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </th>
                                  <td class="vam">
                                    <span class="fz15 fw400">
                                      Web & App Design
                                    </span>
                                  </td>
                                  <td class="vam">
                                    <span class="fz14 fw400">
                                      $500.00/Fixed
                                    </span>
                                  </td>
                                  <td>
                                    <div class="d-flex">
                                      <a
                                        href=""
                                        class="icon me-2"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        title="Edit"
                                      >
                                        <span class="flaticon-pencil"></span>
                                      </a>
                                      <a
                                        href=""
                                        class="icon"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        title="Delete"
                                      >
                                        <span class="flaticon-delete"></span>
                                      </a>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <th scope="row">
                                    <div class="freelancer-style1 box-shadow-none row m-0 p-0 align-items-lg-end">
                                      <div class="d-lg-flex px-0">
                                        <div class="details mb15-md-md">
                                          <h5 class="title mb10">
                                            English content writer for Fintech
                                          </h5>
                                          <p class="mb-0 fz14 list-inline-item mb5-sm pe-1">
                                            <i class="flaticon-place fz16 vam text-thm2 me-1"></i>{" "}
                                            London, UK
                                          </p>
                                          <p class="mb-0 fz14 list-inline-item mb5-sm pe-1">
                                            <i class="flaticon-30-days fz16 vam text-thm2 me-1 bdrl1 pl15 pl0-xs bdrn-xs"></i>{" "}
                                            2 hours ago
                                          </p>
                                          <p class="mb-0 fz14 list-inline-item mb5-sm text-thm">
                                            <i class="flaticon-contract fz16 vam me-1 bdrl1 pl15 pl0-xs bdrn-xs"></i>{" "}
                                            1 Received
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </th>
                                  <td class="vam">
                                    <span class="fz15 fw400">
                                      Web & App Design
                                    </span>
                                  </td>
                                  <td class="vam">
                                    <span class="fz14 fw400">
                                      $500.00/Fixed
                                    </span>
                                  </td>
                                  <td>
                                    <div class="d-flex">
                                      <a
                                        href=""
                                        class="icon me-2"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        title="Edit"
                                      >
                                        <span class="flaticon-pencil"></span>
                                      </a>
                                      <a
                                        href=""
                                        class="icon"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        title="Delete"
                                      >
                                        <span class="flaticon-delete"></span>
                                      </a>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <th scope="row">
                                    <div class="freelancer-style1 box-shadow-none row m-0 p-0 align-items-lg-end">
                                      <div class="d-lg-flex px-0">
                                        <div class="details mb15-md-md">
                                          <h5 class="title mb10">
                                            Full-stack Developer to help us to
                                            build our
                                          </h5>
                                          <p class="mb-0 fz14 list-inline-item mb5-sm pe-1">
                                            <i class="flaticon-place fz16 vam text-thm2 me-1"></i>{" "}
                                            London, UK
                                          </p>
                                          <p class="mb-0 fz14 list-inline-item mb5-sm pe-1">
                                            <i class="flaticon-30-days fz16 vam text-thm2 me-1 bdrl1 pl15 pl0-xs bdrn-xs"></i>{" "}
                                            2 hours ago
                                          </p>
                                          <p class="mb-0 fz14 list-inline-item mb5-sm text-thm">
                                            <i class="flaticon-contract fz16 vam me-1 bdrl1 pl15 pl0-xs bdrn-xs"></i>{" "}
                                            1 Received
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </th>
                                  <td class="vam">
                                    <span class="fz15 fw400">
                                      Web & App Design
                                    </span>
                                  </td>
                                  <td class="vam">
                                    <span class="fz14 fw400">
                                      $500.00/Fixed
                                    </span>
                                  </td>
                                  <td>
                                    <div class="d-flex">
                                      <a
                                        href=""
                                        class="icon me-2"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        title="Edit"
                                      >
                                        <span class="flaticon-pencil"></span>
                                      </a>
                                      <a
                                        href=""
                                        class="icon"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        title="Delete"
                                      >
                                        <span class="flaticon-delete"></span>
                                      </a>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <th scope="row">
                                    <div class="freelancer-style1 box-shadow-none row m-0 p-0 align-items-lg-end">
                                      <div class="d-lg-flex px-0">
                                        <div class="details mb15-md-md">
                                          <h5 class="title mb10">
                                            Looking for team members for web
                                            agency
                                          </h5>
                                          <p class="mb-0 fz14 list-inline-item mb5-sm pe-1">
                                            <i class="flaticon-place fz16 vam text-thm2 me-1"></i>{" "}
                                            London, UK
                                          </p>
                                          <p class="mb-0 fz14 list-inline-item mb5-sm pe-1">
                                            <i class="flaticon-30-days fz16 vam text-thm2 me-1 bdrl1 pl15 pl0-xs bdrn-xs"></i>{" "}
                                            2 hours ago
                                          </p>
                                          <p class="mb-0 fz14 list-inline-item mb5-sm text-thm">
                                            <i class="flaticon-contract fz16 vam me-1 bdrl1 pl15 pl0-xs bdrn-xs"></i>{" "}
                                            1 Received
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </th>
                                  <td class="vam">
                                    <span class="fz15 fw400">
                                      Web & App Design
                                    </span>
                                  </td>
                                  <td class="vam">
                                    <span class="fz14 fw400">
                                      $500.00/Fixed
                                    </span>
                                  </td>
                                  <td>
                                    <div class="d-flex">
                                      <a
                                        href=""
                                        class="icon me-2"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        title="Edit"
                                      >
                                        <span class="flaticon-pencil"></span>
                                      </a>
                                      <a
                                        href=""
                                        class="icon"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        title="Delete"
                                      >
                                        <span class="flaticon-delete"></span>
                                      </a>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <th scope="row">
                                    <div class="freelancer-style1 box-shadow-none row m-0 p-0 align-items-lg-end">
                                      <div class="d-lg-flex px-0">
                                        <div class="details mb15-md-md">
                                          <h5 class="title mb10">
                                            Video animator to bring some
                                            illustrations to life
                                          </h5>
                                          <p class="mb-0 fz14 list-inline-item mb5-sm pe-1">
                                            <i class="flaticon-place fz16 vam text-thm2 me-1"></i>{" "}
                                            London, UK
                                          </p>
                                          <p class="mb-0 fz14 list-inline-item mb5-sm pe-1">
                                            <i class="flaticon-30-days fz16 vam text-thm2 me-1 bdrl1 pl15 pl0-xs bdrn-xs"></i>{" "}
                                            2 hours ago
                                          </p>
                                          <p class="mb-0 fz14 list-inline-item mb5-sm text-thm">
                                            <i class="flaticon-contract fz16 vam me-1 bdrl1 pl15 pl0-xs bdrn-xs"></i>{" "}
                                            1 Received
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </th>
                                  <td class="vam">
                                    <span class="fz15 fw400">
                                      Web & App Design
                                    </span>
                                  </td>
                                  <td class="vam">
                                    <span class="fz14 fw400">
                                      $500.00/Fixed
                                    </span>
                                  </td>
                                  <td>
                                    <div class="d-flex">
                                      <a
                                        href=""
                                        class="icon me-2"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        title="Edit"
                                      >
                                        <span class="flaticon-pencil"></span>
                                      </a>
                                      <a
                                        href=""
                                        class="icon"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        title="Delete"
                                      >
                                        <span class="flaticon-delete"></span>
                                      </a>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <th scope="row">
                                    <div class="freelancer-style1 box-shadow-none row m-0 p-0 align-items-lg-end">
                                      <div class="d-lg-flex px-0">
                                        <div class="details mb15-md-md">
                                          <h5 class="title mb10">
                                            Food Delviery Mobile App
                                          </h5>
                                          <p class="mb-0 fz14 list-inline-item mb5-sm pe-1">
                                            <i class="flaticon-place fz16 vam text-thm2 me-1"></i>{" "}
                                            London, UK
                                          </p>
                                          <p class="mb-0 fz14 list-inline-item mb5-sm pe-1">
                                            <i class="flaticon-30-days fz16 vam text-thm2 me-1 bdrl1 pl15 pl0-xs bdrn-xs"></i>{" "}
                                            2 hours ago
                                          </p>
                                          <p class="mb-0 fz14 list-inline-item mb5-sm text-thm">
                                            <i class="flaticon-contract fz16 vam me-1 bdrl1 pl15 pl0-xs bdrn-xs"></i>{" "}
                                            1 Received
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </th>
                                  <td class="vam">
                                    <span class="fz15 fw400">
                                      Web & App Design
                                    </span>
                                  </td>
                                  <td class="vam">
                                    <span class="fz14 fw400">
                                      $500.00/Fixed
                                    </span>
                                  </td>
                                  <td>
                                    <div class="d-flex">
                                      <a
                                        href=""
                                        class="icon me-2"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        title="Edit"
                                      >
                                        <span class="flaticon-pencil"></span>
                                      </a>
                                      <a
                                        href=""
                                        class="icon"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        title="Delete"
                                      >
                                        <span class="flaticon-delete"></span>
                                      </a>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            <div class="mbp_pagination text-center mt30">
                              <ul class="page_navigation">
                                <li class="page-item">
                                  <a class="page-link" href="#">
                                    {" "}
                                    <span class="fas fa-angle-left"></span>
                                  </a>
                                </li>
                                <li class="page-item">
                                  <a class="page-link" href="#">
                                    1
                                  </a>
                                </li>
                                <li
                                  class="page-item active"
                                  aria-current="page"
                                >
                                  <a class="page-link" href="#">
                                    2 <span class="sr-only">(current)</span>
                                  </a>
                                </li>
                                <li class="page-item">
                                  <a class="page-link" href="#">
                                    3
                                  </a>
                                </li>
                                <li class="page-item">
                                  <a class="page-link" href="#">
                                    4
                                  </a>
                                </li>
                                <li class="page-item">
                                  <a class="page-link" href="#">
                                    5
                                  </a>
                                </li>
                                <li class="page-item">
                                  <a class="page-link" href="#">
                                    ...
                                  </a>
                                </li>
                                <li class="page-item">
                                  <a class="page-link" href="#">
                                    20
                                  </a>
                                </li>
                                <li class="page-item">
                                  <a class="page-link" href="#">
                                    <span class="fas fa-angle-right"></span>
                                  </a>
                                </li>
                              </ul>
                              <p class="mt10 mb-0 pagination_page_count text-center">
                                1 – 20 of 300+ property available
                              </p>
                            </div>
                          </div>
                        </div>
                        <div
                          class="tab-pane fade"
                          id="nav-item4"
                          role="tabpanel"
                          aria-labelledby="nav-item4-tab"
                        >
                          <div class="packages_table table-responsive">
                            <table class="table-style3 table at-savesearch">
                              <thead class="t-head">
                                <tr>
                                  <th scope="col">Title</th>
                                  <th scope="col">Category</th>
                                  <th scope="col">Type/Cost</th>
                                  <th scope="col">Actions</th>
                                </tr>
                              </thead>
                              <tbody class="t-body">
                                <tr>
                                  <th scope="row">
                                    <div class="freelancer-style1 box-shadow-none row m-0 p-0 align-items-lg-end">
                                      <div class="d-lg-flex px-0">
                                        <div class="details mb15-md-md">
                                          <h5 class="title mb10">
                                            Food Delviery Mobile App
                                          </h5>
                                          <p class="mb-0 fz14 list-inline-item mb5-sm pe-1">
                                            <i class="flaticon-place fz16 vam text-thm2 me-1"></i>{" "}
                                            London, UK
                                          </p>
                                          <p class="mb-0 fz14 list-inline-item mb5-sm pe-1">
                                            <i class="flaticon-30-days fz16 vam text-thm2 me-1 bdrl1 pl15 pl0-xs bdrn-xs"></i>{" "}
                                            2 hours ago
                                          </p>
                                          <p class="mb-0 fz14 list-inline-item mb5-sm text-thm">
                                            <i class="flaticon-contract fz16 vam me-1 bdrl1 pl15 pl0-xs bdrn-xs"></i>{" "}
                                            1 Received
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </th>
                                  <td class="vam">
                                    <span class="fz15 fw400">
                                      Web & App Design
                                    </span>
                                  </td>
                                  <td class="vam">
                                    <span class="fz14 fw400">
                                      $500.00/Fixed
                                    </span>
                                  </td>
                                  <td>
                                    <div class="d-flex">
                                      <a
                                        href=""
                                        class="icon me-2"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        title="Edit"
                                      >
                                        <span class="flaticon-pencil"></span>
                                      </a>
                                      <a
                                        href=""
                                        class="icon"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        title="Delete"
                                      >
                                        <span class="flaticon-delete"></span>
                                      </a>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <th scope="row">
                                    <div class="freelancer-style1 box-shadow-none row m-0 p-0 align-items-lg-end">
                                      <div class="d-lg-flex px-0">
                                        <div class="details mb15-md-md">
                                          <h5 class="title mb10">
                                            Swift / SwiftUI Developer for B2B
                                            iOS apps
                                          </h5>
                                          <p class="mb-0 fz14 list-inline-item mb5-sm pe-1">
                                            <i class="flaticon-place fz16 vam text-thm2 me-1"></i>{" "}
                                            London, UK
                                          </p>
                                          <p class="mb-0 fz14 list-inline-item mb5-sm pe-1">
                                            <i class="flaticon-30-days fz16 vam text-thm2 me-1 bdrl1 pl15 pl0-xs bdrn-xs"></i>{" "}
                                            2 hours ago
                                          </p>
                                          <p class="mb-0 fz14 list-inline-item mb5-sm text-thm">
                                            <i class="flaticon-contract fz16 vam me-1 bdrl1 pl15 pl0-xs bdrn-xs"></i>{" "}
                                            1 Received
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </th>
                                  <td class="vam">
                                    <span class="fz15 fw400">
                                      Web & App Design
                                    </span>
                                  </td>
                                  <td class="vam">
                                    <span class="fz14 fw400">
                                      $500.00/Fixed
                                    </span>
                                  </td>
                                  <td>
                                    <div class="d-flex">
                                      <a
                                        href=""
                                        class="icon me-2"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        title="Edit"
                                      >
                                        <span class="flaticon-pencil"></span>
                                      </a>
                                      <a
                                        href=""
                                        class="icon"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        title="Delete"
                                      >
                                        <span class="flaticon-delete"></span>
                                      </a>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <th scope="row">
                                    <div class="freelancer-style1 box-shadow-none row m-0 p-0 align-items-lg-end">
                                      <div class="d-lg-flex px-0">
                                        <div class="details mb15-md-md">
                                          <h5 class="title mb10">
                                            English content writer for Fintech
                                          </h5>
                                          <p class="mb-0 fz14 list-inline-item mb5-sm pe-1">
                                            <i class="flaticon-place fz16 vam text-thm2 me-1"></i>{" "}
                                            London, UK
                                          </p>
                                          <p class="mb-0 fz14 list-inline-item mb5-sm pe-1">
                                            <i class="flaticon-30-days fz16 vam text-thm2 me-1 bdrl1 pl15 pl0-xs bdrn-xs"></i>{" "}
                                            2 hours ago
                                          </p>
                                          <p class="mb-0 fz14 list-inline-item mb5-sm text-thm">
                                            <i class="flaticon-contract fz16 vam me-1 bdrl1 pl15 pl0-xs bdrn-xs"></i>{" "}
                                            1 Received
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </th>
                                  <td class="vam">
                                    <span class="fz15 fw400">
                                      Web & App Design
                                    </span>
                                  </td>
                                  <td class="vam">
                                    <span class="fz14 fw400">
                                      $500.00/Fixed
                                    </span>
                                  </td>
                                  <td>
                                    <div class="d-flex">
                                      <a
                                        href=""
                                        class="icon me-2"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        title="Edit"
                                      >
                                        <span class="flaticon-pencil"></span>
                                      </a>
                                      <a
                                        href=""
                                        class="icon"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        title="Delete"
                                      >
                                        <span class="flaticon-delete"></span>
                                      </a>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <th scope="row">
                                    <div class="freelancer-style1 box-shadow-none row m-0 p-0 align-items-lg-end">
                                      <div class="d-lg-flex px-0">
                                        <div class="details mb15-md-md">
                                          <h5 class="title mb10">
                                            Full-stack Developer to help us to
                                            build our
                                          </h5>
                                          <p class="mb-0 fz14 list-inline-item mb5-sm pe-1">
                                            <i class="flaticon-place fz16 vam text-thm2 me-1"></i>{" "}
                                            London, UK
                                          </p>
                                          <p class="mb-0 fz14 list-inline-item mb5-sm pe-1">
                                            <i class="flaticon-30-days fz16 vam text-thm2 me-1 bdrl1 pl15 pl0-xs bdrn-xs"></i>{" "}
                                            2 hours ago
                                          </p>
                                          <p class="mb-0 fz14 list-inline-item mb5-sm text-thm">
                                            <i class="flaticon-contract fz16 vam me-1 bdrl1 pl15 pl0-xs bdrn-xs"></i>{" "}
                                            1 Received
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </th>
                                  <td class="vam">
                                    <span class="fz15 fw400">
                                      Web & App Design
                                    </span>
                                  </td>
                                  <td class="vam">
                                    <span class="fz14 fw400">
                                      $500.00/Fixed
                                    </span>
                                  </td>
                                  <td>
                                    <div class="d-flex">
                                      <a
                                        href=""
                                        class="icon me-2"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        title="Edit"
                                      >
                                        <span class="flaticon-pencil"></span>
                                      </a>
                                      <a
                                        href=""
                                        class="icon"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        title="Delete"
                                      >
                                        <span class="flaticon-delete"></span>
                                      </a>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <th scope="row">
                                    <div class="freelancer-style1 box-shadow-none row m-0 p-0 align-items-lg-end">
                                      <div class="d-lg-flex px-0">
                                        <div class="details mb15-md-md">
                                          <h5 class="title mb10">
                                            Looking for team members for web
                                            agency
                                          </h5>
                                          <p class="mb-0 fz14 list-inline-item mb5-sm pe-1">
                                            <i class="flaticon-place fz16 vam text-thm2 me-1"></i>{" "}
                                            London, UK
                                          </p>
                                          <p class="mb-0 fz14 list-inline-item mb5-sm pe-1">
                                            <i class="flaticon-30-days fz16 vam text-thm2 me-1 bdrl1 pl15 pl0-xs bdrn-xs"></i>{" "}
                                            2 hours ago
                                          </p>
                                          <p class="mb-0 fz14 list-inline-item mb5-sm text-thm">
                                            <i class="flaticon-contract fz16 vam me-1 bdrl1 pl15 pl0-xs bdrn-xs"></i>{" "}
                                            1 Received
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </th>
                                  <td class="vam">
                                    <span class="fz15 fw400">
                                      Web & App Design
                                    </span>
                                  </td>
                                  <td class="vam">
                                    <span class="fz14 fw400">
                                      $500.00/Fixed
                                    </span>
                                  </td>
                                  <td>
                                    <div class="d-flex">
                                      <a
                                        href=""
                                        class="icon me-2"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        title="Edit"
                                      >
                                        <span class="flaticon-pencil"></span>
                                      </a>
                                      <a
                                        href=""
                                        class="icon"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        title="Delete"
                                      >
                                        <span class="flaticon-delete"></span>
                                      </a>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <th scope="row">
                                    <div class="freelancer-style1 box-shadow-none row m-0 p-0 align-items-lg-end">
                                      <div class="d-lg-flex px-0">
                                        <div class="details mb15-md-md">
                                          <h5 class="title mb10">
                                            Video animator to bring some
                                            illustrations to life
                                          </h5>
                                          <p class="mb-0 fz14 list-inline-item mb5-sm pe-1">
                                            <i class="flaticon-place fz16 vam text-thm2 me-1"></i>{" "}
                                            London, UK
                                          </p>
                                          <p class="mb-0 fz14 list-inline-item mb5-sm pe-1">
                                            <i class="flaticon-30-days fz16 vam text-thm2 me-1 bdrl1 pl15 pl0-xs bdrn-xs"></i>{" "}
                                            2 hours ago
                                          </p>
                                          <p class="mb-0 fz14 list-inline-item mb5-sm text-thm">
                                            <i class="flaticon-contract fz16 vam me-1 bdrl1 pl15 pl0-xs bdrn-xs"></i>{" "}
                                            1 Received
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </th>
                                  <td class="vam">
                                    <span class="fz15 fw400">
                                      Web & App Design
                                    </span>
                                  </td>
                                  <td class="vam">
                                    <span class="fz14 fw400">
                                      $500.00/Fixed
                                    </span>
                                  </td>
                                  <td>
                                    <div class="d-flex">
                                      <a
                                        href=""
                                        class="icon me-2"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        title="Edit"
                                      >
                                        <span class="flaticon-pencil"></span>
                                      </a>
                                      <a
                                        href=""
                                        class="icon"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        title="Delete"
                                      >
                                        <span class="flaticon-delete"></span>
                                      </a>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <th scope="row">
                                    <div class="freelancer-style1 box-shadow-none row m-0 p-0 align-items-lg-end">
                                      <div class="d-lg-flex px-0">
                                        <div class="details mb15-md-md">
                                          <h5 class="title mb10">
                                            Food Delviery Mobile App
                                          </h5>
                                          <p class="mb-0 fz14 list-inline-item mb5-sm pe-1">
                                            <i class="flaticon-place fz16 vam text-thm2 me-1"></i>{" "}
                                            London, UK
                                          </p>
                                          <p class="mb-0 fz14 list-inline-item mb5-sm pe-1">
                                            <i class="flaticon-30-days fz16 vam text-thm2 me-1 bdrl1 pl15 pl0-xs bdrn-xs"></i>{" "}
                                            2 hours ago
                                          </p>
                                          <p class="mb-0 fz14 list-inline-item mb5-sm text-thm">
                                            <i class="flaticon-contract fz16 vam me-1 bdrl1 pl15 pl0-xs bdrn-xs"></i>{" "}
                                            1 Received
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </th>
                                  <td class="vam">
                                    <span class="fz15 fw400">
                                      Web & App Design
                                    </span>
                                  </td>
                                  <td class="vam">
                                    <span class="fz14 fw400">
                                      $500.00/Fixed
                                    </span>
                                  </td>
                                  <td>
                                    <div class="d-flex">
                                      <a
                                        href=""
                                        class="icon me-2"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        title="Edit"
                                      >
                                        <span class="flaticon-pencil"></span>
                                      </a>
                                      <a
                                        href=""
                                        class="icon"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        title="Delete"
                                      >
                                        <span class="flaticon-delete"></span>
                                      </a>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            <div class="mbp_pagination text-center mt30">
                              <ul class="page_navigation">
                                <li class="page-item">
                                  <a class="page-link" href="#">
                                    {" "}
                                    <span class="fas fa-angle-left"></span>
                                  </a>
                                </li>
                                <li class="page-item">
                                  <a class="page-link" href="#">
                                    1
                                  </a>
                                </li>
                                <li
                                  class="page-item active"
                                  aria-current="page"
                                >
                                  <a class="page-link" href="#">
                                    2 <span class="sr-only">(current)</span>
                                  </a>
                                </li>
                                <li class="page-item">
                                  <a class="page-link" href="#">
                                    3
                                  </a>
                                </li>
                                <li class="page-item">
                                  <a class="page-link" href="#">
                                    4
                                  </a>
                                </li>
                                <li class="page-item">
                                  <a class="page-link" href="#">
                                    5
                                  </a>
                                </li>
                                <li class="page-item">
                                  <a class="page-link" href="#">
                                    ...
                                  </a>
                                </li>
                                <li class="page-item">
                                  <a class="page-link" href="#">
                                    20
                                  </a>
                                </li>
                                <li class="page-item">
                                  <a class="page-link" href="#">
                                    <span class="fas fa-angle-right"></span>
                                  </a>
                                </li>
                              </ul>
                              <p class="mt10 mb-0 pagination_page_count text-center">
                                1 – 20 of 300+ property available
                              </p>
                            </div>
                          </div>
                        </div>
                        <div
                          class="tab-pane fade"
                          id="nav-item5"
                          role="tabpanel"
                          aria-labelledby="nav-item5-tab"
                        >
                          <div class="packages_table table-responsive">
                            <table class="table-style3 table at-savesearch">
                              <thead class="t-head">
                                <tr>
                                  <th scope="col">Title</th>
                                  <th scope="col">Category</th>
                                  <th scope="col">Type/Cost</th>
                                  <th scope="col">Actions</th>
                                </tr>
                              </thead>
                              <tbody class="t-body">
                                <tr>
                                  <th scope="row">
                                    <div class="freelancer-style1 box-shadow-none row m-0 p-0 align-items-lg-end">
                                      <div class="d-lg-flex px-0">
                                        <div class="details mb15-md-md">
                                          <h5 class="title mb10">
                                            Food Delviery Mobile App
                                          </h5>
                                          <p class="mb-0 fz14 list-inline-item mb5-sm pe-1">
                                            <i class="flaticon-place fz16 vam text-thm2 me-1"></i>{" "}
                                            London, UK
                                          </p>
                                          <p class="mb-0 fz14 list-inline-item mb5-sm pe-1">
                                            <i class="flaticon-30-days fz16 vam text-thm2 me-1 bdrl1 pl15 pl0-xs bdrn-xs"></i>{" "}
                                            2 hours ago
                                          </p>
                                          <p class="mb-0 fz14 list-inline-item mb5-sm text-thm">
                                            <i class="flaticon-contract fz16 vam me-1 bdrl1 pl15 pl0-xs bdrn-xs"></i>{" "}
                                            1 Received
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </th>
                                  <td class="vam">
                                    <span class="fz15 fw400">
                                      Web & App Design
                                    </span>
                                  </td>
                                  <td class="vam">
                                    <span class="fz14 fw400">
                                      $500.00/Fixed
                                    </span>
                                  </td>
                                  <td>
                                    <div class="d-flex">
                                      <a
                                        href=""
                                        class="icon me-2"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        title="Edit"
                                      >
                                        <span class="flaticon-pencil"></span>
                                      </a>
                                      <a
                                        href=""
                                        class="icon"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        title="Delete"
                                      >
                                        <span class="flaticon-delete"></span>
                                      </a>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <th scope="row">
                                    <div class="freelancer-style1 box-shadow-none row m-0 p-0 align-items-lg-end">
                                      <div class="d-lg-flex px-0">
                                        <div class="details mb15-md-md">
                                          <h5 class="title mb10">
                                            Swift / SwiftUI Developer for B2B
                                            iOS apps
                                          </h5>
                                          <p class="mb-0 fz14 list-inline-item mb5-sm pe-1">
                                            <i class="flaticon-place fz16 vam text-thm2 me-1"></i>{" "}
                                            London, UK
                                          </p>
                                          <p class="mb-0 fz14 list-inline-item mb5-sm pe-1">
                                            <i class="flaticon-30-days fz16 vam text-thm2 me-1 bdrl1 pl15 pl0-xs bdrn-xs"></i>{" "}
                                            2 hours ago
                                          </p>
                                          <p class="mb-0 fz14 list-inline-item mb5-sm text-thm">
                                            <i class="flaticon-contract fz16 vam me-1 bdrl1 pl15 pl0-xs bdrn-xs"></i>{" "}
                                            1 Received
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </th>
                                  <td class="vam">
                                    <span class="fz15 fw400">
                                      Web & App Design
                                    </span>
                                  </td>
                                  <td class="vam">
                                    <span class="fz14 fw400">
                                      $500.00/Fixed
                                    </span>
                                  </td>
                                  <td>
                                    <div class="d-flex">
                                      <a
                                        href=""
                                        class="icon me-2"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        title="Edit"
                                      >
                                        <span class="flaticon-pencil"></span>
                                      </a>
                                      <a
                                        href=""
                                        class="icon"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        title="Delete"
                                      >
                                        <span class="flaticon-delete"></span>
                                      </a>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <th scope="row">
                                    <div class="freelancer-style1 box-shadow-none row m-0 p-0 align-items-lg-end">
                                      <div class="d-lg-flex px-0">
                                        <div class="details mb15-md-md">
                                          <h5 class="title mb10">
                                            English content writer for Fintech
                                          </h5>
                                          <p class="mb-0 fz14 list-inline-item mb5-sm pe-1">
                                            <i class="flaticon-place fz16 vam text-thm2 me-1"></i>{" "}
                                            London, UK
                                          </p>
                                          <p class="mb-0 fz14 list-inline-item mb5-sm pe-1">
                                            <i class="flaticon-30-days fz16 vam text-thm2 me-1 bdrl1 pl15 pl0-xs bdrn-xs"></i>{" "}
                                            2 hours ago
                                          </p>
                                          <p class="mb-0 fz14 list-inline-item mb5-sm text-thm">
                                            <i class="flaticon-contract fz16 vam me-1 bdrl1 pl15 pl0-xs bdrn-xs"></i>{" "}
                                            1 Received
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </th>
                                  <td class="vam">
                                    <span class="fz15 fw400">
                                      Web & App Design
                                    </span>
                                  </td>
                                  <td class="vam">
                                    <span class="fz14 fw400">
                                      $500.00/Fixed
                                    </span>
                                  </td>
                                  <td>
                                    <div class="d-flex">
                                      <a
                                        href=""
                                        class="icon me-2"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        title="Edit"
                                      >
                                        <span class="flaticon-pencil"></span>
                                      </a>
                                      <a
                                        href=""
                                        class="icon"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        title="Delete"
                                      >
                                        <span class="flaticon-delete"></span>
                                      </a>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <th scope="row">
                                    <div class="freelancer-style1 box-shadow-none row m-0 p-0 align-items-lg-end">
                                      <div class="d-lg-flex px-0">
                                        <div class="details mb15-md-md">
                                          <h5 class="title mb10">
                                            Full-stack Developer to help us to
                                            build our
                                          </h5>
                                          <p class="mb-0 fz14 list-inline-item mb5-sm pe-1">
                                            <i class="flaticon-place fz16 vam text-thm2 me-1"></i>{" "}
                                            London, UK
                                          </p>
                                          <p class="mb-0 fz14 list-inline-item mb5-sm pe-1">
                                            <i class="flaticon-30-days fz16 vam text-thm2 me-1 bdrl1 pl15 pl0-xs bdrn-xs"></i>{" "}
                                            2 hours ago
                                          </p>
                                          <p class="mb-0 fz14 list-inline-item mb5-sm text-thm">
                                            <i class="flaticon-contract fz16 vam me-1 bdrl1 pl15 pl0-xs bdrn-xs"></i>{" "}
                                            1 Received
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </th>
                                  <td class="vam">
                                    <span class="fz15 fw400">
                                      Web & App Design
                                    </span>
                                  </td>
                                  <td class="vam">
                                    <span class="fz14 fw400">
                                      $500.00/Fixed
                                    </span>
                                  </td>
                                  <td>
                                    <div class="d-flex">
                                      <a
                                        href=""
                                        class="icon me-2"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        title="Edit"
                                      >
                                        <span class="flaticon-pencil"></span>
                                      </a>
                                      <a
                                        href=""
                                        class="icon"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        title="Delete"
                                      >
                                        <span class="flaticon-delete"></span>
                                      </a>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <th scope="row">
                                    <div class="freelancer-style1 box-shadow-none row m-0 p-0 align-items-lg-end">
                                      <div class="d-lg-flex px-0">
                                        <div class="details mb15-md-md">
                                          <h5 class="title mb10">
                                            Looking for team members for web
                                            agency
                                          </h5>
                                          <p class="mb-0 fz14 list-inline-item mb5-sm pe-1">
                                            <i class="flaticon-place fz16 vam text-thm2 me-1"></i>{" "}
                                            London, UK
                                          </p>
                                          <p class="mb-0 fz14 list-inline-item mb5-sm pe-1">
                                            <i class="flaticon-30-days fz16 vam text-thm2 me-1 bdrl1 pl15 pl0-xs bdrn-xs"></i>{" "}
                                            2 hours ago
                                          </p>
                                          <p class="mb-0 fz14 list-inline-item mb5-sm text-thm">
                                            <i class="flaticon-contract fz16 vam me-1 bdrl1 pl15 pl0-xs bdrn-xs"></i>{" "}
                                            1 Received
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </th>
                                  <td class="vam">
                                    <span class="fz15 fw400">
                                      Web & App Design
                                    </span>
                                  </td>
                                  <td class="vam">
                                    <span class="fz14 fw400">
                                      $500.00/Fixed
                                    </span>
                                  </td>
                                  <td>
                                    <div class="d-flex">
                                      <a
                                        href=""
                                        class="icon me-2"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        title="Edit"
                                      >
                                        <span class="flaticon-pencil"></span>
                                      </a>
                                      <a
                                        href=""
                                        class="icon"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        title="Delete"
                                      >
                                        <span class="flaticon-delete"></span>
                                      </a>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <th scope="row">
                                    <div class="freelancer-style1 box-shadow-none row m-0 p-0 align-items-lg-end">
                                      <div class="d-lg-flex px-0">
                                        <div class="details mb15-md-md">
                                          <h5 class="title mb10">
                                            Video animator to bring some
                                            illustrations to life
                                          </h5>
                                          <p class="mb-0 fz14 list-inline-item mb5-sm pe-1">
                                            <i class="flaticon-place fz16 vam text-thm2 me-1"></i>{" "}
                                            London, UK
                                          </p>
                                          <p class="mb-0 fz14 list-inline-item mb5-sm pe-1">
                                            <i class="flaticon-30-days fz16 vam text-thm2 me-1 bdrl1 pl15 pl0-xs bdrn-xs"></i>{" "}
                                            2 hours ago
                                          </p>
                                          <p class="mb-0 fz14 list-inline-item mb5-sm text-thm">
                                            <i class="flaticon-contract fz16 vam me-1 bdrl1 pl15 pl0-xs bdrn-xs"></i>{" "}
                                            1 Received
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </th>
                                  <td class="vam">
                                    <span class="fz15 fw400">
                                      Web & App Design
                                    </span>
                                  </td>
                                  <td class="vam">
                                    <span class="fz14 fw400">
                                      $500.00/Fixed
                                    </span>
                                  </td>
                                  <td>
                                    <div class="d-flex">
                                      <a
                                        href=""
                                        class="icon me-2"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        title="Edit"
                                      >
                                        <span class="flaticon-pencil"></span>
                                      </a>
                                      <a
                                        href=""
                                        class="icon"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        title="Delete"
                                      >
                                        <span class="flaticon-delete"></span>
                                      </a>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <th scope="row">
                                    <div class="freelancer-style1 box-shadow-none row m-0 p-0 align-items-lg-end">
                                      <div class="d-lg-flex px-0">
                                        <div class="details mb15-md-md">
                                          <h5 class="title mb10">
                                            Food Delviery Mobile App
                                          </h5>
                                          <p class="mb-0 fz14 list-inline-item mb5-sm pe-1">
                                            <i class="flaticon-place fz16 vam text-thm2 me-1"></i>{" "}
                                            London, UK
                                          </p>
                                          <p class="mb-0 fz14 list-inline-item mb5-sm pe-1">
                                            <i class="flaticon-30-days fz16 vam text-thm2 me-1 bdrl1 pl15 pl0-xs bdrn-xs"></i>{" "}
                                            2 hours ago
                                          </p>
                                          <p class="mb-0 fz14 list-inline-item mb5-sm text-thm">
                                            <i class="flaticon-contract fz16 vam me-1 bdrl1 pl15 pl0-xs bdrn-xs"></i>{" "}
                                            1 Received
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </th>
                                  <td class="vam">
                                    <span class="fz15 fw400">
                                      Web & App Design
                                    </span>
                                  </td>
                                  <td class="vam">
                                    <span class="fz14 fw400">
                                      $500.00/Fixed
                                    </span>
                                  </td>
                                  <td>
                                    <div class="d-flex">
                                      <a
                                        href=""
                                        class="icon me-2"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        title="Edit"
                                      >
                                        <span class="flaticon-pencil"></span>
                                      </a>
                                      <a
                                        href=""
                                        class="icon"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        title="Delete"
                                      >
                                        <span class="flaticon-delete"></span>
                                      </a>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            <div class="mbp_pagination text-center mt30">
                              <ul class="page_navigation">
                                <li class="page-item">
                                  <a class="page-link" href="#">
                                    {" "}
                                    <span class="fas fa-angle-left"></span>
                                  </a>
                                </li>
                                <li class="page-item">
                                  <a class="page-link" href="#">
                                    1
                                  </a>
                                </li>
                                <li
                                  class="page-item active"
                                  aria-current="page"
                                >
                                  <a class="page-link" href="#">
                                    2 <span class="sr-only">(current)</span>
                                  </a>
                                </li>
                                <li class="page-item">
                                  <a class="page-link" href="#">
                                    3
                                  </a>
                                </li>
                                <li class="page-item">
                                  <a class="page-link" href="#">
                                    4
                                  </a>
                                </li>
                                <li class="page-item">
                                  <a class="page-link" href="#">
                                    5
                                  </a>
                                </li>
                                <li class="page-item">
                                  <a class="page-link" href="#">
                                    ...
                                  </a>
                                </li>
                                <li class="page-item">
                                  <a class="page-link" href="#">
                                    20
                                  </a>
                                </li>
                                <li class="page-item">
                                  <a class="page-link" href="#">
                                    <span class="fas fa-angle-right"></span>
                                  </a>
                                </li>
                              </ul>
                              <p class="mt10 mb-0 pagination_page_count text-center">
                                1 – 20 of 300+ property available
                              </p>
                            </div>
                          </div>
                        </div>
                        <div
                          class="tab-pane fade"
                          id="nav-item6"
                          role="tabpanel"
                          aria-labelledby="nav-item6-tab"
                        >
                          <div class="packages_table table-responsive">
                            <table class="table-style3 table at-savesearch">
                              <thead class="t-head">
                                <tr>
                                  <th scope="col">Title</th>
                                  <th scope="col">Category</th>
                                  <th scope="col">Type/Cost</th>
                                  <th scope="col">Actions</th>
                                </tr>
                              </thead>
                              <tbody class="t-body">
                                <tr>
                                  <th scope="row">
                                    <div class="freelancer-style1 box-shadow-none row m-0 p-0 align-items-lg-end">
                                      <div class="d-lg-flex px-0">
                                        <div class="details mb15-md-md">
                                          <h5 class="title mb10">
                                            Food Delviery Mobile App
                                          </h5>
                                          <p class="mb-0 fz14 list-inline-item mb5-sm pe-1">
                                            <i class="flaticon-place fz16 vam text-thm2 me-1"></i>{" "}
                                            London, UK
                                          </p>
                                          <p class="mb-0 fz14 list-inline-item mb5-sm pe-1">
                                            <i class="flaticon-30-days fz16 vam text-thm2 me-1 bdrl1 pl15 pl0-xs bdrn-xs"></i>{" "}
                                            2 hours ago
                                          </p>
                                          <p class="mb-0 fz14 list-inline-item mb5-sm text-thm">
                                            <i class="flaticon-contract fz16 vam me-1 bdrl1 pl15 pl0-xs bdrn-xs"></i>{" "}
                                            1 Received
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </th>
                                  <td class="vam">
                                    <span class="fz15 fw400">
                                      Web & App Design
                                    </span>
                                  </td>
                                  <td class="vam">
                                    <span class="fz14 fw400">
                                      $500.00/Fixed
                                    </span>
                                  </td>
                                  <td>
                                    <div class="d-flex">
                                      <a
                                        href=""
                                        class="icon me-2"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        title="Edit"
                                      >
                                        <span class="flaticon-pencil"></span>
                                      </a>
                                      <a
                                        href=""
                                        class="icon"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        title="Delete"
                                      >
                                        <span class="flaticon-delete"></span>
                                      </a>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <th scope="row">
                                    <div class="freelancer-style1 box-shadow-none row m-0 p-0 align-items-lg-end">
                                      <div class="d-lg-flex px-0">
                                        <div class="details mb15-md-md">
                                          <h5 class="title mb10">
                                            Swift / SwiftUI Developer for B2B
                                            iOS apps
                                          </h5>
                                          <p class="mb-0 fz14 list-inline-item mb5-sm pe-1">
                                            <i class="flaticon-place fz16 vam text-thm2 me-1"></i>{" "}
                                            London, UK
                                          </p>
                                          <p class="mb-0 fz14 list-inline-item mb5-sm pe-1">
                                            <i class="flaticon-30-days fz16 vam text-thm2 me-1 bdrl1 pl15 pl0-xs bdrn-xs"></i>{" "}
                                            2 hours ago
                                          </p>
                                          <p class="mb-0 fz14 list-inline-item mb5-sm text-thm">
                                            <i class="flaticon-contract fz16 vam me-1 bdrl1 pl15 pl0-xs bdrn-xs"></i>{" "}
                                            1 Received
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </th>
                                  <td class="vam">
                                    <span class="fz15 fw400">
                                      Web & App Design
                                    </span>
                                  </td>
                                  <td class="vam">
                                    <span class="fz14 fw400">
                                      $500.00/Fixed
                                    </span>
                                  </td>
                                  <td>
                                    <div class="d-flex">
                                      <a
                                        href=""
                                        class="icon me-2"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        title="Edit"
                                      >
                                        <span class="flaticon-pencil"></span>
                                      </a>
                                      <a
                                        href=""
                                        class="icon"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        title="Delete"
                                      >
                                        <span class="flaticon-delete"></span>
                                      </a>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <th scope="row">
                                    <div class="freelancer-style1 box-shadow-none row m-0 p-0 align-items-lg-end">
                                      <div class="d-lg-flex px-0">
                                        <div class="details mb15-md-md">
                                          <h5 class="title mb10">
                                            English content writer for Fintech
                                          </h5>
                                          <p class="mb-0 fz14 list-inline-item mb5-sm pe-1">
                                            <i class="flaticon-place fz16 vam text-thm2 me-1"></i>{" "}
                                            London, UK
                                          </p>
                                          <p class="mb-0 fz14 list-inline-item mb5-sm pe-1">
                                            <i class="flaticon-30-days fz16 vam text-thm2 me-1 bdrl1 pl15 pl0-xs bdrn-xs"></i>{" "}
                                            2 hours ago
                                          </p>
                                          <p class="mb-0 fz14 list-inline-item mb5-sm text-thm">
                                            <i class="flaticon-contract fz16 vam me-1 bdrl1 pl15 pl0-xs bdrn-xs"></i>{" "}
                                            1 Received
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </th>
                                  <td class="vam">
                                    <span class="fz15 fw400">
                                      Web & App Design
                                    </span>
                                  </td>
                                  <td class="vam">
                                    <span class="fz14 fw400">
                                      $500.00/Fixed
                                    </span>
                                  </td>
                                  <td>
                                    <div class="d-flex">
                                      <a
                                        href=""
                                        class="icon me-2"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        title="Edit"
                                      >
                                        <span class="flaticon-pencil"></span>
                                      </a>
                                      <a
                                        href=""
                                        class="icon"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        title="Delete"
                                      >
                                        <span class="flaticon-delete"></span>
                                      </a>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <th scope="row">
                                    <div class="freelancer-style1 box-shadow-none row m-0 p-0 align-items-lg-end">
                                      <div class="d-lg-flex px-0">
                                        <div class="details mb15-md-md">
                                          <h5 class="title mb10">
                                            Full-stack Developer to help us to
                                            build our
                                          </h5>
                                          <p class="mb-0 fz14 list-inline-item mb5-sm pe-1">
                                            <i class="flaticon-place fz16 vam text-thm2 me-1"></i>{" "}
                                            London, UK
                                          </p>
                                          <p class="mb-0 fz14 list-inline-item mb5-sm pe-1">
                                            <i class="flaticon-30-days fz16 vam text-thm2 me-1 bdrl1 pl15 pl0-xs bdrn-xs"></i>{" "}
                                            2 hours ago
                                          </p>
                                          <p class="mb-0 fz14 list-inline-item mb5-sm text-thm">
                                            <i class="flaticon-contract fz16 vam me-1 bdrl1 pl15 pl0-xs bdrn-xs"></i>{" "}
                                            1 Received
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </th>
                                  <td class="vam">
                                    <span class="fz15 fw400">
                                      Web & App Design
                                    </span>
                                  </td>
                                  <td class="vam">
                                    <span class="fz14 fw400">
                                      $500.00/Fixed
                                    </span>
                                  </td>
                                  <td>
                                    <div class="d-flex">
                                      <a
                                        href=""
                                        class="icon me-2"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        title="Edit"
                                      >
                                        <span class="flaticon-pencil"></span>
                                      </a>
                                      <a
                                        href=""
                                        class="icon"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        title="Delete"
                                      >
                                        <span class="flaticon-delete"></span>
                                      </a>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <th scope="row">
                                    <div class="freelancer-style1 box-shadow-none row m-0 p-0 align-items-lg-end">
                                      <div class="d-lg-flex px-0">
                                        <div class="details mb15-md-md">
                                          <h5 class="title mb10">
                                            Looking for team members for web
                                            agency
                                          </h5>
                                          <p class="mb-0 fz14 list-inline-item mb5-sm pe-1">
                                            <i class="flaticon-place fz16 vam text-thm2 me-1"></i>{" "}
                                            London, UK
                                          </p>
                                          <p class="mb-0 fz14 list-inline-item mb5-sm pe-1">
                                            <i class="flaticon-30-days fz16 vam text-thm2 me-1 bdrl1 pl15 pl0-xs bdrn-xs"></i>{" "}
                                            2 hours ago
                                          </p>
                                          <p class="mb-0 fz14 list-inline-item mb5-sm text-thm">
                                            <i class="flaticon-contract fz16 vam me-1 bdrl1 pl15 pl0-xs bdrn-xs"></i>{" "}
                                            1 Received
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </th>
                                  <td class="vam">
                                    <span class="fz15 fw400">
                                      Web & App Design
                                    </span>
                                  </td>
                                  <td class="vam">
                                    <span class="fz14 fw400">
                                      $500.00/Fixed
                                    </span>
                                  </td>
                                  <td>
                                    <div class="d-flex">
                                      <a
                                        href=""
                                        class="icon me-2"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        title="Edit"
                                      >
                                        <span class="flaticon-pencil"></span>
                                      </a>
                                      <a
                                        href=""
                                        class="icon"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        title="Delete"
                                      >
                                        <span class="flaticon-delete"></span>
                                      </a>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <th scope="row">
                                    <div class="freelancer-style1 box-shadow-none row m-0 p-0 align-items-lg-end">
                                      <div class="d-lg-flex px-0">
                                        <div class="details mb15-md-md">
                                          <h5 class="title mb10">
                                            Video animator to bring some
                                            illustrations to life
                                          </h5>
                                          <p class="mb-0 fz14 list-inline-item mb5-sm pe-1">
                                            <i class="flaticon-place fz16 vam text-thm2 me-1"></i>{" "}
                                            London, UK
                                          </p>
                                          <p class="mb-0 fz14 list-inline-item mb5-sm pe-1">
                                            <i class="flaticon-30-days fz16 vam text-thm2 me-1 bdrl1 pl15 pl0-xs bdrn-xs"></i>{" "}
                                            2 hours ago
                                          </p>
                                          <p class="mb-0 fz14 list-inline-item mb5-sm text-thm">
                                            <i class="flaticon-contract fz16 vam me-1 bdrl1 pl15 pl0-xs bdrn-xs"></i>{" "}
                                            1 Received
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </th>
                                  <td class="vam">
                                    <span class="fz15 fw400">
                                      Web & App Design
                                    </span>
                                  </td>
                                  <td class="vam">
                                    <span class="fz14 fw400">
                                      $500.00/Fixed
                                    </span>
                                  </td>
                                  <td>
                                    <div class="d-flex">
                                      <a
                                        href=""
                                        class="icon me-2"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        title="Edit"
                                      >
                                        <span class="flaticon-pencil"></span>
                                      </a>
                                      <a
                                        href=""
                                        class="icon"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        title="Delete"
                                      >
                                        <span class="flaticon-delete"></span>
                                      </a>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <th scope="row">
                                    <div class="freelancer-style1 box-shadow-none row m-0 p-0 align-items-lg-end">
                                      <div class="d-lg-flex px-0">
                                        <div class="details mb15-md-md">
                                          <h5 class="title mb10">
                                            Food Delviery Mobile App
                                          </h5>
                                          <p class="mb-0 fz14 list-inline-item mb5-sm pe-1">
                                            <i class="flaticon-place fz16 vam text-thm2 me-1"></i>{" "}
                                            London, UK
                                          </p>
                                          <p class="mb-0 fz14 list-inline-item mb5-sm pe-1">
                                            <i class="flaticon-30-days fz16 vam text-thm2 me-1 bdrl1 pl15 pl0-xs bdrn-xs"></i>{" "}
                                            2 hours ago
                                          </p>
                                          <p class="mb-0 fz14 list-inline-item mb5-sm text-thm">
                                            <i class="flaticon-contract fz16 vam me-1 bdrl1 pl15 pl0-xs bdrn-xs"></i>{" "}
                                            1 Received
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </th>
                                  <td class="vam">
                                    <span class="fz15 fw400">
                                      Web & App Design
                                    </span>
                                  </td>
                                  <td class="vam">
                                    <span class="fz14 fw400">
                                      $500.00/Fixed
                                    </span>
                                  </td>
                                  <td>
                                    <div class="d-flex">
                                      <a
                                        href=""
                                        class="icon me-2"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        title="Edit"
                                      >
                                        <span class="flaticon-pencil"></span>
                                      </a>
                                      <a
                                        href=""
                                        class="icon"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        title="Delete"
                                      >
                                        <span class="flaticon-delete"></span>
                                      </a>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            <div class="mbp_pagination text-center mt30">
                              <ul class="page_navigation">
                                <li class="page-item">
                                  <a class="page-link" href="#">
                                    {" "}
                                    <span class="fas fa-angle-left"></span>
                                  </a>
                                </li>
                                <li class="page-item">
                                  <a class="page-link" href="#">
                                    1
                                  </a>
                                </li>
                                <li
                                  class="page-item active"
                                  aria-current="page"
                                >
                                  <a class="page-link" href="#">
                                    2 <span class="sr-only">(current)</span>
                                  </a>
                                </li>
                                <li class="page-item">
                                  <a class="page-link" href="#">
                                    3
                                  </a>
                                </li>
                                <li class="page-item">
                                  <a class="page-link" href="#">
                                    4
                                  </a>
                                </li>
                                <li class="page-item">
                                  <a class="page-link" href="#">
                                    5
                                  </a>
                                </li>
                                <li class="page-item">
                                  <a class="page-link" href="#">
                                    ...
                                  </a>
                                </li>
                                <li class="page-item">
                                  <a class="page-link" href="#">
                                    20
                                  </a>
                                </li>
                                <li class="page-item">
                                  <a class="page-link" href="#">
                                    <span class="fas fa-angle-right"></span>
                                  </a>
                                </li>
                              </ul>
                              <p class="mt10 mb-0 pagination_page_count text-center">
                                1 – 20 of 300+ property available
                              </p>
                            </div>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Dashboard;
