import React, { useState, useEffect, useRef } from "react";
import Swal from "sweetalert2";
import { Link, useNavigate } from "react-router-dom";
import { db, auth } from "../firebase";
import { useUserAuth } from "./Portal/Context/UserAuthContext";
import userImg from "../images/user.png";
function Navbar() {
  const { logOut, user } = useUserAuth();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleLogout = async () => {
    try {
      await logOut();
      Swal.close(); // Close the SweetAlert
      window.location.reload();
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    const fetchUserData = async () => {
      const userId = auth.currentUser ? auth.currentUser.uid : null;

      if (userId) {
        try {
          // Try fetching from the Freelancers collection
          const freelancerDocRef = db.collection("Freelancers").doc(userId);
          const freelancerDoc = await freelancerDocRef.get();

          if (freelancerDoc.exists) {
            const userData = freelancerDoc.data();
            setUserData(userData);
          } else {
            // If not found in Freelancers, try fetching from the Clients collection
            const clientDocRef = db.collection("Clients").doc(userId);
            const clientDoc = await clientDocRef.get();

            if (clientDoc.exists) {
              const userData = clientDoc.data();
              setUserData(userData);
            } else {
              console.log("User document does not exist in either collection");
            }
          }
        } catch (error) {
          console.log("Error getting user document:", error);
        }
      } else {
        console.log("User is not authenticated");
      }

      setLoading(false);
    };

    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        fetchUserData();
      } else {
        setLoading(false);
        console.log("User is not authenticated");
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  const [isVisible, setIsVisible] = useState(false);
  const divRef = useRef(null);

  const toggleDiv = () => {
    setIsVisible(!isVisible);
  };

  const handleClickOutside = (event) => {
    if (divRef.current && !divRef.current.contains(event.target)) {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    if (isVisible) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isVisible]);

  if (loading) {
    return <div class="preloader"></div>;
  }

  return (
    <>
      <header class="header-nav nav-innerpage-style stricky main-menu border-0 fixed-top">
        {/* <!-- Ace Responsive Menu --> */}
        <nav class="posr">
          <div class="container posr menu_bdrt1">
            <div class="row align-items-center justify-content-between">
              <div class="col-auto px-0">
                <div class="d-flex align-items-center justify-content-between">
                  <div class="logos">
                    <Link class="header-logo logo2" to="/">
                      <img src="images/header-logo3.svg" alt="Falconxx.in" />
                    </Link>
                  </div>
                  {/* <div class="home1_style">
                    <div id="mega-menu">
                      <a class="btn-mega fw500" href="#">
                        <span class="pl30 pl10-xl pr5 fz15 vam flaticon-menu"></span>
                        Categories
                      </a>
                      <ul class="menu ps-0">
                        <li>
                          <a class="dropdown">
                            <span class="menu-icn flaticon-developer"></span>
                            <span class="menu-title">Development & IT</span>
                          </a>
                          <div
                            class="drop-menu d-flex justify-content-between"
                            style={{ height: "70vh", overflowY: "scroll" }}
                          >
                            <div class="one-fourth">
                              <div class="h6 cat-title">
                                Data Science &amp; Analytics
                              </div>
                              <ul class="ps-0 mb30">
                                <li>
                                  <a>Data Analyst</a>
                                </li>
                                <li>
                                  <a>Data Science</a>
                                </li>
                                <li>
                                  <a>Microsoft Power BI</a>
                                </li>
                                <li>
                                  <a>Geographic Information System (GIS)</a>
                                </li>
                                <li>
                                  <a>Data Engineer</a>
                                </li>
                                <li>
                                  <a>Tableau Software</a>
                                </li>
                                <li>
                                  <a>R Jobs</a>
                                </li>
                                <li>
                                  <a>Data Visualization Jobs</a>
                                </li>
                                <li>
                                  <a>Google Analytics Jobs</a>
                                </li>
                              </ul>
                              <div class="h6 cat-title">Development</div>
                              <ul class="ps-0 mb-10">
                                <li>
                                  <a>HTML</a>
                                </li>
                                <li>
                                  <a>React.js</a>
                                </li>
                                <li>
                                  <a>Web Scraping</a>
                                </li>
                                <li>
                                  <a>Amazon Backend Developer</a>
                                </li>
                                <li>
                                  <a>Python</a>
                                </li>
                                <li>
                                  <a>SQL </a>
                                </li>
                                <li>
                                  <a>Django</a>
                                </li>
                                <li>
                                  <a>PHP Developer </a>
                                </li>
                                <li>
                                  <a>Golang</a>
                                </li>
                                <li>
                                  <a>Ruby on Rails</a>
                                </li>
                                <li>
                                  <a>C++ Developer</a>
                                </li>
                                <li>
                                  <a>C# Programming</a>
                                </li>
                                <li>
                                  <a>Amazon Web Services</a>
                                </li>
                                <li>
                                  <a>JavaScript </a>
                                </li>
                                <li>
                                  <a>Remote UX Design</a>
                                </li>
                                <li>
                                  <a>Vue.js </a>
                                </li>
                                <li>
                                  <a>CSS </a>
                                </li>
                                <li>
                                  <a>Application Programmer </a>
                                </li>
                                <li>
                                  <a>Node.js </a>
                                </li>
                                <li>
                                  <a>Software Development </a>
                                </li>
                                <li>
                                  <a>Salesforce App Developer</a>
                                </li>
                                <li>
                                  <a>ASP.NET</a>
                                </li>
                              </ul>
                            </div>
                            <div class="one-third">
                              <div class="h6 cat-title">Emerging Tech</div>
                              <ul class="ps-0 mb40">
                                <li>
                                  <a>Machine Learning</a>
                                </li>
                                <li>
                                  <a>Ethical Hacking</a>
                                </li>
                                <li>
                                  <a>Blockchain</a>
                                </li>
                                <li>
                                  <a>Cryptocurrency</a>
                                </li>
                                <li>
                                  <a>Artificial Intelligence</a>
                                </li>
                                <li>
                                  <a>Remote Solidity</a>
                                </li>
                                <li>
                                  <a>AI Content</a>
                                </li>
                                <li>
                                  <a>ChatGPT</a>
                                </li>
                                <li>
                                  <a>GPT-3</a>
                                </li>
                                <li>
                                  <a>OpenAI Codex</a>
                                </li>
                                <li>
                                  <a>Midjourney </a>
                                </li>
                                <li>
                                  <a>StableDiffusion</a>
                                </li>
                                <li>
                                  <a>Google BERT </a>
                                </li>
                              </ul>

                              <div class="h6 cat-title">Game Development</div>
                              <ul class="ps-0 mb40">
                                <li>
                                  <a>Mobile Game Development</a>
                                </li>
                                <li>
                                  <a>Game Development</a>
                                </li>
                                <li>
                                  <a>Unity Programmer</a>
                                </li>
                              </ul>

                              <div class="h6 cat-title">
                                Mobile App Development
                              </div>
                              <ul class="ps-0 mb-0">
                                <li>
                                  <a>Flutter </a>
                                </li>
                                <li>
                                  <a>Android Developer</a>
                                </li>
                                <li>
                                  <a>Mobile App Developer</a>
                                </li>
                                <li>
                                  <a>React Native</a>
                                </li>
                                <li>
                                  <a>iOS Developer</a>
                                </li>
                              </ul>
                            </div>
                            <div class="one-third">
                              <div class="h6 cat-title">Web Development</div>
                              <ul class="ps-0 mb40">
                                <li>
                                  <a>Web Developer</a>
                                </li>
                                <li>
                                  <a>WordPress</a>
                                </li>
                                <li>
                                  <a>Shopify</a>
                                </li>
                                <li>
                                  <a>Shopify Theme Designer</a>
                                </li>
                                <li>
                                  <a>UI Designer</a>
                                </li>
                                <li>
                                  <a>Figma </a>
                                </li>
                                <li>
                                  <a>Web Design</a>
                                </li>
                                <li>
                                  <a>Webflow </a>
                                </li>
                                <li>
                                  <a>WiX </a>
                                </li>
                                <li>
                                  <a>Elementor </a>
                                </li>
                                <li>
                                  <a>Ecommerce Development</a>
                                </li>
                                <li>
                                  <a>Frontend Developer</a>
                                </li>
                              </ul>

                              <div class="h6 cat-title">Other</div>
                              <ul class="ps-0 mb-0">
                                <li>
                                  <a>Excel </a>
                                </li>
                                <li>
                                  <a>VBA </a>
                                </li>
                                <li>
                                  <a>DevOps Engineering</a>
                                </li>
                                <li>
                                  <a>IT Outsourcing</a>
                                </li>
                                <li>
                                  <a>Bug Testing</a>
                                </li>
                                <li>
                                  <a>Remote QA </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </li>
                        <li>
                          <a class="dropdown">
                            <span class="menu-icn flaticon-web-design-1"></span>
                            <span class="menu-title">Design & Creative</span>
                          </a>
                          <div
                            class="drop-menu d-flex justify-content-between"
                            style={{ height: "70vh", overflowY: "scroll" }}
                          >
                            <div class="one-third">
                              <div class="h6 cat-title">Animation</div>
                              <ul class="ps-0 mb40">
                                <li>
                                  <a>Animation</a>
                                </li>
                                <li>
                                  <a>Blender3D</a>
                                </li>
                                <li>
                                  <a>2D Animator</a>
                                </li>
                                <li>
                                  <a>VFX Animation</a>
                                </li>
                                <li>
                                  <a>3D Animation</a>
                                </li>
                                <li>
                                  <a>Whiteboard Animation Video</a>
                                </li>
                                <li>
                                  <a>Video Animation</a>
                                </li>
                              </ul>
                              <div class="h6 cat-title">Illustration</div>
                              <ul class="ps-0 mb-0">
                                <li>
                                  <a>Illustration</a>
                                </li>
                                <li>
                                  <a>Drawing </a>
                                </li>
                                <li>
                                  <a>Children's Book Illustrator</a>
                                </li>
                                <li>
                                  <a>Digital Art </a>
                                </li>
                                <li>
                                  <a>Comic Art </a>
                                </li>
                                <li>
                                  <a>Pixel Art </a>
                                </li>
                                <li>
                                  <a>Textile Design </a>
                                </li>
                                <li>
                                  <a>Character Design </a>
                                </li>
                                <li>
                                  <a>Adobe Illustrator </a>
                                </li>
                                <li>
                                  <a>Game Artist </a>
                                </li>
                                <li>
                                  <a>Infographic Design </a>
                                </li>
                              </ul>
                            </div>
                            <div class="one-third">
                              <div class="h6 cat-title">Graphic Design</div>
                              <ul class="ps-0 mb40">
                                <li>
                                  <a>Graphic Design</a>
                                </li>
                                <li>
                                  <a>Photo Editor </a>
                                </li>
                                <li>
                                  <a>Logo Design</a>
                                </li>
                                <li>
                                  <a>Adobe Photoshop</a>
                                </li>
                                <li>
                                  <a>Photo Retouching</a>
                                </li>
                                <li>
                                  <a>T-Shirt Design </a>
                                </li>
                                <li>
                                  <a>Motion Graphics </a>
                                </li>
                                <li>
                                  <a>CorelDRAW</a>
                                </li>
                                <li>
                                  <a>Social Media Design </a>
                                </li>
                                <li>
                                  <a>Creative & Talent</a>
                                </li>
                                <li>
                                  <a>Pattern Maker</a>
                                </li>
                                <li>
                                  <a>Adobe After Effects</a>
                                </li>
                              </ul>

                              <div class="h6 cat-title">Audio Production</div>
                              <ul class="ps-0 mb-0">
                                <li>
                                  <a>Podcasting </a>
                                </li>
                                <li>
                                  <a>Audio Mixing</a>
                                </li>
                                <li>
                                  <a>Audio Engineering</a>
                                </li>
                                <li>
                                  <a>Audio Editing</a>
                                </li>
                              </ul>
                            </div>
                            <div class="one-third">
                              <div class="h6 cat-title">Interior Design</div>
                              <ul class="ps-0 mb40">
                                <li>
                                  <a>Architecture </a>
                                </li>
                                <li>
                                  <a>Interior Design</a>
                                </li>
                                <li>
                                  <a>Landscape Design</a>
                                </li>
                                <li>
                                  <a>CAD Designe</a>
                                </li>
                              </ul>
                              <div class="h6 cat-title">Other</div>
                              <ul class="ps-0 mb-0">
                                <li>
                                  <a>Branding </a>
                                </li>
                                <li>
                                  <a>3D Modeling</a>
                                </li>
                                <li>
                                  <a>Manga </a>
                                </li>
                                <li>
                                  <a>Scriptwriting </a>
                                </li>
                                <li>
                                  <a>Instructional Design</a>
                                </li>
                                <li>
                                  <a>Photography </a>
                                </li>
                                <li>
                                  <a>Product Photography </a>
                                </li>
                                <li>
                                  <a>Microsoft PowerPoint </a>
                                </li>
                                <li>
                                  <a>Presentation Design </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </li>
                        <li>
                          <a class="dropdown">
                            <span class="menu-icn flaticon-digital-marketing"></span>
                            <span class="menu-title">Sales & Marketing</span>
                          </a>
                          <div
                            class="drop-menu d-flex justify-content-between"
                            style={{ height: "70vh", overflowY: "scroll" }}
                          >
                            <div class="one-third">
                              <div class="h6 cat-title">Analytics</div>
                              <ul class="ps-0 mb40">
                                <li>
                                  <a>Google Tag Manager</a>
                                </li>
                                <li>
                                  <a>Data Analyst</a>
                                </li>
                                <li>
                                  <a>Google Analytics</a>
                                </li>
                                <li>
                                  <a>Marketing Strategy</a>
                                </li>
                                <li>
                                  <a>Marketing Automation</a>
                                </li>
                                <li>
                                  <a>Web Analyst</a>
                                </li>
                              </ul>
                              <div class="h6 cat-title">Content Marketing</div>
                              <ul class="ps-0 mb-0">
                                <li>
                                  <a>Blog Writing</a>
                                </li>
                                <li>
                                  <a>Content Creating</a>
                                </li>
                                <li>
                                  <a>Content Marketing</a>
                                </li>
                                <li>
                                  <a>Content Strategies</a>
                                </li>
                              </ul>
                            </div>
                            <div class="one-third">
                              <div class="h6 cat-title">Digital Marketing</div>
                              <ul class="ps-0 mb40">
                                <li>
                                  <a>Digital Marketing</a>
                                </li>
                                <li>
                                  <a>Affiliate Marketing</a>
                                </li>
                                <li>
                                  <a>Ad Posting</a>
                                </li>
                                <li>
                                  <a>Email Marketing</a>
                                </li>
                                <li>
                                  <a>Online Advertising</a>
                                </li>
                                <li>
                                  <a>Google Analytics</a>
                                </li>
                                <li>
                                  <a>Internet Marketing</a>
                                </li>
                                <li>
                                  <a>Classifieds Posting</a>
                                </li>
                                <li>
                                  <a>Spotify </a>
                                </li>
                                <li>
                                  <a>Display Marketing </a>
                                </li>
                                <li>
                                  <a>MailChimp </a>
                                </li>
                              </ul>
                              <div class="h6 cat-title">SEO/SEM</div>
                              <ul class="ps-0 mb-0">
                                <li>
                                  <a>Google Ads</a>
                                </li>
                                <li>
                                  <a>Pay Per Click</a>
                                </li>
                                <li>
                                  <a>App Store Optimization (ASO)</a>
                                </li>
                                <li>
                                  <a>Search Engine Marketing (SEM)</a>
                                </li>
                                <li>
                                  <a>SEO</a>
                                </li>
                                <li>
                                  <a>Link Building</a>
                                </li>
                              </ul>
                            </div>
                            <div class="one-third">
                              <div class="h6 cat-title">Social Media</div>
                              <ul class="ps-0 mb40">
                                <li>
                                  <a>Brand Consulting</a>
                                </li>
                                <li>
                                  <a>Market Research</a>
                                </li>
                                <li>
                                  <a>Marketing Strategy</a>
                                </li>
                                <li>
                                  <a>Kartra </a>
                                </li>
                                <li>
                                  <a>Marketing Automation</a>
                                </li>
                                <li>
                                  <a>Marketing </a>
                                </li>
                                <li>
                                  <a>Online Sales </a>
                                </li>
                                <li>
                                  <a>Event Planning </a>
                                </li>
                                <li>
                                  <a>Cold Calling </a>
                                </li>
                                <li>
                                  <a>Telemarketing </a>
                                </li>
                                <li>
                                  <a>Lead Generation </a>
                                </li>
                                <li>
                                  <a>Data Cleaning </a>
                                </li>
                                <li>
                                  <a>Prospect List </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </li>
                        <li>
                          <a class="dropdown" href="#">
                            <span class="menu-icn flaticon-translator"></span>
                            <span class="menu-title">
                              Writing & Translation
                            </span>
                          </a>
                          <div
                            class="drop-menu d-flex justify-content-between"
                            style={{ height: "70vh", overflowY: "scroll" }}
                          >
                            <div class="one-third">
                              <div class="h6 cat-title">Academic Writing</div>
                              <ul class="ps-0 mb40">
                                <li>
                                  <a>Essay Writing</a>
                                </li>
                                <li>
                                  <a>Academic Writing</a>
                                </li>
                                <li>
                                  <a>Research </a>
                                </li>
                                <li>
                                  <a>Grant Writing</a>
                                </li>
                                <li>
                                  <a>Economist </a>
                                </li>
                                <li>
                                  <a>Report Writing</a>
                                </li>
                              </ul>
                              <div class="h6 cat-title">Content Writing</div>
                              <ul class="ps-0 mb-0">
                                <li>
                                  <a>Resume </a>
                                </li>
                                <li>
                                  <a>Proposal</a>
                                </li>
                                <li>
                                  <a>Business </a>
                                </li>
                                <li>
                                  <a>Press Release</a>
                                </li>
                                <li>
                                  <a>Freelance </a>
                                </li>
                                <li>
                                  <a>Copywriting </a>
                                </li>
                                <li>
                                  <a>Content </a>
                                </li>
                                <li>
                                  <a>Ghostwriting </a>
                                </li>
                                <li>
                                  <a>Article </a>
                                </li>
                                <li>
                                  <a>Online </a>
                                </li>
                                <li>
                                  <a>Blog </a>
                                </li>
                                <li>
                                  <a>Remote Personal Trainer </a>
                                </li>
                                <li>
                                  <a>Astrology </a>
                                </li>
                                <li>
                                  <a>Legal Writing </a>
                                </li>
                                <li>
                                  <a>eBook </a>
                                </li>
                                <li>
                                  <a>Research Paper </a>
                                </li>
                                <li>
                                  <a>Article Rewriting </a>
                                </li>
                                <li>
                                  <a>Travel </a>
                                </li>
                                <li>
                                  <a>Product Description </a>
                                </li>
                                <li>
                                  <a>Fact Checker </a>
                                </li>
                                <li>
                                  <a>SEO </a>
                                </li>
                                <li>
                                  <a>Social Media Content Creator </a>
                                </li>
                                <li>
                                  <a>Wikipedia </a>
                                </li>
                                <li>
                                  <a>Fashion </a>
                                </li>
                                <li>
                                  <a>Website Copywriting </a>
                                </li>
                                <li>
                                  <a>Book </a>
                                </li>
                              </ul>
                            </div>
                            <div class="one-third">
                              <div class="h6 cat-title">Creative Writing</div>
                              <ul class="ps-0 mb40">
                                <li>
                                  <a>Short Story</a>
                                </li>
                                <li>
                                  <a>Creative </a>
                                </li>
                                <li>
                                  <a>Lyrics </a>
                                </li>
                                <li>
                                  <a>Children Story Writer</a>
                                </li>
                                <li>
                                  <a>Screenwriting </a>
                                </li>
                                <li>
                                  <a>Comedy Writing</a>
                                </li>
                                <li>
                                  <a>Speech </a>
                                </li>
                              </ul>
                              <div class="h6 cat-title">
                                Editing and Proofreading
                              </div>
                              <ul class="ps-0 mb-0">
                                <li>
                                  <a>Proofreading </a>
                                </li>
                                <li>
                                  <a>Copy Editing</a>
                                </li>
                                <li>
                                  <a>Editing </a>
                                </li>
                                <li>
                                  <a>English Proofreading </a>
                                </li>
                                <li>
                                  <a>Beta Reader </a>
                                </li>
                                <li>
                                  <a>AP Style </a>
                                </li>
                                <li>
                                  <a>Grammar </a>
                                </li>
                                <li>
                                  <a>Academic Editing </a>
                                </li>
                                <li>
                                  <a>German Proofreading </a>
                                </li>
                                <li>
                                  <a>Developmental Editing </a>
                                </li>
                                <li>
                                  <a>Book Editing </a>
                                </li>
                              </ul>
                            </div>
                            <div class="one-third">
                              <div class="h6 cat-title">Technical Writing</div>
                              <ul class="ps-0 mb40">
                                <li>
                                  <a>Technical </a>
                                </li>
                                <li>
                                  <a>Scientific </a>
                                </li>
                                <li>
                                  <a>Financial </a>
                                </li>
                                <li>
                                  <a>UX </a>
                                </li>
                                <li>
                                  <a>Technical Analysis </a>
                                </li>
                                <li>
                                  <a>White Paper</a>
                                </li>
                              </ul>
                              <div class="h6 cat-title">Translation</div>
                              <ul class="ps-0 mb-0">
                                <li>
                                  <a>Translation </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </li>
                        <li>
                          <a class="dropdown">
                            <span class="menu-icn flaticon-microphone"></span>
                            <span class="menu-title">
                              Admin & Customer Support
                            </span>
                          </a>
                          <div
                            class="drop-menu d-flex justify-content-between"
                            style={{ height: "70vh", overflowY: "scroll" }}
                          >
                            <div class="one-third">
                              <div class="h6 cat-title">Customer Service</div>
                              <ul class="ps-0 mb40">
                                <li>
                                  <a>Chat Support </a>
                                </li>
                                <li>
                                  <a>Customer Service</a>
                                </li>
                                <li>
                                  <a>Email Support</a>
                                </li>
                                <li>
                                  <a>Email Handling</a>
                                </li>
                                <li>
                                  <a>CustomerSupport</a>
                                </li>
                                <li>
                                  <a>Call Center</a>
                                </li>
                              </ul>
                              <div class="h6 cat-title">Data Entry</div>
                              <ul class="ps-0 mb-0">
                                <li>
                                  <a>Data Entry</a>
                                </li>
                                <li>
                                  <a>Typing </a>
                                </li>
                                <li>
                                  <a>Microsoft Excel</a>
                                </li>
                                <li>
                                  <a>PDF Conversion</a>
                                </li>
                                <li>
                                  <a>Data Encoder </a>
                                </li>
                                <li>
                                  <a>Remote Medical Billing</a>
                                </li>
                                <li>
                                  <a>Microsoft Word </a>
                                </li>
                                <li>
                                  <a>Spreadsheets </a>
                                </li>
                                <li>
                                  <a>Google Docs </a>
                                </li>
                                <li>
                                  <a>eBay Listing/Writing </a>
                                </li>
                                <li>
                                  <a>Web Research </a>
                                </li>
                                <li>
                                  <a>Web Data Scraping </a>
                                </li>
                                <li>
                                  <a>Data Collection </a>
                                </li>
                                <li>
                                  <a>Data Mining </a>
                                </li>
                              </ul>
                            </div>
                            <div class="one-third">
                              <div class="h6 cat-title">
                                Personal/Virtual Assistance
                              </div>
                              <ul class="ps-0 mb40">
                                <li>
                                  <a>Virtual Assistant</a>
                                </li>
                                <li>
                                  <a>Transcription </a>
                                </li>
                                <li>
                                  <a>Amazon FBA</a>
                                </li>
                                <li>
                                  <a>Microsoft PowerPoint</a>
                                </li>
                                <li>
                                  <a>Canva </a>
                                </li>
                                <li>
                                  <a>Internet Research</a>
                                </li>
                                <li>
                                  <a>Amazon Product Listing</a>
                                </li>
                                <li>
                                  <a>Administrative Support </a>
                                </li>
                                <li>
                                  <a>Drop Shipping </a>
                                </li>
                                <li>
                                  <a>Research </a>
                                </li>
                                <li>
                                  <a>Microsoft Office </a>
                                </li>
                                <li>
                                  <a>Cold Calling </a>
                                </li>
                                <li>
                                  <a>Appointment Setter </a>
                                </li>
                                <li>
                                  <a>Remote Order Processing </a>
                                </li>
                                <li>
                                  <a>Google Sheets </a>
                                </li>
                                <li>
                                  <a>Executive Assistant </a>
                                </li>
                                <li>
                                  <a>Google Slides </a>
                                </li>
                              </ul>
                              <div class="h6 cat-title">
                                Web Research & Posting
                              </div>
                              <ul class="ps-0 mb-0">
                                <li>
                                  <a>Web Scraping </a>
                                </li>
                                <li>
                                  <a>Etsy Administration</a>
                                </li>
                                <li>
                                  <a>Amazon Product Listing</a>
                                </li>
                                <li>
                                  <a>Internet Researchers</a>
                                </li>
                                <li>
                                  <a>Web Researchers</a>
                                </li>
                                <li>
                                  <a>eBay Listing/Writing</a>
                                </li>
                                <li>
                                  <a>Fact Checkers</a>
                                </li>
                              </ul>
                            </div>
                            <div class="one-third">
                              <div class="h6 cat-title">Other</div>
                              <ul class="ps-0 mb-0">
                                <li>
                                  <a>Proofreading</a>
                                </li>
                                <li>
                                  <a>Remote Project Manager </a>
                                </li>
                                <li>
                                  <a>Editing </a>
                                </li>
                                <li>
                                  <a>Remote Management</a>
                                </li>
                                <li>
                                  <a>Task Coordination</a>
                                </li>
                                <li>
                                  <a>Bookkeepers </a>
                                </li>
                                <li>
                                  <a>Presentation designers </a>
                                </li>
                                <li>
                                  <a>Google Slides Specialists </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </li>

                        <li>
                          <a class="dropdown">
                            <span class="menu-icn flaticon-ruler"></span>
                            <span class="menu-title">
                              Engineering & Architecture
                            </span>
                          </a>
                          <div
                            class="drop-menu d-flex justify-content-between"
                            style={{ height: "70vh", overflowY: "scroll" }}
                          >
                            <div class="one-third">
                              <div class="h6 cat-title">Architecture</div>
                              <ul class="ps-0 mb40">
                                <li>
                                  <a>CAD Designer</a>
                                </li>
                                <li>
                                  <a>Autodesk Revit</a>
                                </li>
                                <li>
                                  <a>Drafting </a>
                                </li>
                                <li>
                                  <a>CAD Drafting</a>
                                </li>
                                <li>
                                  <a>Floor Plan </a>
                                </li>
                                <li>
                                  <a>CAD </a>
                                </li>
                                <li>
                                  <a>ArchiCAD </a>
                                </li>
                                <li>
                                  <a>Engineering Drawing </a>
                                </li>
                                <li>
                                  <a>Interior Design </a>
                                </li>
                              </ul>
                              <div class="h6 cat-title">
                                CAD / Technical Drawings
                              </div>
                              <ul class="ps-0 mb-0">
                                <li>
                                  <a>3D Modeling</a>
                                </li>
                                <li>
                                  <a>Architecture </a>
                                </li>
                                <li>
                                  <a>SketchUp </a>
                                </li>
                                <li>
                                  <a>3D Rendering</a>
                                </li>
                                <li>
                                  <a>3D Printing </a>
                                </li>
                                <li>
                                  <a>3d Visualizer </a>
                                </li>
                                <li>
                                  <a>Architectural Rendering </a>
                                </li>
                                <li>
                                  <a>Fusion 360 </a>
                                </li>
                                <li>
                                  <a>3ds Max </a>
                                </li>
                                <li>
                                  <a>Rhinoceros 3D </a>
                                </li>
                                <li>
                                  <a>AutoCAD </a>
                                </li>
                                <li>
                                  <a>Product Design </a>
                                </li>
                              </ul>
                            </div>
                            <div class="one-third">
                              <div class="h6 cat-title">Engineering</div>
                              <ul class="ps-0 mb40">
                                <li>
                                  <a>Chemistry </a>
                                </li>
                                <li>
                                  <a>Chemical Engineering</a>
                                </li>
                                <li>
                                  <a>Electrical Engineering</a>
                                </li>
                                <li>
                                  <a>PLC Programming</a>
                                </li>
                                <li>
                                  <a>PCB Design</a>
                                </li>
                                <li>
                                  <a>MATLAB </a>
                                </li>
                                <li>
                                  <a>Arduino </a>
                                </li>
                                <li>
                                  <a>Electronics </a>
                                </li>
                                <li>
                                  <a>Circuit Design </a>
                                </li>
                                <li>
                                  <a>Robotics </a>
                                </li>
                                <li>
                                  <a>SolidWorks </a>
                                </li>
                                <li>
                                  <a>Remote Mechanical Engineering </a>
                                </li>
                                <li>
                                  <a>Mechanical Designer </a>
                                </li>
                                <li>
                                  <a>Structural Engineering </a>
                                </li>
                                <li>
                                  <a>Engineering Design </a>
                                </li>
                                <li>
                                  <a>Reverse Engineering </a>
                                </li>
                                <li>
                                  <a>ANSYS </a>
                                </li>
                                <li>
                                  <a>Industrial Design </a>
                                </li>
                                <li>
                                  <a>Civil Engineering </a>
                                </li>
                              </ul>
                            </div>
                            <div class="one-third">
                              <div class="h6 cat-title">Other</div>
                              <ul class="ps-0 mb40">
                                <li>
                                  <a>Sketching </a>
                                </li>
                                <li>
                                  <a>Contract Management </a>
                                </li>
                                <li>
                                  <a>Contract Manufacturing</a>
                                </li>
                                <li>
                                  <a>Logistics Manager </a>
                                </li>
                                <li>
                                  <a>Math Tutor</a>
                                </li>
                                <li>
                                  <a>Math</a>
                                </li>
                                <li>
                                  <a>Excel Experts</a>
                                </li>
                                <li>
                                  <a>Chemists</a>
                                </li>
                                <li>
                                  <a>Machine Learning Engineers</a>
                                </li>
                                <li>
                                  <a>Google Analytics Experts</a>
                                </li>
                                <li>
                                  <a>Mathematicians</a>
                                </li>
                                <li>
                                  <a>Pharmaceutical Industry Specialists</a>
                                </li>
                                <li>
                                  <a>Statistical Analysts </a>
                                </li>
                                <li>
                                  <a>MATLAB developers</a>
                                </li>
                                <li>
                                  <a>Geologists</a>
                                </li>
                                <li>
                                  <a>Biologists</a>
                                </li>
                                <li>
                                  <a>Algorithm developers</a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </li>
                        <li>
                          <a class="dropdown" href="#">
                            <span class="menu-icn flaticon-goal"></span>
                            <span class="menu-title">Finance & Accounting</span>
                          </a>
                          <div
                            class="drop-menu d-flex justify-content-between"
                            style={{ height: "70vh", overflowY: "scroll" }}
                          >
                            <div class="one-third">
                              <div class="h6 cat-title">Accounting</div>
                              <ul class="ps-0 mb40">
                                <li>
                                  <a>Account Reconciliation</a>
                                </li>
                                <li>
                                  <a>Accounting </a>
                                </li>
                                <li>
                                  <a>Accounts Payable Management</a>
                                </li>
                                <li>
                                  <a>Accounts Receivable</a>
                                </li>
                                <li>
                                  <a>Certified Public Accountant (CPA) </a>
                                </li>
                                <li>
                                  <a>Financial Accounting </a>
                                </li>
                                <li>
                                  <a>Financial Management </a>
                                </li>
                                <li>
                                  <a>Financial Reporting </a>
                                </li>
                                <li>
                                  <a>Intuit TurboTax </a>
                                </li>
                                <li>
                                  <a>Tax Preparation </a>
                                </li>
                                <li>
                                  <a>Wave Accounting </a>
                                </li>
                                <li>
                                  <a>Intuit QuickBooks </a>
                                </li>

                                <li>
                                  <a>Payroll Processing </a>
                                </li>
                                <li>
                                  <a>Xero Accounting </a>
                                </li>
                              </ul>
                              <div class="h6 cat-title">
                                Business Management
                              </div>
                              <ul class="ps-0 mb-0">
                                <li>
                                  <a>Business Analyst</a>
                                </li>
                                <li>
                                  <a>Business Intelligence</a>
                                </li>
                                <li>
                                  <a>Market Research</a>
                                </li>
                                <li>
                                  <a>Business </a>
                                </li>
                                <li>
                                  <a>Business Development</a>
                                </li>
                                <li>
                                  <a>Business Consultant </a>
                                </li>
                                <li>
                                  <a>Business Planner </a>
                                </li>
                                <li>
                                  <a>Consulting </a>
                                </li>
                                <li>
                                  <a>Startup Consulting </a>
                                </li>
                              </ul>
                            </div>
                            <div class="one-third">
                              <div class="h6 cat-title">Finance</div>
                              <ul class="ps-0 mb40">
                                <li>
                                  <a>Corporate Finance</a>
                                </li>
                                <li>
                                  <a>Financial Analyst</a>
                                </li>
                                <li>
                                  <a>Financial Forecaster</a>
                                </li>
                                <li>
                                  <a>Financial Modeling</a>
                                </li>
                                <li>
                                  <a>Financial Planner</a>
                                </li>
                                <li>
                                  <a>Foreign Exchange Trading</a>
                                </li>
                                <li>
                                  <a>Real Estate Financial Models</a>
                                </li>
                              </ul>
                              <div class="h6 cat-title">Other</div>
                              <ul class="ps-0 mb-0">
                                <li>
                                  <a>Crowdfunding </a>
                                </li>
                                <li>
                                  <a>Fundraising </a>
                                </li>
                                <li>
                                  <a>Venture Capital Consulting</a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div> */}
                  {/* <!-- Responsive Menu Structure--> */}
                  <ul
                    id="respMenu"
                    style={{ marginLeft: "10px" }}
                    class="ace-responsive-menu"
                    data-menu-style="horizontal"
                  >
                    <li class="visible_list">
                      <Link class="list-item" to="/">
                        <span class="title">Home</span>
                      </Link>
                    </li>

                    <li class="visible_list">
                      <Link class="list-item" to="/about_us">
                        <span class="title">About Us</span>
                      </Link>
                    </li>
                    <li class="visible_list">
                      <Link class="list-item" to="/contact_us">
                        <span class="title">Contact</span>
                      </Link>
                    </li>

                    {/* <li class="visible_list">
                      <Link class="list-item" to="/services">
                        <span class="title">Services</span>
                      </Link>
                    </li> */}

                    <li class="visible_list">
                      <Link class="list-item" to="/pricings">
                        <span class="title">Pricing</span>
                      </Link>
                    </li>

                    <li class="visible_list">
                      <Link class="list-item" to="/blogs">
                        <span class="title">Blogs</span>
                      </Link>
                    </li>

                    <li class="visible_list">
                      <Link class="list-item" to="/shop_now">
                        <span class="title">Services</span>
                      </Link>
                    </li>
                    <li class="visible_list">
                      <Link class="list-item" to="/freelancers">
                        <span class="title">Freelancers</span>
                      </Link>
                    </li>
                    <li class="visible_list">
                      <Link class="list-item" to="/projects">
                        <span class="title">Projects</span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-auto px-0">
                <div class="d-flex align-items-center">
                  <Link
                    style={{ marginLeft: "5px" }}
                    class="ud-btn btn-thm7 add-joining"
                    to="/join_us"
                  >
                    Join Us
                  </Link>

                  {/* <Link
                    style={{ marginLeft: "5px" }}
                    class="ud-btn btn-thm add-joining"
                    to="/place_order"
                  >
                    Order Project
                  </Link> */}

                  {/* <Link
                    class="ud-btn btn-thm6 add-joining"
                    style={{ marginLeft: "5px" }}
                    to="/signin"
                  >
                    Sign In
                  </Link> */}

                  {userData ? (
                    <>
                      <div
                        onClick={toggleDiv}
                        style={{
                          display: "flex",
                          cursor: "pointer",
                          marginTop: "5px",
                          marginLeft: "12px",
                        }}
                      >
                        <div>
                          <img
                            className="rounded"
                            src={
                              userData.img != null || userData.img != undefined
                                ? userData.img
                                : userImg
                            }
                            style={{
                              width: "40px",
                              height: "40px",
                              objectFit: "cover",
                            }}
                          />
                        </div>
                        <div style={{ paddingLeft: "5px" }}>
                          <h5 style={{ fontSize: "15px", marginTop: "3px" }}>
                            {userData ? userData.fname : ""}
                          </h5>
                          <h6 style={{ fontSize: "10px", marginTop: "-7px" }}>
                            {userData.designation != null ||
                            userData.designation != undefined
                              ? userData.designation
                              : "Freelancer"}
                          </h6>
                        </div>
                      </div>
                    </>
                  ) : (
                    <Link
                      to="/signin"
                      class="ud-btn btn-thm6 add-joining"
                      style={{ marginLeft: "5px", cursor: "pointer" }}
                    >
                      Sign In
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </div>
        </nav>
        {userData && userData.profilestatus == "pendingC" ? (
          <>
            <div
              style={{
                textAlign: "center",
                padding: "5px",
                color: "red",
                backgroundColor: "#ffdbd4",
              }}
            >
              <span>
                Complete your profile to start your journey{" "}
                <Link to="/profile">
                  <i class="fa fa-external-link"></i>
                </Link>
              </span>
            </div>
          </>
        ) : (
          ""
        )}
      </header>

      <div class="hiddenbar-body-ovelay"></div>

      {isVisible && (
        <div
          ref={divRef}
          className="toggleDiv"
          style={{
            height: "22vh",
            width: "15vw",
            transition: "ease-in-out 1sec",
            backgroundColor: "white",
            border: "1px solid #d6d6d6",
            borderRadius: "5px",
            position: "fixed",
            right: "30px",
            top: "60px",
            padding: "7px 0px",
            zIndex: "10000",
          }}
        >
          <div style={{ padding: "0px 18px" }}>
            <li style={{ margin: "3px 1px" }}>
              <Link to="/profile">My Profile</Link>
            </li>

            {userData.designation != "Client" ? (
              <>
                <li style={{ margin: "5px 1px" }}>
                  <Link to="/my_chats">Chats</Link>
                </li>
              </>
            ) : (
              <>
                <li style={{ margin: "5px 1px" }}>
                  <Link to="/my_projects">My Projects</Link>
                </li>

                <li style={{ margin: "5px 1px" }}>
                  <Link to="/chats">Chats</Link>
                </li>
              </>
            )}

            <hr style={{ margin: "8px 1px", backgroundColor: "grey" }} />
            <li>
              <a style={{ cursor: "pointer" }} onClick={handleLogout}>
                Log Out
              </a>
            </li>
          </div>
        </div>
      )}

      {/* <!-- Mobile Nav  --> */}
      <div id="page" class="mobilie_header_nav stylehome1 fixed-top bg-light">
        <div class="mobile-menu">
          <div class="header bdrb1">
            <div class="menu_and_widgets">
              <div class="mobile_menu_bar d-flex justify-content-between align-items-center">
                <a class="mobile_logo" href="#">
                  <img src="images/header-logo3.svg" alt="" />
                </a>
                <div class="right-side text-end">
                  <Link class="" to="/join_us">
                    Join Us
                  </Link>
                  <a class="menubar ml30" onClick={toggleMenu}>
                    <img src="images/mobile-dark-nav-icon.svg" alt="" />
                  </a>
                </div>
              </div>
            </div>
            <div class="posr">
              <div class="mobile_menu_close_btn">
                <span class="far fa-times"></span>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- /.mobile-menu --> */}
        <nav id="menu" className={isMenuOpen ? "open" : ""}>
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>

            <li>
              <Link to="/services">Services</Link>
            </li>

            <li>
              <Link to="/pricings">Pricing</Link>
            </li>

            <li>
              <Link to="/blogs">Blogs</Link>
            </li>

            <li>
              <Link to="/about_us">About Us</Link>
            </li>
            <li>
              <Link to="/contact_us">Contact Us</Link>
            </li>
            <li>
              <Link to="/shop_now">Shop</Link>
            </li>

            {/* <li>
              <Link
                style={{ color: "white" }}
                class="ud-btn btn-thm"
                to="/place_order"
              >
                Order Project
              </Link>
            </li> */}

            <li>
              <Link
                to="/signin"
                style={{ marginLeft: "5px", cursor: "pointer" }}
              >
                Sign In
              </Link>
            </li>

            {/* <!-- Only for Mobile View --> */}
          </ul>
        </nav>
      </div>
    </>
  );
}

export default Navbar;
