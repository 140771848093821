import React, { useEffect, useState, useRef } from "react";
import { db } from "../../firebase";
import {
  serverTimestamp,
  doc,
  addDoc,
  collection,
  getDoc,
  onSnapshot,
  updateDoc,
  arrayUnion,
} from "firebase/firestore";

const Chats = ({ itemId, userId }) => {
  const [chatData, setChatData] = useState([]);
  const [messageInput, setMessageInput] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);
  const chatBoxRef = useRef(null);
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const timerID = setInterval(() => tick(), 1000);
    return () => clearInterval(timerID);
  }, []);

  const tick = () => {
    setCurrentTime(new Date());
  };

  let idd = itemId + "-" + userId;

  useEffect(() => {
    const fetchChatData = async () => {
      try {
        if (itemId) {
          const chatDocRef = doc(db, "Chats", idd);
          const unsubscribe = onSnapshot(chatDocRef, (docSnapshot) => {
            if (docSnapshot.exists()) {
              setChatData(docSnapshot.data().messages || []);
            }
          });

          const userDoc = await getDoc(doc(db, "Clients", itemId));
          if (userDoc.exists()) {
            setSelectedUser(userDoc.data());
          } else {
            setSelectedUser(null);
          }

          return () => unsubscribe();
        }
      } catch (error) {
        console.error("Error fetching chat data:", error);
      }
    };

    fetchChatData();
  }, [itemId, userId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let chatDocRef;

      if (!itemId) {
        const newChatDocRef = await addDoc(collection(db, "Chats"), {
          createdAt: serverTimestamp(),
          messages: [],
        });
        chatDocRef = newChatDocRef;
      } else {
        chatDocRef = doc(db, "Chats", idd);
      }

      await updateDoc(chatDocRef, {
        messages: arrayUnion({
          message: messageInput,
          timestamp: currentTime.toLocaleString(),
          user: "Freelancer",
          userName: selectedUser.fname,
        }),
      });

      setMessageInput("");
    } catch (error) {
      console.error("Error sending message: ", error);
    }
  };

  if (!itemId) {
    return (
      <div style={{ textAlign: "center", marginTop: "20%" }}>
        Please select a freelancer to view chats.
      </div>
    );
  }

  return (
    <>
      <div id="messages_boxes">
        <div className="user_heading px-0 mx30">
          {selectedUser && (
            <div id="selected_user">
              <div className="wrap">
                <img
                  className="img-fluid mr10"
                  style={{ width: "45px", height: "45px", objectFit: "cover" }}
                  src={selectedUser.img}
                  alt={selectedUser.name}
                />
                <div className="meta d-sm-flex justify-content-sm-between align-items-center">
                  <div className="authors">
                    <h6 className="name mb-0">
                      {selectedUser.fname} {selectedUser.lname}
                    </h6>
                    <p className="preview">Active</p>
                  </div>
                  <div>
                    {/* <a
                      className="text-decoration-underline fz14 fw500 text-red ff-heading"
                      href=""
                    >
                      Delete Conversation
                    </a> */}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="inbox_chatting_box" ref={chatBoxRef}>
          <ul className="chatting_content">
            {chatData &&
              chatData.map((chat, index) => (
                <li
                  key={index}
                  className={
                    chat.user === "Client"
                      ? "sent float-start"
                      : "reply float-end"
                  }
                >
                  <div className="d-flex align-items-center mb15">
                    <div className="title fz15">
                      {chat.user != "Client" ? "You" : chat.userName}{" "}
                      <small className="ml10" style={{ fontSize: "10px" }}>
                        {chat.timestamp}
                      </small>
                    </div>
                  </div>
                  <p>{chat.message}</p>
                </li>
              ))}
          </ul>
        </div>
        <div className="mi_text">
          <div className="message_input">
            <form className="d-flex align-items-center" onSubmit={handleSubmit}>
              <input
                className="form-control"
                type="text"
                placeholder="Type a Message"
                aria-label="Search"
                value={messageInput}
                onChange={(e) => setMessageInput(e.target.value)}
              />
              <button className="btn ud-btn btn-thm" type="submit">
                Send Message<i className="fal fa-arrow-right-long"></i>
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Chats;
