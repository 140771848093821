import React from "react";
import GoToTop from "../GoToTop";
import { Link } from "react-router-dom";
function Joinus() {
  return (
    <>
      <GoToTop />
      <div class="body_content" style={{ marginTop: "10vh" }}>
        {/* <!-- Breadcumb Sections --> */}
        <section class="breadcumb-section wow fadeInUp mt40">
          <div class="cta-commmon-v1 cta-banner bgc-thm2 mx-auto maxw1600 pt120 pb120 bdrs16 position-relative overflow-hidden d-flex align-items-center mx20-lg">
            <img
              class="left-top-img wow zoomIn"
              src="images/vector-img/left-top.png"
              alt=""
            />
            <img
              class="right-bottom-img wow zoomIn"
              src="images/vector-img/right-bottom.png"
              alt=""
            />
            <div class="container">
              <div class="row">
                <div class="col-xl-5">
                  <div
                    class="position-relative wow fadeInUp"
                    data-wow-delay="300ms"
                  >
                    <h2 class="text-white">Work Your Way</h2>
                    <p class="text mb30 text-white">
                      Unleash Your Talent: Flourish as a Freelancer
                    </p>
                    <Link class="ud-btn btn-thm" to="/signup">
                      Get Started<i class="fal fa-arrow-right-long"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!-- About Section Area --> */}
        <section class="our-faq pb90 pt100">
          <div class="container">
            <div class="row wow fadeInUp">
              <div class="col-lg-4">
                <div class="vertical-tab">
                  <div class="widget_list">
                    <nav>
                      <div
                        class="nav flex-column nav-tabs text-start"
                        id="nav-tab"
                        role="tablist"
                      >
                        <button
                          class="nav-link active text-start"
                          id="nav-accountpayment-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#nav-accountpayment"
                          type="button"
                          role="tab"
                          aria-controls="nav-accountpayment"
                          aria-selected="true"
                        >
                          <span>For Partnership</span>
                        </button>
                        <button
                          class="nav-link text-start"
                          id="nav-manageother-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#nav-manageother"
                          type="button"
                          role="tab"
                          aria-controls="nav-manageother"
                          aria-selected="false"
                        >
                          <span>For Freelancing</span>
                        </button>
                      </div>
                    </nav>
                  </div>
                </div>
              </div>
              <div class="col-lg-8">
                <div class="tab-content" id="nav-tabContent">
                  <div
                    class="tab-pane fade show active"
                    id="nav-accountpayment"
                    role="tabpanel"
                    aria-labelledby="nav-accountpayment-tab"
                  >
                    <div class="for-hire">
                      <h4>For Partnership</h4>
                      <p class="text mb30">
                        Discover the power of freelance talent with FXCTS, your
                        premier marketplace for connecting with skilled
                        professionals across diverse industries. Whether you
                        need creative designers, expert developers, talented
                        writers, or marketing gurus, FXCTS has the right
                        freelancers for your project.
                        <br />
                        <br />
                        With FXCTS, finding the perfect match is effortless.
                        Explore comprehensive freelancer profiles, review
                        portfolios showcasing their expertise, and evaluate
                        client feedback to make informed hiring decisions.
                        Seamless communication channels foster collaboration,
                        ensuring smooth project progress and timely delivery.
                        <br />
                        <br />
                        Rest easy knowing that your financial transactions are
                        secure with FXCTS. Choose from a range of trusted
                        payment methods, providing convenience and peace of
                        mind.
                        <br />
                        <br />
                        Experience the agility, flexibility, and expertise that
                        freelance talent offers. Join FXCTS today and unleash
                        the potential of freelance professionals to elevate your
                        projects to new heights of success. Embrace the future
                        of work with FXCTS and achieve your business objectives
                        with confidence.
                      </p>

                      <a href="" class="ud-btn btn-thm-border mb25 me-4">
                        Get Started<i class="fal fa-arrow-right-long"></i>
                      </a>
                    </div>
                  </div>
                  <div
                    class="tab-pane fade"
                    id="nav-manageother"
                    role="tabpanel"
                    aria-labelledby="nav-manageother-tab"
                  >
                    <div class="for-hire">
                      <h4>For Freelancing</h4>
                      <p class="text mb30">
                        Partner with FXCTS: Empower the Freelance Community with
                        Your Projects
                        <br />
                        <br />
                        Are you looking to leverage the skills and expertise of
                        the freelance community for your projects? Partnering
                        with FXCTS opens up a world of possibilities. By
                        collaborating with us, you can tap into a vast pool of
                        talented freelancers and unlock their potential to
                        deliver exceptional results.
                        <br />
                        <br />
                        At FXCTS, we are committed to fostering a thriving
                        freelance ecosystem. As our partner, you gain access to
                        our extensive network of skilled professionals spanning
                        various industries. Whether you need assistance with
                        design, development, content creation, or any other
                        project requirement, our freelance community is ready to
                        deliver.
                        <br />
                        <br />
                        By providing projects through FXCTS, you empower
                        freelancers to showcase their abilities and contribute
                        to your success. You can trust our platform to
                        facilitate seamless communication, efficient project
                        management, and secure payment processes.
                        <br />
                        <br />
                        Join forces with FXCTS and harness the power of the
                        freelance community. Together, we can create meaningful
                        opportunities, foster collaboration, and drive
                        innovation. Partner with us today to witness the
                        transformative impact of connecting projects with
                        exceptional freelance talent.
                      </p>
                      <Link
                        to="/signup"
                        class="ud-btn btn-thm-border mb25 me-4"
                      >
                        Get Started<i class="fal fa-arrow-right-long"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!-- Faq Area --> */}
        <section class="our-faq pb90">
          <div class="container">
            <div class="row">
              <div class="col-lg-6 m-auto wow fadeInUp" data-wow-delay="300ms">
                <div class="main-title text-center">
                  <h2 class="title">Frequently Asked Questions</h2>
                </div>
              </div>
            </div>
            <div class="row wow fadeInUp" data-wow-delay="300ms">
              <div class="col-xl-8 mx-auto">
                <div class="ui-content">
                  <div class="accordion-style1 faq-page mb-4 mb-lg-5">
                    <div class="accordion" id="accordionExample">
                      <div class="accordion-item active">
                        <h2 class="accordion-header" id="headingOne">
                          <button
                            class="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseOne"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                          >
                            What methods of payments are supported?
                          </button>
                        </h2>
                        <div
                          id="collapseOne"
                          class="accordion-collapse collapse show"
                          aria-labelledby="headingOne"
                          data-parent="#accordionExample"
                        >
                          <div class="accordion-body">
                            We support various methods of payment to ensure
                            convenience and flexibility for our customers. Here
                            are some of the commonly accepted payment methods:
                            <br />
                            <br />
                            Credit/Debit Cards: We accept major credit and debit
                            cards, including Visa, Mastercard, American Express,
                            and Discover. This allows for secure and
                            straightforward online transactions.
                            <br />
                            <br />
                            Bank Transfers: You can make payments through direct
                            bank transfers or wire transfers. This option is
                            suitable for customers who prefer transferring funds
                            directly from their bank accounts.
                            <br />
                            <br />
                            Online Payment Platforms: We also accept payments
                            through popular online payment platforms such as
                            PayPal, Stripe, and Google Pay. These platforms
                            offer secure and convenient payment processing.
                            <br />
                            <br />
                            Mobile Wallets: If you prefer using mobile payment
                            apps, we support popular options like Apple Pay and
                            Samsung Pay. These mobile wallets enable quick and
                            contactless transactions.
                            <br />
                            <br />
                            Cryptocurrencies: In some cases, we may accept
                            payments in cryptocurrencies such as Bitcoin,
                            Ethereum, or other digital currencies. This offers
                            an additional level of privacy and security for
                            certain transactions.
                            <br />
                            <br />
                            It's important to note that the specific payment
                            methods available may vary depending on your
                            location, the nature of the transaction, and any
                            applicable regulations. We strive to offer a range
                            of payment options to accommodate different
                            preferences and ensure a smooth payment experience
                            for our customers.
                          </div>
                        </div>
                      </div>
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="headingTwo">
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseTwo"
                            aria-expanded="false"
                            aria-controls="collapseTwo"
                          >
                            Can I cancel at anytime?
                          </button>
                        </h2>
                        <div
                          id="collapseTwo"
                          class="accordion-collapse collapse"
                          aria-labelledby="headingTwo"
                          data-parent="#accordionExample"
                        >
                          <div class="accordion-body">
                            The ability to cancel depends on the specific terms
                            and conditions of the service or product you are
                            referring to. In many cases, cancellation policies
                            are determined by the provider or seller. It's
                            important to review the terms of service or purchase
                            agreement to understand the cancellation policy and
                            any associated fees or requirements.
                            <br />
                            <br />
                            Some services or subscriptions may allow for
                            cancellation at any time, while others may have
                            specific conditions or notice periods. For products,
                            cancellation policies can vary depending on factors
                            such as return policies, warranties, or refund
                            eligibility.
                            <br />
                            <br />
                            To ensure clarity and avoid any potential issues, it
                            is advisable to familiarize yourself with the
                            cancellation policy before making a purchase or
                            entering into an agreement. If you have specific
                            concerns or questions regarding cancellation, it is
                            best to reach out to the provider or seller directly
                            for further information and clarification.
                          </div>
                        </div>
                      </div>
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="headingThree">
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseThree"
                            aria-expanded="false"
                            aria-controls="collapseThree"
                          >
                            How do I get a receipt for my purchase?
                          </button>
                        </h2>
                        <div
                          id="collapseThree"
                          class="accordion-collapse collapse"
                          aria-labelledby="headingThree"
                          data-parent="#accordionExample"
                        >
                          <div class="accordion-body">
                            To obtain a receipt for your completed projects, you
                            can follow these general steps: <br />
                            <br />
                            Contact the provider or freelancer: Reach out to the
                            individual or company with whom you worked on the
                            project. They should be able to assist you in
                            obtaining a receipt.
                            <br />
                            <br />
                            Request a receipt: Clearly communicate that you
                            require a receipt for the completed project. Provide
                            any relevant details such as the project name,
                            completion date, and payment information to
                            facilitate the process.
                            <br />
                            <br />
                            Specify receipt format: If you have specific
                            requirements for the receipt format (e.g., digital
                            or physical copy, specific details to be included),
                            make sure to communicate your preferences to the
                            provider.
                            <br />
                            <br />
                            Confirm delivery method: Determine how you would
                            like to receive the receipt. It can be emailed, sent
                            via a messaging platform, or delivered through
                            postal mail, depending on your preference and the
                            provider's capabilities.
                            <br />
                            <br />
                            Review and verify the receipt: Once you receive the
                            receipt, carefully review its contents to ensure
                            accuracy. Check that all necessary information,
                            including payment details, project description, and
                            relevant dates, are included and correct.
                            <br />
                            <br />
                            Store and organize the receipt: Save a copy of the
                            receipt for your records. You may want to keep it in
                            a designated folder or file for future reference,
                            accounting purposes, or reimbursement needs.
                            <br />
                            <br />
                            Remember, the specific process and details for
                            obtaining a receipt may vary depending on the
                            provider or freelancer you worked with. It's always
                            best to communicate your request directly and
                            promptly to ensure a smooth receipt issuance
                            process.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default Joinus;
