import React, { useState, useEffect } from "react";
import GoToTop from "../../../GoToTop";
import { Link, useNavigate } from "react-router-dom";
import { db, auth } from "../../../firebase";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import Swal from "sweetalert2";

function StepC() {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const userId = auth.currentUser ? auth.currentUser.uid : null;
  const [skills, setSkills] = useState("");
  const [designation, setDesignation] = useState("");
  const [services, setServices] = useState("");
  const [rate, setRate] = useState("");
  const [languages, setLanguage] = useState("");

  useEffect(() => {
    // const setAuthPersistence = async () => {
    //   try {
    //     await auth.setPersistence("local");
    //   } catch (error) {
    //     console.log("Error setting auth persistence:", error);
    //   }
    // };

    const fetchUserData = async () => {
      if (userId) {
        try {
          const userDocRef = db.collection("Freelancers").doc(userId);
          const userDoc = await userDocRef.get();

          if (userDoc.exists) {
            const userData = userDoc.data();
            setUserData(userData);
            // Set gender, country, city, about from Firestore
            setDesignation(userData.designation || "");
            setSkills(userData.skills || "");
            setServices(userData.services || "");
            setRate(userData.rate || "");
            setLanguage(userData.languages || "");
          } else {
            // navigate("/signin");
            console.log("loading");
          }
        } catch (error) {
          console.log("Error getting user document:", error);
        }
      } else {
        console.log("loading");
      }

      setLoading(false);
    };

    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        // setAuthPersistence();
        fetchUserData();
      } else {
        setLoading(false);
        navigate("/signin");
      }
    });

    return () => unsubscribe();
  }, [navigate, userId]);

  let servicefee = 0;

  if (rate > 400) {
    servicefee = 55;
  } else if (rate > 200) {
    servicefee = 40;
  } else {
    servicefee = 25;
  }

  let service = "-₹" + servicefee;
  let totalamount = rate - servicefee;
  let total = 0;
  if (rate <= servicefee) {
    total = 0.0;
  } else {
    total = "₹" + totalamount;
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      // Create a new document in Firestore collection
      await db.collection("Freelancers").doc(userId).update({
        services,
        skills,
        designation,
        rate,
        servicefee,
        languages,
        profilestatus: "pendingC",
      });

      Swal.fire({
        title: "Success",
        text: "Hurray! Profile Updated",
        icon: "success",
      });
      navigate("/");

      // Reset form fields and close the modal
    } catch (error) {
      Swal.fire({
        title: "Error",
        text: "Something went wrong!",
        icon: "success",
      });
      console.log("Error submitting applied project:", error);
    }
  };

  if (loading) {
    return <div></div>;
  }

  return (
    <>
      <GoToTop />
      <div className="body_content" style={{ marginTop: "10vh" }}>
        {/* <!-- Breadcumb Sections --> */}
        <section className="breadcumb-section mt40">
          <div className="container">
            <div className="dashboard_title_area">
              <h2>My Profile</h2>
              <p className="text">
                Explore and update your profile with ease, managing details,
                images, and skills effortlessly for professional presentation.
              </p>
            </div>

            <div className="row">
              <div className="col-xl-12">
                <div className="ps-widget bgc-white bdrs4 p30 mb30 overflow-hidden position-relative">
                  <div className="row">
                    <form className="form-style1" onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="mb20">
                            <label className="heading-color ff-heading fw500 mb10">
                              What work are you here to do
                            </label>
                            <input
                              required
                              value={skills}
                              onChange={(e) => setSkills(e.target.value)}
                              type="text"
                              placeholder="Javascript, HTML, CSS, Figma"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="col-sm-12">
                          <div className="mb20">
                            <label className="heading-color ff-heading fw500 mb10">
                              A title to tell the world what you do.
                            </label>
                            <input
                              required
                              value={designation}
                              onChange={(e) => setDesignation(e.target.value)}
                              type="text"
                              placeholder="Software Engineer, Graphic Designer, Content Writer"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="col-sm-12">
                          <div className="mb20">
                            <label className="heading-color ff-heading fw500 mb10">
                              What are the main services you offer?
                            </label>
                            <input
                              value={services}
                              onChange={(e) => setServices(e.target.value)}
                              type="text"
                              placeholder="Graphic, ERP/CRM System"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="col-sm-12">
                          <div className="mb20">
                            <label className="heading-color ff-heading fw500 mb10">
                              Tell us which languages you speak
                            </label>
                            <input
                              value={languages}
                              onChange={(e) => setLanguage(e.target.value)}
                              type="text"
                              placeholder="English, Hindi, Bangla, Tamil"
                              className="form-control"
                            />
                          </div>
                        </div>
                      </div>

                      <div
                        className="dashboard_title_area"
                        style={{ margin: "40px 0" }}
                      >
                        <h4>Hourly rates for your services:</h4>
                      </div>
                      <div className="row">
                        <div className="col-lg-9 col-6">
                          <div className="mb20">
                            <h3 className="heading-color ff-heading fw500 mb10">
                              Hourly rate
                            </h3>
                            <p>Total amount the client will see.</p>
                          </div>
                        </div>
                        <div className="col-lg-2 col-4">
                          <div className="mb20">
                            <input
                              required
                              style={{ textAlign: "right" }}
                              value={rate}
                              onChange={(e) => setRate(e.target.value)}
                              type="number"
                              placeholder="₹0.0"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-lg-1 col-2">
                          <div className="mb20" style={{ paddingTop: "10px" }}>
                            / hour
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div className="row">
                        <div className="col-lg-9 col-6">
                          <div className="mb20">
                            <h3 className="heading-color ff-heading fw500 mb10">
                              Service fee
                            </h3>
                            <p>
                              This helps us run the platform and provide
                              services like payment protection and customer
                              support
                            </p>
                          </div>
                        </div>
                        <div className="col-lg-2 col-4">
                          <div className="mb20" style={{ display: "inline" }}>
                            <input
                              required
                              value={service}
                              style={{ textAlign: "right", border: "none" }}
                              readOnly
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-lg-1 col-2">
                          <div className="mb20" style={{ paddingTop: "10px" }}>
                            / hour
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div className="row">
                        <div className="col-lg-9 col-6">
                          <div className="mb20">
                            <h3 className="heading-color ff-heading fw500 mb10">
                              Hourly rate
                            </h3>
                            <p>Total amount the client will see.</p>
                          </div>
                        </div>
                        <div className="col-lg-2 col-4">
                          <div className="mb20">
                            <input
                              value={total}
                              readOnly
                              style={{ textAlign: "right", border: "none" }}
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-lg-1 col-2">
                          <div className="mb20" style={{ paddingTop: "10px" }}>
                            / hour
                          </div>
                        </div>
                      </div>
                      <button className="ud-btn btn-thm" type="submit">
                        Save & Continue
                        <i className="fal fa-arrow-right-long"></i>
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default StepC;
