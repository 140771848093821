import React, { useState } from "react";
import { Link } from "react-router-dom";
import GoToTop from "../../GoToTop";

function Login() {
  return (
    <>
      <GoToTop />
      <div class="body_content">
        {/* <!-- Our LogIn Area --> */}
        <section class="our-login">
          <div class="container">
            <div class="row">
              <div class="col-lg-6 m-auto wow fadeInUp" data-wow-delay="300ms">
                <div class="main-title text-center">
                  <h2 class="title">Join as a client or freelancer</h2>
                </div>
              </div>
            </div>

            <div className="container" style={{ maxWidth: "600px" }}>
              <div className="row">
                <div className="col-lg">
                  <Link to="/client_signup">
                    <div className="card signUp_card">
                      <div className="card-body">
                        <h3>I’m a client, hiring for a project</h3>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-lg">
                  <Link to="/signup">
                    <div className="card signUp_card">
                      <div className="card-body">
                        <h3>I’m a freelancer, looking for work</h3>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default Login;
