import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { db, auth } from "../../firebase";
import { onSnapshot, getDoc, doc } from "firebase/firestore";
import GoToTop from "../../GoToTop";

const truncateHTML = (html, maxLength) => {
  const div = document.createElement("div");
  div.innerHTML = html;

  let truncated = "";
  let charCount = 0;

  const traverseNodes = (node) => {
    if (charCount >= maxLength) return;

    if (node.nodeType === Node.TEXT_NODE) {
      const text = node.textContent.slice(0, maxLength - charCount);
      truncated += text;
      charCount += text.length;
    } else if (node.nodeType === Node.ELEMENT_NODE) {
      truncated += `<${node.nodeName.toLowerCase()}`;

      for (let attr of node.attributes) {
        truncated += ` ${attr.name}="${attr.value}"`;
      }
      truncated += ">";

      for (let child of node.childNodes) {
        traverseNodes(child);
        if (charCount >= maxLength) break;
      }

      truncated += `</${node.nodeName.toLowerCase()}>`;
    }
  };

  traverseNodes(div);
  return truncated;
};

function Projects() {
  const [name, setName] = useState("");
  const [projects, setProjects] = useState([]);
  const [clients, setClients] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const [userData, setUserData] = useState(null);
  const userId = auth.currentUser ? auth.currentUser.uid : null;

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        const userId = user.uid;
        try {
          const userDocRef = db.collection("Clients").doc(userId);
          const userDoc = await userDocRef.get();

          if (userDoc.exists) {
            const userData = userDoc.data();
            setUserData(userData);
            setName(userData.fname + " " + userData.lname || "");
          } else {
            console.log("User document not found");
            navigate("/signin");
          }
        } catch (error) {
          console.log("Error getting user document:", error);
        }
      } else {
        console.log("User not authenticated");
        navigate("/signin");
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, [navigate]);

  useEffect(() => {
    const unsub = onSnapshot(
      db.collection("Projects").where("user", "==", userId),
      async (snapshot) => {
        let list = [];
        let clientData = {};

        for (const docSnap of snapshot.docs) {
          const project = { id: docSnap.id, ...docSnap.data() };
          list.push(project);

          const clientRef = doc(db, "Clients", project.user);
          const clientDoc = await getDoc(clientRef);
          if (clientDoc.exists()) {
            clientData[project.user] = clientDoc.data();
          }
        }

        setProjects(list);
        setClients(clientData);
        setLoading(false);
      },

      (error) => {
        console.error(error);
      }
    );
    return () => {
      unsub();
    };
  }, []);

  const formatPrice = (price) => {
    return new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
    }).format(price);
  };

  return (
    <>
      <GoToTop />
      {/* <!-- Filter Content In Hiddn SideBar --> */}
      <div class="lefttside-hidden-bar">
        <div class="hsidebar-header bdrb1">
          <h4 class="list-title">All filters</h4>
          <div class="sidebar-close-icon">
            <span class="far fa-times"></span>
          </div>
        </div>
        <div class="hsidebar-content">
          <div class="widget-wrapper">
            <div class="sidebar-accordion">
              <div class="accordion" id="accordionExample2">
                <div class="card mb20 pb10">
                  <div class="card-header active" id="headingZero">
                    <h4>
                      <button
                        class="btn btn-link ps-0"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseZero"
                        aria-expanded="true"
                        aria-controls="collapseZero"
                      >
                        Category
                      </button>
                    </h4>
                  </div>
                  {/* <div
                    id="collapseZero"
                    class="collapse show"
                    aria-labelledby="headingZero"
                    data-parent="#accordionExample"
                  >
                    <div class="card-body card-body px-0 pt-0">
                      <div class="checkbox-style1">
                        <label class="custom_checkbox">
                          UX Designer
                          <input type="checkbox" />
                          <span class="checkmark"></span>
                          <span class="right-tags">(1,945)</span>
                        </label>
                        <label class="custom_checkbox">
                          Web Developers
                          <input type="checkbox" checked="checked" />
                          <span class="checkmark"></span>
                          <span class="right-tags">(8,136)</span>
                        </label>
                        <label class="custom_checkbox">
                          Illustrators
                          <input type="checkbox" />
                          <span class="checkmark"></span>
                          <span class="right-tags">(917)</span>
                        </label>
                        <label class="custom_checkbox">
                          Node.js
                          <input type="checkbox" />
                          <span class="checkmark"></span>
                          <span class="right-tags">(240)</span>
                        </label>
                        <label class="custom_checkbox">
                          Project Managers
                          <input type="checkbox" />
                          <span class="checkmark"></span>
                          <span class="right-tags">(2,460)</span>
                        </label>
                      </div>
                      <a class="text-thm" href="">
                        +20 more
                      </a>
                    </div>
                  </div> */}
                </div>
                <div class="card mb20 pb10">
                  <div class="card-header" id="heading011">
                    <h4>
                      <button
                        class="btn btn-link ps-0"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapse011"
                        aria-expanded="true"
                        aria-controls="collapse011"
                      >
                        Project type
                      </button>
                    </h4>
                  </div>
                  <div
                    id="collapse011"
                    class="collapse"
                    aria-labelledby="heading011"
                    data-parent="#accordionExample"
                  >
                    <div class="card-body card-body px-0 pt-0">
                      <div class="switch-style1">
                        <div class="form-check form-switch mb20">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            id="flexSwitchCheckDefault3"
                          />
                          <label
                            class="form-check-label"
                            for="flexSwitchCheckDefault3"
                          >
                            Fixed
                          </label>
                          <span class="right-tags">(1,945)</span>
                        </div>
                      </div>
                      <div class="switch-style1">
                        <div class="form-check form-switch">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            id="flexSwitchCheckDefault4"
                            checked="checked"
                          />
                          <label
                            class="form-check-label"
                            for="flexSwitchCheckDefault4"
                          >
                            Hourly
                          </label>
                          <span class="right-tags">(8,136)</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card mb20 pb0">
                  <div class="card-header" id="headingOnes">
                    <h4>
                      <button
                        class="btn btn-link ps-0"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOnes"
                        aria-expanded="true"
                        aria-controls="collapseOnes"
                      >
                        Price
                      </button>
                    </h4>
                  </div>
                  <div
                    id="collapseOnes"
                    class="collapse"
                    aria-labelledby="headingOnes"
                    data-parent="#accordionExample"
                  >
                    <div class="card-body card-body px-0 pt-0">
                      {/* <!-- Range Slider Mobile Version --> */}
                      <div class="range-slider-style2">
                        <div class="range-wrapper">
                          <div class="mb10 mt15" id="slider"></div>
                          <div class="d-flex align-items-center justify-content-center">
                            <span id="slider-range-value1"></span>
                            <i class="fa-sharp fa-solid fa-minus mx-2 dark-color icon"></i>
                            <span id="slider-range-value2"></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card mb20 pb10">
                  <div class="card-header" id="headingZero2">
                    <h4>
                      <button
                        class="btn btn-link ps-0"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseZero2"
                        aria-expanded="true"
                        aria-controls="collapseZero2"
                      >
                        Skills
                      </button>
                    </h4>
                  </div>
                  <div
                    id="collapseZero2"
                    class="collapse"
                    aria-labelledby="headingZero2"
                    data-parent="#accordionExample"
                  >
                    <div class="card-body card-body px-0 pt-0">
                      <div class="checkbox-style1">
                        <label class="custom_checkbox">
                          Adobe Photoshop
                          <input type="checkbox" />
                          <span class="checkmark"></span>
                          <span class="right-tags">(1,945)</span>
                        </label>
                        <label class="custom_checkbox">
                          Figma
                          <input type="checkbox" checked="checked" />
                          <span class="checkmark"></span>
                          <span class="right-tags">(8,136)</span>
                        </label>
                        <label class="custom_checkbox">
                          Sketch
                          <input type="checkbox" />
                          <span class="checkmark"></span>
                          <span class="right-tags">(917)</span>
                        </label>
                        <label class="custom_checkbox">
                          Adobe XD
                          <input type="checkbox" />
                          <span class="checkmark"></span>
                          <span class="right-tags">(240)</span>
                        </label>
                        <label class="custom_checkbox">
                          Balsamiq
                          <input type="checkbox" />
                          <span class="checkmark"></span>
                          <span class="right-tags">(2,460)</span>
                        </label>
                      </div>
                      <a class="text-thm" href="">
                        +20 more
                      </a>
                    </div>
                  </div>
                </div>
                <div class="card mb20 pb5">
                  <div class="card-header" id="headingTwos">
                    <h4>
                      <button
                        class="btn btn-link ps-0"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwos"
                        aria-expanded="true"
                        aria-controls="collapseTwos"
                      >
                        Location
                      </button>
                    </h4>
                  </div>
                  <div
                    id="collapseTwos"
                    class="collapse"
                    aria-labelledby="headingTwos"
                    data-parent="#accordionExample"
                  >
                    <div class="card-body card-body px-0 pt-0">
                      <div class="search_area mb15">
                        <input
                          type="text"
                          class="form-control"
                          placeholder="What are you looking for?"
                        />
                        <label>
                          <span class="flaticon-loupe"></span>
                        </label>
                      </div>
                      <div class="checkbox-style1 mb15">
                        <label class="custom_checkbox">
                          United States
                          <input type="checkbox" />
                          <span class="checkmark"></span>
                          <span class="right-tags">(1,945)</span>
                        </label>
                        <label class="custom_checkbox">
                          United Kingdom
                          <input type="checkbox" checked="checked" />
                          <span class="checkmark"></span>
                          <span class="right-tags">(8,136)</span>
                        </label>
                        <label class="custom_checkbox">
                          Canada
                          <input type="checkbox" />
                          <span class="checkmark"></span>
                          <span class="right-tags">(917)</span>
                        </label>
                        <label class="custom_checkbox">
                          Germany
                          <input type="checkbox" />
                          <span class="checkmark"></span>
                          <span class="right-tags">(240)</span>
                        </label>
                        <label class="custom_checkbox">
                          Turkey
                          <input type="checkbox" />
                          <span class="checkmark"></span>
                          <span class="right-tags">((2,460)</span>
                        </label>
                      </div>
                      <a class="text-thm" href="">
                        +20 more
                      </a>
                    </div>
                  </div>
                </div>
                <div class="card mb20 pb5">
                  <div class="card-header" id="headingThrees">
                    <h4>
                      <button
                        class="btn btn-link ps-0"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThrees"
                        aria-expanded="true"
                        aria-controls="collapseThrees"
                      >
                        Languange
                      </button>
                    </h4>
                  </div>
                  <div
                    id="collapseThrees"
                    class="collapse"
                    aria-labelledby="headingThrees"
                    data-parent="#accordionExample"
                  >
                    <div class="card-body card-body px-0 pt-0">
                      <div class="checkbox-style1 mb15">
                        <label class="custom_checkbox">
                          Turkish
                          <input type="checkbox" />
                          <span class="checkmark"></span>
                          <span class="right-tags">(1,945)</span>
                        </label>
                        <label class="custom_checkbox">
                          English
                          <input type="checkbox" checked="checked" />
                          <span class="checkmark"></span>
                          <span class="right-tags">(8,136)</span>
                        </label>
                        <label class="custom_checkbox">
                          Italian
                          <input type="checkbox" />
                          <span class="checkmark"></span>
                          <span class="right-tags">(917)</span>
                        </label>
                        <label class="custom_checkbox">
                          Spanish
                          <input type="checkbox" />
                          <span class="checkmark"></span>
                          <span class="right-tags">(240)</span>
                        </label>
                      </div>
                      <a class="text-thm" href="">
                        +20 more
                      </a>
                    </div>
                  </div>
                </div>
                <div class="card mb20 pb5">
                  <div class="card-header" id="headingFours">
                    <h4>
                      <button
                        class="btn btn-link ps-0"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFours"
                        aria-expanded="true"
                        aria-controls="collapseFours"
                      >
                        English Level
                      </button>
                    </h4>
                  </div>
                  <div
                    id="collapseFours"
                    class="collapse"
                    aria-labelledby="headingFours"
                    data-parent="#accordionExample"
                  >
                    <div class="card-body card-body px-0 pt-0">
                      <div class="checkbox-style1">
                        <label class="custom_checkbox">
                          Basic level
                          <input type="checkbox" />
                          <span class="checkmark"></span>
                          <span class="right-tags">(1,945)</span>
                        </label>
                        <label class="custom_checkbox">
                          Bilingual
                          <input type="checkbox" checked="checked" />
                          <span class="checkmark"></span>
                          <span class="right-tags">(8,136)</span>
                        </label>
                        <label class="custom_checkbox">
                          Fluent
                          <input type="checkbox" />
                          <span class="checkmark"></span>
                          <span class="right-tags">(917)</span>
                        </label>
                        <label class="custom_checkbox">
                          Native
                          <input type="checkbox" />
                          <span class="checkmark"></span>
                          <span class="right-tags">(240)</span>
                        </label>
                      </div>
                      <a class="text-thm" href="">
                        +20 more
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="body_content">
        <section class="breadcumb-section">
          <div class="container">
            <div class="row">
              <div class="col-lg-12">
                <div class="breadcumb-style1">
                  <div class="breadcumb-list">
                    <Link to="/">Home</Link>
                    <a href="">Projects</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="breadcumb-section pt-10">
          <div class="container">
            <div class="row wow fadeInUp">
              <div class="col-xl-7">
                <div class="position-relative">
                  <h2>My Projects</h2>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="pt30 pb90">
          <div class="container">
            <div class="row">
              <div class="col-lg-12">
                <div class="row align-items-center mb20">
                  <div class="col-md-9">
                    <div class="text-center text-md-start">
                      <p class="text mb-0 mb10-sm">
                        <span class="fw500">{projects.length}</span> projects
                        available
                      </p>
                    </div>
                  </div>
                  <div class="col-md-3" style={{ textAlign: "right" }}>
                    <Link to="/create_project_" class="ud-btn btn-dark">
                      Add Project
                    </Link>
                  </div>
                  {/* <div class="col-md-6">
                    <div class="page_control_shorting d-md-flex align-items-center justify-content-center justify-content-md-end">
                      <div class="dropdown-lists d-block d-lg-none me-2 mb10-sm">
                        <ul class="p-0 mb-0 text-center text-md-start">
                          <li>
                            <button
                              type="button"
                              class="open-btn filter-btn-left"
                            >
                              {" "}
                              <img
                                class="me-2"
                                src="images/icon/all-filter-icon.svg"
                                alt=""
                              />{" "}
                              All Filter
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div> */}
                </div>
                <div class="row">
                  {projects &&
                    projects.map((item, index) => (
                      <div class="col-md-6 col-lg-12" key={index}>
                        <div class="freelancer-style1 bdr1 hover-box-shadow row ms-0 align-items-lg-center">
                          <div class="col-lg-8 ps-0">
                            <div class="d-lg-flex bdrr1 bdrn-xl pr15 pr0-lg">
                              <div class="thumb w60 position-relative rounded-circle mb15-md">
                                <img
                                  class="rounded-circle mx-auto"
                                  style={{
                                    width: "40px",
                                    height: "40px",
                                    objectFit: "cover",
                                  }}
                                  src={
                                    clients[item.user]?.img ||
                                    "images/team/client-1.png"
                                  }
                                  alt=""
                                />
                                {/* <span class="online-badge2"></span> */}
                              </div>
                              <div class="details ml15 ml0-md mb15-md">
                                <h5 class="title mb-3">{item.title}</h5>
                                <p class="mb-0 fz14 list-inline-item mb5-sm pe-1">
                                  <i class="flaticon-place fz16 vam text-thm2 me-1"></i>{" "}
                                  {item.location || "Location not available"}
                                </p>
                                <p class="mb-0 fz14 list-inline-item mb5-sm pe-1">
                                  <i class="flaticon-30-days fz16 vam text-thm2 me-1 bdrl1 pl15 pl0-xs bdrn-xs"></i>{" "}
                                  2 hours ago
                                </p>
                                <p class="mb-0 fz14 list-inline-item mb5-sm">
                                  <i class="flaticon-contract fz16 vam text-thm2 me-1 bdrl1 pl15 pl0-xs bdrn-xs"></i>{" "}
                                  1 Received
                                </p>
                                <p
                                  class="text mt10"
                                  style={{ fontSize: "13px" }}
                                  dangerouslySetInnerHTML={{
                                    __html: truncateHTML(item.pDesc, 246),
                                  }}
                                ></p>
                                <div class="skill-tags d-flex align-items-center justify-content-start">
                                  {item.skills
                                    ? item.skills
                                        .split(",")
                                        .slice(0, 3)
                                        .map((skill, skillIndex) => (
                                          <span
                                            className="tag mx5"
                                            key={skillIndex}
                                          >
                                            {skill.trim()}{" "}
                                            {/* Trim any leading or trailing spaces */}
                                          </span>
                                        ))
                                    : "-"}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-4 ps-0 ps-xl-3 pe-0">
                            <div class="details">
                              <div class="text-lg-end">
                                <h4>
                                  ₹{item.minCost} - ₹{item.maxCost}
                                </h4>
                                <p class="text">{item.work_type} Rate</p>
                              </div>
                              <div class="d-grid mt15">
                                <a
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    navigate(`/project/${item.id}`)
                                  }
                                  class="ud-btn btn-light-thm"
                                >
                                  View Details
                                  <i class="fal fa-arrow-right-long"></i>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
                {/* <div class="row">
                  <div class="mbp_pagination mt30 text-center">
                    <ul class="page_navigation">
                      <li class="page-item">
                        <a class="page-link" href="#">
                          {" "}
                          <span class="fas fa-angle-left"></span>
                        </a>
                      </li>
                      <li class="page-item">
                        <a class="page-link" href="#">
                          1
                        </a>
                      </li>
                      <li class="page-item active" aria-current="page">
                        <a class="page-link" href="#">
                          2 <span class="sr-only">(current)</span>
                        </a>
                      </li>
                      <li class="page-item">
                        <a class="page-link" href="#">
                          3
                        </a>
                      </li>
                      <li class="page-item">
                        <a class="page-link" href="#">
                          4
                        </a>
                      </li>
                      <li class="page-item d-inline-block d-sm-none">
                        <a class="page-link" href="#">
                          ...
                        </a>
                      </li>
                      <li class="page-item">
                        <a class="page-link" href="#">
                          5
                        </a>
                      </li>
                      <li class="page-item d-none d-sm-inline-block">
                        <a class="page-link" href="#">
                          ...
                        </a>
                      </li>
                      <li class="page-item d-none d-sm-inline-block">
                        <a class="page-link" href="#">
                          20
                        </a>
                      </li>
                      <li class="page-item">
                        <a class="page-link" href="#">
                          <span class="fas fa-angle-right"></span>
                        </a>
                      </li>
                    </ul>
                    <p class="mt10 mb-0 pagination_page_count text-center">
                      1 – 20 of 300+ property available
                    </p>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default Projects;
